import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { filter, map, tap } from 'rxjs';

import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';

import { TableColumn } from '../models/table-column.interface';
import { DropdownOption } from '../mine-dropdown/mine-dropdown.interface';
import { ActionMenuOptions, MineTableHeaderOptions } from './mine-table-header.interface';
import { MineActionMenuComponent } from '../mine-action-menu/mine-action-menu.component';
import { MineTableFilterComponent } from './mine-table-filter/mine-table-filter.component';
import { MineSearchInputComponent } from '../mine-search-input/mine-search-input.component';
import { MineTableColumnPickerComponent } from './mine-table-column-picker/mine-table-column-picker.component';
import { MineButtonTertiaryComponent } from '../mine-button-tertiary/mine-button-tertiary.component';
import { ButtonTheme } from '../models/button-theme.enum';

@Component({
  standalone: true,
  selector: 'mine-table-header',
  templateUrl: './mine-table-header.component.html',
  styleUrls: ['./mine-table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule, ReactiveFormsModule, ContentPipeModule, MineSearchInputComponent, 
    MineTableColumnPickerComponent, MineTableFilterComponent, MineActionMenuComponent,
    MineButtonTertiaryComponent
  ]
})
export class MineTableHeaderComponent implements OnInit {
  readonly maxSavedViews = 10;
  buttonTheme = ButtonTheme.Inherit;

  @Input()
  options = { search: true, filter: true, results: true, columnPicker: true, actionsMenu: true } as MineTableHeaderOptions;

  @Input()
  searchPlaceholder?: string;

  @Input()
  searchInitialValue: string;

  @Input()
  filters?: FilterCategory[] = [];

  @Input()
  resultsCount?: number = 0;

  @Input()
  columns?: Map<string, TableColumn> = new Map<string, TableColumn>();

  @Input()
  actionMenuOptions?: ActionMenuOptions;

  @Input()
  disableFiltering?: boolean;

  @Input()
  savedViewsCount?: number;

  @Output()
  searchChanged = new EventEmitter<DropdownOption>();

  @Output()
  filtersChanged = new EventEmitter<FilterCategory[]>();

  @Output()
  menuActionClicked = new EventEmitter<any>();

  @Output()
  columnsChanged = new EventEmitter<Map<string, TableColumn>>();
  
  @Output()
  saveViewClicked = new EventEmitter<void>();

  currentSearch: DropdownOption;
  
  searchControl: FormControl<DropdownOption>;

  @ViewChild('tableFilters', { static: false }) tableFilters: MineTableFilterComponent;

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.options.search) {
     this.initSearch(); 
    }
  }

  private initSearch(): void {
    this.searchControl = new FormControl<DropdownOption>({ 
      value: { id: this.searchInitialValue, value: this.searchInitialValue }, 
      disabled: this.disableFiltering 
    });

    this.searchControl.valueChanges
      .pipe(
        map(option => option?.id ? option : {id: null, value: null} as DropdownOption),
				filter(option => this.currentSearch?.id !== option.id && this.currentSearch?.value !== option.value),
				tap(option => this.currentSearch = option),
				map(option => this.searchByOption(option)),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe();
	}

  private searchByOption(option: DropdownOption): void {
		if (!option || !option.id || !option.value) {
      this.searchControl.reset();
      this.searchChanged.emit({} as DropdownOption);
      return;
    }

    this.searchChanged.emit(option);
	}

  onColumnsChanged(columns: Map<string, TableColumn>): void {
    this.columnsChanged.emit(columns);
  }

  onFiltersChanged(filters: FilterCategory[]): void {
    if (this.options.search) {
      this.searchByOption({} as DropdownOption);
    }
    this.filtersChanged.emit(filters);
  }

  onMenuActionClicked(event: any): void {
    this.menuActionClicked.emit(event);
  }

  onSaveView(): void {
    this.saveViewClicked.emit();
  }
}