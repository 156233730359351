import { DestroyRef, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BehaviorSubject, map, skip, tap } from "rxjs";

import { environment } from "src/environments/environment";
import { DropdownOption } from "../shared/mine-dropdown/mine-dropdown.interface";
import { ContentPipe } from "../services/content/content.pipe";
import { LoggerService } from "../logger/logger.service";
import { TenantEnum } from "../core/models/tenant.enum";
import { FeatureFlagQuery } from "../feature-flag/state/feature-flag.query";
import { PublicFeatureFlags } from "../api/models/profile/profile-feature-flags.enum";

@Injectable({
    providedIn: 'root'
})
export class TenantService {
    private readonly loggerName: string = 'TenantService';

    private tenantSubject = new BehaviorSubject<DropdownOption>(undefined);

    constructor(
        private logger: LoggerService,
        private destoryRef: DestroyRef,
        private contentPipe: ContentPipe,
        private featureFlagQuery: FeatureFlagQuery
    ) {
        this.initTenant();
    }

    private initTenant(): void {
        this.logger.debug(this.loggerName, `Initializing tenant service`);

		const currentUrl = window.location.href;
		const currentTenant = currentUrl.includes(environment.tenant.us) ? TenantEnum.US: TenantEnum.EU;

		Object.keys(TenantEnum).forEach(key => this.tenantOptions.push(
			{ id: key, value: `${this.getFlagEmoji(TenantEnum[key])} ${this.contentPipe.transform(`common.${TenantEnum[key]}`)}` } as DropdownOption)
        );
        
        this.setTenant(currentTenant);
        this.subscribeToTenantChanges();
    }

    private subscribeToTenantChanges(): void {
        this.tenantSubject.asObservable().pipe(
            skip(1),
            tap(value => this.logger.debug(this.loggerName, `Switching tenant to: ${value.id}`)),
			map(value => this.navigateToTenant(value.id as TenantEnum)),
			takeUntilDestroyed(this.destoryRef)
		).subscribe();
    }

    private navigateToTenant(tenant: TenantEnum): void {
        const newUrl = new URL(environment.tenant[TenantEnum[tenant]]);
        newUrl.pathname = window.location.pathname;
        newUrl.search = window.location.search;
        newUrl.hash = window.location.hash;
        window.location.href = newUrl.toString();
    }

    getTenant(): DropdownOption {
        return this.tenantSubject.getValue();
    }

    setTenant(tenant: TenantEnum): void {
        const value: string = this.tenantOptionValue(tenant);

        this.tenantSubject.next({
			id: tenant,
			value
		} as DropdownOption);
    }

    get tenantOptions(): DropdownOption[] {
        return Object.keys(TenantEnum).map(key => {
            const value: string = this.tenantOptionValue(TenantEnum[key]);

            return {
                id: key,
                value
            } as DropdownOption;
        });
    }

    private tenantOptionValue(tenant: TenantEnum): string {
        const devSignInUpImproveFF = this.featureFlagQuery.getFlag(PublicFeatureFlags.DevSignInUpImprove);
        const emoji: string = this.getFlagEmoji(tenant);
        const text: string = this.contentPipe.transform(`common.${tenant}`);
        return devSignInUpImproveFF ? text : `${emoji} ${text}`;
    }
    
    private getFlagEmoji(countryCode): string {
		const codePoints = countryCode
			.toUpperCase()
			.split('')
			.map(char => 127397 + char.charCodeAt());
		
		return String.fromCodePoint(...codePoints);
	}
}