import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, map, Observable, take, tap } from 'rxjs';

import { CustomViewsQuery } from 'src/app/custom-views/state/custom-views.query';
import { ProcessActivityRolesEnum, ProcessActivityStatusEnum } from 'src/app/api/models/processing-activities/processing-activities.enum';
import { ProcessingActivitiesQuery } from '../state/processing-activities.query';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { PaToggleFilter, PaFilteringGroups, PaStatusEnum, PaFilteringCategoryEnum } from './processing-acivity-filtering.enum';
import { TableStateService } from 'src/app/shared/table-state-service/table-state.service';
import { TablesEnum } from 'src/app/shared/table-state-service/table-state.enum';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { CustomValue } from 'src/app/api/models/company-settings/custom-values.interface';

@Injectable({
  providedIn: 'root'
})
export class ProcessingActivityFilteringService {
  
  private activeFilters = new BehaviorSubject<FilterCategory[]>(null);

  constructor(
    private contentPipe: ContentPipe,
    private tableStateService: TableStateService,
    private processingActivitiesQuery: ProcessingActivitiesQuery,
    private customViewsQuery: CustomViewsQuery,
    private customValuesQuery: CustomValuesQuery,
  ) { }

  selectFilters(view: string): Observable<FilterCategory[]> {
    const savedFilters = this.getSavedFilters(view);
    const filters = [];

    filters.push(this.getDataFlow(savedFilters?.find(f => f.id === PaFilteringGroups.DataFlow), view));
    filters.push(this.getDpia(savedFilters?.find(f => f.id === PaFilteringGroups.Dpia), view));
    filters.push(this.getPaStatus(savedFilters?.find(f => f.id === PaFilteringGroups.PaStatus), view));
    filters.push(this.getRoles(savedFilters?.find(f => f.id === PaFilteringGroups.Roles)));
    return combineLatest([
      this.selectFunctionsFromServer(),
      this.customValuesQuery.selectCustomValuesByType(CustomValueTypeEnum.BusinessUnit),
    ]).pipe(
      tap(res => filters.push(this.getFunctions(res[0], res[1], savedFilters?.find(f => f.id === PaFilteringGroups.Functions), view),)),
      map(() => filters)
    );
  }

  private getSavedFilters(view: string): FilterCategory[] {
    // saved (state) filters are the most important
    const savedFilters = this.tableStateService.getTableFilters(TablesEnum.ProcessingActivities) as FilterCategory[];
    if (savedFilters) {
      return savedFilters;
    }

    // if state not saved, get filters of custom view
    const customView = this.customViewsQuery.getEntity(view);
    if (customView) {
      return [...customView.filters];
    }

    // otherwise, if view is predefined, it will be handled when init the filters
    return [];
  }

  private getPaStatus(savedPaFilters: FilterCategory, view: string): FilterCategory {
    const options = [];
    const selectedStatus = new Set<ProcessActivityStatusEnum>();
    if (savedPaFilters) {
      savedPaFilters?.options.forEach(c => selectedStatus.add(ProcessActivityStatusEnum[c.id]));
    } else {
      if (view === PaStatusEnum.Completed) {
        selectedStatus.add(ProcessActivityStatusEnum.Operational);
      }
      if (view === PaStatusEnum.NotCompleted) {
        selectedStatus.add(ProcessActivityStatusEnum.Draft);
      }
    }

    this.contentPipe.transform('ropa.statusDropdownOptions')?.forEach(option => {
      options.push({
        id: option.id, 
        label: option.value, 
        selected: selectedStatus.has(option.id)
      });
    });

    return {
        id: PaFilteringGroups.PaStatus,
        label: this.contentPipe.transform('processing-activities.statusFiltering'),
        options: options
    };
  }

  private getRoles(savedRolesFilters: FilterCategory): FilterCategory {
    const options = [];
    const selectedStatus = new Set<ProcessActivityRolesEnum>();
    const dataRoles = this.contentPipe.transform('ropa.dataRolesList');
    savedRolesFilters?.options.forEach(c => selectedStatus.add(ProcessActivityRolesEnum[c.id]));

    Object.keys(ProcessActivityRolesEnum).forEach(c => {
      const dataRole = dataRoles.find(dataRole => dataRole.key === c);
      options.push({label: dataRole.value, id: c, selected: selectedStatus.has(ProcessActivityRolesEnum[c])}); 
    });
    
    return {
        id: PaFilteringGroups.Roles,
        label: this.contentPipe.transform('processing-activities.dataRoleFiltering'),
        options: options
    };
  }

  selectFunctionsFromServer(): Observable<string[]> {
    return this.processingActivitiesQuery.functions$.pipe(
      filter(functions => !!functions?.length),
      take(1),
    );
  }

  private getFunctions(functionsFromServer: string[], customFunctions: CustomValue[], savedFunctionsFilters: FilterCategory, view: string) : FilterCategory {
    const crossFunctionalFunctionName = this.processingActivitiesQuery.CROSS_FUNCTIONAL_FUNCTION_NAME;
    const options = [];
    const selectedFunctions = new Set<string>();
    if (savedFunctionsFilters) {
      savedFunctionsFilters.options.forEach(c => selectedFunctions.add(c.id));
    } else {
      selectedFunctions.add(view);
    }

    functionsFromServer?.filter(func => func.toLowerCase() !== crossFunctionalFunctionName.toLowerCase()).forEach(func => {
      options.push({label: func, id: func, selected: selectedFunctions.has(func)}); 
    });

    customFunctions?.forEach(c => {
      options.push({label: c.name, id: c.id, selected: selectedFunctions.has(c.id)}); 
    });

    options.push({label: crossFunctionalFunctionName, id: crossFunctionalFunctionName, selected: selectedFunctions.has(crossFunctionalFunctionName)}); 

    return {
        id: PaFilteringGroups.Functions,
        label: this.contentPipe.transform('processing-activities.functions'),
        options: options
    };
  }

  private getDpia(savedDpiaFilters: FilterCategory, view: string) : FilterCategory {
    const content = this.contentPipe.transform('ropa.filterOptions')[0];
    const selectedStatus = new Set<PaToggleFilter>();
    if (savedDpiaFilters) {
      savedDpiaFilters.options.forEach(item => selectedStatus.add(item.id as PaToggleFilter));
    } else if (view === PaFilteringCategoryEnum.Dpia) {
      selectedStatus.add(PaToggleFilter.Enabled);
    }

    return {
      id: PaFilteringGroups.Dpia,
      label: content.label,
      options: [
        { label: content.options[0].label, id: PaToggleFilter.Enabled, selected: selectedStatus.has(PaToggleFilter.Enabled), },
        { label: content.options[1].label, id: PaToggleFilter.Disabled, selected: selectedStatus.has(PaToggleFilter.Disabled), },
      ]
    };
  }

  private getDataFlow(savedDataFlowFilters: FilterCategory, view: string) : FilterCategory {
    const content = this.contentPipe.transform('ropa.filterOptions')[1];
    const selectedStatus = new Set<PaToggleFilter>();
    if (savedDataFlowFilters) {
      savedDataFlowFilters?.options.forEach(item => selectedStatus.add(item.id as PaToggleFilter));
    } else if (view === PaFilteringCategoryEnum.DataFlow) {
      selectedStatus.add(PaToggleFilter.Enabled);
    }

    return {
      id: PaFilteringGroups.DataFlow,
      label: content.label,
      options: [
        { label: content.options[0].label, id: PaToggleFilter.Enabled, selected: selectedStatus.has(PaToggleFilter.Enabled), },
        { label: content.options[1].label, id: PaToggleFilter.Disabled, selected: selectedStatus.has(PaToggleFilter.Disabled), },
      ]
    };
  }

  setActiveFilters(selectedFilters: FilterCategory[]): void {
    this.activeFilters.next(selectedFilters);
  }

  selectActiveFilters(): Observable<FilterCategory[]> {
    return this.activeFilters.asObservable();
  }

  getActiveFilters(): FilterCategory[] {
    return this.activeFilters.value;
  }
}