import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@ngneat/dialog';
import { MineButtonDirective } from '@mineos/mine-ui';

import { E2EModule } from 'cypress/support/e2e.module';
import { ConfirmationDialog } from './confirmation-dialog.interface';
import { MineButtonPrimaryComponent } from '../mine-button-primary/mine-button-primary.component';
import { MineXButtonComponent } from '../mine-x-button/mine-x-button.component';
import { ButtonTheme } from '../models/button-theme.enum';
import { SafePipe } from '../pipes/safe.pipe';

@Component({
  standalone: true,
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, E2EModule, MineXButtonComponent, MineButtonDirective, MineButtonPrimaryComponent, SafePipe]
})
export class ConfirmationDialogComponent implements OnInit {
  
  okButtonTheme: ButtonTheme;

  public dialogData: ConfirmationDialog;

  constructor(private dialogRef: DialogRef) { }

  ngOnInit(): void {
    this.dialogData = this.dialogRef.data;
    this.okButtonTheme = this.dialogRef.data.okBtnTheme ?? ButtonTheme.Purple;
  }

  okClicked(): void {
		this.dialogRef.close(true);
	}

  cancelClicked(): void {
		this.dialogRef.close(false);
	}
}