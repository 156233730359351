<mine-input class="dropdown__input" [formControl]="inputCtrl" [placeholder]="placeholder" [focus]="true"></mine-input>

<ng-container *ngIf="isLoading; else loaded">
    <ng-content select="[async-skeleton]"></ng-content>
</ng-container>

<ng-template #loaded>
    <ul #dropdown class="dropdown__panel" *ngIf="(filteredOptions?.length || hasCustom || isAsync) && !dropdownWithGroups">

        <ng-container *ngIf="isOptionsViewCustom; else defaultViewOptions">
            <ng-content select="[custom-view-options]"></ng-content>
        </ng-container>

        <ng-template #defaultViewOptions>

            <li class="dropdown__option" *ngFor="let option of filteredOptions; trackBy: trackByFn"
                [attr.data-label]="option?.value" [class.dropdown__option--badge]="option?.badge"
                (click)="onOptionChanged(option)">

                <span class="dropdown__option-text"
                    [class.dropdown__option-text--ellipsis]="!!option?.badge"
                    [tp]="tooltipTpl"
                    [tpOnlyTextOverflow]="true"
                    [innerHTML]="option?.value | safe">
                </span>

                <ng-template #tooltipTpl>
                    <p [innerHTML]="option?.value | safe"></p>
                </ng-template>

                <mine-badge *ngIf="option?.badge"
                    class="dropdown__option-badge" [text]="option.badge.text" [badgeType]="option.badge.type">
                </mine-badge>
            </li>

        </ng-template>

        <ng-container *ngIf="hasCustom">
            <ng-content select="[custom-option-divider]"></ng-content>
            <li class="dropdown__option" [class.dropdown__option--badge]="option?.badge">
                <ng-content select="[custom-option]"></ng-content>
            </li>
        </ng-container>
    </ul>

    <!-- dropdown with grouped options -->
    <div class="dropdown__advanced-panel" *ngIf="(filteredOptions?.length || hasCustom) && dropdownWithGroups">

        <ng-content *ngIf="hasCustom" select="[groups-custom-option]"></ng-content>

        <mine-expansion-panel class="dropdown__expansion-panel" *ngFor="let group of filteredOptions" 
            [isOpen]="true" 
            [id]="group.id" 
            [disableContent]="group.options.length === 0">

            <div class="dropdown__group" header>
                <p>{{group.value}}</p>

                <svg class="dropdown__group-svg">
                    <use xlink:href="../../../assets/img/sprite.svg#icon-Arrow-4"></use>
                </svg>
            </div>

            <ul content>
                <li class="dropdown__option dropdown__option--inside-group" 
                    *ngFor="let option of group?.options; trackBy: trackByFn"
                    [attr.data-label]="option?.value" [class.dropdown__option--badge]="option?.badge"
                    (click)="onOptionChanged(option)">

                    <span class="dropdown__option-text"
                        [class.dropdown__option-text--ellipsis]="!!option?.badge"
                        [tp]="tooltipGroupTpl"
                        [tpOnlyTextOverflow]="true"
                        [innerHTML]="option?.value | safe">
                    </span>

                    <ng-template #tooltipGroupTpl>
                        <span [innerHTML]="option?.value | safe"></span>
                    </ng-template>

                    <mine-badge *ngIf="option?.badge"
                        class="dropdown__option-badge" [text]="option.badge.text" [badgeType]="option.badge.type">
                    </mine-badge>
                </li>

            </ul>
        </mine-expansion-panel>

        <ng-content *ngIf="hasCustom" select="[groups-custom-footer]"></ng-content>

    </div>
</ng-template>
