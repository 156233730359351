import { Injectable } from '@angular/core';
import { ContentPipe } from '../../services/content/content.pipe';
import { DropdownOption } from '../../shared/mine-dropdown/mine-dropdown.interface';
import { RiskRatingEnum } from '../models/risk-rating.enum';
import { SystemUsageEnum } from '../models/usage.enum';

@Injectable({
  providedIn: 'root'
})
export class VendorManagerService {
	readonly systemUsageEnumContent = this.contentPipe.transform('vendor-management.systemUsageEnum')[0];

  constructor(private contentPipe: ContentPipe) { }

  getUsageDropdownOptions(): DropdownOption[] {
    const dropdownOptions = [];
    let rating = 0;
    let addRating = .2;
    Object.entries(SystemUsageEnum).forEach(s => {  
      rating = rating + addRating;
      const option: DropdownOption = {id: `${rating.toPrecision(1)}`, value: this.systemUsageEnumContent[`${s[1]}`]};
      dropdownOptions.push(option);
    });

    return dropdownOptions;

  }

  getScoreDropdownValue(score: number): DropdownOption {
    
    const dropdownOptions = this.getUsageDropdownOptions();
  
    if (score === 0 || !score) {
      return;
    }
    else if (score <= 0.2) {
      return dropdownOptions[0];
    }
    else if (score <= 0.4) {
      return dropdownOptions[1];
    }
    else if (score <= 0.6) {
      return dropdownOptions[2];
    }
    else if (score <= 0.8) {
      return dropdownOptions[3];
    }
    else if (score <= 1 ) {
      return dropdownOptions[4];
    }
  }

  getScoreValue(score: number): SystemUsageEnum {  
    if (score === 0 || !score) {
      return;
    }
    else if (score <= 0.2) {
      return SystemUsageEnum.VeryLow;
    }
    else if (score <= 0.4) {
      return SystemUsageEnum.Low;
    }
    else if (score <= 0.6) {
      return SystemUsageEnum.Medium;
    }
    else if (score <= 0.8) {
      return SystemUsageEnum.High;
    }
    else if (score <= 1 ) {
      return SystemUsageEnum.Critical;
    }
  }

  getCyberPosture(score: number): RiskRatingEnum {
    if (score === 0 || !score) {
      return;
    }
    else if (score <= 20) {
      return RiskRatingEnum.Bad;
    }
    else if (score <= 40) {
      return RiskRatingEnum.Poor;
    }
    else if (score <= 60) {
      return RiskRatingEnum.Fair;
    }
    else if (score <= 80) {
      return RiskRatingEnum.Good;
    }
    else if (score <= 100) {
      return RiskRatingEnum.Excellent;
    }
  }

  getRiskRating(usage: SystemUsageEnum, postureRating: number): RiskRatingEnum {
    const arr = [
      [ RiskRatingEnum.Poor, RiskRatingEnum.Poor, RiskRatingEnum.Bad, RiskRatingEnum.Bad, RiskRatingEnum.Bad ], 
      [ RiskRatingEnum.Fair, RiskRatingEnum.Fair, RiskRatingEnum.Poor, RiskRatingEnum.Poor, RiskRatingEnum.Bad ], 
      [ RiskRatingEnum.Good, RiskRatingEnum.Good, RiskRatingEnum.Fair, RiskRatingEnum.Poor, RiskRatingEnum.Poor ], 
      [ RiskRatingEnum.Excellent, RiskRatingEnum.Good, RiskRatingEnum.Good, RiskRatingEnum.Fair, RiskRatingEnum.Fair ], 
      [ RiskRatingEnum.Excellent, RiskRatingEnum.Excellent, RiskRatingEnum.Excellent, RiskRatingEnum.Good, RiskRatingEnum.Good ], 
    ];

    const usageToColIndex = new Map<SystemUsageEnum, number>();
    usageToColIndex.set(SystemUsageEnum.VeryLow, 0);
    usageToColIndex.set(SystemUsageEnum.Low,1);
    usageToColIndex.set(SystemUsageEnum.Medium, 2);
    usageToColIndex.set(SystemUsageEnum.High, 3);
    usageToColIndex.set(SystemUsageEnum.Critical, 4);

    if (isNaN(postureRating)) {
      return null;
    }

    const ratingToRowIndex = this.getPostureRatingToEnum(postureRating);
    return arr[ratingToRowIndex][usageToColIndex.get(usage)];
  }

  private getPostureRatingToEnum(rating: number): number {
    if (rating < 60) return 0;
    if (rating < 70) return 1;
    if (rating < 80) return 2;
    if (rating < 90) return 3;
    return 4;
  }
}
