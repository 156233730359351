<div #panel class="side-panel" [@floatingInOutRight]="showPanel" [style]="panelStyle"
    (@floatingInOutRight.start)="animationDone=false" (@floatingInOutRight.done)="animationDone=true">
    <svg *ngIf="showXIcon" class="side-panel__x-svg" (click)="toggle()">
        <use xlink:href="../../../assets/img/sprite.svg#icon-Dialog-x"></use>
    </svg>
    
    <ng-content></ng-content>
</div>

<div *ngIf="backdrop" class="backdrop" 
    [state]="backdropState" [class.backdrop--visible]="backdropState === 'show'">
</div>