import { Injectable } from '@angular/core';
import { delay, Observable, switchMap } from 'rxjs';
import { AiAssessmentsService } from 'src/app/ai-assessments/services/ai-assessments.service';
import { DataMisclassificationContext, ExternalBreachContext, InternalBreachContext, OverallocationContext, RiskCalculationContext, RiskHelperType } from 'src/app/api/models/risks/risks.interface';
import { DataTypeSource } from 'src/app/api/models/systems/systems.enum';
import { FrameworkChip } from 'src/app/data-types/models/frameworks.interface';
import { DataTypesService } from 'src/app/data-types/services/data-types.service';
import { FrameworksService } from 'src/app/data-types/services/frameworks.service';
import { ImpactAssessmentService } from 'src/app/impact-assessment/services/impact-assessment.service';
import { ConvertPiiTypeToDataTypePipe } from 'src/app/shared/pipes/convert-pii-type-to-data-type.pipe';
import { SystemInstance } from 'src/app/systems/models/systems.interface';
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { DataSensitivityEnum } from 'src/app/vendor-management/models/data-sensitivity.enum';
import { VendorService } from 'src/app/vendor-management/services/vendor.service';
import { VendorManagementQuery } from 'src/app/vendor-management/vendor-managment.query';

@Injectable({
     providedIn: 'root'
})
export class RiskHelperService {

    constructor(
        private aiAssessmentsService: AiAssessmentsService,
        private vendorManagementQuery: VendorManagementQuery,
        private systemsQuery: SystemsQuery,
        private impactAssessmentService: ImpactAssessmentService,
		private vendorManagementService: VendorService,
		private piiTypeToDataTypePipe: ConvertPiiTypeToDataTypePipe,
		private dataTypesService: DataTypesService,
		private frameworksService: FrameworksService
    ) { }

    getRiskContext(assessmentId: string, riskType: RiskHelperType): Promise<RiskCalculationContext[]> {
		switch (riskType) {
			case RiskHelperType.ExternalBreach:
				return this.getExternalBreachContext(assessmentId);
			
			case RiskHelperType.InternalBreach:
				return this.getInternalBreachContext(assessmentId);

			case RiskHelperType.ExcessiveDataCollection:
				return this.getOverallocationContext(assessmentId);
			
			case RiskHelperType.DataMisclassification:
				return this.getDataMisclassificationContext(assessmentId);

			case RiskHelperType.ContractualBreach:
			default:
				return new Promise(null);
		}
	}

	private async getDataMisclassificationContext(assessmentId: string): Promise<DataMisclassificationContext[]> {
		const systems = await this.aiAssessmentsService.getSystemsByAssessmentId(assessmentId);
		return this.getDataTypesSystemsXFrameworks(systems);
	}

	private getDataTypesSystemsXFrameworks(systems: SystemInstance[]): DataMisclassificationContext[] {
		const dataTypesMap = new Map<string, { name: string, logo: string }[]>();

		const addToMap = (id: string, value: { name: string, logo: string }) => {
			if (dataTypesMap.has(id)) {
				dataTypesMap.set(id, dataTypesMap.get(id).concat([value]));
			}
			else {
				dataTypesMap.set(id, [value]);
			}
		};

		const addPiiDataType = (system: SystemInstance, dataTypeId: string) => {
			const id = this.piiTypeToDataTypePipe.transform(dataTypeId);
			addToMap(id, { name: system.name, logo: system.iconPath });
		};

		systems.forEach(system => {
			system.extendedDataTypes.forEach(dataType => {
				if (dataType.source === DataTypeSource.PIIDIscovered) {
					addPiiDataType(system, dataType.id);
				}
				else {
					addToMap(dataType.id, { name: system.name, logo: system.iconPath });
				}
			});
		});

		const uniqueExtendedDataTypes = systems.flatMap(item => item.extendedDataTypes);
		const dataTypes = this.dataTypesService.getExtendedDataTypes(uniqueExtendedDataTypes);

		return dataTypes.map(dataType => {
			return {
				dataType: dataType.name,
				systems: dataTypesMap.get(dataType.id),
				frameworks: this.getFramworksChips(dataType.frameworks.flatMap(f => f.id))
			} as DataMisclassificationContext;
		})
		.sort((a, b) => b.systems.length - a.systems.length);
	}

	private async getOverallocationContext(assessmentId: string): Promise<OverallocationContext[]> {
		const systems = await this.aiAssessmentsService.getSystemsByAssessmentId(assessmentId);
		return this.getDataTypesRcordsXFrameworks(systems);
	}

    private async getExternalBreachContext(assessmentId: string): Promise<any[]> {
		const systems = await this.aiAssessmentsService.getSystemsByAssessmentId(assessmentId);
        return this.getPostureRatingXDataSensitivity(systems);
	}

    private getPostureRatingXDataSensitivity(systems: SystemInstance[]): ExternalBreachContext[] {
		return systems.map(system => {
			return {
				system: system.systemId,
				name: system.name,
				logo: system.iconPath,
				rating: this.getCyberPosture(system.systemId),
				spinner: false,
				sesitivity: this.getDataSensitivity(system.systemId)
			} as any;
		})
		.sort(this.sortByRating);
	}

	private getCyberPosture(system: string): string | null {
		if (this.vendorManagementQuery.hasEntity(system)) {
			return this.vendorManagementQuery.getRating(system);
		}

		return null;
	}

	private async getInternalBreachContext(assessmentId: string): Promise<InternalBreachContext[]> {
		const systems = await this.aiAssessmentsService.getSystemsByAssessmentId(assessmentId);
		return this.getEmployeesXDataSensitivity(systems);
	}

	private getEmployeesXDataSensitivity(systems: SystemInstance[]): InternalBreachContext[] {
		return systems.map(system => {
			return {
				system: system.systemId,
				name: system.name,
				logo: system.iconPath,
				employees: this.systemsQuery.getAccountsConnected(system.systemId),
				sesitivity: this.getDataSensitivity(system.systemId)
			} as InternalBreachContext;
		})
		.sort((a, b) => b.employees - a.employees);
	}

	private sortByRating(a: ExternalBreachContext, b: ExternalBreachContext): number {
		const aRating = a.rating ?? 0;
		const bRating = b.rating ?? 0;

		return +bRating - +aRating;
	}

    private getDataSensitivity(systemId: string): DataSensitivityEnum {
		const dataTypes = this.systemsQuery.getSystemDataTypes(systemId);
		return this.impactAssessmentService.getSystemDataSensitivity(dataTypes);
	}

	getCyberPostureFromServer(systemId: string): Observable<string> {
		return this.vendorManagementService.getRiskFromServer(systemId).pipe(
				delay(300),
				switchMap(() => this.vendorManagementQuery.selectRating(systemId))
			);
	}

	private getDataTypesRcordsXFrameworks(systems: SystemInstance[]): OverallocationContext[] {
		const recordsMap = new Map<string, number>();

		const addToMap = (id: string, records: number) => {
			if (recordsMap.has(id)) {
				recordsMap.set(id, recordsMap.get(id) + records);
			}
			else {
				recordsMap.set(id, records ?? 0);
			}
		};

		const addPiiDataType = (systemId: string, dataTypeId: string) => {
			const records = this.systemsQuery.getDataTypeRecords(systemId, dataTypeId);
			const id = this.piiTypeToDataTypePipe.transform(dataTypeId);

			addToMap(id, records);
		};

		const addManualDataType = (dataTypeId: string) => {
			addToMap(dataTypeId, 0);
		};

		systems.forEach(system => {
			system.extendedDataTypes.forEach(dataType => {
				if (dataType.source === DataTypeSource.PIIDIscovered) {
					addPiiDataType(system.systemId, dataType.id);
				}
				else {
					addManualDataType(dataType.id);
				}
			});
		});

		const uniqueExtendedDataTypes = systems.flatMap(item => item.extendedDataTypes);
		const dataTypes = this.dataTypesService.getExtendedDataTypes(uniqueExtendedDataTypes);

		return dataTypes.map(dataType => {
			return {
				dataType: dataType.name,
				records: recordsMap.get(dataType.id),
				frameworks: this.getFramworksChips(dataType.frameworks.flatMap(f => f.id))
			} as OverallocationContext;
		})
		.sort((a, b) => b.records - a.records);
	}

	private getFramworksChips(ids: string[]): FrameworkChip[] {
		const frameworks = this.frameworksService.getFrameworks(ids);
		return frameworks.map(f => this.frameworksService.getFrameworkChip(f));
	}
}