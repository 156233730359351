import { Directive, Input, Renderer2, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[cy]'
})
export class DataCyDirective implements OnInit {

  @Input('cy') cyData: string | Array<string | number>;

  constructor(private renderer: Renderer2, private elm: ElementRef) { }

  ngOnInit(): void {
    if(this.cyData) {
      const dataCyValue = Array.isArray(this.cyData) ? this.cyData.join('-') : this.cyData;
      this.renderer.setAttribute(this.elm.nativeElement, 'data-cy', dataCyValue);
    }
  }
}