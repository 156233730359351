import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Output, EventEmitter, HostListener, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AnimationSpeedEnum } from 'src/app/animations/animation-state.enum';
import { Animations } from 'src/app/animations/animations';

@Component({
  standalone: true,
  selector: 'mine-side-panel',
  templateUrl: './mine-side-panel.component.html',
  styleUrls: ['./mine-side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  animations: [
    Animations.inOutRightAnimation('floatingInOutRight', AnimationSpeedEnum.Normal, '100%', '0', '0', '100%', 'ease'),
  ]
})
export class MineSidePanelComponent {

  @Input()
  backdrop: boolean = false;

  @Input() allowUpperElement = false;

  @Input() showXIcon = true;
  
  @Input('style') style: string = 'width: 40%;';

  @Output() panelClosed = new EventEmitter<void>();

  @ViewChild('panel') panelElement: ElementRef;

  backdropState: 'show' | 'hide' = 'hide';

  animationDone = false;

  showPanel = false;

  constructor(private cdr: ChangeDetectorRef) {}

  // open / close the panel
  toggle(): void {
    this.showPanel = !this.showPanel;
    // if panel closed - emit close event
    if (!this.showPanel) {
      this.panelClosed?.emit();
      this.backdropState = 'hide';
    }
    else {
      this.backdropState = 'show';
    }
  }

  get panelStyle() {
     return this.style;
  }

  triggerChangeDetection() {
    this.cdr.detectChanges();
  }

  @HostListener('document:keydown.escape', ['$event']) onEscape() {
    this.toggle();
  }

  @HostListener('document:click', ['$event.target'])
	onClick(targetElement) {
    if (this.allowUpperElement) {
      const clickedInside = this.panelElement?.nativeElement?.contains(targetElement);

      // check if upper dialog is presented
      if (!!document.querySelector('ngneat-dialog')) return;
  
      if (!clickedInside && this.showPanel && this.animationDone) {
        this.toggle();
      }
    }
	}
}
