import { Component, ChangeDetectionStrategy, OnInit, HostListener } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';

import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { ContentPipe } from 'src/app/services/content/content.pipe';

import { MineButtonDirective } from '@mineos/mine-ui';
import { NoWhitespaceValidator } from 'src/app/shared/validators/input-validators';
import { RisksCatalogService } from '../services/risks-catalog.service';
import { MineInputComponent } from 'src/app/shared/mine-input/mine-input.component';
import { SanitizationService } from 'src/app/shared/services/sanitization.service';
import { CustomRiskDialogContent } from './custom-risk-dialog.interface';

@Component({
  selector: 'custom-risk-dialog',
  standalone: true,
  templateUrl: './custom-risk-dialog.component.html',
  styleUrls: ['./custom-risk-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MineButtonDirective, ContentPipeModule, ReactiveFormsModule, MineInputComponent]
})

export class CustomRiskDialogComponent implements OnInit {  
  constructor(private contentPipe: ContentPipe,
    private risksCatalogService: RisksCatalogService,
    private dialogRef: DialogRef,
    private sanitizationService: SanitizationService
  ) { }

  private data: { name: string };

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    event.preventDefault();
  }

  content: CustomRiskDialogContent;
  isEdit: boolean;
  form: FormGroup;

  ngOnInit(): void {
    this.data = this.dialogRef.data;
    this.isEdit = !!this.data?.name;
    this.initContent();
    this.initForm();
  }

  closeDialog(risk: string = null): void {
    this.dialogRef.close(risk);
  }

  saveRisk() {
    if(this.form.valid) {
      this.closeDialog(this.form.value.name);
    }
  }

  get error(): string {
    const control = this.form.get('name');

    if (!control?.pristine && control?.invalid && control?.errors) {
      if (control.errors['required'] || control.errors['whitespace']) {
        return this.contentPipe.transform('common.required');
      }
      if (control.errors['duplicate']) {
        return this.contentPipe.transform('risks.riskPanel.riskNameAlreadyExistError');
      }
    }
    return '';
  }

  private initForm(): void {
    this.form = new FormGroup({
      name: new FormControl<string>(this.data?.name, [Validators.required, NoWhitespaceValidator, this.riskNameUniquenessValidator()]),
    });
  }

  private riskNameUniquenessValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => { 
      const skipValidation = this.isEdit && this.sanitizationService.sanitizeText(this.data?.name) === this.sanitizationService.sanitizeText(control?.value);

      if(skipValidation) {
        return null;
      }

      if(control && this.risksCatalogService.isCustomRiskAlreadyExist(control?.value)) {
        return { duplicate: true };
      }
      return null;
    };
  }

  private initEditContent(): void {
    this.content = {
      title: this.contentPipe.transform('risks.customRiskDialog.editCustomRiskTitle'),
      action: this.contentPipe.transform('risks.customRiskDialog.update'),
      nameLabel: this.contentPipe.transform('risks.customRiskDialog.editNameLabel'),
      cancel: this.contentPipe.transform('risks.customRiskDialog.cancel'),
      placeholder: this.contentPipe.transform('risks.customRiskDialog.namePlaceholder')
    };
  }

  private initCreateContent(): void { 
    this.content = {
      title: this.contentPipe.transform('risks.customRiskDialog.createCustomRiskTitle'),
      action: this.contentPipe.transform('risks.customRiskDialog.create'),
      nameLabel: this.contentPipe.transform('risks.customRiskDialog.createNameLabel'),
      cancel: this.contentPipe.transform('risks.customRiskDialog.cancel'),
      placeholder: this.contentPipe.transform('risks.customRiskDialog.namePlaceholder')
    };
  }

  private initContent(): void {
    if(this.isEdit) {
      this.initEditContent();
    } else {
      this.initCreateContent();
    }
  }
}
