<h2 class="mine-title-s risk__title">
    {{ content?.title }}
</h2>

<form [formGroup]="form">
    <mine-input
        id="name"
        data-cy="risk__custom-risk-name"
        [required]="true"
        [error]="error"
        [formControl]="form.get('name')"
        [placeholder]="content.placeholder">
        <span class="risk__label" mine-input-label>{{ content?.nameLabel }}</span>
    </mine-input>
</form>

<div class="risk__btn-container">
    <button
        (click)="closeDialog()"
        mine-button 
        color="secondary">
        {{ content.cancel }}
    </button>
    <button
        (click)="saveRisk()"
        data-cy="risk__btn-create-custom-risk"
        mine-button color="primary"
        [disabled]="form?.invalid">
        {{ content?.action }}
	</button>
</div>