import { Injectable, Type } from '@angular/core';
import { DialogConfig, DialogRef, DialogService } from '@ngneat/dialog';
import { AddLanguageDialogComponent } from '../automations/add-language-dialog/add-language-dialog.component';
import { OnboardingMobileCompatibilityDialogComponent } from '../onboarding/onboarding-mobile-compatibility-dialog/onboarding-mobile-compatibility-dialog.component';
import { ProfileMemberDialogComponent } from '../profile/profile-member-dialog/profile-member-dialog.component';
import { Location } from '@angular/common';
import { PreviewDialogData } from '../dsr/dsr-ticket/dsr-ticket-content/dsr-ticket-processing/dialogs/integrations-preview-dialog/integration-preview-dialog-data.interface';
import { IntegrationsPreviewDialogComponent } from '../dsr/dsr-ticket/dsr-ticket-content/dsr-ticket-processing/dialogs/integrations-preview-dialog/integrations-preview-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class DialogsManagerService {
	private dialogRef: DialogRef;
	width: string = '620px';

	constructor(
		public dialog: DialogService,
		private location: Location,
	) {
		this.closeDialogOnUrlChange();
	}

	private closeDialogOnUrlChange(): void {
		this.location.onUrlChange(() => {
			if (this.dialog.dialogs.length > 0) {
				this.dialog.closeAll();
			};
		});
	}

	private getDialogConfig(width: string, data?: any, enableClose: boolean = true): Partial<DialogConfig> {
		const config = {
			enableClose: enableClose,
			backdrop: true,
			windowClass: 'app-general-dialog-style',
			width: width,
			height: 'auto',
			minHeight: 'auto',
			closeOnNavigation: true,
			data: data,
		};
		return config;
	}

	openMobileCompatibilityDialog(exportComp: Type<OnboardingMobileCompatibilityDialogComponent>): DialogRef {
		return this.open(exportComp, this.getDialogConfig(this.width));
	}

	openMemberComponent(comp: Type<ProfileMemberDialogComponent>): DialogRef {
		const width = '403px';
		return this.open(comp, this.getDialogConfig(width));
	}

	openPreviewIntegration(data: PreviewDialogData): DialogRef {
		const width = '940px';
		return this.open(IntegrationsPreviewDialogComponent, this.getDialogConfig(width, data));
	}

	openDialogCommon<T>(component: T, data: any = null, width: string = this.width, enableClose?: boolean): DialogRef {
		return this.open(component, this.getDialogConfig(width, data, enableClose));
	}

	openAddLanguageDialog(): DialogRef {
		const width = '725px';
		return this.open(AddLanguageDialogComponent, this.getDialogConfig(width));
	}

	private open(component: any, config: Partial<DialogConfig>): DialogRef {
		// Remove the overflowX on the html tag to prevent backddrop scrolling.
		// (NgNeat class that disables it is on body tag)
		const HTMLElement = document.querySelector('html');
		HTMLElement.style.overflowX = 'initial';
		
		this.dialogRef = this.dialog.open(component, config);
		if (this.dialogRef) {
			this.dialogRef.afterClosed$.subscribe(() => {
				this.dialogRef = undefined;
				HTMLElement.style.overflowX = '';
			});
		}
		return this.dialogRef;
	}
}
