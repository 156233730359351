import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { map, Observable, shareReplay, tap } from 'rxjs';

import { Animations } from 'src/app/animations/animations';
import { CategoriesListItem } from 'src/app/shared/models/categories-list.interface';
import { CommonModule } from '@angular/common';
import { RxIf } from '@rx-angular/template/if';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { MineViewSectionComponent } from 'src/app/shared/mine-view-section/mine-view-section.component';
import { RiskCatalog, RiskRegistryFilteringCategoryEnum, RiskRegistryFilteringGroupEnum } from 'src/app/api/models/risks/risks.interface';
import { MineViewItemComponent } from 'src/app/shared/mine-view-section/mine-view-item/mine-view-item.component';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { SkeletonDirective } from 'src/app/shared/skeletons/skeleton.directive';
import { RiskRegistryTableService } from '../services/risk-registry-table.service';
import { RisksCatalogService } from '../services/risks-catalog.service';
import { FilterCategory, FilterOption } from 'src/app/core/models/filtering.interface';

const rxAngularDirectives = [
  RxIf,
  RxFor,
  RxLet,
];

@Component({
  selector: 'risk-registry-categories-list',
  templateUrl: './risk-registry-categories-list.component.html',
  styleUrls: ['./risk-registry-categories-list.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeInOut],
  imports: [
    ...rxAngularDirectives,
    CommonModule,
    ContentPipeModule,
    MineViewSectionComponent,
    MineViewItemComponent,
    SkeletonDirective
  ],
})
export class RiskRegistryCategoriesListComponent implements OnInit {

  activeListItem: string;

  loading$: Observable<boolean>;

  listItems = new Map<string, CategoriesListItem>();

  riskTypes$: Observable<Array<RiskCatalog>>;

  readonly RiskRegistryFilteringCategoryEnum = RiskRegistryFilteringCategoryEnum;

  private catalog: RiskCatalog[] = [];

  @Output()
  filtersChanged = new EventEmitter<FilterCategory[]>();

  constructor(
    private contentPipe: ContentPipe,
    private riskRegistryTableService: RiskRegistryTableService,
    private risksCatalogService: RisksCatalogService
  ) {}

  ngOnInit(): void {
    this.initRiskTypes();
    this.initSeverity();
    this.loading$ = this.riskRegistryTableService.selectLoading().pipe(
      shareReplay()
    );
  }

  private initSeverity(): void {
    const severityFilterList = this.contentPipe.transform('risks.riskRegistry.severityFilterList');

    this.listItems.set(RiskRegistryFilteringCategoryEnum.All, { 
      title: severityFilterList.all, 
      count$: this.riskRegistryTableService.selectCountByInherentRisk(RiskRegistryFilteringCategoryEnum.All)
    });
    
    this.listItems.set(RiskRegistryFilteringCategoryEnum.Low, { 
      title: severityFilterList.low, 
      count$: this.riskRegistryTableService.selectCountByInherentRisk(RiskRegistryFilteringCategoryEnum.Low)
    });
    
    this.listItems.set(RiskRegistryFilteringCategoryEnum.Medium, { 
      title: severityFilterList.medium, 
      count$: this.riskRegistryTableService.selectCountByInherentRisk(RiskRegistryFilteringCategoryEnum.Medium)
    });
    
    this.listItems.set(RiskRegistryFilteringCategoryEnum.High, { 
      title: severityFilterList.high, 
      count$: this.riskRegistryTableService.selectCountByInherentRisk(RiskRegistryFilteringCategoryEnum.High)
    });
    
    this.listItems.set(RiskRegistryFilteringCategoryEnum.Missing, { 
      title: severityFilterList.missing, 
      count$: this.riskRegistryTableService.selectCountByInherentRisk(RiskRegistryFilteringCategoryEnum.Missing)
    });

    this.activeListItem = this.riskRegistryTableService.getActiveView() || RiskRegistryFilteringCategoryEnum.All;
}

  private initRiskTypes(): void {
    this.riskTypes$ = this.risksCatalogService.selectRisksCatalog(true).pipe(
      map((catalog: RiskCatalog[]) => catalog.filter(t => t.isDefault)),
      tap(catalog => this.catalog = catalog),
      tap(types => { 
          types.forEach(({ type, name }) => {
            this.listItems.set(type, { title: name, count$: this.riskRegistryTableService.selectCountByType(type) });
          });
      }),
    ) as Observable<RiskCatalog[]>;
  }

  onListItemClick(view: string): void {
    let filters: FilterCategory[] = [];

    this.activeListItem = view;

    this.riskRegistryTableService.setActiveView(view);

    const riskCatalog = this.catalog?.find(riskCatalog => riskCatalog.type === view);

    if (riskCatalog) {
        filters = [{
            id: RiskRegistryFilteringGroupEnum.RiskType,
            label: this.contentPipe.transform('risks.riskRegistry.riskTypeFilterGroup'),
            options: [{
                id: view,
                label: riskCatalog.name,
                selected: true
            }] as FilterOption[]
        } as FilterCategory];
    } else if(view !== RiskRegistryFilteringCategoryEnum.All) {
        filters = [{
          id: RiskRegistryFilteringGroupEnum.InherentRisk,
          label: this.contentPipe.transform('risks.riskRegistry.inherentRiskFilterGroup'),
          options: [{
              id: view,
              label: view,
              selected: true
          }] as FilterOption[]
        } as FilterCategory];
    }

    this.filtersChanged.emit(filters);
  }
}