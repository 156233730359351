import { Directive, ElementRef, Input } from '@angular/core';
import { RiskRatingEnum } from 'src/app/vendor-management/models/risk-rating.enum';

@Directive({
  standalone: true,
  selector: '[scoreToColor]'
})
export class ScoreToColorDirective {
  @Input()
  scoreToColor: RiskRatingEnum = null;

  constructor(private elm: ElementRef) { }
  
	ngOnChanges() {
    let color;
    switch (this.scoreToColor) {
      case  RiskRatingEnum.Bad:
        color =  'var(--mine-red)';
        break;
      case  RiskRatingEnum.Poor:
        color =  'var(--avatar-orange)';
        break;
      case  RiskRatingEnum.Fair:
        color =  'var(--mine-yellow)';
        break;
      case  RiskRatingEnum.Good:
        color =  'var(--mine-apple-green)';
        break;
      case  RiskRatingEnum.Excellent:
        color =  'var(--mine-green-dark)';
        break;
        
      default:
        color = 'var(--mine-grey-normal)';
    }

    this.elm.nativeElement.style.color = color;
	}
}