export enum AiAssessmentsFormControlTypeEnum {
    Group = 'group',
    Text = 'text',
    Textarea = 'textarea',
    Datepicker = 'datepicker',
    File = 'file',
    Dropdown = 'dropdown',
    NewSection = 'new-section',
    CustomComponent = 'custom-component',
}

export enum AiAssessmentsColumnEnum {
    Name = "name",
    Type = "type",
    Status = "status",
    DataSources = "dataSources",
    ReviewDate = "reviewDate",
    DataSubjects = "dataSubjects",
    BusinessUnit = "businessUnit",
}

export enum AiAssessmentsTemplatesColumnEnum {
    Name = "name",
    Type = "type",
    CreatedBy = "createdBy",
    UpdatedBy = "updatedBy",
}

export enum AiAssessmentsFilteringGroupEnum {
    Type = "type",
    Status = "status",
    ReviewDate = "reviewDate",
    DataSource = "dataSource",
    DataRole = "dataRole",
    DataSubject = "dataSubject",
    BusinessUnit = "businessUnit",
    BusinessOwner = "businessOwner",
}

export enum AiAssessmentStatusEnum {
    Draft = "Draft",
    Completed = "Completed",
}

export enum AiAssessmentFilteringCategoryEnum {
    All = 'All',
    Draft = "Draft",
    Completed = "Completed",
    Due = "Due"
}

export enum AiAssessmentsActionsEnum {
    Clone = 'Clone',
    Delete = 'Delete',
    Edit = 'Edit',
    Export = 'Export',
    MarkAsDraft = 'Mark as draft',
}

export enum AiAssessmentTemplateEnum {
    Empty = 'Empty',
    AI = 'AI',
    AI_EU = 'AI-EU',
    LIA = 'LIA',
    PA = 'PA',
    PIA = 'PIA',
    TIA = 'TIA',
}

export enum AiAssessmentTemplateTypeEnum {
    MineOS = 'MineOS',
    Custom = 'Custom'
}

export enum AiAssessmentTemplateModeEnum {
    Builder = 'Builder',
    Preview = 'Preview'
}

export enum AiAssessmentCustomComponentEnum {
    BusinessPurpose = 'businessPurpose',
    DataSources = 'dataSources',
    Risks = 'risks',
    DataSubjects = 'dataSubjects',
    BusinessUnit = 'businessUnit',
    DataRole = 'dataRole',
    DataTypes = 'dataTypes',
    LegalBasis = 'legalBasis',
    TransferMechanism = 'transferMechanism',
    BusinessOwner = 'businessOwner',
    PartiesWithAccess = 'partiesWithAccess',
    DpiaNeeded = 'dpiaNeeded',
    LinkedAssessment = 'linkedAssessment',
    DataFlow = 'dataFlow',
    DataFlowNeeded = 'dataFlowNeeded',
}

export enum AiAssessmentsExportFileFormatEnum {
    PDF = 'PDF',
    CSV = 'CSV'
  }

export enum AiAssessmentFormControlLogicType {
    HasSome = 'HasSome',
    HasExactly = 'HasExactly',
}

export enum AiAssessmentFormControlLogicField {
    Type = 'type',
    QuestionId ='questionId',
    QuestionValue = 'questionValue',
}