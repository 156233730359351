import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef, ViewContainerRef, OnInit, HostListener, DestroyRef, ContentChildren, QueryList} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { tap } from 'rxjs';

import { Animations } from 'src/app/animations/animations';
import { ChipColorName } from '../mine-chip/mine-chip.enum';
import { AnimationStateEnum } from 'src/app/animations/animation-state.enum';
import { MineAddItemComponent } from '../mine-add-item/mine-add-item.component';
import { MineButtonTertiaryComponent } from '../mine-button-tertiary/mine-button-tertiary.component';
import { CategoryHighlightDirective } from '../directives/category-highlight.directive';
import { MineChipComponent } from '../mine-chip/mine-chip.component';
import { ChipColor, ChipConfig, MineChip } from '../mine-chip/mine-chip.interface';
import { AddItemConfig, CustomStyle, ValidationConfig } from './mine-chips-input.interface';
import { TextAreaAutoSizeDirective } from 'src/app/requests/common/text-area-auto-size.directive';
import { ContentPipeModule } from './../../services/content/content-pipe.module';
import { DropdownOption } from '../mine-dropdown/mine-dropdown.interface';
import { TippyModule } from '../tippy.module';
import { SafePipe } from '../pipes/safe.pipe';

@Component({
  standalone: true,
  selector: 'mine-chips-input',
  templateUrl: './mine-chips-input.component.html',
  styleUrls: ['./mine-chips-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MineChipComponent, MineButtonTertiaryComponent, MineAddItemComponent, CategoryHighlightDirective, TippyModule, TextAreaAutoSizeDirective, ContentPipeModule, SafePipe],
  animations: [Animations.fadeIn, Animations.fadeInOnEnter]
})
export class MineChipsInputComponent implements OnInit {
  readonly defaultChip = MineChip.get(ChipColorName.Grey);

  @Input() validationConfig: ValidationConfig;

  @Input() baseColor: ChipColor = MineChip.get(ChipColorName.Grey);
  
  @Input() customStyle: CustomStyle = {maxHeight: '80px'};

  @Input() chips = new Map<string, ChipConfig>();

  @Input() placeholder: string;

  @Input() addItemConfig: AddItemConfig;

  @Input() errorLabel = '';

  @Input() initialOptionList: DropdownOption[];

  //get all chipConfig data when chip added, so you can access the id or relevant data
  @Input() private getChipsOnEvent: boolean;

  @Input() closeDropdown: EventEmitter<void>;

  @Input() updateInitialOptionList: EventEmitter<DropdownOption[]>;

  @Input() isAsync: boolean = false;

  @Input() isLoading: boolean = false;

  @Output() onChipsChanged: EventEmitter<string[] | Map<string, ChipConfig>> = new EventEmitter<string[] | Map<string, ChipConfig>>();

  @Output() addBtnClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output() searchAsync = new EventEmitter<string>();

  @ViewChild('chipInput', { static: false }) chipInput: ElementRef;

  @ViewChild('addBtn', { static: false, read: ElementRef }) addBtn: ElementRef;

  @ViewChild('addItem', { static: false, read: ElementRef }) addItem: ElementRef;
  
  enterFade: AnimationStateEnum;

  inputIsActive = false;

  inputWithValue = false;

  inputBorder = false;

  showDropdown: boolean;

  dropdownLeft: string;
  
  hintPresent: boolean = false;

  constructor(
    private readonly viewRef: ViewContainerRef,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) { }

  @ContentChildren('hint') hintElements!: QueryList<ElementRef>;

  @HostListener('document:click', ['$event.target'])
	onClick(targetElement) {
		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
      this.showDropdown = false;
		}
	}

  ngOnInit(): void {
    this.enterFade = AnimationStateEnum.Show;
    this.inputBorder = this.viewRef.element.nativeElement.classList.contains('input-border');
    this.initialOptionList = this.initialOptionList ?? this.addItemConfig?.dropDownOptions;
    this.cdr.detectChanges();

    this.closeDropdown?.pipe(
      tap(() => this.showDropdown = false),
      tap(() => this.cdr.detectChanges()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.updateInitialOptionList?.pipe(
      tap(options => this.initialOptionList = options),
      tap(() => this.cdr.detectChanges()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  ngAfterContentInit() {
    this.hintPresent = this.hintElements?.length > 0;
  }

  onRemoveChip(chip: string): void {
    this.chips.delete(chip);
    this.validateChips();
    this.notifyOnChipsChanged();

    if (!!this.addItemConfig) {
      this.addItemConfig.dropDownOptions = this.restoreItem();
    }
    
    this.chipInput?.nativeElement.focus();
    this.cdr.detectChanges();
  }

  private restoreItem(): DropdownOption[] {
    const filterSelected = (arr: DropdownOption[]): DropdownOption[] => {
      return arr.filter(item => !this.chips.has(item.value));
    };
    
    // Created a deep copy of the data in order to avoid mutations.
    let options = structuredClone(this.initialOptionList);
    if (this.addItemConfig.groups) {
      options.forEach(group => group.options = filterSelected(group.options));
    }
    else {
      options = filterSelected(options);
    }

    return options;
  }

  onRemoveChipFromKeyboard(): void {
    if (this.chipInput.nativeElement.value) {
      this.chipInput.nativeElement.value = this.chipInput.nativeElement.value.slice(0, this.chipInput.nativeElement.value.length);
    } else if (!this.chipInput.nativeElement.value && this.chips?.size > 0) {
      const chipsAsArray = Array.from(this.chips);
      this.onRemoveChip(chipsAsArray[chipsAsArray.length - 1][0]);
    }
    this.cdr.detectChanges();
  }

  addChip(chip: DropdownOption): void {
    const chipColor = this.initialOptionList?.find(c => c.value === chip.value)?.chipColor;
    this.chips.set(chip.value, { id: chip.id, isRemovable: true, isCustom: chip.isCustomValue, chipColor: chipColor ?? MineChip.get(ChipColorName.Grey) });
    
    if (this.chipInput?.nativeElement.value) {
      this.chipInput.nativeElement.value = '';
    } 

    this.inputWithValue = false;
    this.validateChips();
    this.notifyOnChipsChanged();
  }

  asIsOrder() {
    return 1;
  }

  private validateChips(): void {
    if (!this.validationConfig?.customValidation) {
      return;
    }
    if (this.chips?.size === 0) {
      this.errorLabel = '';
    } else {
      this.errorLabel = '';
      Array.from(this.chips)?.forEach((chipItem) => this.chipValidation(chipItem[0]));
      this.inputIsActive = this.errorLabel === '';
    }
  }

  private chipValidation(chipKey): void {
    this.validationConfig?.customValidation(chipKey, (validationMsg) => {
      if (validationMsg !== '') {
        this.setError(chipKey, validationMsg);
      } else {
        this.chips.get(chipKey).chipColor = this.baseColor;
      }
    });
    this.cdr.detectChanges();
  }

  private setError(chipKey: string, error: string): void {
    this.errorLabel = error;
    this.chips.get(chipKey).chipColor = MineChip.get(ChipColorName.Red);
    this.cdr.detectChanges();
  }

  private notifyOnChipsChanged(): void {
    if (this.getChipsOnEvent) {
      this.onChipsChanged.emit(this.chips);
    } else {
      this.onChipsChanged.emit(Array.from(this.chips.keys()));
    }
  }

  onTyping(text: string): void {
    this.inputWithValue = !!text;
    this.cdr.detectChanges();
  }

  onCloseOptionsClick(value: DropdownOption): void {
    this.showDropdown = false;
    if (!!value?.value) {
      this.addChip(value); 
    }
    this.cdr.detectChanges();
  }

  get filteredOptions() {
    return this.filter();
  }

  private filter(): DropdownOption[] {
    return this.addItemConfig.groups ? this.filterGroupedDropdownOptions() : this.filterDropdownOptions();
  }

  private filterDropdownOptions(): DropdownOption[] {
    const selectedChipKeys = Array.from(this.chips.keys());
    const filteredArray = this.addItemConfig.dropDownOptions.filter(res => !selectedChipKeys.includes(res.value));
    return filteredArray;
  }

  private filterGroupedDropdownOptions(): DropdownOption[] {
    const selectedChipKeys = Array.from(this.chips.keys());
    const filteredOptions: DropdownOption[] = [];
    this.addItemConfig.dropDownOptions.forEach(group => {
      filteredOptions.push({ ...group, options: group.options.filter(item => !selectedChipKeys.includes(item.value))});
    });
    return filteredOptions;
  }

  openDropdown(): void {
    this.addBtnClicked.emit();
    
    if (!this.addItemConfig.lockedFeaturePopup) {
      this.showDropdown = true;
      this.cdr.detectChanges();
      this.dropdownLeft = this.addItemConfig.preventDropdownOffset ? '0px': `${this.getDropdownLeftOffset()}px`;
    }
  }

  private getDropdownLeftOffset(): number {
    const bounding = this.addItem.nativeElement.getBoundingClientRect();
    if (bounding.right + Number.parseInt(this.addItemConfig.width) > (window.innerWidth || document.documentElement.clientWidth)) {
      // Right side is out of viewport
      return 0;
    }

    return this.addBtn.nativeElement.offsetLeft;
  }
}
