import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, merge, Observable, of, filter, first, map, switchMap, tap } from 'rxjs';
import { LoggerService } from 'src/app/logger/logger.service';
import { AutomationsQuery } from './automations.query';
import { AutomationStore } from './automations.store';
import { ApiClientAutomationsService } from 'src/app/api/api-client-automations.service';
import { Automation, AutomationsConfigResponse, AutomationStatus, AutomationResponse, CloseTemplatesResponse, TemplateResponse, AutomationLocalized } from 'src/app/api/models/automations/automations.interface';
import { AutomationType } from '../../api/models/automations/automation-type.enum';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { AutomationResponseUpdate, AutomationResponseBase } from 'src/app/api/models/automations/automation-update.interface';
import { LanguageConfiguration } from 'src/app/privacy-center/localization.interface';
import { CloseTemplateEnum } from 'src/app/requests/models/close-template.enum';
import { TemplateResponseType } from 'src/app/api/models/automations/template-response-type.enum';

@Injectable({ 
	providedIn: 'root' 
})
export class AutomationsService {
	private readonly loggerName: string = 'AutomationsService';

	private closeTemplates = new BehaviorSubject<CloseTemplatesResponse>(null);
	closeTemplates$ = this.closeTemplates.asObservable();

	constructor(
		private automationsQuery: AutomationsQuery,
		private automationsStore: AutomationStore,
		private apiClientAutomationsService: ApiClientAutomationsService,
		private featureFlagQuery: FeatureFlagQuery,
		private contentPipe: ContentPipe,
		private logger: LoggerService,
	) { }

	setStore(): Observable<void | boolean> {
		const loading$ = this.automationsQuery.selectLoading();

		const set$ = loading$.pipe(
				filter(response => !response)
			);

		const notSet$ = loading$.pipe(
				filter(response => response),
				switchMap(() => this.getAutomations())
			);

		return merge(set$, notSet$).pipe(first());
	}

	getAutomations(locale?: string): Observable<void> {
		const localeCode = locale ?? (this.contentPipe.transform('templates-localization.defaultLanguage') as LanguageConfiguration).localizationCode;
		return this.apiClientAutomationsService.getAutomationsConfig(localeCode).pipe(
				map(response => this.setAutomationsStore(response, localeCode)),
				tap(() => this.logger.info(this.loggerName, 'Automations store is set')),
		);
	}

	getEnabledAutomations(automations: AutomationType[]): Observable<Map<AutomationType, boolean>> {
		return this.automationsQuery.selectManyAutomations(automations).pipe(
			switchMap(response => (
				iif(()=> !!response.length,
					of(this.getMapEnabledAutomation(automations, response)),
					this.getAutomationsSet(automations),
				)
			)),
		);
	}

	getAutomationTypeFromTemplateType(templateType: CloseTemplateEnum): AutomationType {
		switch (templateType) {
			case CloseTemplateEnum.CancelTemplate:
				return AutomationType.CanceledConfiguration;
			
			case CloseTemplateEnum.CompleteTemplate:
				return AutomationType.CompletedConfiguration;
			
			case CloseTemplateEnum.RejectTemplate:
				return AutomationType.RejectConfiguration;
			
			case CloseTemplateEnum.CopyCancelTemplate:
				return AutomationType.CopyCanceledConfiguration;
			
			case CloseTemplateEnum.CopyCompleteTemplate:
				return AutomationType.CopyCompletedConfiguration;
			
			case CloseTemplateEnum.CopyRejectTemplate:
				return AutomationType.CopyRejectConfiguration;
			
			case CloseTemplateEnum.DoNotMailCancelTemplate:
				return AutomationType.DoNotMailCancelConfig;
			
			case CloseTemplateEnum.DoNotMailCompleteTemplate:
				return AutomationType.DoNotMailCompleteConfig;
			
			case CloseTemplateEnum.DoNotMailRejectTemplate:
				return AutomationType.DoNotMailRejectConfig;
			
			case CloseTemplateEnum.RightToEditCancelTemplate:
				return AutomationType.RightToEditCancelConfig;
			
			case CloseTemplateEnum.RightToEditCompleteTemplate:
				return AutomationType.RightToEditCompleteConfig;
			
			case CloseTemplateEnum.RightToEditRejectTemplate:
				return AutomationType.RightToEditRejectConfig;

			case CloseTemplateEnum.DoNotSellCancelTemplate:
				return AutomationType.DoNotSellCancelConfig;
			
			case CloseTemplateEnum.DoNotSellCompleteTemplate:
				return AutomationType.DoNotSellCompleteConfig;
			
			case CloseTemplateEnum.DoNotSellRejectTemplate:
				return AutomationType.DoNotSellRejectConfig;
			
			default:
				return AutomationType.RejectConfiguration;
		}
	}

	private getAutomationsSet(automations): Observable<Map<AutomationType, boolean>> {
		return this.getAutomations().pipe(
			switchMap(() => this.automationsQuery.selectManyAutomations(automations)),
			map(response => this.getMapEnabledAutomation(automations, response))
		);
	}

	private getMapEnabledAutomation(ids: AutomationType[], result: Automation[]): Map<AutomationType, boolean> {
		let automationMapped = new Map<AutomationType, boolean>();
		ids.forEach(id => automationMapped.set(id, result.find(x => x.id === id)?.content?.enabled));
		return automationMapped;
	}

	private setAutomationsStore(automations: AutomationsConfigResponse, localeCode: string): void {
		if (this.automationsQuery.hasEntity(localeCode)) {
			this.handleUpdateLocale(automations, localeCode);
			return;
		}

		if (this.featureFlagQuery.getFlag(FeatureFlags.DevServerTemplates)) {
			this.setNewStoreState(automations, localeCode);
		} else {
			this.setNewStoreStateLegacy(automations, localeCode);
		}
	}

	private handleUpdateLocale(automations: AutomationsConfigResponse, localeCode: string): void {
		Object.keys(automations).forEach(key => {
			const type = this.getAutomationTypeKey(key);
			const value = this.getAutomation(AutomationType[type], automations[key]);
			this.updateStore(value.id, value.content, localeCode);
		});
	}

	private getAutomationTypeKey(value: string): string {
		return Object.entries(AutomationType).find(([key, val])=> val===value)?.[0];
	}

	private setNewStoreState(automations: AutomationsConfigResponse, localeCode: string): void {
		const entities: Automation[] = [];
		for (let type in AutomationType) {
			const t = AutomationType[type];
			entities.push(this.getAutomation(t, automations[t]));
		}

		const storeState: AutomationLocalized = { locale: localeCode, automations: entities };
		this.automationsStore.add([storeState]);
	}

	private setNewStoreStateLegacy(automations: AutomationsConfigResponse, localeCode: string): void {
		const entities: Automation[] = [];
		for (let type in AutomationType) {
			const t = AutomationType[type];
			const automation: Automation = !!automations[t]
				? this.getAutomation(t, automations[t])
				: this.getAutomationEmptyLegacy(t);
			entities.push(automation);
		}
		const storeState: AutomationLocalized = { locale: localeCode, automations: entities };
		this.automationsStore.add([storeState]);
	}

	private getAutomation(id: AutomationType, content: AutomationStatus | AutomationResponse): Automation {
		return {
			id,
			content
		} as Automation;
	}

	private getAutomationEmptyLegacy(id: AutomationType): Automation {
		switch (id) {
			case AutomationType.CopyCompletedConfiguration:
			case AutomationType.CopyCanceledConfiguration:
			case AutomationType.CopyRejectConfiguration:
				return { id, content: this.getEmptyCopyTemplateLegacy(id) };
			case AutomationType.CompletedConfiguration:
			case AutomationType.AckConfiguration:
			case AutomationType.CanceledConfiguration:
			case AutomationType.RejectConfiguration:
				return { id, content: this.getEmptyTemplateLegacy(id) };
			case AutomationType.DoNotMailCancelConfig:
			case AutomationType.DoNotMailRejectConfig:
			case AutomationType.DoNotMailCompleteConfig:
				return {id, content: {...this.getEmptyTemplateLegacy(id), responseType: TemplateResponseType.DoNotMail} as AutomationResponse};
			case AutomationType.RightToEditCancelConfig:
			case AutomationType.RightToEditCompleteConfig:
			case AutomationType.RightToEditRejectConfig:
				return {id, content: {...this.getEmptyTemplateLegacy(id), responseType: TemplateResponseType.RightToEdit} as AutomationResponse};
			case AutomationType.DoNotSellCancelConfig:
			case AutomationType.DoNotSellCompleteConfig:
			case AutomationType.DoNotSellRejectConfig:
				return {id, content: {...this.getEmptyTemplateLegacy(id), responseType: TemplateResponseType.DoNotSell} as AutomationResponse};
		};
	}
	
	private getEmptyCopyTemplateLegacy(id: AutomationType): AutomationResponse {
		let automationResponse: AutomationResponse = this.getEmptyTemplateLegacy(id);
		automationResponse.responseType = TemplateResponseType.GetCopy;
		return automationResponse;
	}

	private getEmptyTemplateLegacy(id: AutomationType): AutomationResponse {
		return {
			createdAt: undefined,
			enabled: false,
			lastModified: undefined,
			counter: 0,
			agentId: undefined,
			lastUsed: undefined,
			template: this.getDefaultTemplateLegacy(id),
			responseType: TemplateResponseType.Delete
		} as AutomationResponse;
	}

	private getDefaultTemplateLegacy(type: AutomationType): string {
		switch(type) {
			case AutomationType.CanceledConfiguration:
				return this.contentPipe.transform('automations.defaultTemplatesCanceled');
			case AutomationType.CompletedConfiguration:
				return this.contentPipe.transform('automations.defaultTemplatesCompleted');
			case AutomationType.RejectConfiguration:
				return this.contentPipe.transform('automations.defaultTemplatesRejected');
			case AutomationType.AckConfiguration:
				return this.contentPipe.transform('automations.defaultTemplatesAck');
			case AutomationType.CopyCanceledConfiguration:
				return this.contentPipe.transform('automations.defaultTemplatesCopyCanceled');
			case AutomationType.CopyCompletedConfiguration:
				return this.contentPipe.transform('automations.defaultTemplatesCopyCompletedV2');
			case AutomationType.CopyRejectConfiguration:
				return this.contentPipe.transform('automations.defaultTemplatesCopyRejected');
			case AutomationType.DoNotMailCancelConfig: 
				return this.contentPipe.transform('automations.defaultDoNotMailCancel');
			case AutomationType.DoNotMailCompleteConfig: 
				return this.contentPipe.transform('automations.defaultDoNotMailComplete');
			case AutomationType.DoNotMailRejectConfig: 
				return this.contentPipe.transform('automations.defaultDoNotMailReject');
			case AutomationType.RightToEditCancelConfig: 
				return this.contentPipe.transform('automations.defaultRightToEditCancel');
			case AutomationType.RightToEditRejectConfig: 
				return this.contentPipe.transform('automations.defaultRightToEditReject');
			case AutomationType.RightToEditCompleteConfig: 
				return this.contentPipe.transform('automations.defaultRightToEditComplete');
			case AutomationType.DoNotSellCancelConfig:
				return this.contentPipe.transform('automations.defaultDoNotSellCancel');
			case AutomationType.DoNotSellCompleteConfig:
				return this.contentPipe.transform('automations.defaultDoNotSellComplete');
			case AutomationType.DoNotSellRejectConfig:
				return this.contentPipe.transform('automations.defaultDoNotSellReject');
			default: return "";
		}
	}

	setAutomationResponseConfig(automationConfig: AutomationResponseUpdate, id: AutomationType): Observable<void> {
		return this.apiClientAutomationsService.updateResponseAutomationsConfig(automationConfig).pipe(
				map(() => this.generateResponseAutomationConfig(automationConfig, id)),
				tap(response => this.updateStore(id, response, automationConfig.templateLocale)),
				switchMap(response => this.updateClosedTemplates(response, id)),
			);
	}

	automationResponseTest(automationTest: AutomationResponseBase): Observable<void> {
		return this.apiClientAutomationsService.testResponseAutomationsConfig(automationTest);
	}

	private generateResponseAutomationConfig(config: AutomationResponseUpdate, id: AutomationType): AutomationResponse {
		const currAutomation = this.automationsQuery.getAutomation(id).content;
		
		return {
			lastUsed: new Date().toString(),
			createdAt: !!currAutomation.createdAt ? currAutomation.createdAt : new Date().toString(),
			// TODO: update AgentId when we have that data
			agentId: currAutomation.agentId ?? '',
			template: config.template,
			counter: currAutomation.counter,
			enabled: config.enabled,
			lastModified: new Date().toString(),
			templateLocale: config.templateLocale,
			responseType: config.responseType,
			displayName: (currAutomation as AutomationResponse)?.displayName,
		} as AutomationResponse;
	}

	private updateStore(id: AutomationType, value: AutomationStatus | AutomationResponse, locale?: string): void {
		const localeCode = locale ?? (this.contentPipe.transform('templates-localization.defaultLanguage') as LanguageConfiguration).localizationCode;
		const automations: Automation[] = this.automationsQuery.getEntity(localeCode)?.automations ?? [];
		const automationIndex: number = automations.findIndex((item) => item.id === id);
		let newAutomations: Automation[] = [...automations];
		newAutomations.splice(automationIndex, 1, { id, content: value } as Automation);
		this.automationsStore.update(localeCode, { locale: localeCode, automations: newAutomations } as AutomationLocalized);
	}

	featureEnabled(automationId): boolean {
		return this.featureFlagQuery.getFlag(this.automationIdToFeatureFlagName(automationId));
	}

	private automationIdToFeatureFlagName(id: AutomationType): FeatureFlags {
		switch(id) {
			case AutomationType.CompletedConfiguration:
			case AutomationType.CopyCompletedConfiguration:
				return FeatureFlags.CompletedMailAutomation;
			case AutomationType.RejectConfiguration:
			case AutomationType.CopyRejectConfiguration:
				return FeatureFlags.RejectedMailAutomation;
			case AutomationType.AckConfiguration:
				return FeatureFlags.AckMailAutomation;
			case AutomationType.CanceledConfiguration:
			case AutomationType.CopyCanceledConfiguration:
				return FeatureFlags.CanceledMailAutomation;
			default:
				throw new Error(`automaion ${id} doesn't exist`);
		}
	}

	getTemplates(locale?: string): Observable<CloseTemplatesResponse> {
		locale = locale ?? (this.contentPipe.transform('templates-localization.defaultLanguage') as LanguageConfiguration).localizationCode;
		const set$ = this.closeTemplates$.pipe(
				filter(response => !!response),
			);

		const notSet$ = this.closeTemplates$.pipe(
				filter(response => !response),
				switchMap(() => this.getTemplatesServer(locale))
			);

		return merge(set$, notSet$);
	}

	private getTemplatesServer(locale: string): Observable<CloseTemplatesResponse> {
		return this.apiClientAutomationsService.getCloseTemplates(locale).pipe(
			map(res => this.getDefaultClosedText(res)),
			tap(res => this.closeTemplates.next(res))
		);
	}

	private getDefaultClosedText(response: CloseTemplatesResponse): CloseTemplatesResponse {
		return {
			rejectTemplate: response.rejectTemplate ?? { template: this.contentPipe.transform('automations.defaultTemplatesRejected'), alignedLeft: true} as TemplateResponse,
			completeTemplate: response.completeTemplate ?? { template: this.contentPipe.transform('automations.defaultTemplatesCompleted'), alignedLeft: true} as TemplateResponse,
			cancelTemplate: response.cancelTemplate ?? { template: this.contentPipe.transform('automations.defaultTemplatesCanceled'), alignedLeft: true } as TemplateResponse,
			copyRejectTemplate: response.copyRejectTemplate ?? { template: this.contentPipe.transform('automations.defaultTemplatesCopyRejected'), alignedLeft: true } as TemplateResponse,
			copyCompleteTemplate: response.copyCompleteTemplate ?? { template: this.contentPipe.transform('automations.defaultTemplatesCopyCompleted'), alignedLeft: true } as TemplateResponse,
			copyCancelTemplate: response.copyCancelTemplate ?? { template: this.contentPipe.transform('automations.defaultTemplatesCopyCanceled'), alignedLeft: true } as TemplateResponse,
		};
	}

	private updateClosedTemplates(automationResponse: AutomationResponse, id: AutomationType): Observable<void> {
		return this.getTemplates().pipe(
			first(),
			map(tempResponse => this.updateResponse(automationResponse, tempResponse, id)),
			tap(res => id !== AutomationType.AckConfiguration ? this.closeTemplates.next(res): ''),
			map(() => null)
		);
	}

	updateResponse(automationResponse: AutomationResponse, tempResponse: CloseTemplatesResponse, id: AutomationType): CloseTemplatesResponse {
		switch (id) {
			case AutomationType.CanceledConfiguration:
				tempResponse.cancelTemplate.template = automationResponse.template;
				tempResponse.cancelTemplate.alignedLeft = automationResponse.alignedLeft;
				break;
			
			case AutomationType.CompletedConfiguration:
				tempResponse.completeTemplate.template = automationResponse.template;
				tempResponse.completeTemplate.alignedLeft = automationResponse.alignedLeft;
				break;
			
			case AutomationType.RejectConfiguration:
				tempResponse.rejectTemplate.template = automationResponse.template;
				tempResponse.rejectTemplate.alignedLeft = automationResponse.alignedLeft;
				break;
			
			case AutomationType.CopyCanceledConfiguration:
				tempResponse.copyCancelTemplate.template = automationResponse.template;
				tempResponse.copyCancelTemplate.alignedLeft = automationResponse.alignedLeft;
				break;
			
			case AutomationType.CopyCompletedConfiguration:
				tempResponse.copyCompleteTemplate.template = automationResponse.template;
				tempResponse.copyCompleteTemplate.alignedLeft = automationResponse.alignedLeft;
				break;

			case AutomationType.CopyRejectConfiguration:
				tempResponse.copyRejectTemplate.template = automationResponse.template;
				tempResponse.copyRejectTemplate.alignedLeft = automationResponse.alignedLeft;
				break;
		}

		return tempResponse;
	}
}
