import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any, field: string): any[] {
    array?.sort((a: any, b: any) => {
      const itemA = a[field] ?? '';
      const itemB = b[field] ?? '';
      if (itemA < itemB) {
        return 1;
      } else if (itemA > itemB) {
        return -1;
      } else {
        return 0;
      }
    });
    return array;
  }
}