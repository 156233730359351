import { CommonModule } from '@angular/common';
import { Component, ComponentRef, Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, Renderer2, ViewContainerRef } from '@angular/core';
import { SortDirectionEnum } from '../sort-direction.enum';

@Directive({
  standalone: true,
  selector: '[mine-sort-header]'
})
export class MineSortHeaderDirective implements OnInit {

  @Input('mine-sort-header')
  name: string;

  @Input()
  disabled: boolean;

  @Output()
  sort = new EventEmitter<MineSortHeaderDirective>();

  private sortSvgComponent: ComponentRef<MineSortSvgComponent>;

  constructor(
    private elm: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef
  ) { }

  @HostBinding('class.disabled')
	get hostClass() {
		return this.disabled;
	}

  @HostListener('click', ['$event'])
  onClick($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();

    if (!this.disabled && this.elm.nativeElement.contains($event.target) && this.sortSvgComponent) {
      const currentDirection = this.sortSvgComponent.instance.direction;
      this.sortSvgComponent.instance.direction = currentDirection === SortDirectionEnum.Default ? 
        SortDirectionEnum.Asc : currentDirection === SortDirectionEnum.Asc ? SortDirectionEnum.Desc : SortDirectionEnum.Default;

      this.sortSvgComponent.hostView.detectChanges();
      this.sort.emit(this);
    }
  }

  @HostListener('mouseover')
  onMouseOver() {
    if (!this.disabled && this.sortSvgComponent?.instance.show !== true) {
      this.setActive(this.sortSvgComponent.instance.direction);
    }
  }

  @HostListener('mouseout')
  onMouseOut() {
    if (!this.disabled && this.sortSvgComponent?.instance.direction === SortDirectionEnum.Default) {
      this.clear();
    }
  }

  ngOnInit(): void {
    this.createSvgComponent();
  }

  getDirection(): SortDirectionEnum {
    return this.sortSvgComponent?.instance.direction ?? SortDirectionEnum.Default;
  }

  setDisabled(disabled: boolean): void {
    if (disabled) {
      this.clear();
      this.disabled = true;
    }
  }

  setActive(direction: SortDirectionEnum): void {
    if (this.sortSvgComponent) {
      this.sortSvgComponent.instance.show = true;
      this.sortSvgComponent.instance.direction = direction;
      this.sortSvgComponent.hostView.detectChanges();
    }
  }

  clear(): void {
    if (this.sortSvgComponent) {
      this.sortSvgComponent.instance.show = false;
      this.sortSvgComponent.instance.direction = SortDirectionEnum.Default;
      this.sortSvgComponent.hostView.detectChanges();
    }
  }

  private createSvgComponent(): void {
    this.viewContainerRef.clear();
    this.sortSvgComponent = this.viewContainerRef.createComponent(MineSortSvgComponent);

    this.renderer.addClass(this.elm.nativeElement, 'mine-sort-header');
    this.renderer.appendChild(this.elm.nativeElement, this.sortSvgComponent.location.nativeElement);
  }
}

@Component({
  standalone: true,
  template: `
    <div [ngStyle]="{
      'width': '2.4rem', 
      'height': '2.4rem', 
      'opacity': show ? '1' : '0', 
      'background-image': 'url(assets/img/SVG/Sort-' + direction + '.svg)'}">
    </div>
  `,
  imports: [CommonModule]
})
class MineSortSvgComponent {

  @Input()
  show = false;

  @Input()
  direction = SortDirectionEnum.Default;
}