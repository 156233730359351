import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RoutesManager } from '../models/routes.interfaces';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { MineButtonPrimaryComponent } from '../mine-button-primary/mine-button-primary.component';
import { MineButtonTertiaryComponent } from '../mine-button-tertiary/mine-button-tertiary.component';

@Component({
  standalone: true,
  selector: 'portal-no-permissions',
  templateUrl: './portal-no-permissions.component.html',
  styleUrls: ['./portal-no-permissions.component.scss'],
  imports: [ContentPipeModule, MineButtonPrimaryComponent, MineButtonTertiaryComponent]
})
export class PortalNoPermissionsComponent {
  readonly routesManager = RoutesManager;
  assetsUrl = environment.assets.url;
  url = environment.about.url;

  back() {
    window.location.href =`${this.url}faq/for-companies`;
  }

  redirectToFaq() {
    window.location.href =`${this.url}enriched-dsr`;
  }
}