import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, filter, first, map, switchMap, tap  } from 'rxjs';

import { LoggerService } from 'src/app/logger/logger.service';
import { ApiClientCompanySettingsService } from 'src/app/api/api-client-company-settings.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { CustomFramework, CustomFrameworksResponse } from 'src/app/api/models/company-settings/frameworks.interface';
import { Framework } from 'src/app/data-types/models/frameworks.interface';
import { DataType } from 'src/app/data-types/models/data-types.interface';
import { FrameworksStore } from './frameworks.store';
import { FrameworksQuery } from './frameworks.query';
import { DataTypesQuery } from 'src/app/data-types/state/data-types.query';

@Injectable({ 
	providedIn: 'root' 
})
export class FrameworksService {
	
	private readonly loggerName: string = 'FrameworksService';

	constructor(
		private contentPipe: ContentPipe,
		private logger: LoggerService,
		private apiClientCompanySettings: ApiClientCompanySettingsService,
		private frameworksStore: FrameworksStore,
		private frameworksQuery: FrameworksQuery,
		private dataTypesQuery: DataTypesQuery,
	) { }

	// frameworks store is combination of pre-defined frameworks that retrived from squidex 
	// and custom frameworks from company settings api 
	init(): Observable<void> {
		return this.dataTypesQuery.selectLoading().pipe(
			filter(res => !res),
			switchMap(() => this.loadCustomFrameworks()),
			first(),
			tap(res => this.setFrameworksStore(res)),
			tap(() => this.frameworksStore.setLoading(false)),
			map(() => void 0),
		);
	}

	loadCustomFrameworks(): Observable<Framework[]> {
		return this.apiClientCompanySettings.getCustomFrameworks().pipe(
			map(res => res.frameworks),
			tap(res => this.setDataTypesOfCustomFrameworks(res)),
			map(res => this.convertCustomFrameworksToFrameworks(res)),
			catchError(err => {
				this.logger.error(this.loggerName, `Cant load custom frameworks. error message: ${err.message}`);
				return EMPTY;
			}),
		);
	}

	private setFrameworksStore(customFrameworks: Framework[]): void {
		const cmsFrameworks = this.contentPipe.transform('systems.frameworks').map(f => ({...f, isCustom: false})) as Framework[];
		this.frameworksStore.set([...cmsFrameworks, ...customFrameworks]);
	}

	saveNewCustomFramework(newFramework: CustomFramework): Observable<CustomFramework> {
		return this.apiClientCompanySettings.saveNewCustomFramework(newFramework).pipe(
			tap(res => this.addFrameworkToStore(this.convertCustomFrameworksToFrameworks([res])[0])),
			catchError(err => {
				this.logger.error(this.loggerName, `Cant save new custom framework. framework data: ${newFramework} error message: ${err.message}`);
				return EMPTY;
			}),
		);
	}

	private addFrameworkToStore(newFramework: Framework): void {
		this.frameworksStore.add(newFramework);
	}

	updateCustomFramework(framework: CustomFramework): Observable<CustomFramework> {
		return this.apiClientCompanySettings.updateCustomFramework(framework).pipe(
			tap(() => this.setDataTypesOfCustomFrameworks([framework])),
			tap(res => this.updateFrameworkInStore(this.convertCustomFrameworksToFrameworks([res])[0])),
			catchError(err => {
				this.logger.error(this.loggerName, `Cant update existing custom framework. framework data: ${framework} error message: ${err.message}`);
				return EMPTY;
			}),
		);
	}

	private updateFrameworkInStore(updatedFramework: Framework): void {
		this.frameworksStore.update(updatedFramework.id, updatedFramework);
	}

	private deleteFrameworkFromStore(frameworkId: string): void {
		this.frameworksStore.remove(frameworkId);
	}

	private convertCustomFrameworksToFrameworks(customFrameworks: CustomFramework[]): Framework[] {
		return customFrameworks.map(f => ({
			id: f.id,
			displayName: f.name,
			isCustom: true,
			textColor: f.color,
			backgroundColor: f.color,
		}));
	}

	// for init / update frameworks of data types
	private setDataTypesOfCustomFrameworks(customFrameworks: CustomFramework[]): void {
		customFrameworks.forEach(f => {
			const frameworkDataTypes = new Set<string>(f.dataTypes);
			const dataTypes = this.dataTypesQuery.getDataTypes(f.dataTypes);
			dataTypes.forEach(d => {
				const dataTypesFrameworks = new Set<string>(d.frameworks?.map(df => df.id));
				const frameworks = frameworkDataTypes.has(d.id) && !dataTypesFrameworks.has(f.id) ? [...d.frameworks, ...[{ id: f.id }]] : 
								!frameworkDataTypes.has(d.id) && dataTypesFrameworks.has(f.id) ? d.frameworks.filter(df => df.id !== f.id) :
								d.frameworks;
				
					this.dataTypesQuery.updateFrameworksOfExistingDataType(d.id, frameworks);
			});
		});
	}

	// delete frameworks of data types
	private deleteDataTypesOfCustomFrameworks(customFrameworkId: string): void {
		let dataTypes: DataType[] = this.dataTypesQuery.getAll();
		dataTypes.forEach(d => {
			if (d.frameworks.find(f => f.id === customFrameworkId) !== undefined) {
				const frameworks = d.frameworks.filter(f => f.id !== customFrameworkId);
				this.dataTypesQuery.updateFrameworksOfExistingDataType(d.id, frameworks);

			}
		});
	}

	deleteCustomFramework(frameworkId: string): Observable<CustomFrameworksResponse> {
		return this.apiClientCompanySettings.deleteCustomFramework(frameworkId).pipe(
			tap(() => this.deleteFrameworkFromStore(frameworkId)),
			tap(() => this.deleteDataTypesOfCustomFrameworks(frameworkId)),
			catchError(err => {
				this.logger.error(this.loggerName, `Cant delete custom framework. framework id: ${frameworkId} error message: ${err.message}`);
				return EMPTY;
			}),
		);
	}

	isCustomFrameworkNameAlreadyExist(newFrameworkName: string): boolean {
		const namesSet = new Set<string>(this.frameworksQuery.getCustomFrameworks().map(f => f.displayName?.toLowerCase()) ?? []);
		return namesSet.has(newFrameworkName?.toLowerCase()) ?? false;
	}
	
}