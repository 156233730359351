import { Injectable } from "@angular/core";
import { AiAssessmentFormControlProperties, AiAssessmentLogicByControl, AiAssessmentLogicOptionsBySection, AiAssessmentsFormControl } from "../../models/ai-assessments.interface";
import { AiAssessmentFormControlLogicField, AiAssessmentFormControlLogicType } from "../../models/ai-assessments.enum";
import { DropdownOption } from "src/app/shared/mine-dropdown/mine-dropdown.interface";
import { LOGIC_DEFAULT_VALUES } from "../../ai-assessments-templates/ai-assessments-template-form/ai-assessments-template-form.constant";

function findControlById(id: string, sections: AiAssessmentsFormControl[]): AiAssessmentFormControlProperties {
  let controlFound: AiAssessmentsFormControl | null = null;
  let sectionFound: AiAssessmentsFormControl | null = null;
  let controlIndexFound: number | null = null;
  let sectionIndexFound: number | null = null;

  sections.forEach((section, sectionIndex) => section.controls.forEach((control, controlIndex) => {
    if (control.id !== id) {
      return;
    }
    controlFound = control;
    sectionFound = section;
    controlIndexFound = controlIndex;
    sectionIndexFound = sectionIndex;
  }));
  
  return { 
    control: controlFound,
    section: sectionFound,
    controlIndex: controlIndexFound,
    sectionIndex: sectionIndexFound,
  };
}

@Injectable()
export class AiAssessmentsLogicUtil {
  findControlById(id: string, sections: AiAssessmentsFormControl[]): AiAssessmentFormControlProperties {
    return findControlById(id, sections);
  }

  updateOptions(
    currentLogicFields: AiAssessmentLogicByControl,
    sections: AiAssessmentsFormControl[],
    logicOptionsBySection: AiAssessmentLogicOptionsBySection,
    controlId: string,
    newOptions: DropdownOption[],
    newValue?: string,
  ): AiAssessmentLogicByControl {
    Object.entries(currentLogicFields).forEach(([id, logic]) => {
      if (logic?.questionId === controlId) {
        if (!logic || !logic.questionValue?.length) {
          return;
        }
        const { sectionIndex } = findControlById(controlId, sections);
        const multiple = logicOptionsBySection[sectionIndex]?.fieldPropertiesById?.[controlId]?.multiple;
        const options = logic.questionValue.split(',');

        const updatedOptions = multiple
          ? options.map((option) => {
            const found = newOptions.map(({ value }) => value).some(value => value === option);
            if (found) {
              return option;
            }
            return newValue;
          })
          : options.filter((option) => newOptions.some(({ id }) => id === option));

        const questionValue = updatedOptions.filter(option => !!option).join(',');
        currentLogicFields[id] = { ...logic, questionValue };
      }
    });
    return currentLogicFields;
  }

  toggleLogic(
    currentLogicFields: AiAssessmentLogicByControl,
    controlId: string,
    hasLogic: boolean
  ): AiAssessmentLogicByControl {
    if (hasLogic) {
      currentLogicFields[controlId] = { ...LOGIC_DEFAULT_VALUES };
    } else {
      delete currentLogicFields[controlId];
    }
    return currentLogicFields;
  }

  updateFieldValue(
    currentLogicFields: AiAssessmentLogicByControl,
    controlId: string,
    field: string,
    newValue: string,
    multiple: boolean
  ): AiAssessmentLogicByControl {
    const currentLogic = currentLogicFields[controlId];
    if (
      (!newValue && newValue !== '') ||
      !currentLogic) {
      return currentLogicFields;
    }
    currentLogicFields[controlId] = {
      ...currentLogicFields[controlId],
      [field]: newValue,
    };
    if (
      field === AiAssessmentFormControlLogicField.QuestionId &&
      currentLogic?.questionId !== newValue
    ) {
      currentLogicFields[controlId].questionValue = '';
      if (!multiple) {
        currentLogicFields[controlId].type = AiAssessmentFormControlLogicType.HasExactly;
      }
    }
    return currentLogicFields;
  }

  reset(
    currentLogicFields: AiAssessmentLogicByControl,
    controlId: string | string[],
  ): AiAssessmentLogicByControl | undefined {
    const controlIds = controlId instanceof Array ? controlId : [controlId];
    Object.entries(currentLogicFields).forEach(([id, logic]) => {
      if (!controlIds.includes(logic.questionId)) {
        return;
      }
      currentLogicFields[id] = { ...LOGIC_DEFAULT_VALUES };
    });
    return currentLogicFields;
  }

  resetValue(
    currentLogicFields: AiAssessmentLogicByControl,
    controlId: string,
    multiple: boolean,
  ): AiAssessmentLogicByControl | undefined {
    Object.entries(currentLogicFields).forEach(([id, logic]) => {
      if (logic?.questionId !== controlId) {
        return;
      }
      currentLogicFields[id].questionValue = '';
      if (!multiple) {
        currentLogicFields[id].type = AiAssessmentFormControlLogicType.HasExactly;
      }
    });
    return currentLogicFields;
  }
}
