import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, HostBinding, ViewChild, ElementRef, ChangeDetectorRef, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';

import { AnalyticsEvent } from 'src/app/analytics/analytics-event';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { MineSpinnerComponent } from '../mine-spinner/mine-spinner.component';
import { ButtonTheme } from '../models/button-theme.enum';
import { TippyModule } from '../tippy.module';

@Component({
	standalone: true,
	selector: 'mine-button-tertiary',
	templateUrl: './mine-button-tertiary.component.html',
	styleUrls: ['./mine-button-tertiary.component.scss'],
	imports: [CommonModule, TippyModule, MineSpinnerComponent]
})
export class MineButtonTertiaryComponent implements AfterViewInit, OnChanges {

	@Input() label: string; // eventCatagory in GA

	@Input()
	isDisabled: boolean;

	@Input()
	spinner: boolean;

	@Input()
	customStyle: string = '';

	@Input()
	tooltip: string;
	
	@Output()
	onClick = new EventEmitter<void>();

	@Input() btnTheme: ButtonTheme = ButtonTheme.Purple;

	@ViewChild('content', { static: true })
	content: ElementRef;

	@HostBinding('class') get hostClass() {
		return this.btnTheme;
	}

	@HostBinding('class.disabled') get hostDisabledClass() {
		return this.isDisabled && !this.tooltip;
	}

	constructor(
		private cdref: ChangeDetectorRef,
		private analytics: AnalyticsService
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.text) {
			this.setContentWidth();
		}
	}

	ngAfterViewInit(): void {
		this.setContentWidth();
	}

	onBtnClick() {
		if (!this.isDisabled) {
			if (this.label) {
				const e = {
					eventCategory: this.label,
					eventAction: EventActionType.Click,
				} as AnalyticsEvent;

				this.analytics.track(e);
			}
			this.onClick.emit();
		}
	}

	private setContentWidth(): void {
		this.content.nativeElement.style.minWidth = 0;
		this.cdref.detectChanges();

		this.content.nativeElement.style.minWidth = `${this.content.nativeElement.offsetWidth}px`;
	}
}
