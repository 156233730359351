import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ExternalBreachContext, RiskHelper, RiskHelperType } from 'src/app/api/models/risks/risks.interface';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { RxFor } from '@rx-angular/template/for';
import { FormControl } from '@angular/forms';
import { first, from, map, Observable, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { RiskCalculationContext } from 'src/app/processing-activities/models/dpia.interface';
import { RxLet } from '@rx-angular/template/let';
import { RiskHelperService } from '../services/risk-helper.service';
import { environment } from 'src/environments/environment';
import { RxIf } from '@rx-angular/template/if';
import { SentenceCasePipe } from 'src/app/shared/pipes/sentence-case.pipe';
import { VendorScoreToColorPipe } from 'src/app/shared/pipes/vendor-score-to-color.pipe';
import { MineServiceIconComponent } from 'src/app/shared/mine-service-icon/mine-service-icon.component';
import { MineButtonPrimaryComponent } from 'src/app/shared/mine-button-primary/mine-button-primary.component';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { Router } from '@angular/router';
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { ScoreToColorDirective } from 'src/app/shared/directives/score-to-color.directive';
import { DisplayNumberPipe } from 'src/app/shared/pipes/display-number.pipe';
import { MineChipComponent } from 'src/app/shared/mine-chip/mine-chip.component';
import { FrameworkChipSecondaryColorPipe } from 'src/app/shared/pipes/framework-chip-secondary-color.pipe';

enum TabsEnum {
	Likelihood,
	Severity,
	Mitigation
}

const rxAngularDirectives = [
	RxFor,
	RxLet,
	RxIf
  ];

@Component({
	selector: 'risk-helper-panel',
	templateUrl: './risk-helper-panel.component.html',
	styleUrls: ['./risk-helper-panel.component.scss'],
    standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
    imports: [
		...rxAngularDirectives,
		ContentPipeModule,
		SafePipe,
		CommonModule,
		SentenceCasePipe,
		VendorScoreToColorPipe,
		MineServiceIconComponent,
		MineButtonPrimaryComponent,
		ScoreToColorDirective,
		DisplayNumberPipe,
		MineChipComponent,
		FrameworkChipSecondaryColorPipe
	]
})
export class RiskHelperPanelComponent implements AfterViewInit, OnInit {

	@ViewChildren('panel', { read: ElementRef })
	tabsElm: QueryList<ElementRef>;

	@ViewChild('externalBreach')
	externalBreachTpl: TemplateRef<any>;

	@ViewChild('internalBreach')
	internalBreachTpl: TemplateRef<any>;
	
	@ViewChild('overallocation')
	overallocationTpl: TemplateRef<any>;
	
	@ViewChild('misclassification')
	misclassificationTpl: TemplateRef<any>;

	@Input()
	assessmentId: string;

	@Input()
	risk: RiskHelper;

	tabCtrl = new FormControl(TabsEnum.Likelihood);

	underlineStyle = { width: '0', left: '0', transition: '' };

	showTable = false;

	tableTpl: TemplateRef<any>;

	readonly TableMaxIconsForDisplay = 2;

	tableData: { headers: string[], context$: Observable<RiskCalculationContext[]> };

	readonly assetsUrl = environment.assets.url + '/Portal';

	readonly RiskHelperType = RiskHelperType;

	constructor(
        private contentPipe: ContentPipe,
		private destroyRef: DestroyRef,
		private cdr: ChangeDetectorRef,
		private riskHelperService: RiskHelperService,
		private systemsQuery: SystemsQuery,
		private router: Router,
	) { }
	ngOnInit(): void {
		this.initTable();
	}

	onTabClick(tab: TabsEnum): void {
		this.tabCtrl.setValue(tab);
		this.setShowTable();
	}

	onClickRow(system: string): void {
		if (this.risk.id === RiskHelperType.DataMisclassification ||
			this.risk.id === RiskHelperType.ExcessiveDataCollection) {
				return;
		}
		
		this.systemsQuery.selectFriendlyPath(system).pipe(
				switchMap(friendlyPath => this.router.navigate([RoutesManager.systems, friendlyPath])),
				first()
			)
			.subscribe();
	}

	onClickGetRating($event: Event, system: string, index: number): void {
		$event.stopPropagation();
		$event.preventDefault();
	
		// Set spinner to true for the specific row
		this.tableData.context$.pipe(
			tap((context) => {
				if (context[index]) {
					(context[index] as ExternalBreachContext).spinner = true;
					this.cdr.detectChanges();
				}
			}),
			switchMap((context) =>
				this.riskHelperService.getCyberPostureFromServer(system).pipe(
					first(),
					tap((rating) => {
						if (context[index]) {
							(context[index] as ExternalBreachContext).rating = rating;
							(context[index] as ExternalBreachContext).spinner = false;
							this.cdr.detectChanges();
						}
					})
				)
			)
		).subscribe();
	}

	ngAfterViewInit(): void {
		this.tabCtrl.valueChanges.pipe(
			map(() => this.moveUnderline()),
			takeUntilDestroyed(this.destroyRef)
		)
		.subscribe();

		setTimeout(() => {
			this.moveUnderline();

			//Initiation is here to avoid the animation on first screen load
			this.underlineStyle.transition = 'all 0.2s ease-in-out';
		}, 0);

		this.setTableTemplate();
		this.cdr.detectChanges();
	}

	private moveUnderline(): void {		
		const elements = this.tabsElm.toArray();
		const activeTab = elements[this.tabCtrl.value];
		this.underlineStyle.width = `${activeTab.nativeElement.offsetWidth}px`;
		this.underlineStyle.left = `${activeTab.nativeElement.offsetLeft}px`;
		this.cdr.markForCheck();
	}

	private setShowTable(): void {
		this.showTable = !!this.risk.calculate?.tableHeaders?.length
		 && this.tabCtrl.value !== TabsEnum.Mitigation;
	}

	private initTable(): void {
		this.setShowTable();
		this.tableData = { 
			headers: this.risk.calculate?.tableHeaders?.map(item => item.name), 
			context$: from(this.riskHelperService.getRiskContext(this.assessmentId, this.risk.id as any))
		};
	}

	private setTableTemplate(): void {
		switch(this.risk.id) {
			case RiskHelperType.ExternalBreach:
				this.tableTpl = this.externalBreachTpl;
				break;

			case RiskHelperType.InternalBreach:
				this.tableTpl = this.internalBreachTpl;
				break;

			case RiskHelperType.ExcessiveDataCollection:
				this.tableTpl = this.overallocationTpl;
				break;
			
			case RiskHelperType.DataMisclassification:
				this.tableTpl = this.misclassificationTpl;
				break;
			
			case RiskHelperType.ContractualBreach:
			default:
				return;
		}
	}

    get aboutRiskTitle(): string {
        return this.contentPipe.transform('risks.riskHelper.aboutRisk', { params: { riskName: this.risk.title } }); 
    }

	get tabs(): { name: string }[] {
		return this.contentPipe.transform('risks.riskHelper.tabs');
	}

	get content(): string {
		switch (this.tabCtrl.value) {
			case TabsEnum.Likelihood:
				return this.risk.calculate?.likelihood;

			case TabsEnum.Severity:
				return this.risk.calculate?.severity;

			case TabsEnum.Mitigation:
				return this.risk.calculate?.mitigation;

			default:
				return this.risk.calculate?.likelihood;
		}
	}

	trackByFn(index: number, item: string) {
		return index;
	}
}
