import { DropdownOption } from "src/app/shared/mine-dropdown/mine-dropdown.interface";
import { AiAssessmentFormControlLogicType } from "../../models/ai-assessments.enum";
import { AiAssessmentFormControlLogic } from "../../models/ai-assessments.interface";

export const LOGIC_DEFAULT_VALUES: AiAssessmentFormControlLogic = {
  type: AiAssessmentFormControlLogicType.HasExactly,
  questionId: '',
  questionValue: '',
};

export const LOGIC_TYPE_LABELS: Record<AiAssessmentFormControlLogicType, string> = {
  [AiAssessmentFormControlLogicType.HasExactly]: 'ai-assessments.logicHasExactly',
  [AiAssessmentFormControlLogicType.HasSome]: 'ai-assessments.logicHasSome',
};

export const LOGIC_TYPE_OPTIONS: DropdownOption[] = Object.entries(LOGIC_TYPE_LABELS).map(([id, value]) => ({ id, value }));

export const MAX_FILE_INPUTS = 10;