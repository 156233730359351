import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	standalone: true,
  	selector: '[clickClose]'
})
export class DropdownClickCloseDirective {

  	constructor(private elm: ElementRef) { }

	@HostListener('document:click', ['$event.target'])
	onClick(targetElement) {
		const clickedInside = this.elm.nativeElement.contains(targetElement);

		if (!clickedInside) {
			this.elm.nativeElement.firstElementChild.checked = false;
		}
	}
}
