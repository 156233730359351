import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemInstance } from '../systems/models/systems.interface';
import { RisksRatingsResponse } from '../vendor-management/models/risk-rating.interface';
import { HttpClientHelperService } from './http-client-helper.service';
import { RiskBatchResponse, RiskRatingResponse, UsageScore } from './models/data-mapping/vendor-management/vendor.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiClientUserVendorService {
	private readonly controller = 'vendorrisk';

  constructor( 
    private httpClientHelper: HttpClientHelperService
  ) { }

	getSystemRiskRating(systemId: string): Observable<RiskRatingResponse> {
		return this.httpClientHelper.invokeGetAuth<RiskRatingResponse>(`${this.controller}/SystemRiskRating/${systemId}`);
	}

	setSystemUsage(systemId: string, body: UsageScore): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/systemUsage/${systemId}`, body);
	}

	getRiskBatch(systems: SystemInstance[]): Observable<RiskBatchResponse> {
		let query = 'systems=' + systems.map(system => system.systemId).join('&systems=');
		return this.httpClientHelper.invokeGetAuth<RiskBatchResponse>(`${this.controller}/batch?${query}`);
	}

	getAllRiskRatings(): Observable<RisksRatingsResponse> {
		return this.httpClientHelper.invokeGetAuth<RisksRatingsResponse>(`${this.controller}/AllRisks`);
	}
}
