<label [attr.data-label]="label" (click)="toggle()" 
       class="panel__label" 
       [class.panel__label--disabled]="disableToggle">
    <ng-content select="[header]"></ng-content>    
</label>

<div *ngIf="!disableContent"
     [@showHide]="state"
     (@showHide.start)="onAnimationStart()"
     (@showHide.done)="onAnimationDone()"
     class="panel__content"
     #panelContent>
    <ng-content select="[content]"></ng-content>
</div>