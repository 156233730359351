import { CommonModule, KeyValue } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subscription, tap } from 'rxjs';

import { MineCheckboxComponent } from '../../mine-checkbox/mine-checkbox.component';
import { MineCheckboxGroupComponent } from '../../mine-checkbox-group/mine-checkbox-group.component';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { TableColumn } from '../../models/table-column.interface';

@Component({
  standalone: true,
  selector: 'mine-table-column-picker',
  templateUrl: './mine-table-column-picker.component.html',
  styleUrls: ['./mine-table-column-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule, ReactiveFormsModule, ContentPipeModule, MineCheckboxGroupComponent, 
    MineCheckboxComponent
  ]
})
export class MineTableColumnPickerComponent implements OnChanges {

  readonly panelStyle = {
    width: '28.4rem',
    maxHeight: '33rem',
    right: '0',
    padding: '0',
    marginTop: '5px',
    overflow: 'hidden',
    color: 'var(--mine-blue-dark)',
    position: 'absolute',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-between',
  };

  @Input()
  columns: Map<string, TableColumn> = new Map<string, TableColumn>();

  @Output()
  columnsChanged = new EventEmitter<Map<string, TableColumn>>();

  columnForm: FormGroup = new FormGroup({});

  private formSub: Subscription;

  private destroyRef = inject(DestroyRef);

  ngOnChanges(changes: SimpleChanges): void {
    this.formSub?.unsubscribe();

    const formControls = {};
    this.columns.forEach(c => {
      formControls[c.key] = new FormControl<boolean>({ value: !!c.inView, disabled: c.disabled });
    });
    this.columnForm = new FormGroup(formControls);
    
    this.formSub = this.columnForm.valueChanges.pipe(
      tap(res => this.columnChanges(res)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private columnChanges(columns: any): void {
    Object.entries(columns).forEach(c => {
      const newCol = {...this.columns.get(c[0]), inView: c[1] as boolean};
      this.columns.set(c[0], newCol);
    });

    this.columnsChanged.emit(this.columns);
  }

  originalOrder = (a: KeyValue<string, string[]>, b: KeyValue<string, string[]>): number => 0;
}