import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of, EMPTY, catchError, first, map, tap } from 'rxjs';
import { ApiClientUserVendorService } from '../../api/api-client-vendor-risk.service';
import { RiskRatingResponse, UsageScore } from '../../api/models/data-mapping/vendor-management/vendor.interface';
import { FilterCategory } from '../../core/models/filtering.interface';
import { ContentPipe } from '../../services/content/content.pipe';
import { RiskFilter } from '../vendor-filtering/vendor-filter.enum';
import { FeatureFlags } from '../../api/models/profile/profile-feature-flags.enum';
import { ActivatePortalDialogService } from '../../core/activate-portal-dialog/activate-portal-dialog.service';
import { FeatureFlagQuery } from '../../feature-flag/state/feature-flag.query';
import { SystemsQuery } from '../../systems/state/systems.query';
import { SystemsService } from '../../systems/state/systems.service';
import { RiskRating, RisksRatingsResponse } from '../models/risk-rating.interface';
import { SystemUsageEnum } from '../models/usage.enum';
import { VendorManagerService } from './vendor-manager.service';
import { VendorFilteringService } from '../vendor-filtering/vendor-filtering.service';
import { LoggerService } from '../../logger/logger.service';
import { SystemInstance } from 'src/app/systems/models/systems.interface';
import { VendorManagementQuery } from '../vendor-managment.query';
import { VendorManagementStore } from '../vendor-managment.store';

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  private readonly loggerName: string = 'VendorService';
  private activeFilters = new BehaviorSubject<FilterCategory[]>([]);

  constructor(private logger: LoggerService,
              private apiClientVendor: ApiClientUserVendorService,
              private systemService: SystemsService,
              private activatePortalDialogService: ActivatePortalDialogService,
              private featureFlagQuery: FeatureFlagQuery,
              private systemQuery: SystemsQuery,
              private contentPipe: ContentPipe,
              private vendorManager: VendorManagerService,
              private vendorManagementQuery: VendorManagementQuery,
              private vendorManagementStore: VendorManagementStore,
              private vendorFilteringService: VendorFilteringService) { }
  
  init(): Observable<void> {
    this.logger.debug(this.loggerName, 'init()');
    return this.setStore().pipe(map(() => void 0));
  }

  private setStore(): Observable<boolean> {
		return this.getAllRiskRatings().pipe(
			first(),
      map(res => res.items),
			map((res) => {
				const riskItems: RiskRating[] = [];
				for (let key of Object.keys(res)) {
					riskItems.push({systemId: key, ...this.addUsageToRisk(key, res[key])});
				}
				return riskItems;
			}),
			map((res) => this.vendorManagementStore.set(res)),
			map(() => true)
		);
	}

  setSystemUsage(systemId: string, score: UsageScore): Observable<number> {
    return this.apiClientVendor.setSystemUsage(systemId, score)
    .pipe(
      map(() => score.score),
      tap((score) => this.systemService.updateSystemUsageStore(systemId, score)),
      tap(() => this.updateSystemRisk(systemId))
    );
  }

  private getSystemRiskServer(systemId: string): Observable<RiskRatingResponse> {
    return this.apiClientVendor.getSystemRiskRating(systemId).pipe(
      tap(() => this.systemService.updateSystemUsageStore(systemId, null)),
      tap((res) => this.updateSystemRisk(systemId, res)),  
      catchError(error => {
        this.logger.error(this.loggerName, `getSystemRiskServer() Error: ${error.name} ,${error.message}`);
        return EMPTY;
      })
    );
  }

  private updateSystemRisk(systemId: string, riskRatingFromServer?: RiskRating): void {
    let riskRating: RiskRating = riskRatingFromServer ?? this.vendorManagementQuery.getEntity(systemId);
    if(!!riskRating){
      riskRating = this.addUsageToRisk(systemId, riskRating);
      this.vendorManagementStore.upsert(systemId, {systemId,...riskRating});
    } else {
      this.vendorManagementStore.upsert(systemId, null);
    }
  }

  private addUsageToRisk(systemId: string, riskRating: RiskRating): RiskRating {
    const systemUsage = this.systemQuery.getEntity(systemId)?.systemUsage;
    if(systemUsage){
      const usage = systemUsage.manuallySet ? systemUsage.score : systemUsage.discoveryScore;
      if (usage) {
        const usageCalc = this.vendorManager.getScoreValue(usage);
        const riskCalc = this.vendorManager.getRiskRating(usageCalc as SystemUsageEnum, +riskRating?.riskRating);
        riskRating = {...riskRating, riskCalc };
      }
    }
    return riskRating;
  }

  getRiskFromServer(systemId: string): Observable<RiskRatingResponse> {
    if (!this.featureFlagQuery.getFlag(FeatureFlags.PanoraysPlan)) { //don't allow customers that arent in panorays plan to get rating
      this.activatePortalDialogService.showPremiumDialog(FeatureFlags.PanoraysPlan);
      return of(null);
    }
    return this.getSystemRiskServer(systemId);
  } 

  getFilters(): FilterCategory[] {
    return [
      this.getRiskFilter(),
    ];
  }

  private getAllRiskRatings(): Observable<RisksRatingsResponse> {
    return this.apiClientVendor.getAllRiskRatings();
  }

  private getRiskFilter(): FilterCategory {
    const content = this.contentPipe.transform('vendor-management.filterOptions')[0];
    const selectedStatus = new Set<RiskFilter>();
    this.activeFilters.value[0]?.options.forEach(item => selectedStatus.add(RiskFilter[item.id]));
    
    return {
      label: content.label,
      options: [
        { label: content.options[0].label, id: RiskFilter.HasRisk, selected: selectedStatus.has(RiskFilter.HasRisk), },
        { label: content.options[1].label, id: RiskFilter.NoRisk, selected: selectedStatus.has(RiskFilter.NoRisk), },
      ]
    };
  }

  getSelectedSystems(): Observable<SystemInstance[]> {
    return combineLatest([
      this.vendorFilteringService.selectActiveFilters(), 
      this.systemQuery.selectSystemsWithDomain()])
    .pipe(
        map(([filters, systems]) => this.handleSorting(filters, systems))
      );
  }

  handleSorting(filters: FilterCategory[], systems: SystemInstance[]): SystemInstance[] {
    if (!filters || !filters[0]?.options || !filters[0]?.options.length) { //no filters
      return systems;
    }

    let filteredSystems: SystemInstance[] = [];
    
    if (filters[0].options.some(f => f.id === RiskFilter.HasRisk)) {
      systems.forEach(s => {
        if (!!this.vendorManagementQuery.getEntity(s.systemId)) {
          filteredSystems.push(s);
        }
      });
    }
    if (filters[0].options.some(f => f.id === RiskFilter.NoRisk)) {
      systems.forEach(s => {
        if (!this.vendorManagementQuery.hasEntity(s.systemId)) {
          filteredSystems.push(s);
        }
      });
    }
    return filteredSystems;
  }
}