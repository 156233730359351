import { Pipe, PipeTransform } from '@angular/core';
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { SystemsIconsQuery } from 'src/app/systems/state/systems-icons.query';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';

import { AiAssessmentTableEntity } from '../models/ai-assessments.interface';
import { AiAssessmentEntity } from '../../api/models/ai-assessments/ai-assessments.interface';
import { AiAssessmentEntityTypeEnum } from 'src/app/api/models/ai-assessments/ai-assessments.enum';
import { AiAssessmentsColumnEnum } from '../models/ai-assessments.enum';

@Pipe({
    standalone: true,
    name: 'relatedEntitiesArray'
})
export class RelatedEntitiesArrayPipe implements PipeTransform {

    constructor(
        private systemsQuery: SystemsQuery,
        private systemsIconsQuery: SystemsIconsQuery,
        private customValuesQuery: CustomValuesQuery,
    ) {}

    transform(relatedEntities: AiAssessmentEntity[], column: AiAssessmentsColumnEnum, identifierProp = 'id'): AiAssessmentTableEntity[] {
        switch (column) {
            case AiAssessmentsColumnEnum.DataSources:
                return this.selectRelatedEntites(relatedEntities, AiAssessmentEntityTypeEnum.System, identifierProp);
            
            case AiAssessmentsColumnEnum.DataSubjects:
                return this.selectRelatedEntites(relatedEntities, AiAssessmentEntityTypeEnum.DataSubject, identifierProp);

            case AiAssessmentsColumnEnum.BusinessUnit:
                return this.selectRelatedEntites(relatedEntities, AiAssessmentEntityTypeEnum.BusinessUnit, identifierProp);

            default:
                return [] as AiAssessmentTableEntity[];
        }
    }

    private selectRelatedEntites(relatedEntities: AiAssessmentEntity[], type: AiAssessmentEntityTypeEnum, identifierProp: string): AiAssessmentTableEntity[] {
        const entities = [] as AiAssessmentTableEntity[];
        relatedEntities.filter(entity => entity.type === type).forEach(entity => {
            switch (entity.type) {
                case AiAssessmentEntityTypeEnum.System:
                    const system = this.systemsQuery.getEntity(entity?.[identifierProp]);
                    if (system) {
                        entities.push({
                            id: entity.id,
                            type: type,
                            name: system.name,
                            iconPath: this.systemsIconsQuery.getIcon(system.systemType)
                        } as AiAssessmentTableEntity);
                    }
                    break;
                
                default:
                    entities.push({
                        id: entity.id,
                        type: type,
                        name: entity.isCustom ? this.customValuesQuery.getEntity(entity.id)?.name : entity.id,
                    } as AiAssessmentTableEntity);
                    break;
            }
        });
        
        return entities;
    }
}