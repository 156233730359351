import { RxState } from "@rx-angular/state";
import { rxActions } from "@rx-angular/state/actions";
import { DestroyRef, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, filter, finalize, first, map, merge, Observable, of, startWith, switchMap, tap } from "rxjs";

import { LoggerService } from "../../../../src/app/logger/logger.service";
import { ApiClientRisksService } from "../../../../src/app/api/api-client-risks.service";
import { Risk, RiskCatalog, RiskRateEnum, RiskRegistryFilteringGroupEnum, RiskRegistryFilters } from "src/app/api/models/risks/risks.interface";
import { RiskRegistryTableState } from "./risks-registry-table.state.interface";
import { TableColumn } from "src/app/shared/models/table-column.interface";
import { ContentPipe } from "src/app/services/content/content.pipe";
import { RiskRegistryActions } from "../models/risk-registry-actions.interface";
import { FilterCategory } from "src/app/core/models/filtering.interface";
import { SystemInstance } from "src/app/systems/models/systems.interface";
import { SentenceCasePipe } from "src/app/shared/pipes/sentence-case.pipe";
import { AiAssessmentsService } from "src/app/ai-assessments/services/ai-assessments.service";
import { RisksCatalogService } from "../services/risks-catalog.service";
import { CustomValuesQuery } from "src/app/company-settings/state/custom-values/custom-values.query";
import { CustomValueTypeEnum } from "src/app/api/models/company-settings/custom-values.enum";
import { CustomValue } from "src/app/api/models/company-settings/custom-values.interface";
import { AiAssessmentTemplateEnum } from "src/app/ai-assessments/models/ai-assessments.enum";

@Injectable({
    providedIn: 'root'
})
export class RiskRegistryTableStore extends RxState<RiskRegistryTableState> {

    private readonly loggerName: string = 'RiskRegistryTableStore';
    
    private readonly initState = {
        empty: undefined,
        loading: true,
        disabled: false,
        riskRegistry: undefined,
        columns: undefined,
        filters: undefined,
        search: undefined,
        sort: undefined,
        view: undefined,
    } as RiskRegistryTableState;

    public actions = rxActions<RiskRegistryActions>();

    private initial$ = new BehaviorSubject<Map<number, Risk>>(null);

    constructor(
        private logger: LoggerService,
        private apiClientRisksService: ApiClientRisksService,
        private contentPipe: ContentPipe,
        private destroyRef: DestroyRef,
        private sentenceCasePipe: SentenceCasePipe,
        private aiAssessmentsService: AiAssessmentsService,
        private risksCatalogService: RisksCatalogService,
        private customValuesQuery: CustomValuesQuery,
    ) {
        super();
        this.set(this.initState);
        this.set({ 'columns': this.getColumns() });
        
        this.getRisksFromServer().pipe(
            first(),
            tap(risks => this.initial$.next(null)),
            tap(risks => this.set({ 'riskRegistry': risks })),
        ).subscribe();

        const search$ = this.actions.changeSearchValue$.pipe(
            startWith(''),
            debounceTime(300),
            distinctUntilChanged(),
        );

        const filters$ = this.actions.changeFiltersValue$.pipe(
            map(filters => this.getQueryParams(filters)),
            startWith({})
        );

        const sort$ = this.actions.changeSortValue$.pipe(
            startWith(null)
        );

        const riskDeleted$ = this.actions.riskDeleted$.pipe(
            startWith(null),
        );

        const updateAssessment$ = this.actions.updateAssessment$.pipe(
            startWith(null),
        );

        merge([updateAssessment$, riskDeleted$]).pipe(
            switchMap(() => this.getRisksFromServer()),
            tap(risks => this.initial$.next(risks)),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe();

        this.connect(
            'riskRegistry',
            combineLatest([search$, filters$, sort$, riskDeleted$, updateAssessment$]).pipe(
                switchMap(([search, filters, sort]) =>
                    this.apiClientRisksService.getRiskRegistry(search, filters as RiskRegistryFilters, sort).pipe(
                        map(risks => new Map(risks.map(risk => [risk.id, risk])))
                    )
                )
            )
        );

        this.connect('filters', this.selectFilters());
        
        this.connect('loading', this.getInitialData().pipe(
            map(() => false)
        ));

        this.connect('empty', this.getInitialData().pipe(
            map(risks => !risks?.size)
        ));
    }

    getInitialData(): Observable<Map<number, Risk>> {
        return this.initial$.asObservable();
    } 

    private getColumns(): Map<string, TableColumn> { //danny
        let columns: TableColumn[] = [...this.contentPipe.transform('risks.riskRegistry.tableColumns')];
        
        const map = new Map<string, TableColumn>();

        for (let column of columns) {
            map.set(column.key, column);
        }

        return map;
    }

    private getRisksFromServer(): Observable<Map<number, Risk> | undefined> {
        return this.apiClientRisksService.getRiskRegistry().pipe(
            first(),
            map(risks => new Map(risks.map(risk => ([risk.id, risk])))),
            catchError(err => {
                console.error(err);
                this.logger.error(this.loggerName, err);
                return of(new Map());
            }),
        );
    }

    selectFilters(): Observable<FilterCategory[]> { //danny
        return this.select('loading').pipe(
            filter(loading => !loading),
            switchMap(() => combineLatest([
                this.selectAssessmentTypesFilterGroup(),
                this.selectDataSourcesFilterGroup(),
                this.selectDataTypesFilterGroup(),
                this.selectMitigationsFilterGroup()
            ])),
            map(([assessmentTypesFilter, dataSourcesFilter, riskTypesFilter, mitigationsTypesFilter]) => {
                
                const residualRiskFilter = 
                    this.getRiskFilterGroup(RiskRegistryFilteringGroupEnum.ResidualRisk, 
                        this.contentPipe.transform('risks.riskRegistry.residualRiskFilterGroup'));
                        
                const inherentRiskFilter = 
                    this.getRiskFilterGroup(RiskRegistryFilteringGroupEnum.InherentRisk, 
                        this.contentPipe.transform('risks.riskRegistry.inherentRiskFilterGroup'));

                const filters = [inherentRiskFilter, residualRiskFilter];
   
                if(assessmentTypesFilter?.options?.length) {
                    filters.push(assessmentTypesFilter);
                }

                if (dataSourcesFilter?.options?.length) {
                    filters.push(dataSourcesFilter);
                }

                if(riskTypesFilter?.options?.length) {
                    filters.push(riskTypesFilter);
                }

                if(mitigationsTypesFilter?.options?.length) {
                    filters.push(mitigationsTypesFilter);
                }
                
                return filters;
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private getRiskFilterGroup(id: string, label: string): FilterCategory {
        return {
            id,
            label,
            options: Object.keys(RiskRateEnum)?.map(rate => ({
                id: rate,
                label: rate,
                selected: false
            }))
        } as FilterCategory;
    }

    private selectAssessmentTypesFilterGroup(): Observable<FilterCategory> {
        return this.aiAssessmentsService.selectUniqueAssessmentsTypesWithRisks().pipe(
            first(),
            map(assessmentTypes => this.getTypesFilterGroup(assessmentTypes)),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private selectDataSourcesFilterGroup(): Observable<FilterCategory> {
        return this.aiAssessmentsService.selectUniqueDataSourcesWithRisks().pipe(
            first(),
            map(dataSources => this.getDataSourcesFilterGroup(dataSources)),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private selectDataTypesFilterGroup(): Observable<FilterCategory> {
        return this.risksCatalogService.selectRisksCatalog(true).pipe(
            first(),
            map(risksCatalog => this.getRiskTypesFilterGroup(risksCatalog as RiskCatalog[])),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private selectMitigationsFilterGroup(): Observable<FilterCategory> {
        return this.customValuesQuery.selectCustomValuesByType(CustomValueTypeEnum.Mitigation).pipe(
            first(),
            map(mitigations => this.getMitigationsFilterGroup(mitigations)),
            takeUntilDestroyed(this.destroyRef)
        );
    }

    private getTypesFilterGroup(types: string[]): FilterCategory {
        const cmsTypes = this.contentPipe.transform('ai-assessments.types');
        const options = types.reduce((acc, curr) => {
            if (!acc.some(item => cmsTypes[AiAssessmentTemplateEnum[item]] === cmsTypes[AiAssessmentTemplateEnum[curr]])) {
                acc.push(curr);
            }
            return acc;
        }, []).sort((a, b) => a.localeCompare(b));

        return {
            id: RiskRegistryFilteringGroupEnum.AssessmentType,
            label: this.contentPipe.transform('ai-assessments.typeFilterGroup'),
            options: options.map(type => ({
                id: type,
                label: cmsTypes[type],
                selected: false
            }))
        } as FilterCategory;
    }

    private getMitigationsFilterGroup(customValues: CustomValue[]) {
        const predefinedOptions = this.contentPipe.transform('ropa.mitigations')
            .map(({ key, value }) => ({ id: key, label: value}));

        const customOptions = customValues?.map(customValue => ({
            id: customValue.id,
            label: this.sentenceCasePipe.transform(customValue.name),
            selected: false
        }));

        return {
            id: RiskRegistryFilteringGroupEnum.Mitigation,
            label: this.contentPipe.transform('risks.riskRegistry.mitigationFilterGroup'),
            options: [...predefinedOptions, ...customOptions].sort((a, b) => a.label?.localeCompare(b.label)) ?? []
        } as FilterCategory;
    }
    
    private getDataSourcesFilterGroup(dataSources: SystemInstance[]): FilterCategory {
        return {
            id: RiskRegistryFilteringGroupEnum.DataSource,
            label: this.contentPipe.transform('risks.riskRegistry.dataSourceFilterGroup'),
            options: dataSources?.map(dataSource => ({
                id: dataSource.systemId,
                label: this.sentenceCasePipe.transform(dataSource.name),
                selected: false
            })).sort((a, b) => a.label?.localeCompare(b.label)) ?? []
        } as FilterCategory;
    }

    private getRiskTypesFilterGroup(riskCatalogs: RiskCatalog[]): FilterCategory {

        return {
            id: RiskRegistryFilteringGroupEnum.RiskType,
            label: this.contentPipe.transform('risks.riskRegistry.riskTypeFilterGroup'),
            options: riskCatalogs?.map(riskCatalog => ({
                id: riskCatalog.type,
                label: this.sentenceCasePipe.transform(riskCatalog.name),
                selected: false
            })).sort((a, b) => a.label?.localeCompare(b.label)) ?? []
        } as FilterCategory;
    }

    private getQueryParams(filterCategories: FilterCategory[]): RiskRegistryFilters {
        if (!filterCategories) return null;

        let filters: RiskRegistryFilters;
        
        for (let filter of filterCategories) {
            if(filter.id === RiskRegistryFilteringGroupEnum.InherentRisk) {
                filters = { ...filters, inherentRisk: filter.options.map(o => o.id) };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.ResidualRisk) {
                filters = { ...filters, residualRisk: filter.options.map(o => o.id) };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.Mitigation) {
                filters = { ...filters, mitigations: filter.options.map(o => o.label)?.join(',') };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.DataSource) {
                filters = { ...filters, dataSources: filter.options.map(o => o.id).join(',') };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.RiskType) {
                filters = { ...filters, riskTypes: filter.options.map(o => o.id) };
            }

            if(filter.id === RiskRegistryFilteringGroupEnum.AssessmentType) {
                filters = { ...filters, assessmentTypes: filter.options.map(o => o.id).join(',') };
            }
        }

        return filters;
    }

    setActiveFilters(updatedFilters: FilterCategory[]): void {        
        this.selectFilters().pipe(
            first(),
            tap(filters => this.set({ filters })),
            finalize(() => {
                const reduceFn = (oldState: RiskRegistryTableState) => ({
                    filters: oldState.filters.map(filter => {
                        const id = filter.id;
                        const updatedFilter = updatedFilters.find(f => f.id === id);
                        for (let o of filter.options) {
                            o.selected = updatedFilter?.options.find(option => option.id === o.id)?.selected ?? false;
                        }
                        return filter;
                    })
                });
                this.set(reduceFn);
            })).subscribe();
    }

    updateRisk(updatedRisk: Risk): void {
        const risksCatalog = this.get('riskRegistry');
        risksCatalog.set(updatedRisk.id, updatedRisk);
        this.set('riskRegistry', () => new Map(risksCatalog.entries()));
    }
}