<mine-button-tertiary class="group__btn"
	[isDisabled]="disabled" 
	[btnTheme]="btnTheme" 
	[tooltip]="tooltip"
	(onClick)="listOpen = !listOpen">

	<ng-content select="[mine-checkbox-group-label]"></ng-content>

</mine-button-tertiary>

<div class="group__panel" tabindex="0" 
	[attr.id]="id" 
	[ngStyle]="panelStyle"
	[ngClass]="{'group__panel--disabled': !!disabled, 'group__panel--open': listOpen}">

	<ng-container *ngIf="listOpen">

		<ng-content></ng-content>

	</ng-container>

</div>