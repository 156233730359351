import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MineButtonDirective } from '@mineos/mine-ui';
import { DialogRef } from '@ngneat/dialog';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { CustomValuesService } from 'src/app/company-settings/state/custom-values/custom-values.service';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { MineInputComponent } from 'src/app/shared/mine-input/mine-input.component';
import { NoWhitespaceValidator } from 'src/app/shared/validators/input-validators';
import { FieldTypeToTextPipe } from '../../../pipes/field-type-to-text.pipe';
import { first, tap } from 'rxjs';
import { customValueNameValidator } from './new-custom-value-dialog.validators';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';

@Component({
  standalone: true,
  selector: 'new-custom-value-dialog',
  templateUrl: './new-custom-value-dialog.component.html',
  styleUrls: ['./new-custom-value-dialog.component.scss'],
  imports: [
    CommonModule,
    MineInputComponent,
    MineButtonDirective,
    FormsModule,
    ReactiveFormsModule,
    ContentPipeModule,
    FieldTypeToTextPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewCustomValueDialogComponent implements OnInit {

  fieldType: CustomValueTypeEnum;

  nameCtrl: FormControl;

  constructor(
    private dialogRef: DialogRef,
    private customValuesService: CustomValuesService,
    private customValuesQuery: CustomValuesQuery,
  ) {}

  ngOnInit(): void {
    this.fieldType = this.dialogRef.data;
    this.nameCtrl = new FormControl('', [Validators.required, NoWhitespaceValidator, customValueNameValidator(this.customValuesQuery, this.customValuesService.getPredefinedValues(this.fieldType), this.fieldType)]);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  createCustomValue(): void {
    if (this.nameCtrl.valid) {
      this.customValuesService.addCustomValue(this.fieldType, this.nameCtrl.value).pipe(
        first(),
        tap(res => this.dialogRef.close(res)),
      ).subscribe();
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.createCustomValue();
  }
}
