import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContentPipe } from 'src/app/services/content/content.pipe';
import { DialogsManagerService } from 'src/app/services/dialogs-manager.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.interface';

@Injectable({
  providedIn: 'root'
})
export class LeavePageGuard implements CanDeactivate<ConfirmationDialog> {

  private readonly defaultConfig = {
    title: this.contentPipe.transform('common.leavePageTitle'),
    okBtn: this.contentPipe.transform('common.leave'),
    cancelBtn: this.contentPipe.transform('common.stay'),
    showXBtn: true,
  };

  constructor(
    private contentPipe: ContentPipe,
    private dialogService: DialogsManagerService
  ) { }

  //don't forget to implement LeavePageAbstract with leavePageConfig (defining dialog content) and skipGuard (if form is dirty/not dirty)
  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
      const someActiveExtraGuards = component?.extraGuards && component.extraGuards.some((guard: () => boolean) => guard());
      
      if (component.skipGuard && !someActiveExtraGuards) {
        return true;
      }
      const dialogRef = this.dialogService.openDialogCommon(ConfirmationDialogComponent, component.leavePageConfig ?? this.defaultConfig);
      return dialogRef.afterClosed$;
  }
}
