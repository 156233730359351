import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { IntegrationIpaasService } from '../systems/services/integration-ipaas.service';
import { ActivatedRoute } from '@angular/router';
import { first, map, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MineButtonDirective } from '@mineos/mine-ui';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MineButtonDirective,
  ],
  selector: 'setup-non-dsr-integration',
  templateUrl: './setup-non-dsr-integration.component.html',
  styleUrl: './setup-non-dsr-integration.component.scss'
})
export class SetupNonDsrIntegrationComponent implements OnInit {

  authSuccess: WritableSignal<boolean> = signal(false);

  constructor(
    private route: ActivatedRoute,
    private ipaasService: IntegrationIpaasService,
  ) { }

  ngOnInit(): void {
    this.ipaasService.authenticate().pipe(first(), tap(() => this.authSuccess.set(true))).subscribe();
  }

  setup(): void {
    this.route.params.pipe(
      first(),
      map(params => params['integrationId']),
      tap(integrationId => this.ipaasService.configureNonDsrIntegrationInstance(integrationId)),
    ).subscribe();
  }
}
