import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SanitizationService {

  constructor(private sanitizer: DomSanitizer) {}

  // Generic sanitizeText function
  sanitizeText(text: string): string {
    const sanitized = this.sanitizer.sanitize(1, text?.toLowerCase());
    return sanitized || '';
  }
}