import { Component, OnInit, HostListener, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { Observable, filter, first, tap } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { environment } from 'src/environments/environment';
import { ChatService } from 'src/app/chat/chat.service';
import { AuthProviders } from 'src/app/api/models/auth/auth-providers';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { Animations } from 'src/app/animations/animations';
import { Profile } from 'src/app/profile/models/profile.interface';

// WILL BE REMOVED //
@Component({
	selector: 'app-menu-temporary',
	templateUrl: './app-menu-temporary.component.html',
	styleUrls: ['./app-menu-temporary.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [Animations.fadeInOut]
})
export class AppTemporaryMenuComponent implements OnInit {
	environment = environment;

	profile$: Observable<Profile>;
	profilePicture: string;

	checked: boolean;

	constructor(private profileQuery: ProfileQuery,
				private authService: AuthService,
				private router: Router,
				private intercom: ChatService,
				private elementRef: ElementRef) { }

	ngOnInit() {
		this.profile$ = this.profileQuery.select();
	}

	@HostListener('document:click', ['$event.target'])
	onClick(targetElement) {
		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.closeProfileMenu();
		}
	}

	onLogout(): void {
		const isAuth0Provider = this.profileQuery.getValue()?.identityProvider?.toLowerCase() === AuthProviders.auth0;
		this.authService.logout().pipe(
			first(),
			filter(() => !isAuth0Provider),
			tap(() => this.router.navigate([RoutesManager.login])),
		).subscribe();
	}

	onFeedbackClick() {
		this.closeProfileMenu();
		this.intercom.show();
	}
	
	closeProfileMenu() {
		this.checked = false;
	}

	setFallbackIcon(): void {
		this.profilePicture = `${this.environment.assets.url}/fallback-avatar-124-x-124-px.png`;
	}
}