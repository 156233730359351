import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, ViewChild, EventEmitter, Output, Input, HostBinding } from '@angular/core';
import { map, tap, Observable } from 'rxjs';
import { AnimationStateEnum } from 'src/app/animations/animation-state.enum';
import { Animations } from 'src/app/animations/animations';
import { OnboardingTasks } from 'src/app/api/models/profile/profile-onboarding-tasks.interface';
import { OnboardingTasksService } from 'src/app/services/onboarding-tasks/onboarding-tasks.service';
import * as confetti from 'canvas-confetti';

@Component({
  selector: 'onboarding-progress-bar',
  templateUrl: './onboarding-progress-bar.component.html',
  styleUrls: ['./onboarding-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.progressBar, Animations.glow, Animations.fadeOut, Animations.enterFromBottom]
})
export class OnboardingProgressBarComponent implements OnInit {

  private readonly prograssBarWidth = 120;
  private readonly confettiColors = ['#7552F6', '#9897EE', '#BFBFF3', '#F6F4FD'];
  
  progress$: Observable<string>;
  numberOfTasks: number = 0;
  doneTasks: string[] = [];
  
  startGlowing: AnimationStateEnum;
  private showConfetti = false;

  openedTasks = true;
  fadeOutAfterCompletedTasks: AnimationStateEnum;

	@ViewChild('confettiCanvasBar', { static: true })
  confettiCanvasBar: ElementRef;
  
  @HostBinding('class.shrink') @Input() shrink: boolean;

  @Output() progressFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
      private onboardingTasksService: OnboardingTasksService,
      private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.progress$ = this.onboardingTasksService.onboardingTasks$.pipe(
      tap(tasks => this.numberOfTasks = Object.keys(tasks).length),
      tap(() => this.celebrate()),
      map(tasks => this.calculateProgress(tasks)),
      tap(() => this.cdr.detectChanges())
    );
  }

  private calculateProgress(tasks: OnboardingTasks): string {
    const doneTasks = new Set<string>();
      for (const task in tasks) {
        if (tasks[task]) {
          doneTasks.add(task);
        }
      }
    this.doneTasks = Array.from(doneTasks.values());
    return `${(this.prograssBarWidth/this.numberOfTasks)*this.doneTasks.length}px`;
  }

  celebrate(): void {
    this.startGlowing = AnimationStateEnum.Show;    

    if (!this.showConfetti) {
      this.showConfetti = true;
      return;
    }
    
    const myConfetti = confetti.create(this.confettiCanvasBar.nativeElement, {
      resize: true,
      useWorker: true,
    });

    myConfetti({
      colors: this.confettiColors,
      spread: 50,
      startVelocity: 35,
      particleCount: 100,
      origin: { y: -0.7 }
    });

    this.cdr.detectChanges();
	}

  stopGlowing():void {
		this.startGlowing = AnimationStateEnum.Hide;		   

    if (this.doneTasks.length === this.numberOfTasks) {
      setTimeout(() => this.fadeOutAfterCompletedTasks = AnimationStateEnum.Show, 700);
    }
  }

  onboardingTasksFinished(): void {
    if (this.doneTasks.length === this.numberOfTasks) {
      this.openedTasks = false;
    }
  }

  destroyComponent(): void {
      this.progressFinished.emit(); 
  }
}
