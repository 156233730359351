import { ChangeDetectionStrategy, Component, computed, OnInit, Signal } from '@angular/core';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { CommonModule } from '@angular/common';
import { RxIf } from '@rx-angular/template/if';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { MineInfoBoxComponent } from 'src/app/shared/mine-info-box/mine-info-box.component';
import { BoxBgColor } from 'src/app/shared/mine-info-box/mine-info-box.enum';
import { RisksCatalogService } from '../services/risks-catalog.service';
import { RiskCatalog } from 'src/app/api/models/risks/risks.interface';
import { Animations } from 'src/app/animations/animations';
import { SkeletonDirective } from 'src/app/shared/skeletons/skeleton.directive';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { MineButtonDirective } from '@mineos/mine-ui';
import { CustomRiskDialogComponent } from '../custom-risk-dialog/custom-risk-dialog.component';
import { filter, first, map, switchMap } from 'rxjs';
import { DialogsManagerService } from 'src/app/services/dialogs-manager.service';
import { RandomUUIDPipe } from 'src/app/shared/pipes/random-uuid.pipe';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

const rxAngularDirectives = [
  RxIf,
  RxFor,
  RxLet,
];

enum RiskCatlogSectionTypeEnum  {
  Custom = 'custom',
  Default = 'default',
  Hidden = 'hidden'
}

@Component({
  selector: 'risk-catalog-panel',
  standalone: true,
  templateUrl: './risk-catalog-panel.component.html',
  styleUrls: ['./risk-catalog-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeInOut],
  imports: [
    ContentPipeModule,
    CommonModule,
    MineInfoBoxComponent,
    ...rxAngularDirectives,
    SkeletonDirective,
    MineButtonDirective,
  ],
  providers: [RandomUUIDPipe]
})
export class RiskCatalogPanelComponent implements OnInit {
  constructor (
    private risksCatloagService: RisksCatalogService,
    private contentPipe: ContentPipe,
    private dialogManager: DialogsManagerService,
    private randomUUIDPipe: RandomUUIDPipe
  ) {}

  private readonly deleteRiskConfirmation = {
    title: this.contentPipe.transform('risks.deleteRiskConfirmationDialog.title'),
    text:  this.contentPipe.transform('risks.deleteRiskConfirmationDialog.text'),
    cancelBtn: this.contentPipe.transform('risks.deleteRiskConfirmationDialog.cancel'),
    okBtn: this.contentPipe.transform('risks.deleteRiskConfirmationDialog.deleteAnyway'),
  };
  
  readonly infoBoxBgColor = BoxBgColor.Grey;
  readonly RiskCatlogSectionTypeEnum = RiskCatlogSectionTypeEnum;
  readonly orderedRiskCatalogSections = [RiskCatlogSectionTypeEnum.Custom, RiskCatlogSectionTypeEnum.Default, RiskCatlogSectionTypeEnum.Hidden];

  risksBySectionMap: Signal<Map<RiskCatlogSectionTypeEnum, RiskCatalog[]>>;
  dataBySectionMap = new Map<RiskCatlogSectionTypeEnum, { [key: string]: string | boolean }>();

  loading: Signal<boolean>;

  ngOnInit() {
    this.loading = this.risksCatloagService.getLoading();
    this.initSectionData();
    this.mapRisksBySection();
  }

  private initSectionData(): void {
    this.dataBySectionMap.set(RiskCatlogSectionTypeEnum.Custom, {
       title: this.contentPipe.transform('risks.riskCatalogPanel.customRisksTitle'),
       noResults: this.contentPipe.transform('risks.riskCatalogPanel.noCustomRisks'),
       isPredefined: false,
      });
    this.dataBySectionMap.set(RiskCatlogSectionTypeEnum.Default, { 
      title: this.contentPipe.transform('risks.riskCatalogPanel.defaultRisksTitle'),
      noResults: this.contentPipe.transform('risks.riskCatalogPanel.noDefaultRisks'),
      actionText: this.contentPipe.transform('risks.riskCatalogPanel.hide'),
      isPredefined: true,
    });
    this.dataBySectionMap.set(RiskCatlogSectionTypeEnum.Hidden, {
      title: this.contentPipe.transform('risks.riskCatalogPanel.hiddenRisksTitle'),
      noResults: this.contentPipe.transform('risks.riskCatalogPanel.noHiddenRisks'),
      actionText: this.contentPipe.transform('risks.riskCatalogPanel.unhide'),
      isPredefined: true,
    });
  }

  onNewRiskClick(): void {
    const ref = this.dialogManager.openDialogCommon(CustomRiskDialogComponent, null, "385px");
    ref.afterClosed$.pipe(
      first(),
      filter(res => !!res),
      map(name => ({ 
        name,
        type: this.randomUUIDPipe.transform(), 
        isHidden: false,
        isDefault: false 
      })),
      switchMap(riskCatalog => this.risksCatloagService.createRiskCatalog(riskCatalog)),
      first()
    ).subscribe();
  }

  onExistingRiskClick(riskItem: RiskCatalog): void {
    const ref = this.dialogManager.openDialogCommon(CustomRiskDialogComponent, { name: riskItem.name }, "385px");
    ref.afterClosed$.pipe(
      first(),
      filter(res => !!res),
      map(name => ({ 
        ...riskItem,
        name,
      })),
      switchMap(riskCatalog => this.risksCatloagService.updateRiskCatalog(riskCatalog.id, riskCatalog)),
      first()
    ).subscribe();
  }

  trackByFn(index: number, item: RiskCatalog) {
      return item.type;
  }

  toggleCatalogItemVisibility(riskCatalogItem: RiskCatalog): void {
    const updatedRiskItem = { ...riskCatalogItem, isHidden: !riskCatalogItem.isHidden };
    this.risksCatloagService.updateRiskCatalogByType(riskCatalogItem.type, updatedRiskItem).subscribe();
  }

  deleteRiskCatalog({ type, id }) {
    this.dialogManager.openDialogCommon(ConfirmationDialogComponent, this.deleteRiskConfirmation, '430px').afterClosed$.pipe(
      filter(res => res),
      switchMap(() => this.risksCatloagService.deleteRiskCatalog(id, type)),
      first()
    )
    .subscribe();
  }

  private mapRisksBySection() {
    const risksCatalogSignal = this.risksCatloagService.getRisksCatalog(true);

    this.risksBySectionMap = computed(() => {
        const risksBySectionMap = new Map<RiskCatlogSectionTypeEnum, RiskCatalog[]>();

        // Initialize sections
        Object.values(RiskCatlogSectionTypeEnum).forEach(sectionType => {
            risksBySectionMap.set(sectionType, []);
        });

        const catalogItems = risksCatalogSignal();

        catalogItems.forEach((catalogItem: RiskCatalog) => {
            if (!catalogItem.isDefault) {
                risksBySectionMap.get(RiskCatlogSectionTypeEnum.Custom).push(catalogItem);
            } else if (catalogItem.isHidden) {
                risksBySectionMap.get(RiskCatlogSectionTypeEnum.Hidden).push(catalogItem);
            } else {
                risksBySectionMap.get(RiskCatlogSectionTypeEnum.Default).push(catalogItem);
            }
        });

        // Sort each section alphabetically
        risksBySectionMap.forEach((risks, sectionType) => {
            risks.sort((a, b) => a.name?.localeCompare(b.name));
        });

        return risksBySectionMap;
    });
  }
}
