import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[mineHighlightSubStr]'
})
export class MineHighlightSubStrDirective implements OnChanges {

	@Input() string: string;

	@Input() search: string;

	constructor(private elm: ElementRef) { }

	ngOnChanges() {
		let temp = '' + this.string;
		this.search?.split(' ').forEach(str => {
			if (!!str && !!temp) {
				const regex = new RegExp(str, 'gi');
				temp = temp.replace(regex, match => {
					return `<mark>${match}</mark>`;
				});
			}
		});
		
		this.elm.nativeElement.innerHTML = temp;
	}
}
