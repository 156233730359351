import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { inject } from '@angular/core';

import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { LoggerService } from 'src/app/logger/logger.service';
import { AuthService } from '../auth.service';

const loggerName: string = 'LoggedInUserGuard';

const LoggedInUserGuardFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const logger = inject(LoggerService);
    const authService = inject(AuthService);

    // Block for users who are authenticated
    const tree: UrlTree = router.parseUrl(RoutesManager.homePage);

    return authService.isLoggedIn$.pipe(
      tap(isLoggedIn => logger.debug(loggerName, `isLoggedIn: ${isLoggedIn}`)),
      map(response => response ? tree : true),
    );   
};

export const LoggedInUserGuard: CanMatchFn = LoggedInUserGuardFn;
