import { CommonModule } from "@angular/common";
import { Component, ChangeDetectionStrategy, Input, HostListener, HostBinding, OnInit } from "@angular/core";
import { AnalyticsService } from "src/app/analytics/analytics.service";
import { EventActionType } from "src/app/analytics/event-action.enum";
import { AuthProviders } from "src/app/api/models/auth/auth-providers";
import { ContentPipeModule } from "src/app/services/content/content-pipe.module";

@Component({
	selector: "mine-provider-button",
	templateUrl: "./mine-provider-button.component.html",
	styleUrls: ["./mine-provider-button.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, ContentPipeModule]
})
export class MineProviderButtonComponent implements OnInit {

	@Input() provider: string;

	@Input() id: string = 'Provider button';

	AuthProviders = AuthProviders;

	constructor(private analytics: AnalyticsService) {}

	ngOnInit(): void {
		if (!Object.values(AuthProviders).includes(<AuthProviders>this.provider)) {
			this.provider = 'default';
		}
	}

    @HostBinding('class')
    get themeClass() {
        return `${this.provider}`;
    }

	@HostListener('click', [])
	onClick(){
		this.analytics.track({ eventCategory: `${this.id} - ${this.provider}`, eventAction: EventActionType.Click });
	}
}
