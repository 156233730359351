import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, tap, map } from 'rxjs';
import { Dpo } from 'src/app/api/models/onboarding/dpo';
import { Company } from './models/company.interface';
import { ProfileQuery } from '../profile/state/profile.query';
import { ProfileService } from '../profile/state/profile.service';
import { LoggerService } from '../logger/logger.service';
import { ApiClientOnboardingService } from 'src/app/api/api-client-onboarding.service';
import { VerifyUserResponse } from '../api/models/onboarding/verify-user-response';
import { LocalStorageHelper } from '../services/localStorage-helper';
import { Profile } from '../profile/models/profile.interface';

@Injectable({
	providedIn: 'root'
})
export class OnboardingService {
	private readonly loggerName: string = 'OnboardingService';

	private companyName = new BehaviorSubject<string>(null);
	companyName$ = this.companyName.asObservable();

	private companyDpo = new BehaviorSubject<Dpo>(null);
	companyDpo$ = this.companyDpo.asObservable();

	constructor(private profileQuery: ProfileQuery,
				private profileService: ProfileService,
				private apiClientOnboarding: ApiClientOnboardingService,
				private logger: LoggerService) { }

	getProfileEmail(): Observable<string> {
		return this.profileQuery.select().pipe(map(res => res.email));
	}

	getProfileCompletedOnboarding(): Observable<boolean> {
		return this.profileQuery.select().pipe(map(res => res.completedOnboarding));
	}

	getDpo(): Observable<Dpo> {
		if (this.companyDpo.value) {
			return this.companyDpo$;
		}
		else {
			return this.apiClientOnboarding.getDpoFromDomain().pipe(
				tap(() => this.logger.info(this.loggerName, 'getDpo() succeeded')),
				tap(res => this.companyDpo.next(res)));
		}
	}

	createCompanyV2(company: Company): Observable<Dpo> {
		return this.apiClientOnboarding.createCompanyV2(company).pipe(
			tap(() => this.logger.info(this.loggerName, 'createCompanyV2() succeeded')),
			tap(res => this.companyDpo.next(res)));
	}
	
	sendDomainCompanyName(companyName?: string): Observable<void> {
		const company: Company = { 'companyName': companyName ?? this.companyName.value };

		return this.apiClientOnboarding.setDomainData(company).pipe(
			tap(() => this.logger.info(this.loggerName, 'setDomainData() succeeded'))
		);
	}

	setCompletedOnboarding(): Observable<void> {
		return this.apiClientOnboarding.setCompletedOnboarding().pipe(
				tap(() => this.logger.info(this.loggerName, 'setCompletedOnboarding() succeeded')),
				tap(() => LocalStorageHelper.removeOnboardingRedirect())
			);
	}

	companyNameUpdate(companyName: string): void {
		this.companyName.next(companyName);
	}

	approveUser(token: string): Observable<Partial<VerifyUserResponse>> {
		return this.apiClientOnboarding.approveUser(token);
	}

	updateProfile(): Observable<Profile> {
		return this.profileService.getUserProfile();
	}
}
