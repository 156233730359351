<ng-content select="[icon]"></ng-content>

<p class="view-item__label" 
    [innerText]="label" 
    [tp]="label" 
    [tpOnlyTextOverflow]="true">
</p>

<ng-content select="[indicator]"></ng-content>

<ng-content select="[count]"></ng-content>

<ng-content select="[menu-actions]"></ng-content>