import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { GoogleAnalyticsService } from '../analytics/google-analytics.service';
import { Observable } from 'rxjs';
import { HttpConsts } from "../api/http-consts";
import { LocalStorageHelper } from "../services/localStorage-helper";
import { environment } from "src/environments/environment";
import { AuthService } from '../auth/auth.service';
import { ProfileQuery } from "../profile/state/profile.query";

@Injectable({
    providedIn: "root"
})
export class HttpHeadersInterceptor implements HttpInterceptor {
    public headers;
    private addWithCradentials = false;

    constructor(
        private analytics: GoogleAnalyticsService,
        private authService: AuthService,
        private profileQuery: ProfileQuery
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.headers = {
            'session-id': this.analytics.getSessionId(),
        };
        
        if (!(req.body instanceof FormData)) {
            // Only set the Content-Type header for non-FormData requests. FormData is the Type that allows to send File
            this.headers['Content-Type'] = 'application/json; charset=utf-8';
        }

        this.addWithCradentials = false;
        this.handleHeader(req);

        const requestWithHeaders = req.clone({ setHeaders: this.headers, withCredentials: this.addWithCradentials });
        return next.handle(requestWithHeaders);
    }

    private handleHeader(req: HttpRequest<any>): void {
        if (req.url.startsWith(`${environment.api.accessPointUrl}`)) {
            this.addWithCradentials = true;
            const userId = this.authService.getLoggedInUserId();
            const companyId = this.getCompanyId(userId);

            this.setCompanyId(companyId);
        }
    }

    private getCompanyId(userId: string): string | undefined {
        const storedCompanyId = this.profileQuery.getCompanyId();

        return storedCompanyId ? storedCompanyId : LocalStorageHelper.getLastConnectedCompanyId(userId);
    }

    private setCompanyId(companyId: string): void {
        if (companyId) {
            this.headers[HttpConsts.companyId] = companyId;
        }
    }
}