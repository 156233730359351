<header class="panel__header">

	<div class="panel__header-icon">
		<svg class="panel__header-svg">
			<use xlink:href="../../../assets/img/sprite.svg#icon-hexagon-warning"></use>
		</svg>
	</div>

	<span class="panel__primary-label">{{ 'risks.riskCatalogPanel.title' | content }}</span>

</header>

<div class="panel__content">
    <mine-info-box class="panel__info" [bgColor]="infoBoxBgColor">
        <svg class="panel__info-icon" box-icon>
            <use xlink:href="../../../assets/img/sprite.svg#icon-question-mark"></use>
        </svg>
        <p box-content>
            {{ 'risks.riskCatalogPanel.infoBoxText' | content }}
        </p>
    </mine-info-box>

    <ng-container *rxFor="let sectionType of orderedRiskCatalogSections">
        <div class="panel__section-header">
            <p class="panel__section-title">{{ dataBySectionMap.get(sectionType).title }}</p>
            <button *rxIf="!dataBySectionMap.get(sectionType).isPredefined" mine-button color="secondary"
                (click)="onNewRiskClick()"
                data-cy="panel__add-risk"
                class="panel__add-risk">
                {{ 'risks.riskCatalogPanel.newRiskButton' | content }}
            </button>
        </div>

        <div class="panel__section-content" [attr.data-cy]="'panel__section-content--' + sectionType">
            <ng-container *rxIf="loading(); else risksCatalogData">

                <div *rxFor="let riskItem of [1,2,3]; index as i" class="panel__risk-item">
                    <div *skeleton="true; width: '3rem'; height: '3rem';"></div>
                    <p *skeleton="true; width: '7rem'; height: '1rem'; style: 'margin-left: 1.2rem';"></p>
                </div>
            
            </ng-container>
            
            <ng-template #risksCatalogData>
            
                    <ng-container *rxLet="risksBySectionMap().get(sectionType) as risks">
                        <ng-container *rxLet="dataBySectionMap.get(sectionType) as sectionData">
                            <ng-container *ngIf="risks?.length > 0; else noRiskItems">
                                <div @fadeInOut *rxFor="let riskItem of risks; index as i; trackBy: trackByFn" class="panel__risk-item"
                                    [attr.data-cy]="'panel__risk-item-' + sectionType + '-' + (sectionData.isPredefined ? riskItem.type : riskItem.id)">
                                    <p class="panel__risk-item-name">{{ riskItem.name }}</p>
                                    <ng-container *rxIf="sectionData.isPredefined; else customRiskActions">
                                        <a (click)="toggleCatalogItemVisibility(riskItem)" data-cy="panel__risk-item-action" class="panel__risk-item-action" [ngClass]="'panel__risk-item-action--' + sectionType">
                                            {{ sectionData.actionText }}
                                        </a>
                                    </ng-container>
                                    <ng-template #customRiskActions>
                                        <div class="panel__risk-item-actions">
                                            <svg (click)="deleteRiskCatalog(riskItem)" data-cy="panel__risk-item-remove-svg" class="panel__risk-item-remove-svg">
                                                <use xlink:href="../../../assets/img/sprite.svg#icon-trash-flat"></use>
                                            </svg>
                            
                                            <svg (click)="onExistingRiskClick(riskItem)" class="panel__risk-item-pencil-svg">
                                                <use xlink:href="../../../assets/img/sprite.svg#icon-pencil"></use>
                                            </svg>
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
            
                    <ng-template #noRiskItems>
                        <div *rxFor="let riskItem of [].constructor(3); index as i" class="panel__empty-row">
                            <p *rxIf="i === 1">{{ dataBySectionMap.get(sectionType).noResults }}</p>
                        </div>
                    </ng-template>
            </ng-template>
        </div>
    </ng-container>
</div>