import { Component, ChangeDetectionStrategy, HostListener, ElementRef, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MineButtonTertiaryComponent } from '../mine-button-tertiary/mine-button-tertiary.component';
import { ButtonTheme } from '../models/button-theme.enum';

@Component({
  standalone: true,
	selector: 'mine-checkbox-group',
	templateUrl: './mine-checkbox-group.component.html',
	styleUrls: ['./mine-checkbox-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MineButtonTertiaryComponent]
})
export class MineCheckboxGroupComponent {
  listOpen = false;
  btnTheme = ButtonTheme.Grey;
  
  @Input() id: string; //unique id is a must
  @Input() panelStyle: string;
  @Input() disabled = false;
  @Input() tooltip: string;
  @Output() panelClosed = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef
  ) { }

  @HostBinding('class.active')
  get hostClass() {
    return this.listOpen;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.closeOptionsPanel();
      this.panelClosed?.emit();
    }
  }

  private closeOptionsPanel() {
    this.listOpen = false;
  }
}