import { DataType } from 'src/app/data-types/models/data-types.interface';
import { SystemInstance } from 'src/app/systems/models/systems.interface';
import { ConvertPiiTypeToDataTypePipe } from 'src/app/shared/pipes/convert-pii-type-to-data-type.pipe';
import { DataTypesService } from 'src/app/data-types/services/data-types.service';
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { PoliciesQuery } from './policies.query';
import { PoliciesStore } from './policies.store';
import { Policy } from './../../api/models/policies/policies.interface';
import { Injectable } from "@angular/core";
import { ApiClientPoliciesService } from "src/app/api/api-client-policies.service";
import { Observable, merge, tap, map, filter, first, switchMap } from 'rxjs';
import { LoggerService } from 'src/app/logger/logger.service';
import { PolicyId } from '../models/policy.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { DataTypeSource, SuggestedStateEnum } from 'src/app/api/models/systems/systems.enum';

@Injectable({
    providedIn: 'root',
})
export class PoliciesService {
    private readonly loggerName: string = 'PoliciesService';
    private readonly minRecordsCount: number = 2;

    constructor(
        private logger: LoggerService,
        private contentPipe: ContentPipe,
        private systemQuery: SystemsQuery,
        private policiesStore: PoliciesStore,
        private policiesQuery: PoliciesQuery,
        private dataTypesService: DataTypesService,
        private apiClientPoliciesService: ApiClientPoliciesService,
        private convertPiiTypeToDataType: ConvertPiiTypeToDataTypePipe,
    ) { }

    init(): Observable<void> {
        this.logger.debug(this.loggerName, 'init()');
        return this.getPolicies();
    }

    setPolicy(policy: Policy): Observable<void> {
        return this.apiClientPoliciesService.setPolicy(policy).pipe(
            tap(() => this.updateStore(policy))
        );
    }

    getPolicies(): Observable<void> {
        this.logger.debug(this.loggerName, 'getPolicies()');
        
        return this.apiClientPoliciesService.getPolicies().pipe(
            map(res => this.policiesStore.set(res.policies)),
        );
    }

    selectPolicies(): Observable<Policy[]> {
        const loading$ = this.policiesQuery.selectLoading();

        const set$ = loading$.pipe(
            filter(response => !response),
            switchMap(() => this.policiesQuery.selectAll())
        );

        const notSet$ = loading$.pipe(
            filter(response => response),
            switchMap(() => this.getPolicies()),
            switchMap(() => this.policiesQuery.selectAll())
        );

        return merge(set$, notSet$).pipe(first());
    }

    private updateStore(policy: Policy): void {
        this.policiesStore.upsert(policy.id, policy);
    }

    selectPolicyViolationsMap(): Observable<Map<string, SystemInstance[]>> {
        return this.systemQuery.selectLoading().pipe(
            filter(loading => !loading),
            switchMap(() => this.selectPolicies()),
            map(policies => this.selectPoliciesViolatingSystems(policies))
        );
    }

    private selectPoliciesViolatingSystems(policies: Policy[]): Map<string, SystemInstance[]> {
        policies = policies.filter(policy => !!policy.enabled);
        const policyViolationsMap = new Map<string, SystemInstance[]>();
        const multipleViolationsFound = this.contentPipe.transform('dashboard.multipleViolationsFound');
        const systems = Array.from(new Set([].concat(...policies.map(policy => policy.systems.map(system => system.id)))));

        systems.map(system => this.systemQuery.getEntity(system)).filter(Boolean).filter(system => !system.isArchived).map(system => {
            const systemViolatedPolicies = policies.map(policy => this.isSystemViolatingPolicy(policy, system));
            if (systemViolatedPolicies.filter(Boolean)?.length > 1) {
                // system is violating multiple policies
                if (policyViolationsMap.has(multipleViolationsFound)) {
                    policyViolationsMap.set(multipleViolationsFound, policyViolationsMap.get(multipleViolationsFound).concat(system));
                }
                else {
                    policyViolationsMap.set(multipleViolationsFound, [system]);
                }
            }
            else if (systemViolatedPolicies.filter(Boolean)?.length > 0) {
                const policyId = policies.find(policy => this.isSystemViolatingPolicy(policy, system))?.id;
                if (policyId && policyViolationsMap.has(policyId)) {
                    policyViolationsMap.set(policyId, policyViolationsMap.get(policyId).concat(system));
                }
                else if (policyId) {
                    policyViolationsMap.set(policyId, [system]);
                }
            }
        });

        return policyViolationsMap;
    }

    private isSystemViolatingPolicy(policy: Policy, system: SystemInstance): boolean {
        if (policy.systems.find(ps => ps.id === system.systemId) && !system.isArchived) {
            return policy.id === PolicyId.Count ? this.isPolicyIrregularity(policy, system) : this.isPolicyMisplacement(policy, system);
        }
        return false;
    }

    private isPolicyIrregularity(policy: Policy, system: SystemInstance): boolean {
        const dataTypes = this.dataTypesService.getExtendedDataTypes(system.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted));
        
        return !!dataTypes.find(d => {
            const dataTypeRecord = system?.extendedDataTypes?.find(e => e.source === DataTypeSource.Manual ? e.id === d.id : this.convertPiiTypeToDataType.transform(e.id) === d.id);
            if (dataTypeRecord?.numOfRecords < policy.recordCount && dataTypeRecord?.numOfRecords > this.minRecordsCount ) {
                return true;
            }
        });
    }

    private isPolicyMisplacement(policy: Policy, system: SystemInstance): boolean {
        const dataTypes = this.dataTypesService.getExtendedDataTypes(system.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted));

        return !!dataTypes.find(d => d?.frameworks?.find(f => f.id === policy.id));
    }

    //check DataType in system violates policy
    //get policies and filter the relevant policies for system async
    isDataTypeInSystemViolated(dataType: DataType, system: SystemInstance): Observable<boolean> {
        
        return this.selectPolicies().pipe(
            map(policies => policies?.filter(policy => policy?.systems?.find(policySystem => policySystem.id === system.systemId) && policy.enabled)),
            map(policies => this.checkDataType(dataType, policies, system))
        );
    }

    //check DataType in system violates policy
    //get policies and filter the relevant policies for system synchronic
    getDataTypeInSystemViolated(dataType: DataType, system: SystemInstance): boolean {
        const policies = this.policiesQuery.getAll();
        const relevantPolicies = policies?.filter(policy => policy?.systems?.find(policySystem => policySystem.id === system.systemId) && policy.enabled);
        return this.checkDataType(dataType, relevantPolicies, system);
    }

    //check if dataType violates the policy
    private checkDataType(dataType: DataType, policies: Policy[], system: SystemInstance): boolean {
        let dataTypeRecord = this.getDataTypeRecord(system, dataType.id);

        return policies.some(policy => {
            return this.checkPolicies(policy, dataType, dataTypeRecord);
        });
    }

    isSystemViolatedForDataType(system: SystemInstance, dataType: DataType): Observable<boolean> {
        const dataTypes = this.dataTypesService.getExtendedDataTypes(system.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted));

        return this.selectPolicies().pipe(
            map(policies => policies?.filter(policy => policy?.systems?.find(policySystem => policySystem?.id === system?.systemId && policy?.enabled))),
            map(policies => dataTypes.some(systemDataType => { 
                return systemDataType?.id === dataType?.id ? this.checkDataType(systemDataType, policies, system) : false;
            })),
        );
    }

    isDataTypeViolated(dataType: DataType, dataTypeSystems: SystemInstance[]): Observable<boolean> {
        
        return this.policiesQuery.selectAll().pipe(
            map(policies => policies?.filter(policy => policy.enabled && (dataType.frameworks?.find(framework => framework.id === policy.id) || policy.id === PolicyId.Count))),
            map(policies => this.checkSystemsInDataType(policies, dataType, dataTypeSystems))
        );
    }

    checkSystemsInDataType(policies: Policy[], dataType: DataType, dataTypeSystems: SystemInstance[]): boolean {
        return dataTypeSystems.some(system => {
            let dataTypeRecord = this.getDataTypeRecord(system, dataType.id);
            
            return policies.some(policy => {
                if (policy.systems.find(s => s.id === system.systemId)) {
                    return this.checkPolicies(policy, dataType, dataTypeRecord);
                }
            });
        });
    }

    private checkPolicies(policy: Policy, dataType: DataType, dataTypeRecord: number): boolean {
        const isIrregularityViolated = policy.id === PolicyId.Count && dataTypeRecord < policy.recordCount && dataTypeRecord > this.minRecordsCount;
        const isMisplacementViolated = dataType?.frameworks?.find(framework => framework.id === policy.id);
        
        return !!isIrregularityViolated || !!isMisplacementViolated;
    }

    private getDataTypeRecord(system: SystemInstance, dataTypeId: string): number {
        return system?.extendedDataTypes?.find(y => this.convertPiiTypeToDataType.transform(y.id) === dataTypeId)?.numOfRecords;   
    }
    
}