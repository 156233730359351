<ng-content select="[label]"></ng-content>

<div class="chips-input__wrapper"
    [ngClass]="{ 'chips-input__wrapper--active': inputIsActive, 'chips-input__wrapper--error': errorLabel }"
    [ngStyle]="{'max-height': customStyle.maxHeight}"
    >
    
    <mine-chip *ngFor="let chip of chips | keyvalue: asIsOrder" class="chips-input__item" [tooltip]="chip.value?.tooltip"
        [backgroundColor]="chip.value.chipColor?.backgroundColor ?? defaultChip.backgroundColor" 
        [color]="chip.value.chipColor?.textColor ?? defaultChip.textColor"
        [isRemovable]="chip.value?.isRemovable ?? true" (onRemoveClick)="onRemoveChip(chip.key)">
        <span class="chips-input__content" tp tpUseTextContent [tpOnlyTextOverflow]="true">
            {{chip.key}}
        </span>
    </mine-chip>
    
    <ng-container *ngIf="!addItemConfig?.fixedOptions">
        <input autoSize *ngIf="chips?.size < validationConfig?.maxChips && !errorLabel" #chipInput
        class="chips-input__input" type="text" [placeholder]="!chips?.size && !inputWithValue ? placeholder : ''"
        (keyup.enter)="addChip(chipInput.value)" (keydown.backspace)="onRemoveChipFromKeyboard()"
        (focus)="inputIsActive = true" (focusout)="inputIsActive = false" (keyup)="onTyping(chipInput.value)">
    </ng-container>

    <mine-button-tertiary #addBtn 
        *ngIf="(!!addItemConfig && filteredOptions.length) || (!!addItemConfig && addItemConfig.disable) || (!!addItemConfig && addItemConfig.hasCustomOption) || isAsync"
        [isDisabled]="(addItemConfig?.disable || chips?.size === validationConfig?.maxChips) ?? false"
        [tooltip]="addItemConfig?.tooltip ?? ''" 
        (onClick)="openDropdown()">
        {{'common.addMineChip' | content}}
    </mine-button-tertiary>

</div>

<mine-add-item #addItem @fadeInOnEnter *ngIf="showDropdown" 
    class="chips-input__add-item-input"
    [ngStyle]="{'width': addItemConfig.width, 'left': dropdownLeft}"
    [dynamicMenu]="true"
    [options]="filteredOptions"
    [isAsync]="isAsync"
    [isLoading]="isLoading"
    [hasCustom]="addItemConfig.hasCustomOption"
    [placeholder]="addItemConfig.inputPlaceHolder"
    [isOptionsViewCustom]="addItemConfig.isOptionsViewCustom"
    (onCloseOptionsClick)="onCloseOptionsClick($event)"
    (searchAsync)="searchAsync.emit($event)"
>
    <ng-content select="[async-skeleton]" async-skeleton></ng-content>
    <ng-content select="[custom-view-options]" custom-view-options></ng-content>
    <ng-content select="[custom-option-divider]" custom-option-divider></ng-content>
    <ng-content select="[custom-option]" custom-option></ng-content>
    <ng-content select="[groups-custom-option]" groups-custom-option></ng-content>
</mine-add-item>

<div>
    <p [@fadeIn]="enterFade" *ngIf="errorLabel" class="chips-input__error" [innerText]="errorLabel"></p>
    <div [@fadeIn]="enterFade" *ngIf="!errorLabel && (inputWithValue || inputBorder || chips?.size > 0) && hintPresent"
        class="chips-input__hint">
        <ng-content select="[hint]"></ng-content>
    </div>
</div>