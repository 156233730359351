import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, HostListener, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { E2EModule } from 'cypress/support/e2e.module';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { MenuPositionEnum } from './mine-action-menu-enum';
import { Animations } from 'src/app/animations/animations';

@Component({
  standalone: true,
  selector: 'mine-action-menu',
  templateUrl: './mine-action-menu.component.html',
  styleUrls: ['./mine-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ContentPipeModule, CommonModule, E2EModule],
  animations: [Animations.fadeInOut]
})
export class MineActionMenuComponent {

  @Output()
	action = new EventEmitter<string>();

  @Input()
  options: DropdownOption[];

  @Input()
  position = MenuPositionEnum.Bottom;

  @Output()
  menuOpened = new EventEmitter<void>();

  @Output()
  menuClosed = new EventEmitter<void>();

  showActionsMenu: boolean;
  
  constructor(
    private elementRef: ElementRef
  ) { }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showActionsMenu = false;
      this.menuClosed.emit();
    }
  }

  menuClick($event: MouseEvent): void {
    this.showActionsMenu = !this.showActionsMenu;
    if (this.showActionsMenu) {
      this.menuOpened.emit();
    }
    else {
      this.menuClosed.emit();
    }
    this.stopEvent($event);
  }

  onActionClick(optionId: string, $event): void {
    if (this.options.find(option => option.id === optionId)?.disabled) return;
    this.action.emit(optionId);
    this.showActionsMenu = false;
    this.menuClosed.emit();
    this.stopEvent($event);
  }

  // when menu is over other button we need to stop events from bubbling up
  private stopEvent($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
  }
}
