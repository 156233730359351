import { Injectable } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { catchError, delay, map, of, shareReplay } from "rxjs";

import { LoggerService } from "../../../../src/app/logger/logger.service";
import { ApiClientRisksService } from "../../../../src/app/api/api-client-risks.service";
import { RisksCatalogsState } from "./risks-catalog.state.interface";
import { RiskCatalog, RiskCatalogsResponse } from "src/app/api/models/risks/risks.interface";

@Injectable({
    providedIn: 'root'
})
export class RisksCatalogStore extends RxState<RisksCatalogsState> {

    private readonly loggerName: string = 'RisksCatalogStore';
    
    private readonly initState = {
        empty: undefined,
        loading: true,
        disabled: false,
        risksCatalog: undefined,
    } as RisksCatalogsState;
    
    private readonly _risksCatalog$ = this.apiClientRisksService.getRisksCatalog().pipe(
        shareReplay(),
        delay(100),
        map(res => this.mapRiskCategories(res)),
        catchError(err => {
            console.error(err);
            this.logger.error(this.loggerName, err);
            return of(new Map());
        })
    );

    private mapRiskCategories(res: RiskCatalogsResponse): Map<string, RiskCatalog> {
        const risksCatalog = { ...res.customCatalogRisks, ...res.predefinedCatalogRisks };
        return new Map(Object.entries(risksCatalog).map(([_, riskCatalog]) => [riskCatalog.type, riskCatalog]));
    }
    
    constructor(
        private logger: LoggerService,
        private apiClientRisksService: ApiClientRisksService,
    ) {
        super();
        this.set(this.initState);
        this.connect('risksCatalog', this._risksCatalog$ as any);
        this.connect('loading',  this._risksCatalog$.pipe(
            map(() => false)
        ));
        this.connect('empty', this._risksCatalog$.pipe(
            map(risks => !risks?.size)
        ));
    }

    addRiskCatalog(newRiskCatalog: RiskCatalog): void {
        this.set((state) => ({
          risksCatalog: new Map(state.risksCatalog).set(newRiskCatalog.type, newRiskCatalog),
        }));
    }

    updateRiskCatalog(updatedRiskCatalog: RiskCatalog): void {
        const risksCatalog = this.get('risksCatalog');
        risksCatalog.set(updatedRiskCatalog.type, updatedRiskCatalog);
        this.set('risksCatalog', () => new Map(risksCatalog.entries()));
    }

    deleteRiskCatalog(type: string): void {
        const updatedRisksCatalog = new Map(this.get('risksCatalog'));
        updatedRisksCatalog.delete(type);
        this.set('risksCatalog', () => updatedRisksCatalog);
    }
}