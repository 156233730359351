import { Component, ChangeDetectionStrategy, Input, OnInit, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TippyModule } from '../tippy.module';
import { MineBadgeType } from './mine-badge.enum';

@Component({
  standalone: true,
  selector: 'mine-badge',
  templateUrl: './mine-badge.component.html',
  styleUrls: ['./mine-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TippyModule]
})
export class MineBadgeComponent implements OnInit {

  icon: string;

  @Input()
  text: string;

  @Input()
  badgeType: MineBadgeType;

  @HostBinding('class')
  get badgeHostClass() {
    return this.badgeType;
  };
  
  ngOnInit(): void {
    this.setIcon();
  }

  private setIcon(): void {
    switch (this.badgeType) {
      case MineBadgeType.Error:
      case MineBadgeType.Attention:
        this.icon = 'icon-exclamation-mark';
        break;
      
      case MineBadgeType.Scan:
        this.icon = 'icon-clock-1';
        break;
      
      case MineBadgeType.Suggestion:
        this.icon = 'icon-sparking';
        break;
      
      case MineBadgeType.Radar:
        this.icon = 'icon-radar-small';
        break;
      
      case MineBadgeType.PendingResponse:
      case MineBadgeType.Responded:
        this.icon = 'icon-message-full';
        break;

      case MineBadgeType.Autopilot:
      case MineBadgeType.AutopilotV2:
      case MineBadgeType.AutopilotError:
      case MineBadgeType.AutopilotV2Error:
        this.icon = 'icon-bolt';
        break;

      case MineBadgeType.AiAssessment:
        this.icon = 'icon-assessment';
        break;

      case MineBadgeType.AiAssessmentReviewDate:
        this.icon = 'icon-due-date';
        break;

      default:
        break;
    }
  }
}