import { Injectable } from '@angular/core';
import { Observable, throwError, catchError, first, map, tap } from 'rxjs';

import { ProfileQuery } from './profile.query';
import { ApiClientProfileService } from 'src/app/api/api-client-profile.service';
import { ApiClientRequestsService } from 'src/app/api/api-client-requests.service';
import { PlanLinkType } from 'src/app/api/models/plans/plan-link-type';
import { ProfileAgent } from 'src/app/api/models/profile/profile-agent';
import { InviteMemberResponse, ProfileInviteMember } from 'src/app/api/models/profile/profile-invite-member.interface';
import { LoggerService } from 'src/app/logger/logger.service';
import { LocalStorageHelper } from 'src/app/services/localStorage-helper';
import { OnboardingTasksService } from 'src/app/services/onboarding-tasks/onboarding-tasks.service';
import { createInitialState, ProfileStore } from './profile.store';
import { AgentDepartments } from 'src/app/api/models/profile/profile-agent-departments.enum';
import { RoleEnum } from 'src/app/core/models/permission-role.enum';
import { ProfileStatus } from 'src/app/api/models/profile/profile-agent.enum';
import { Profile } from '../models/profile.interface';

@Injectable({ 
	providedIn: 'root' 
})
export class ProfileService {
	private readonly loggerName: string = 'ProfileService';

	constructor(
		private profileStore: ProfileStore,
		private profileQuery: ProfileQuery,
		private logger: LoggerService,
		private apiClientProfile: ApiClientProfileService,
		private apiClientRequests: ApiClientRequestsService,
		private onboardingTasksService: OnboardingTasksService,
	) { }

	getUserProfile(): Observable<Profile> {
		this.logger.debug(this.loggerName, 'getUserProfile()');

		return this.apiClientProfile.getInfo().pipe(
			first(),
			map(profile => ({ ...profile } as Profile)),
			tap(profile => {
				this.logger.info(this.loggerName, `Received profile. uId = (${profile.userId})`);
				this.updateProfile(profile);
			})
		);
	}
	
	private updateProfile(profile: Profile): void {
		if (profile.companyId && profile.userId) {
			this.logger.info(this.loggerName, `Save userId = ${profile.userId} with companyId = ${profile.companyId} to local storage.`);
			LocalStorageHelper.setUserIdAndConnectedCompany(profile.userId, profile.companyId);
		}

		const currentUserId = this.profileQuery.getValue()?.userId;
		if ((profile.userId !== undefined) && (profile.userId !== currentUserId)) {
			this.logger.setUserId(profile.userId);
			this.logger.info(this.loggerName, `User id in store (${currentUserId}) is different than retrieved: ${profile.userId}`);
		}

		this.profileStore.update({...profile, verifiedToCompany: profile.verifiedToCompany === true});
		this.profileStore.setLoading(false);
		
		this.logger.info(this.loggerName, `Update profile store. userId = ${profile.userId}`);
		this.onboardingTasksService.initOnboardingTasks(profile.onboardingProgress);
	}

	updateProfileValue<T>(value: T, key: keyof Profile): void {
		const update = { };
		update[key] = value;
		this.profileStore.update(update as Partial<Profile>);
	}

	getPlanPurchaseLink(planLinkType: PlanLinkType): Observable<string> {
		return this.apiClientProfile.getPlanPurchaseLink(planLinkType).pipe(
			tap(link => this.logger.info(this.loggerName, `Received purchase link = ${link}`))
		);
	}

	getPlanManagementPage(): Observable<string> {
		return this.apiClientProfile.getPlanManagementPage().pipe(
			tap(link => this.logger.info(this.loggerName, `Received plan management link = ${link}`))
		);
	}	

	logout(): void {
		this.updateProfile(createInitialState());
	}

	isCertifiedDomainOrSubdomain(domain: string): boolean {
		return !!this.profileQuery.getValue().domainsInCompany.find(domainInCompany => domainInCompany === domain || domain.endsWith(`.${domainInCompany}`));
	}

	private selectAgents(): Observable<ProfileAgent[]> {
		return this.profileQuery.select('agents');
	}

	updateProfileAfterInvite(member: ProfileInviteMember): Observable<void> {
		const agent = [{
			name: '',
			email: member.inviteeEmail,
			department: member.department,
			role: member.role,
			isAgent: false,
			status: ProfileStatus.Invitee,
		}] as ProfileAgent[];

		return this.selectAgents().pipe(
			first(),
			map(agents => agents.concat(agent)),
			map(agents => this.updateProfileValue<ProfileAgent[]>(agents, 'agents')),
		);
	}

	sendInvite(member: ProfileInviteMember): Observable<InviteMemberResponse> {
		return this.apiClientRequests.inviteAMember(member);
	}

	deleteInvitationServer(agentEmail: string): Observable<void> {
		return this.apiClientRequests.deleteInvitation(agentEmail).pipe(
			catchError(error => {
				this.logger.error(this.loggerName, `deleteInvitation() Error: ${error.name} ,${error.message}`);
				return throwError(error);
			})
		);
	}

	deleteInvitationStore(agentEmail: string): Observable<void> {
		return this.selectAgents().pipe(
			first(),
			map(agents => agents.filter(a => a.email !== agentEmail)),
			map(agents => this.updateProfileValue<ProfileAgent[]>(agents, 'agents')),
		);
	}

	isAgent(agent: string): boolean {
		return !!this.profileQuery.getValue().agents.find(agents => agents.email === agent);
	}

	updateAgentName(firstName: string, lastName: string): Observable<void> {
		return this.apiClientProfile.updateAgentName(firstName, lastName).pipe(
			tap(() => this.updateAgentNameInStore(firstName, lastName)),
		);
	}

	private updateAgentNameInStore(firstName: string, lastName: string, department?: AgentDepartments): void {
		const profile = this.profileQuery.getValue();
		const agents = profile.agents.map(a => {
			if (a.id ===  profile.userId) {
				a.name = `${firstName} ${lastName}`;
				if (department) {
					a.department = department;
				}
			}
			return a;
		});
		const partialProfile: Partial<Profile> = {
			firstName,
			lastName,
			agents,
		};
		this.profileStore.update(partialProfile);
	}

	updatePersonalProfile(firstName: string, lastName: string, department: string): Observable<void> {
		return this.apiClientProfile.updatePersonalProfile(firstName, lastName, department).pipe(
			tap(() => this.updateAgentNameInStore(firstName, lastName, AgentDepartments[department])),
		);
	}

	updateRoleInStore(userId: string, newRole: RoleEnum): void {
		const profile = this.profileQuery.getValue();
		const agents = profile.agents.map(a => {
			if (a.id ===  userId) {
				a = {
					...a,
					role: newRole,
				};
			}
			return a;
		});
		const partialProfile: Partial<Profile> = {
			role: userId === profile.userId ? newRole : profile.role,
			agents,
		};
		this.profileStore.update(partialProfile);
	}

	updateAgentDepartmentInStore(userId: string, newDepartmet: AgentDepartments): void {
		const profile = this.profileQuery.getValue();
		const agents = profile.agents.map(a => {
			if (a.id ===  userId) {
				a = {
					...a,
					department: newDepartmet
				};
			}
			return a;
		});
		const partialProfile: Partial<Profile> = {
			agents,
		};
		this.profileStore.update(partialProfile);
	}

	updateStatusInStore(userId: string, newStatus: ProfileStatus): void {
		const profile = this.profileQuery.getValue();
		const agents = profile.agents.map(a => {
			if (a.id ===  userId) {
				a.status = newStatus;
			}
			return a;
		});
		const partialProfile: Partial<Profile> = {
			agents,
		};
		this.profileStore.update(partialProfile);
	}
}