import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, tap, throwError } from 'rxjs';

import { DataLayerService } from './data-layer.service';
import { DataLayerCustomDimensionsSet } from '../api/models/analytics/data-layer-custom-dimensions-set';
import { LoggerService } from '../logger/logger.service';
import { environment } from 'src/environments/environment';
import { DataLayerCustomDimensions } from '../api/models/analytics/data-layer-custom-dimensions';
import { ProfileQuery } from '../profile/state/profile.query';
import { AnalyticsEvent } from './analytics-event';

declare let ga: any;

@Injectable({
	providedIn: 'root'
})
export class GoogleAnalyticsService {
	private readonly loggerName: string = 'GoogleAnalyticsService';
	private userId: string = '';
	private clientID: string = '';
	private disabled: boolean = false;

	private gaRetryCounter = 0;
	private gaRetryMax = 10;
	private gaRetryTimeout = 300;
	private gaTrackerFound = false;

	constructor(private dataLayerService: DataLayerService,
				private router: Router,
				private logger: LoggerService,
				private profileQuery: ProfileQuery) { }

	private getClientId() {
		var trackers = ga.getAll();
		for (let i = 0; i < trackers.length; i++) {
			if (trackers[i].get('trackingId') === environment.analytics.propertyId) {
				this.clientID = trackers[i].get('clientId');
				this.logger.setSessionId(this.clientID);
				this.logger.debug(this.loggerName, `GA tracker found, clientId: ${this.clientID}`);
				this.gaTrackerFound = true;
				return;
			}
		}
		this.logger.debug(this.loggerName, 'GA tracker not found');
	}

	private checkGa() {
		if (this.gaTrackerFound) {
      		return;
    	}
		if (window.hasOwnProperty('ga') && ga.loaded) {
			this.logger.debug(this.loggerName, 'GA loaded');
			this.getClientId();
		}
		else {	// no GA
			if (this.gaRetryCounter++ > this.gaRetryMax) {
				this.logger.debug(this.loggerName, 'GA not loaded, reached max retries');
			}
			else {
				this.logger.debug(this.loggerName, 'GA not loaded, trying again');
				setTimeout(() => this.checkGa(), this.gaRetryTimeout);
			}
		}
	}

	public init(): void {
		this.updateUserId();

		// skip waiting for GA if user opted out
		if (this.disabled === true) {
			this.logger.debug(this.loggerName, 'GA opted out, skipping');
			return;
		}

		if (document.readyState === 'complete') {
				this.checkGa();
		}
		else {
			window.addEventListener('load', () => {
				setTimeout(() => { this.checkGa(), this.gaRetryTimeout; });
			}, false);
		}
	}

	getSessionId(): string {
		return this.clientID;
	}

	private updateUserId(): void {
		const id = this.profileQuery.select('userId').pipe(
				filter(response => response !== undefined),
				tap(userId => this.logger.debug(this.loggerName, `updating user id: ${userId}`)),
				tap(userId => this.userId = userId)
			).subscribe(() => id.unsubscribe());
	}

	private pushDimensions(): void {
		const dataDimensions: DataLayerCustomDimensionsSet = {
			[DataLayerCustomDimensions.PropertyId]: environment.analytics.propertyId,
			[DataLayerCustomDimensions.UserID]: this.userId
		};

		this.dataLayerService.dimensions = dataDimensions;
		this.dataLayerService.trigger();
	}

	pageView(url: string = this.router.url): void {		
		this.pushDimensions();
		(<any>window).dataLayer.push({
			event: 'virtualPageview',
			virtualPageURL: url,
			virtualPageTitle: url
		});
	}

	emitEvent(analyticsEvent: AnalyticsEvent): void {
		if (analyticsEvent.eventCategory && analyticsEvent.eventAction) {

			this.pushDimensions();
			(<any>window).dataLayer.push({
				event: 'mineCustomEvent',
				eventCategory: analyticsEvent.eventCategory,
				eventAction: analyticsEvent.eventAction,
				eventLabel: analyticsEvent.eventLabel ?? null,
				eventValue: analyticsEvent.eventValue ?? null 
			});
		} else {
			throwError(`Category or Action missing`);
		}
	}

}
