import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterCategory } from 'src/app/core/models/filtering.interface';

@Injectable({
  providedIn: 'root'
})

export class VendorFilteringService {
  private activeFilters = new BehaviorSubject<FilterCategory[]>([]);

  setActiveFilters(selectedFilters: FilterCategory[]): void {
    this.activeFilters.next(selectedFilters);
  }

  selectActiveFilters(): Observable<FilterCategory[]> {
    return this.activeFilters.asObservable();
  }

}
