import { NgOptimizedImage } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'mine-no-results',
  templateUrl: './mine-no-results.component.html',
  styleUrls: ['./mine-no-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ContentPipeModule, NgOptimizedImage]
})
export class MineNoResultsComponent {
  readonly assetsUrl = environment.assets.url;
}
