import { NgOptimizedImage } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

import { Animations } from 'src/app/animations/animations';
import { EmptyStateContent } from './mine-table-empty-state.interface';

@Component({
  standalone: true,
  selector: 'mine-table-empty-state',
  templateUrl: './mine-table-empty-state.component.html',
  styleUrls: ['./mine-table-empty-state.component.scss'],
  animations: [Animations.fadeIn],
  imports: [NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MineTableEmptyStateComponent {
  @HostBinding('@fadeIn') get fadeIn() { return true; };
  @Input() content: EmptyStateContent;
}