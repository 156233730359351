import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from './http-client-helper.service';
import { InviteMemberResponse, ProfileInviteMember } from './models/profile/profile-invite-member.interface';
import { EditTeammate } from './models/teammates/teammates.interface';

@Injectable({
  	providedIn: 'root'
})
export class ApiClientTeammatesService {

	private readonly controller = 'teammates';

  	constructor(private httpClientHelper: HttpClientHelperService) { }

	deactivateTeammate(userId: string): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/deactivateMember/${userId}`);
  	}

	activateTeammate(userId: string): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/activateMember/${userId}`);
  	}

	// can be extended later to edit more fields of teammate
	editTeammate(userId: string, editTeammate: EditTeammate): Observable<void> {
		return this.httpClientHelper.invokePutAuth<void>(`${this.controller}/edit/${userId}`, editTeammate);
  	}

	inviteTeammate(member: ProfileInviteMember): Observable<InviteMemberResponse> {
		return this.httpClientHelper.invokePostAuth<InviteMemberResponse>(`${this.controller}/InviteMember?`, member);
	}

	deleteInvitation(email: string): Observable<void> {
		const mail = { InviteeEmail: email };
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/DeleteInvitation`, mail);
	}

}
