import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable, map, BehaviorSubject } from 'rxjs';

import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { DataFlowNodeTypeEnum, ProcessActivityStatusEnum } from 'src/app/api/models/processing-activities/processing-activities.enum';
import { DataFlowEdge, ProcessingActivity, ProcessingActivityPartial, ProcessingActivitySystemInfo } from 'src/app/api/models/processing-activities/processing-activities.interface';
import { PaToggleFilter, PaFilteringGroups, PaFilteringCategoryEnum, PaStatusEnum } from '../processing-activity-filters/processing-acivity-filtering.enum';
import { ProcessingActivitiesState, ProcessingActivitiesStore } from './processing-activities.store';
import { DataTypesService } from 'src/app/data-types/services/data-types.service';
import { SuggestedStateEnum } from 'src/app/api/models/systems/systems.enum';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { ActivityColumnKeys } from '../models/activity-column.enum';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';
import { DataType } from 'src/app/data-types/models/data-types.interface';
import { CustomFieldsQuery } from 'src/app/company-settings/state/custom-fields/custom-fields.query';
import { FieldInputType } from 'src/app/api/models/company-settings/custom-fields.enum';

@Injectable({
	providedIn: 'root'
})
export class ProcessingActivitiesQuery extends QueryEntity<ProcessingActivitiesState> {

	readonly CROSS_FUNCTIONAL_FUNCTION_NAME = "Cross functional";
	private functions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	functions$ = this.functions.asObservable();

	constructor(
		protected store: ProcessingActivitiesStore,
		private dataTypesService: DataTypesService,
		private customFieldsQuery: CustomFieldsQuery
	) {
		super(store);
	}

	selectActivities(filters: FilterCategory[] = [], sort?: MineSort, search?: string): Observable<ProcessingActivity[]> {
		return this.selectAll({
			filterBy: [
				entity => (
					this.selectSortedActivitiesPartial(filters, entity)
					&&
					this.filterBySearch(search, entity)
				)
			],
			sortBy: (a, b) => this.sortData(a, b, sort.active as ActivityColumnKeys, sort.direction),
		});
	}

	selectActivitiesByView(view?: string, sort?: MineSort): Observable<ProcessingActivity[]> {
		return this.selectAll({
			filterBy: [
				entity => {
					if(view === PaFilteringCategoryEnum.All) {
						return true;
					}
					if (view === PaStatusEnum.Completed) {
						return entity.status === ProcessActivityStatusEnum.Operational;
					}
					if (view === PaStatusEnum.NotCompleted) {
						return entity.status === ProcessActivityStatusEnum.Draft;
					}
					if (view === PaFilteringCategoryEnum.DataFlow) {
						return entity.dataFlow?.length > 0;
					}
					if (view === PaFilteringCategoryEnum.Dpia) {
						return entity.dpiaInfo.dpiaEnabled;
					}
					if (this.functions.value.includes(view)) {
						return entity.functions?.filter(f => f === view)?.length > 0;
					}
				}
			],
			sortBy: (a, b) => this.sortData(a, b, sort.active as ActivityColumnKeys, sort.direction),
		});
	}

	private sortData(a: any, b: any, colKey: ActivityColumnKeys, direction: SortDirectionEnum): number {
		switch (colKey) {
			case ActivityColumnKeys.Name:
				return this.sortByName(a, b, direction);
			case ActivityColumnKeys.Functions:
				return this.sortByFunctions(a, b, direction);
			case ActivityColumnKeys.Status:
				return this.sortByStatus(a, b, direction);
			case ActivityColumnKeys.LastDpia:
				return this.sortByLastDpia(a, b, direction);
			case ActivityColumnKeys.DataSources:
				return this.sortByDataSources(a, b, direction);
			case ActivityColumnKeys.DataSubject:
				return this.sortByDataSubject(a, b, direction);
			case ActivityColumnKeys.DataTypes:
				return this.sortByDataTypes(a, b, direction);
			default:
				return this.sortByCustomField(a, b, colKey, direction);
		}
	}

	private sortByCustomField(a: ProcessingActivity, b: ProcessingActivity, customFieldId: string, direction: SortDirectionEnum): number {
		const fields = this.customFieldsQuery.getEntity(customFieldId);
		if (fields.inputType === FieldInputType.Text) {
			return this.sortByCustomFieldText(a, b, direction, customFieldId);
		} else {
			return this.sortByCustomFieldSelect(a, b, direction, customFieldId);
		}
	}

	private sortByCustomFieldText(a: ProcessingActivity, b: ProcessingActivity, direction: SortDirectionEnum, customFieldId: string): number {
		const customFieldValueA = a.customActivityFields?.find(f => f.id === customFieldId)?.values?.text;
		const customFieldValueB = b.customActivityFields?.find(f => f.id === customFieldId)?.values?.text;
		return direction === SortDirectionEnum.Asc ? 
			customFieldValueA?.localeCompare(customFieldValueB) :
			customFieldValueB?.localeCompare(customFieldValueA);
	}

	private sortByCustomFieldSelect(a: ProcessingActivity, b: ProcessingActivity, direction: SortDirectionEnum, customFieldId: string): number {
		const getCustomFieldValue = (item: any) => item.customActivityFields?.find(f => f.id === customFieldId)?.values?.optionIds?.[0] || null;

		const customFieldValueA = getCustomFieldValue(a);
		const customFieldValueB = getCustomFieldValue(b);
		
		return direction === SortDirectionEnum.Asc ? 
			customFieldValueA?.localeCompare(customFieldValueB) :
			customFieldValueB?.localeCompare(customFieldValueA);
	}

	private sortByName(a: ProcessingActivity, b: ProcessingActivity, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
	}

	private sortByFunctions(a: ProcessingActivity, b: ProcessingActivity, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			(a.functions?.length ?? 0) - (b.functions?.length ?? 0) : 
			(b.functions?.length ?? 0) - (a.functions?.length ?? 0);
	}

	private sortByStatus(a: ProcessingActivity, b: ProcessingActivity, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
	} 

	private sortByLastDpia(a: ProcessingActivity, b: ProcessingActivity, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			new Date(a.dpiaInfo?.lastUpdated)?.getTime() - new Date(b.dpiaInfo?.lastUpdated)?.getTime() : 
			new Date(b.dpiaInfo?.lastUpdated)?.getTime() - new Date(a.dpiaInfo?.lastUpdated)?.getTime();
	} 

	private sortByDataSources(a: ProcessingActivity, b: ProcessingActivity, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			(Object.keys(a.systems)?.length ?? 0) - (Object.keys(b.systems)?.length ?? 0) : 
			(Object.keys(b.systems)?.length ?? 0) - (Object.keys(a.systems)?.length ?? 0);
	} 

	private sortByDataSubject(a: ProcessingActivity, b: ProcessingActivity, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			(a.dataSubjectCategories?.length ?? 0) - (b.dataSubjectCategories?.length ?? 0) : 
			(b.dataSubjectCategories?.length ?? 0) - (a.dataSubjectCategories?.length ?? 0);
	}

	private sortByDataTypes(a: ProcessingActivity, b: ProcessingActivity, direction: SortDirectionEnum): number {
		const aDataTypes = this.dataTypesService.getExtendedDataTypes(a.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted));
		const bDataTypes = this.dataTypesService.getExtendedDataTypes(b.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted));

		return direction === SortDirectionEnum.Asc ?
			(aDataTypes?.length ?? 0) - (bDataTypes?.length ?? 0) : (bDataTypes?.length ?? 0) - (aDataTypes?.length ?? 0);
	}

	private selectSortedActivitiesPartial(filters: FilterCategory[] = [], entity: ProcessingActivity): boolean {
		return this.filterByDpia(filters, entity?.dpiaInfo?.dpiaEnabled) &&
			this.filterByDataFlow(filters, entity.dataFlow?.length > 0) && 
			this.filterByStatus(filters, entity?.status) && 
			this.filterByFunctions(filters, entity?.functions) && 
			this.filterByRoles(filters, entity?.dataRoles);
	}

	selectSortedActivitiesForExport(filters: FilterCategory[] = [], includeAll: boolean): Observable<ProcessingActivity[]> {
		return this.selectAll({
			filterBy: [
				entity => (
					includeAll ? true :this.selectSortedActivitiesPartial(filters, entity)
				)
			],
		});
	}

	selectBySystem(systemId: string): Observable<ProcessingActivityPartial[]> {
		return this.selectAll().pipe(
				map(items => <ProcessingActivityPartial[]>items?.filter(pa => !!pa.systems && !!pa.systems[systemId]))
			);
	}

	selectBySystemAndStatus(systemId: string, paStatus: ProcessActivityStatusEnum): Observable<ProcessingActivity[]> {
		return this.selectAll({
			filterBy: ({ status, systems }) => status === paStatus && !!systems[systemId],
		});
	}

	private filterBySearch(search: string, entity: ProcessingActivity): boolean {
		if (!search) return true;

		return !!Object.keys(entity.systems)?.find(systemId => entity.systems[systemId]?.name?.toLowerCase()?.includes(search?.toLowerCase())) || 
			entity.description?.toLowerCase()?.includes(search?.toLowerCase()) || 
			entity.name?.toLowerCase()?.includes(search?.toLowerCase());
	}

	private filterByStatus(filter: FilterCategory[], status: ProcessActivityStatusEnum): boolean {
		const index = filter?.findIndex(f => f.id == PaFilteringGroups.PaStatus && f.options?.length > 0);
		if (index > -1) {
			const filterOptions = filter[index].options.map(options => options.id);
			return filterOptions.some(f => f === status);
		}
		return true;
	}

	private filterByRoles(filter: FilterCategory[], roles: string[]): boolean {
		const index = filter?.findIndex(f => f.id == PaFilteringGroups.Roles && f.options?.length > 0);
		if (index > -1) {
			const found = filter[index].options.some(r => roles?.indexOf(r.id) >= 0);
			return !!found;
		} 
		return true;
	}

	private filterByFunctions(filter: FilterCategory[], functions: string[]): boolean {
		const index = filter?.findIndex(f => f.id == PaFilteringGroups.Functions && f.options?.length > 0);
		if (index > -1) {
			const found = filter[index].options.some(r => functions?.indexOf(r.id) >= 0);
			return !!found;
		} 
		return true;
	}

	private filterByDpia(filter: FilterCategory[], enabled: boolean): boolean {
		const index = filter?.findIndex(f => f.id == PaFilteringGroups.Dpia && f.options?.length > 0);
		if (index > -1) {
			const filterOptions = filter[index].options.map(options => options.id);
			return filterOptions.some(f => {
				if (f === PaToggleFilter.Enabled) {
					return enabled;
				}			
				if (f === PaToggleFilter.Disabled) {
					return !enabled;
				}
			});
		}
		return true;
	}

	private filterByDataFlow(filter: FilterCategory[], enabled: boolean): boolean {
		const index = filter?.findIndex(f => f.id == PaFilteringGroups.DataFlow && f.options?.length > 0);
		if (index > -1) {
			const filterOptions = filter[index].options.map(options => options.id);
			return filterOptions.some(f => {
				if (f === PaToggleFilter.Enabled) {
					return enabled;
				}			
				if (f === PaToggleFilter.Disabled) {
					return !enabled;
				}
			});
		}
		return true;
	}

	setFunctions(res: string[]): void {
		this.functions.next(res?.sort((a, b) => {
			// make sure cross functional function will be the latest
			if (a?.toLowerCase() === this.CROSS_FUNCTIONAL_FUNCTION_NAME.toLowerCase()) {
				return 1;
			}
			else if (b?.toLowerCase() === this.CROSS_FUNCTIONAL_FUNCTION_NAME.toLowerCase()) {
				return -1;
			}
			return a?.toLowerCase().localeCompare(b?.toLowerCase());
		}));
	}

	selectDataflowCustomEntities(): Observable<DataFlowEdge[]> {
		return this.selectAll({
			filterBy: [
				({ dataFlow }) => !!dataFlow.length
			]
		}).pipe(
			map(pas => pas.flatMap(pa => pa.dataFlow)),
			map(edges => edges.filter(edge => edge.destination.type === DataFlowNodeTypeEnum.CustomEntity || edge.source.type === DataFlowNodeTypeEnum.CustomEntity))
		);
	}

	selectActivitiesWithDataTypeOfSystem(systemId: string, dataTypeId: string, paStatus: ProcessActivityStatusEnum): Observable<ProcessingActivity[]> {
		
		return this.selectAll({
			filterBy: [
				({ status }) => status === paStatus,
				({ systems }) => !!systems[systemId] && this.dataTypesService.getExtendedDataTypes(
					systems[systemId].extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted)
				)
				.filter(e => e.id === dataTypeId)?.length > 0,
			]
		});
	}

	selectActivitiesWithSingleDataTypeOfSystyem(systemId: string, dataTypeId: string): Observable<ProcessingActivity[]> {

		return this.selectAll({
			filterBy: 
			({ systems }) => {
				if (!!systems[systemId]) {
					const dataTypesOfSystem = this.dataTypesService.getExtendedDataTypes(
					systems[systemId].extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted)
					);
					return dataTypesOfSystem.length === 1 && dataTypesOfSystem[0].id === dataTypeId;
				}
				return false;
			}
		});
	}

	getActivitySystems(activityId: string): { [key: string]: ProcessingActivitySystemInfo } {
		return this.getEntity(activityId)?.systems;
	}

	selectCountPaStatus(paStatus: ProcessActivityStatusEnum): Observable<number> {
		return this.selectCount(({status}) => status === paStatus);
	}

	selectCountByFunction(func: string): Observable<number> {
		return this.selectAll({filterBy: entity => entity.functions?.includes(func)}).pipe(
			map(res => res?.length ?? 0)
		);	
	}

	selectCountDataFlow(): Observable<number> {
		return this.selectAll({filterBy: entity => entity.dataFlow?.length > 0}).pipe(
			map(res => res?.length ?? 0)
		);
	}

	selectCountDpia(): Observable<number> {
		return this.selectAll({filterBy: entity => entity?.dpiaInfo?.dpiaEnabled}).pipe(
			map(res => res?.length ?? 0)
		);
	}

	getDataTypes(): Map<string, DataType[]> {
		const processingActivitiesDataTypes = new Map<string, DataType[]>();
		this.getAll().forEach(pa => {
			processingActivitiesDataTypes.set(pa.name, this.dataTypesService.getExtendedDataTypes(pa.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted)));
		});
		return processingActivitiesDataTypes;
	}

	selectCountCustomView(filters: FilterCategory[]): Observable<number> {
		return this.selectAll({filterBy: entity => this.selectSortedActivitiesPartial(filters, entity) }).pipe(
			map(res => res?.length)
		);
	}

	getFunctions(): string[] {
		return this.functions.value;
	}

}
