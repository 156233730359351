import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ContentPipe } from 'src/app/services/content/content.pipe';
import { SystemInstance } from '../../systems/models/systems.interface';
import { SystemsQuery } from '../../systems/state/systems.query';
import { Framework, FrameworkChip } from '../models/frameworks.interface';
import { DataTypesService } from './data-types.service';
import { DataType } from '../models/data-types.interface';
import { SuggestedStateEnum } from 'src/app/api/models/systems/systems.enum';
import { FrameworksQuery } from 'src/app/company-settings/state/frameworks/frameworks.query';

@Injectable({
  providedIn: 'root'
})
export class FrameworksService {

  constructor(
    private dataTypesService: DataTypesService,
    private contentPipe: ContentPipe,
    private systemsQuery: SystemsQuery,
    private frameworksQuery: FrameworksQuery,
  ) { }

  getFrameworksChips(dataTypes: DataType[]): FrameworkChip[] {
    const frameworks = new Set<string>();
    dataTypes.forEach(d => d?.frameworks?.forEach(f => frameworks.add(f?.id)));
    
    const frameworksChips: FrameworkChip[] = [];
    frameworks.forEach(key => {
      const framework = this.getFrameworks([key])[0];
      frameworksChips.push(this.getFrameworkChip(framework));
    });
    return frameworksChips;
  }

  getAllFramworksChips(): FrameworkChip[] {
    const allFrameworks = this.frameworksQuery.getAll();
    return allFrameworks.map(f => this.getFrameworkChip(f));
  }

  getFrameworkChip(framework: Framework): FrameworkChip {
    return {
      id: framework.id,
      text: framework.displayName,
      chip: {
        chipColor: {
          backgroundColor: framework.backgroundColor,
          textColor: framework.textColor,
        },
        isRemovable: false,
      }
    };
  }

  getFrameworks(frameworksIds?: string[]): Framework[] {
    const uniqueFrameworksSet = new Set<string>(frameworksIds);
    const allFrameworks = this.frameworksQuery.getAll();
    return frameworksIds ? allFrameworks.filter(d => uniqueFrameworksSet.has(d.id)) : allFrameworks;
  }

  selectFrameworkSystemsMap(): Observable<Map<string, SystemInstance[]>> {
    return this.systemsQuery.selectAllSystemsWithDataTypes().pipe(
      map(systems => this.initFrameworksToSystemsMap(systems))
    );
  }

  private initFrameworksToSystemsMap(systems: SystemInstance[]) {
    const map = new Map<string, SystemInstance[]>();
    systems.forEach(s => {
      const dataTypes = this.dataTypesService.getExtendedDataTypes(s.extendedDataTypes?.filter(e => e.state === SuggestedStateEnum.Accepted));
      const frameworksByDataTypes = this.getFrameworksChips(dataTypes);
      frameworksByDataTypes.forEach(f => {
        if (map.has(f.text)) {
          map.get(f.text).push(s);
        } else {
          map.set(f.text, [s]);
        }
      });
    });
    this.frameworksQuery.getAll().forEach(f => {
      if(!map.has(f.displayName)) {
        map.set(f.displayName, []);
      }
    });
    return map;
  }
}