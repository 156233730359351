import { PaginatorPlugin } from '@datorama/akita';
import { computed, Inject, Injectable, Signal, signal } from '@angular/core';
import { catchError, filter, iif, map, merge, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { REQUESTS_PAGINATOR } from './requests-paginator';
import { LoggerService } from 'src/app/logger/logger.service';
import { ProfileService } from 'src/app/profile/state/profile.service';
import { OnboardingService } from 'src/app/onboarding/onboarding.service';
import { ApiClientRequestsService } from 'src/app/api/api-client-requests.service';
import { PaginationCursor } from 'src/app/shared/mine-pagination/mine-pagination.interface';
import { ConnectedToMineStatusEnum } from 'src/app/api/models/profile/connected-to-mine-status.enum';
import { RequestsResponse, RequestItem, AutopilotAssignedTicket, ExportAuditLogResponse } from 'src/app/api/models/requests/requests.interface';
import { RequestsListTypeEnum, RequestsStatusEnum } from '../models/requests-list-type.enum';
import { DueDateData, RequestsListPayload, RequestsSearchPayload } from '../models/requests.interface';
import { RequestItemDeep } from 'src/app/api/models/requests/requests.interface';
import { RequestListFilters } from '../requests-list/requests-list.enum';
import { RequestsState, RequestsStore } from './requests.store';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { AutoPilotState, TicketStateEnum } from 'src/app/api/models/requests/ticket-state.enum';
import { RedactResponse } from 'src/app/api/models/requests/redact-reponse.interface';
import { RequestFormConfiguration } from 'src/app/api/models/privacy-center/privacy-center.interface';
import { RequestFormResponse, SubmitRequestForm } from '../request-form/models/interfaces';
import { TicketProcessingState } from 'src/app/api/models/integrations/integrations.interface';
import { TicketState } from 'src/app/api/models/requests/request-state';
import { TicketMetadata, UpdateTicketDetails } from 'src/app/api/models/ticket/ticket.interface';
import { CloseRequest } from 'src/app/api/models/requests/close-request.interface';
import { RequestsQuery } from './requests.query';
import { TicketStateExtended } from '../models/request-item.interface';
import { ApiClientTicketService } from 'src/app/api/api-client-ticket.service';
import { Dpo } from 'src/app/api/models/onboarding/dpo';
import { DaysAgoPipe } from '../common/days-ago.pipe';

import * as dayjs from 'dayjs';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { getTableDefaultSort } from 'src/app/shared/table-state-service/table-state.decorator';
import { TablesEnum } from 'src/app/shared/table-state-service/table-state.enum';

@Injectable({ 
	providedIn: 'root' 
})
export class RequestsService  {
	private readonly loggerName: string = 'RequestsService';

	private activatedEndpoint: 'list' | 'search' = 'list';
	private selectedRequestsView = RequestsStatusEnum.Open;
	private before = signal<string>(null);
	private after = signal<string>(null);
	
	requestListUpdate = signal<string>(null);
	sortUpdate = signal<MineSort>(getTableDefaultSort(TablesEnum.Requests));

	constructor(
		private logger: LoggerService,
		private contentPipe: ContentPipe,
		private requestsQuery: RequestsQuery,
		private requestsStore: RequestsStore,
		private profileService: ProfileService,
		private onboardingService: OnboardingService,
		private apiClientRequests: ApiClientRequestsService,
		private apiClientTicketService: ApiClientTicketService,
		private daysAgoPipe: DaysAgoPipe,
		@Inject(REQUESTS_PAGINATOR) public paginatorRef: PaginatorPlugin<RequestsState>,
	) {	}

	getCursor(): PaginationCursor {
		return {before: this.before(), after: this.after()} as PaginationCursor;
	}

	selectCursor(): Signal<PaginationCursor> {
		return computed(() => ({before: this.before(), after: this.after()} as PaginationCursor));
	}

	setCursor(cursor: PaginationCursor): void {
		this.before.set(cursor.before ?? null);
		this.after.set(cursor.after ?? null);
	}

	list(payload?: RequestsListPayload): Observable<RequestItem[]> {
		this.setActivatedEndpoint('list');
		return this.apiClientTicketService.list(payload).pipe(
			tap((response) => this.initStore(response)),
			map(response => response.requests.map(request => this.mapEntity(request)))
		);
	}

	search(payload: RequestsSearchPayload): Observable<RequestItem[]> {
		this.setActivatedEndpoint('search');
		return this.apiClientTicketService.search(payload).pipe(
			tap((response) => this.initStore(response)),
			map(response => response.requests.map(request => this.mapEntity(request)))
		);
	}

	getTicketInfo(id: string): Observable<RequestItemDeep> {
		return this.apiClientTicketService.getTicketInfo(id).pipe(
			tap(response => this.requestsStore.upsert(id, {...response}))
		);
	}

	private initStore(requestsResponse: RequestsResponse): void {
		this.logger.debug(this.loggerName, `Init store - ${requestsResponse.requests?.length} requests`);
		this.before.set(requestsResponse.tokens?.before ?? null);
		this.after.set(requestsResponse.tokens?.after ?? null);
		this.requestsStore.set(requestsResponse.requests.map(request => this.mapEntity(request)) ?? []);
	}

	private mapEntity(request: RequestItem): RequestItem {
		if (request.ticketState === TicketStateEnum.Redacted) {
			return this.handleRedactedRequest(request);
		}
		return request;
	}

	private handleRedactedRequest(request: RequestItem): RequestItem {
		return {
			...request,
			domain: this.contentPipe.transform('redacted-ticket.redactDomain'),
			userEmail: this.contentPipe.transform('redacted-ticket.redactUserEmail'),
			countryCode: this.contentPipe.transform('redacted-ticket.redactCountryCode'),
		} as RequestItem;
	}

	updateRequestsView(status: RequestsStatusEnum): void {
		this.selectedRequestsView = status;
	}

	getRequestsView(): RequestsStatusEnum {
		return this.selectedRequestsView;
	}

	convertFilterKeyToRequestsStatusEnum(filterKey: string): RequestsStatusEnum {
		switch(filterKey) {
			case RequestListFilters.Open:
				return RequestsStatusEnum.Open;
			case RequestListFilters.Edit:
				return RequestsStatusEnum.Edit;
			case RequestListFilters.Review:
				return RequestsStatusEnum.InProcessV2;	
			case RequestListFilters.Process:
				return RequestsStatusEnum.Processing;
			case RequestListFilters.Reply:
				return RequestsStatusEnum.Notify;
			case RequestListFilters.Unverified:
				return RequestsStatusEnum.Unverified;
			case RequestListFilters.Closed:
				return RequestsStatusEnum.Closed;
			default:
				return;
		}
	}

	convertRequestsStatusToRequestsView(status: RequestsStatusEnum): RequestsListTypeEnum {
		switch(status) {
			case RequestsStatusEnum.Open:
			case RequestsStatusEnum.Edit:
			case RequestsStatusEnum.InProcessV2:
			case RequestsStatusEnum.Notify:
			case RequestsStatusEnum.Processing:
				return RequestsListTypeEnum.Open;
			case RequestsStatusEnum.Closed:
				return RequestsListTypeEnum.Closed;
			default:
				return RequestsListTypeEnum.Unverified;
		}
	}

	sendMail(companyName: string): Observable<void> {
		return this.onboardingService.sendDomainCompanyName(companyName).pipe(
			tap(() => this.profileService.updateProfileValue<ConnectedToMineStatusEnum>(ConnectedToMineStatusEnum.EmailSent, 'connectedToMineStatus'))
		);
	}

	private setActivatedEndpoint(endpoint: 'list' | 'search'): void {
		if (this.activatedEndpoint !== endpoint) {
			this.clearCursor();
		}
		this.activatedEndpoint = endpoint;
	}

	updateLastSeen(ticketId: string): Observable<void> {
		return this.apiClientRequests.setLastSeen(ticketId).pipe(
			tap(() => this.requestsStore.update(ticketId, { notification: false }))
		);
	}

	// update last seen without triggr store
	setLastSeen(ticketId: string): Observable<void> {
		return this.apiClientRequests.setLastSeen(ticketId);
	}

	proccessTicket(accept: boolean, ticketId: string): Observable<void> { //fix
		return this.apiClientRequests.processTicket(accept, ticketId).pipe(
			switchMap((response) => 
			iif(() => response.statusResponse.statusCode === 200,
				of(this.updateProcessTicket(ticketId, response.ticketState, accept)),
				throwError(response)
			)),
		);
	}
	
	private updateProcessTicket(ticketId: string, state: TicketState, accept: boolean): void {
		this.requestsStore.update(ticketId, entity => {
			return {
				...entity,
				accept,
				ticketState: state
			};
		});
	}

	redactTicket(ticketId: string): Observable<RedactResponse> {
		return this.apiClientRequests.redactTicket(ticketId).pipe(
			catchError((error) => {
				this.logger.error(this.loggerName, `redactTicket() Error: ${error.name} ,${error.message}`);
				return throwError(error);
			})
		);
	}

	redactMultipleTickets(ticketId: string, ticketIds: string[]): Observable<void> {
		return this.apiClientRequests.redactMultipleTickets(ticketId, ticketIds)
		.pipe(
			tap(() => this.requestsStore.update(ticketIds, entity => {
				return {
					...entity,
					ticketState: { ...<TicketState>entity.ticketState, state: TicketStateEnum.Redacting } };
				}
			)),		
		);
	}

	isOpenTicket(state: TicketStateEnum): boolean {
		return state === TicketStateEnum.Processing ||
			   state === TicketStateEnum.Notify ||
			   state === TicketStateEnum.Edit;
	}

	getRequestFormConfig(): Observable<RequestFormConfiguration> {
		return this.apiClientRequests.getRequestFormConfig();
	}

	createTicket(ticket: SubmitRequestForm): Observable<RequestFormResponse> {
		return this.apiClientRequests.createTicket(ticket).pipe(
			tap((response: RequestFormResponse) => this.requestsStore.add(response.createdTicket))
		);
	}

	selectRequestsByEmail(email: string): Observable<RequestItem[]> {
		return this.search(this.requestsQuery.getSearchPayload(email, this.sortUpdate(), 1000));
	}

	continueAfterEditTicket(ticketId: string, ticket: SubmitRequestForm): Observable<RequestFormResponse> {
		return this.apiClientRequests.continueAfterEditTicket(ticket, ticketId).pipe(
			tap((response: RequestFormResponse) => this.requestsStore.update(ticketId, response.createdTicket))
		);
	}

	closeTicket(closeRequest: CloseRequest, ticketId: string): Observable<TicketState> {
		return this.apiClientRequests
			.closeRequestV2(closeRequest, ticketId)
			.pipe(
				switchMap((response) => 
					iif (() => response.statusResponse.statusCode === 200,
						of(this.updateCloseStore(ticketId, response.ticketState)),
						throwError(response)
					)
				)
			);
	}

	private updateCloseStore(ticketId: string, ticketState: TicketState): TicketState {
		this.updateTicketState(ticketId, ticketState);
		return ticketState;
	}

	updateTicketProcessState(ticketId: string, state: TicketProcessingState): void {
		this.requestsStore.update(ticketId, entity => {
		   return {
			   ...entity,
			   ticketState: { ...<TicketState>entity.ticketState, publishState: state.publishState, selectedIntegrations: state.selectedIntegrations} };
		   }
	   );	
   	}

	updateEvidenceLastViewed(ticketId: string): void {
		this.requestsStore.update(ticketId, { evidenceLastViewed: new Date().toString() });
	}

	updateTicketDetails(ticketId: string, ticketDetails: UpdateTicketDetails): Observable<void> {
		return this.apiClientTicketService.updateTicketDetails(ticketId, ticketDetails).pipe(
			tap(() => this.updateTicketDetailsInStore(ticketId, ticketDetails)),
		);
	}

	private updateTicketDetailsInStore(ticketId: string, ticketDetails: UpdateTicketDetails): void {
		if (ticketDetails.countryCode) {
			this.requestsStore.update(ticketId, { countryCode: ticketDetails.countryCode });
		}

		if (ticketDetails.ticketType) {
			this.requestsStore.update(ticketId, { ticketType: ticketDetails.ticketType });
		}

		if (ticketDetails.privacyRightId) {
			this.requestsStore.update(ticketId, { privacyRightId: ticketDetails.privacyRightId });
		}

		if (ticketDetails.dueDate) {
			this.requestsStore.update(ticketId, { dueDate: ticketDetails.dueDate });
		}
	}

	updateAcceptedOrRejectedTime(ticketId: string): void {
		this.requestsStore.update(ticketId, { acceptedOrRejectedTime: new Date().toString() });
	}

	notifyTicket(deletedWithoutIntegrations: boolean, ticketId: string): Observable<void> {
		return this.apiClientRequests.notifyTicket(deletedWithoutIntegrations, ticketId).pipe(
			switchMap((response) => 
				iif (() => response.statusResponse.statusCode === 200,
					of(this.updateTicketState(ticketId, response.ticketState)),
					throwError(response)
				)),
		);
	}

	updateTicketState(ticketId: string, state: TicketState): void {
		this.requestsStore.update(ticketId, { ticketState: state });
	}

	updateTicketAccepted(ticketId: string, accept: boolean): void {
		this.requestsStore.update(ticketId, { accept });
	}

   	getFileUrl(ticketId: string): Observable<string> {
		return this.apiClientRequests.getFileUrl(ticketId).pipe(
			map(response => response.url)
		);
	}

	getDpo(): Observable<Dpo> {
		return this.onboardingService.getDpo();
	}

	getProcessState(ticketId: string): Observable<TicketProcessingState> {
		const publishState$ = this.requestsQuery.selectEntity(ticketId).pipe(
			map(res => {
				return { 
					publishState: (<TicketStateExtended>res.ticketState)?.publishState, 
					selectedIntegrations: (<TicketStateExtended>res.ticketState)?.selectedIntegrations 
				} as TicketProcessingState;
			})
		);

		const set$ = publishState$.pipe(
			filter(response => !!response.publishState),
		);

		const notSet$ = publishState$.pipe(
			filter(response => !response.publishState),
			switchMap(() => this.apiClientRequests.getTicketProcessingState(ticketId)),
			tap(response => this.updateTicketProcessState(ticketId, response))
		);

		return merge(set$, notSet$);
	}

	updateMetadata(data: TicketMetadata): Observable<void> {
		return this.apiClientTicketService.updateMetadata(data).pipe(
			tap(() => this.updateTicketMetaData(data.ticketId, data.customFields))
		);
	}

	private updateTicketMetaData(ticketId: string, customFields: { [k: string]: string }): void {
		const updatedCustomFields = {
			...(<RequestItemDeep>this.requestsQuery.getEntity(ticketId))?.customFields,
			...customFields,
		};
		this.requestsStore.update(ticketId, { customFields: updatedCustomFields });
	}

	clearCursor(): void {
		this.before.set(null);
		this.after.set(null);
	}

	assignAutopilot(selectedRequests: string[]): Observable<AutopilotAssignedTicket[]> {
		// Add a timestamp to ensure the value is different, triggering the API call via /list.
		// This prevents the distinctUntilChanged operator from stopping the emission.
		return this.apiClientTicketService.assignAutopilot(selectedRequests).pipe(
			tap(res => this.requestsStore.update(selectedRequests, entity => {
				return {
					...entity,
					autoPilotState: res.assignedTickets.find(t => t.id === entity.ticketId)?.autoPilotState,
				};
			})),		
            map(res => res.assignedTickets),
			tap(res => this.refreshAfterAssignAutopilot(res))
		);
	}

	private refreshAfterAssignAutopilot(assignedTickets: AutopilotAssignedTicket[]): void {
		const successCount = assignedTickets.filter(t => t.autoPilotState === AutoPilotState.Assigned).length;
		if (successCount > 0) {
			this.requestListUpdate.set(new Date().toString());
		}
	}

	getDueDateData(dueDate: string): DueDateData {
		if (dueDate === undefined) {
			return {
				isDueDatePassed: false,
				text: '-',
			};
		}

		const days = dayjs(dueDate).diff(new Date().toDateString(), 'days');
		const isDueDatePassed = days < 0;
		let text;
		
		if (isDueDatePassed) {
			text = this.daysAgoPipe.transform(dueDate);
		} else {
			text = days === 1 ? 
				this.contentPipe.transform('ticket.futureDueDateSingular', { params: {number: days} } ) :
				this.contentPipe.transform('ticket.futureDueDate', { params: {number: days} } );
		}

		return {
			isDueDatePassed,
			text: text,
		};
	}

	exportAuditLog(): Observable<ExportAuditLogResponse> {
		return this.apiClientTicketService.exportAuditLog();
	}

	markAsUnread(ticketIds: string[]): Observable<void> {
		return this.apiClientTicketService.markTicketsAsUnread(ticketIds)		
		.pipe(
			tap(() => this.requestListUpdate.set(new Date().toString()))
		);
	}

	deleteTickets(ticketIds: string[]): Observable<void> {
		return this.apiClientTicketService.deleteTickets(ticketIds)
		.pipe(
			tap(() => this.requestListUpdate.set(new Date().toString()))
		);
	}
}