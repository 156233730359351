import { Component, OnInit, ChangeDetectionStrategy, Input, HostListener, ElementRef, ChangeDetectorRef, AfterViewInit, EventEmitter, Output, OnChanges, SimpleChanges, DestroyRef} from '@angular/core';
import { tap, debounceTime, distinctUntilChanged, from } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SafePipe } from '../pipes/safe.pipe';
import { TippyModule } from '../tippy.module';
import { DropdownOption } from '../mine-dropdown/mine-dropdown.interface';
import { MineInputComponent } from '../mine-input/mine-input.component';
import { MineBadgeComponent } from '../mine-badge/mine-badge.component';
import { MineExpansionPanelComponent } from '../mine-expansion-panel/mine-expansion-panel.component';

@Component({
	standalone: true,
	selector: 'mine-add-item',
	templateUrl: './mine-add-item.component.html',
	styleUrls: ['./mine-add-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, ReactiveFormsModule, TippyModule, MineInputComponent, MineBadgeComponent, MineExpansionPanelComponent, SafePipe],
})
export class MineAddItemComponent implements OnChanges, OnInit, AfterViewInit {
	@Input() options: DropdownOption[];
	@Input() placeholder: string;
	@Input() hasCustom: boolean;
	@Input() dynamicMenu: boolean;
    @Input() isOptionsViewCustom?: boolean;
    @Input() isAsync: boolean;
    @Input() isLoading: boolean;

    @Output()
    onCloseOptionsClick = new EventEmitter<DropdownOption>();

    @Output()
    searchAsync = new EventEmitter<string>();
	
	inputCtrl = new FormControl();
	filteredOptions: DropdownOption[] = [];

	dropdownWithGroups = false; // multiple groups inside dropdown

	private disableHostListener = true;

	constructor(
		private elm: ElementRef, 
		private cdr: ChangeDetectorRef,
		private destroyRef: DestroyRef,
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (this.dynamicMenu && changes?.options?.isFirstChange()) {
			this.setDropdownPositionDynamically();
		}
	}

	ngOnInit(): void {
		this.initDropdownType();
		this.filteredOptions = this.options;
  	}

	  private initDropdownType(): void {
		this.dropdownWithGroups = this.options?.filter(res => res.options?.length > 0)?.length > 0;
	}

	ngAfterViewInit(): void {
		this.elm.nativeElement.style.display = 'block';
		this.inputCtrl.valueChanges.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(value => this.onInputCtrlChange(value?.toLowerCase())),
			tap(() => this.cdr.detectChanges()),
			takeUntilDestroyed(this.destroyRef)
		).subscribe();
	}

    onInputCtrlChange(searchTerm: string): void {
        if (this.isAsync) {
            this.searchAsync.emit(searchTerm);
        } else {
            this.filterOptions(searchTerm);
        }
    }

  	@HostListener('document:click', ['$event.target'])
	onClick(targetElement) {
		if (this.disableHostListener) {
			this.disableHostListener = false;
			return;
		}
		
		const clickedInside = this.elm.nativeElement.contains(targetElement);

		if (!clickedInside) {
			this.onCloseOptionsClick.emit();
		}
	}

	trackByFn(index: number, item: DropdownOption) {
		return item.id;
	}

  	filterOptions(searchTerm: string): void {
		if (searchTerm) {
			this.filteredOptions = this.dropdownWithGroups ? this.filterOptionsWithGroups(searchTerm) : this.filterOptionsWithoutGroups(searchTerm);
		}
		else if (!searchTerm) {
			this.inputCtrl.reset();
			this.filteredOptions = this.options;
		}
	}

	private filterOptionsWithoutGroups(searchTerm: string): DropdownOption[] {
		return this.options?.filter(option => this.compareStrings(option.value, searchTerm));
	}

	private filterOptionsWithGroups(searchTerm: string): DropdownOption[] {
		const filteredOptions = [];
		this.options?.forEach(group => {
			const options = group.options?.filter(option => this.compareStrings(option.value, searchTerm));
			if (options.length > 0) {
				filteredOptions.push({...group, options});
			}
		});
		return filteredOptions;
	}

    private compareStrings(str: string, searchTerm: string): boolean {
        return str.toLowerCase().includes(searchTerm.toLowerCase());
    }

	onOptionChanged(selectedValue: DropdownOption): void {
    	this.onCloseOptionsClick.emit(selectedValue);
	}

	private setDropdownPositionDynamically(): void {
		// Check whether there is enough space for the options dropdown element to be visible 
		// completely in the visible window area, otherwise we change it's position
		from(this.elementVisibleInPercent(this.elm.nativeElement.parentElement)).pipe(
			tap(precent => precent < 100 ? this.changeDropdownPositionOnOverflow(this.elm.nativeElement, precent) : null),
			takeUntilDestroyed(this.destroyRef)
		).subscribe();
	}

	// check how much of an element is visible in viewport
	private elementVisibleInPercent(element: HTMLElement): Promise<number> {
		return new Promise((resolve, reject) => {
			const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
				entries.forEach((entry: IntersectionObserverEntry) => {
					resolve(Math.floor(entry.intersectionRatio * 100));
					clearTimeout(timeout);
					observer.disconnect();
				});
			});

			observer.observe(element);
			// Probably not needed, but in case something goes wrong.
			const timeout = setTimeout(() => {
				reject();
			}, 500);
		});
	}

	private changeDropdownPositionOnOverflow(element: HTMLElement, precent: number): void {
		element.style.left = `unset`;
		element.style.right = `${this.elm.nativeElement.parentElement.offsetWidth * ((100 - precent) / 100)}px`;
	}
}
