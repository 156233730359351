<mine-x-button 
    *ngIf="dialogData.showXBtn" 
    data-cy="dialog__x-btn"
    (onClick)="cancelClicked()">
</mine-x-button>

<img *ngIf="!!dialogData.image" class="mine-title-m dialog__image"  
    data-cy="dialog__image"
    [src]="dialogData.image" 
    [width]="dialogData.imageWidth ?? 150" 
/>

<div class="mine-title-m dialog__title" 
    data-cy="dialog__title"
    [class.dialog__title--margin-bottom]="!dialogData.text" 
    [innerHTML]="dialogData.title | safe">
</div>

<div *ngIf="dialogData.text" class="dialog__text" 
    data-cy="dialog__text"
    [innerHTML]="dialogData.text | safe">
</div>

<div class="dialog__buttons">
    <button mine-button color="secondary" 
        *ngIf="dialogData.cancelBtn" 
        class="dialog__cancel-btn" 
        data-cy="dialog__cancel-btn"
        (click)="cancelClicked()">
        {{dialogData.cancelBtn}}
    </button>

    <mine-button-primary class="dialog__ok-btn" 
        data-cy="dialog__ok-btn"
        [text]="dialogData.okBtn"
        [theme]="okButtonTheme"
        (onClick)="okClicked()">
    </mine-button-primary>
</div>