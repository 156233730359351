<ng-container *rxIf="loading$ | async; else viewsSections">

    <div *rxFor="let section of [1,2]" class="list__loading">

        <ng-container *skeleton="true; width: '6.6rem'; height: '1rem'; style: 'margin-bottom: 8px; border-radius: 10px;'"></ng-container>

        <ng-container *skeleton="true; repeat: 2; width: '100%'; height: '3.3rem'; style: 'margin-bottom: 8px; border-radius: 10px;'"></ng-container>

    </div>

</ng-container>

<ng-template #viewsSections>

    <mine-view-section class="list__view-section" 
        [title]="'risks.riskRegistry.severityViewSection' | content">

        <ng-container *ngTemplateOutlet="listItem; context: { key: RiskRegistryFilteringCategoryEnum.All }"></ng-container>

        <ng-container *ngTemplateOutlet="listItem; context: { key: RiskRegistryFilteringCategoryEnum.High }"></ng-container>

        <ng-container *ngTemplateOutlet="listItem; context: { key: RiskRegistryFilteringCategoryEnum.Medium }"></ng-container>

        <ng-container *ngTemplateOutlet="listItem; context: { key: RiskRegistryFilteringCategoryEnum.Low }"></ng-container>

        <ng-container *ngTemplateOutlet="listItem; context: { key: RiskRegistryFilteringCategoryEnum.Missing }"></ng-container>
    </mine-view-section>

    <ng-container *rxLet="riskTypes$; let riskTypes">

        <ng-container *rxIf="riskTypes?.length">

            <mine-view-section class="list__view-section" 
                [title]="'risks.riskRegistry.kindViewSection' | content">

                <ng-container *rxFor="let riskType of riskTypes">
                    <ng-container *ngTemplateOutlet="listItem; context: { key: riskType.type }"></ng-container>
                </ng-container>

            </mine-view-section>

        </ng-container>

    </ng-container>

    <ng-template #listItem let-key="key">
        <mine-view-item 
            *rxIf="(listItems.get(key)?.count$ | async) > 0"
            class="list__view-item"
            [active]="activeListItem === key"
            [label]="listItems.get(key)?.title"
            (selectView)="onListItemClick(key, $event)">
            <span count 
                  class="list__item-count" 
                  [class.list__item-count--active]="activeListItem === key">
                {{ listItems.get(key)?.count$ | async }}
            </span>
        </mine-view-item>
    </ng-template>

</ng-template>