
import { DataSensitivityEnum } from 'src/app/vendor-management/models/data-sensitivity.enum';
import { AiAssessmentTemplateEnum } from '../../../ai-assessments/models/ai-assessments.enum';
import { AiAssessmentPage } from '../ai-assessments/ai-assessments.interface';
import { FrameworkChip } from "src/app/data-types/models/frameworks.interface";

export interface RiskRegistryFilters {
    mitigations: string,
    inherentRisk: string[],
    residualRisk: string[],
    dataSources: string,
    riskTypes: string[],
    assessmentTypes: string,
}

export enum RiskRegistryFilteringCategoryEnum {
    All = "All",
    High = "High",
    Medium = "Medium",
    Low = "Low",
    Missing = "Missing"
}

export enum RiskRegistryColumnEnum {
    Risk = "name",
    Assessment = "relatedAssessmentId",
    AssessmentType = "assessmentType",
    InherentRisk = "inherentRisk",
    ResidualRisk = "residualRisk",
    Mitigations = "mitigations",
    DataSources = "relatedEntityIds",
    Created = "createdDate"
}

export enum RiskHelperType {
    ExternalBreach = 'externalBreach',
    InternalBreach = 'internalBreach',
    ExcessiveDataCollection = 'excessiveDataCollection',
    DataMisclassification = 'dataMisclassification',
    ContractualBreach = 'contractualBreach',
    Overcollection = 'overcollection'
}

export interface RiskCatalogsResponse {
    customCatalogRisks: { [type: string] : RiskCatalog };
    predefinedCatalogRisks: { [type: string] : RiskCatalog };
}

export interface RiskCatalog {
    id?: number;
    name: string;
    type: string;
    isHidden: boolean;
    isDefault: boolean;
    assessmentTypes?: AiAssessmentTemplateEnum[];
}

export enum RiskRateEnum {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High'
} 

export class Risk {
    id?: number;
    name: string;
    type: string;
    createdDate?: Date;
    updatedDate?: Date;
    assessmentPage?: Partial<AiAssessmentPage>;
    accepted: boolean;
    likelihood: RiskRateEnum;
    severity: RiskRateEnum;
    inherentRisk: RiskRateEnum;
    residualRisk: RiskRateEnum;
    mitigations?: string[];
    comment?: string;
    isDefault?: boolean;
}

export class RiskHelper {
    title: string;
    subTitle?: string;
    id: string;
    bullets?: string;
    calculate?: RiskHelperCalculation;
    schemaId?: string;
}

export class RiskHelperCalculation {
    explanation: string;
    likelihood: string;
    severity: string;
    mitigation: string;
    tableHeaders?: { name: string }[];
}

interface BreachContext {
    system: string;
    name: string;
    logo: string;
    sesitivity: DataSensitivityEnum;
}

export enum RiskRegistryFilteringGroupEnum {
    DataSource = "dataSource",
    RiskType = "riskType",
    Mitigation = "mitigation",
    ResidualRisk = "residualRisk",
    InherentRisk = "inherentRisk",
    AssessmentType = "assessmentType"
}

export interface ExternalBreachContext extends BreachContext {
    rating: string;
    spinner: boolean;
 }

 export interface InternalBreachContext extends BreachContext {
    employees: number;
 }

 interface DataTypesContext {
    dataType: string;
    frameworks: FrameworkChip[];
}

 export interface OverallocationContext extends DataTypesContext {
    records: number;
 }

 export interface DataMisclassificationContext extends DataTypesContext {
    systems: { name: string, logo: string }[];
 }

export declare type RiskCalculationContext = ExternalBreachContext | InternalBreachContext | OverallocationContext | DataMisclassificationContext;