import { EventEmitter, Injectable } from '@angular/core';
import { map, take, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { MessageTypeEnum } from './models/message.enum';
import { Message } from './models/message.interface';

import { LoggerService } from '../logger/logger.service';
import { MineSnackbarService } from '../shared/mine-snackbar/mine-snackbar.service';
import { MineSnackbarType } from '../shared/mine-snackbar/mine-snackbar-type';
import { ContentPipe } from '../services/content/content.pipe';
import { ProfileQuery } from '../profile/state/profile.query';

declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  private readonly loggerName: string = 'PusherService';
  
  channelReady = new EventEmitter<boolean>();

  channelDisconnected = new EventEmitter<void>();
  
  private pusher: any;
  
  private channel: any;

  constructor(
    private logger: LoggerService,
    private contentPipe: ContentPipe,
    private profileQuery: ProfileQuery,
    private snackbarService: MineSnackbarService,
  ) {}

  init(): void {
    this.initConnection();
    this.initChannel();
  }

  private initConnection(): void {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      authorizer: this.authorizer,
    });
  }

  authorizer = (channel, options) => {
    const companyId = this.profileQuery.getCompanyId();
    const authEndpoint = environment.api.accessPointUrl + environment.pusher.auth_endpoint;

    return {
      authorize: (socketId, callback) => {
        var headers = new Headers();
        headers.append("Companyid", companyId);
        headers.append("Content-Type", "application/json; charset=utf-8");
    
        fetch(authEndpoint, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            "socket_id": socketId,
            "channel_name": channel.name,
            "company_id": companyId
          }),
          credentials: 'include',
          redirect: 'follow'
        })
          .then(response => response.json())
          .then(data => callback(null, data))
          .catch(error => {
            console.error(error);
            this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('chatbot.connectionError'));
            this.logger.error(this.loggerName, "pusher:subscription_error");
          });
      }
    };
  };

  private initChannel(): void {
    const companyId = this.profileQuery.getCompanyId();
    this.channel = this.pusher.subscribe(`private-${companyId}`);
    this.logger.debug(this.loggerName, `Subscribing to channel: private-${companyId}`);
    
    const infoLogFn = () => {
      this.logger.debug(this.loggerName, "pusher:subscription_succeeded");
      this.channelReady.emit(true);

      if (!sessionStorage.getItem(`fe_ready-${companyId}`)) {
        this.sendInitMessage();
      }
      else {
        this.getChatHistory();
      }
    };

    const errorLogFn = (error) => {
      console.error(error);
      this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('chatbot.connectionError'));
      this.logger.error(this.loggerName, "pusher:subscription_error");
    };

    this.channel.bind('pusher:subscription_succeeded', infoLogFn);
    this.channel.bind("pusher:subscription_error", errorLogFn);
  }

  private getChatHistory(): void {
    this.profileQuery.selectCompanyId().pipe(
      take(1),
      tap(companyId => sessionStorage.setItem(`fe_ready-${companyId}`, '' + Date.now())),
      tap(companyId => this.logger.debug(this.loggerName, `Sending get history message: { type: ${MessageTypeEnum.USER_GET_HISTORY}, content: ${companyId} }`)),
      map(companyId => this.sendMessage({ type: MessageTypeEnum.USER_GET_HISTORY, content: companyId } as Message)),
    ).subscribe();
  }

  private sendInitMessage(): void {
    this.profileQuery.selectCompanyId().pipe(
      take(1),
      tap(companyId => sessionStorage.setItem(`fe_ready-${companyId}`, '' + Date.now())),
      tap(companyId => this.logger.debug(this.loggerName, `Sending init message: { type: ${MessageTypeEnum.FE_READY}, content: ${companyId} }`)),
      map(companyId => this.sendMessage({ type: MessageTypeEnum.FE_READY, content: companyId } as Message)),
    ).subscribe();
  }

  subscribe(callback: object): void {
    this.channel.bind(environment.pusher.ds_event_type, callback);
  }

  sendMessage(message: Message): void {
    this.channel.trigger(environment.pusher.fe_event_type, message);
  }

  disconnect(): void {
    this.pusher.disconnect();
    this.channelDisconnected.emit();
  }
}
