import { Pipe, PipeTransform } from '@angular/core';
import { RiskRatingEnum } from 'src/app/vendor-management/models/risk-rating.enum';

@Pipe({
  standalone: true,
  name: 'vendorScoreToColor'
})
export class VendorScoreToColorPipe implements PipeTransform {

  transform(score: number): RiskRatingEnum {

    if (!score) {
      return null;
    }
    if (score <= 59) {
      return RiskRatingEnum.Bad;
    }
    else if (score <= 69) {
      return RiskRatingEnum.Poor;
    }
    else if (score <= 79) {
      return RiskRatingEnum.Fair;
    }
    else if (score <= 89) {
      return RiskRatingEnum.Good;
    }
    else if (score <= 100 ) {
      return RiskRatingEnum.Excellent;
    }  
  }
}
