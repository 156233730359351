<mine-checkbox-group *ngIf="columns?.size" class="column-picker__group" 
    [panelStyle]="panelStyle">

    <span class="column-picker__group-btn" mine-checkbox-group-label>
        <svg class="column-picker__column-svg">
            <use xlink:href="../../../assets/img/sprite.svg#icon-columns"></use>
        </svg>
    </span>

    <p class="column-picker__group-header">
        <strong>{{ 'common.columnPickerHeaderText' | content }}</strong>
    </p>

    <form *ngIf="columnForm" [formGroup]="columnForm" class="column-picker__group-form">
        <div content class="column-picker__category-content">
            <mine-checkbox class="column-picker__checkbox" 
                *ngFor="let column of columns | keyvalue: originalOrder"
                [id]="column.key" [formControl]="columnForm.get(column.key)" 
                [center]="true" [text]="column.value.text">
            </mine-checkbox>
        </div>
    </form>

</mine-checkbox-group>