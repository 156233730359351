<mine-expansion-panel [isOpen]="true" [disableToggle]="disableToggle" class="view-section__expansion-panel">

    <p header class="view-section__header">
        
        <span class="view-section__title">{{title}}</span>
        
        <mine-info-icon *ngIf="!!tooltip" class="view-section__icon-tooltip" [heliText]="tooltip"></mine-info-icon>

        <svg *ngIf="!disableToggle" class="view-section__arrow-svg">
			<use xlink:href="../../../assets/img/sprite.svg#icon-Arrow-4"></use>
		</svg>
    </p>

    <div content class="view-section__content">
       <ng-content></ng-content>
    </div>

</mine-expansion-panel>