<mine-checkbox-group *ngIf="filters?.length" 
    class="filtering__group"
    [class.disabled]="disabled" 
    [class.filtering__group--disabled]="disabled"
    [class.filtering__group--selected]="!!totalSelectedFilters"
    [panelStyle]="panelStyle">

    <div class="filtering__button" mine-checkbox-group-label>
        <svg class="filtering__button-icon">
            <use xlink:href="../../../assets/img/sprite.svg#icon-filter"></use>
        </svg>
        {{ filterContent.label }}
        <span [@pimple] *ngIf="!!totalSelectedFilters && !disabled" class="filtering__pimple">
            {{ totalSelectedFilters }}
        </span>
    </div>

    <p class="filtering__header">
        <strong class="filtering__header-text">{{ filterContent.by }}</strong>
        <span class="filtering__header-clear-text" (click)="clearFilters()">{{ filterContent.clearAll }}</span>
    </p>

    <form *ngIf="filtersForm" [formGroup]="filtersForm" class="filtering__form">
        
        <mine-expansion-panel #filterCategories *ngFor="let category of filters; index as i" class="filtering__category" 
            [class.filtering__category--disabled]="category.disabled" [id]="category.label">

            <div header class="filtering__category-header">         
                <p> {{category.label}} {{ selectedFiltersCount.get(category.label) === 0 ? '' : '(' + selectedFiltersCount.get(category.label) + ')'}} </p>
                <svg class="filtering__category-header-svg">
                    <use xlink:href="../../../assets/img/sprite.svg#icon-Arrow-4"></use>
                </svg>
            </div>
        
            <div content class="filtering__category-content">

                <div class="filtering__category-checkbox" *ngFor="let option of category.options">
                    <mine-checkbox class="filtering__checkbox" 
                        [formControl]="filtersForm.get(category.label)?.controls[option.label]"   
                        [center]="true" 
                        [text]="option.label" 
                        [id]="category.label + '-' + option.id">
                    </mine-checkbox>
                </div>

            </div>
        
        </mine-expansion-panel>

    </form>

    <footer class="filtering__footer">

        <mine-button-primary class="filtering__footer-btn" 
            [text]="filterContent.apply"
            (onClick)="applyFilters()">
        </mine-button-primary>
    </footer>

</mine-checkbox-group>