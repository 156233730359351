import { Injectable } from '@angular/core';
import { catchError, filter, map, switchMap, tap, concatMap, first, EMPTY, iif, merge, Observable, of, throwError } from 'rxjs';

import { LoggerService } from 'src/app/logger/logger.service';
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { CompanySettingsService } from 'src/app/company-settings/state/company-settings.service';
import { ApiClientProcessingActivityService } from 'src/app/api/api-client-processing-activities.service';
import { ProcessActivityStatusUpdate, ProcessingActivity, ProcessingActivityConfig } from 'src/app/api/models/processing-activities/processing-activities.interface';
import { ProcessingActivityFilteringService } from '../processing-activity-filters/processing-activity-filtering.service';
import { ProcessActivityStatusEnum } from 'src/app/api/models/processing-activities/processing-activities.enum';
import { ProcessingActivitiesStore } from './processing-activities.store';
import { ProcessingActivitiesQuery } from './processing-activities.query';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { FilterCategory } from 'src/app/core/models/filtering.interface';

@Injectable({ 
	providedIn: 'root' 
})
export class ProcessingActivitiesService  {
	private readonly loggerName: string = 'ProcessingActivitiesService';	

	constructor(
		private logger: LoggerService,
		private systemsQuery: SystemsQuery,
		private featureFlagsQuery: FeatureFlagQuery,
		private processingActivitiesQuery: ProcessingActivitiesQuery,
		private processingActivitiesStore: ProcessingActivitiesStore,
		private paFilteringService: ProcessingActivityFilteringService,
		private apiClientProcessingActivityService: ApiClientProcessingActivityService,
		private companySettingsService: CompanySettingsService,
	) { }

	init(): Observable<void> {
		this.logger.debug(this.loggerName, 'init()');
		return this.featureFlagsQuery.selectFlag(FeatureFlags.ProcessingActivities).pipe(
			concatMap(flag => iif(() => !!flag,
				this.getProcessingActivities(),
				this.setProcessingActivitiesEmpty()
			)),
			switchMap(() => this.selectFunctions()),
			map(() => void 0)
		);
	}

	getProcessingActivities(enforcePullDataFromServer = false): Observable<ProcessingActivity[]> {
		if (enforcePullDataFromServer) {
			return this.getProcessingActivitiesFromServer();
		}

		const loading$ = this.processingActivitiesQuery.selectLoading();

		const getDataFromStore$ = loading$.pipe(
			filter(response => !response),
			switchMap(() => this.processingActivitiesQuery.selectAll())
		);

		const getDataFromServer$ = loading$.pipe(
			filter(response => response),
			switchMap(() => this.getProcessingActivitiesFromServer())
		);

		return merge(getDataFromStore$, getDataFromServer$).pipe(
			tap(() => this.logger.info(this.loggerName, 'get ropa data'))
		);
	}

	private getProcessingActivitiesFromServer(): Observable<ProcessingActivity[]> {
		return this.systemsQuery.selectLoading().pipe(
			filter(res => !res),
			switchMap(() => this.systemsQuery.selectCount()),
			first(),
			switchMap(response => iif(() => response > 0,
				this.setProcessingActivities(),
				this.setProcessingActivitiesEmpty()
				)	
			)
		);
	}

	private setProcessingActivities(): Observable<ProcessingActivity[]> {
		return this.apiClientProcessingActivityService.getProcessingActivities().pipe(
			map(result => this.filterActivitiesWithoutPath(result.data)),
			tap(result => this.processingActivitiesStore.set(result)),
			concatMap(() => this.processingActivitiesQuery.selectAll()),
			catchError(error => {
				this.processingActivitiesStore.set([]);
				this.logger.error(this.loggerName, `getDataMappingFromServer() Error: ${error.name} ,${error.message}`);
				throwError(error.error);
				return EMPTY;
			})
		);
	}

	private filterActivitiesWithoutPath(data: {[key: string]: ProcessingActivity}): {[key: string]: ProcessingActivity} {
		let response = {};
		Object.keys(data).forEach(key => {
			if (data[key].path) {
				response[key] = data[key];
			}
		});
		return response;
	}

	setProcessingActivitiesEmpty(): Observable<ProcessingActivity[]> {
		return of([]).pipe(
			tap(() => this.processingActivitiesStore.set([])),
		);
	}

	getProcessingActivitiesActivity(id: string): Observable<ProcessingActivity> {
		return this.processingActivitiesQuery.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.processingActivitiesQuery.selectEntity(id))
		);
	}

	removeProcessingActivity(id: string): Observable<void> {
		return this.apiClientProcessingActivityService.removeProcessingActivity(id).pipe(
			tap(() => this.processingActivitiesStore.remove(({ path }) => path == id))
		);
	}
	
	addProcessingActivity(ropaItem: ProcessingActivityConfig): Observable<ProcessingActivity> {
		return this.apiClientProcessingActivityService.addProcessingActivity(ropaItem).pipe(
			tap(res => this.processingActivitiesStore.add(res))
		);
	}

	editProcessingActivity(id: string, ropaItem: ProcessingActivity): Observable<void> {
		return this.apiClientProcessingActivityService.editProcessingActivity(id, ropaItem).pipe(
			map(res => this.processingActivitiesStore.update(id, res)),
		);
	}

	updateProcessingActivityStatus(id: string, status: ProcessActivityStatusEnum): Observable<void> {
		const body: ProcessActivityStatusUpdate = {
			paId: id,
			status
		};

		return this.apiClientProcessingActivityService.updateProcessingActivityStatus(body).pipe(
			tap(() => this.processingActivitiesStore.update(id, {status: status}))
		);
	}
	
	removeEnabledDpiaBanner(paId: string): Observable<void> {
		return this.apiClientProcessingActivityService.removeEnabledDpiaBanner(paId).pipe(
			tap(() => this.processingActivitiesStore.update(paId, 
				(entity) => {
					return {
						...entity,
						dpiaInfo: {...entity.dpiaInfo, hideBanner: true}
					};
				}	
			))
		);
	}

	selectActivities(sort?: MineSort, search?: string, filters?: FilterCategory[]): Observable<ProcessingActivity[]> {
		return this.processingActivitiesQuery.selectActivities(filters, sort, search);
	}

	isNameInInventory(name: string): boolean {
		return !!this.processingActivitiesQuery.getAll()?.find(entity => entity.name?.toLowerCase() === name?.toLowerCase());
    }

	selectActivitiesForExport(includeAll: boolean): Observable<ProcessingActivity[]> {
		return this.paFilteringService.selectActiveFilters().pipe(
			switchMap(res => this.processingActivitiesQuery.selectSortedActivitiesForExport(res, includeAll))
		);
	}

	selectFunctions(): Observable<string[]> {
		return this.apiClientProcessingActivityService.getFunctions().pipe(
			tap(functions => this.processingActivitiesQuery.setFunctions(functions))
		);
	}

	addCustomMitigation(name: string): Observable<void> {
		return this.companySettingsService.addCustomMitigation(name);
	}

	addCustomDataSubject(name: string): Observable<void> {
		return this.companySettingsService.addCustomDataSubject(name);
	}

	validateFieldNotUsed(id: string): Observable<boolean> {
        return this.processingActivitiesQuery.selectAll().pipe(
            map(res => this.findFieldWithValue(res, id)),
        );
    }

	private findFieldWithValue(res: ProcessingActivity[], id: string): boolean {
        let hasValue = false;
		res.forEach(r => {
			const field = r.customActivityFields?.find(f => f.id === id)?.values;
			if (!!field?.text || !!field?.optionIds?.length) {
				hasValue = true;
				return;
			}
		});
        return hasValue;
    }

	validateDpiaCustomFieldNotUsed(id: string): Observable<boolean> {
        return this.processingActivitiesQuery.selectAll().pipe(
            map(res => this.findDpiaCustomFieldWithValue(res, id)),
        );
    }

    private findDpiaCustomFieldWithValue(res: ProcessingActivity[], id: string): boolean {
        let hasValue = false;
		res.forEach(r => {
			const field = r.dpiaInfo?.customFields?.find(f => f.id === id)?.values;
			if (!!field?.text || !!field?.optionIds?.length) {
				hasValue = true;
				return;
			}
		});
		
        return hasValue;
    }
}
