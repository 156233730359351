<h1 class="mine-title-m dialog__title">{{ 'company-settings-customization.newCustomValueTitle' | content: { params: {field: (fieldType | fieldTypeToText)} } }}</h1>

<mine-input [formControl]="nameCtrl"
    class="dialog__input" 
    id="name-input"
    [required]="true"
    [error]="nameCtrl.invalid && nameCtrl.hasError('alreadyExists') ? ('company-settings-customization.newCustomValueAlreadyExist' | content) : null"
    [placeholder]="'company-settings-customization.newCustomValuePlaceholder' | content">
    <span class="dialog__input-label" mine-input-label> {{ 'company-settings-customization.newCustomValueLabel' | content }} </span>
</mine-input>

<div class="dialog__buttons">
    
    <button mine-button class="dialog__btn" color="secondary" (click)="cancel()">
        {{ 'common.cancelBtn' | content }}
    </button>

    <button mine-button class="dialog__btn" color="primary" [disabled]="!nameCtrl.valid" (click)="createCustomValue()">
        {{ 'company-settings-customization.newCustomValueCreateBtn' | content }}
    </button>

</div>