//todo: add feature flag here and in ProfileFeatureFlags object
export enum FeatureFlags {
	WebHookEvents = 'webHookEvents',
	AutoCompleteRules = 'autoCompleteRules',
	ApiKeys = 'apiKeys',
	CanceledMailAutomation = 'canceledMailAutomation',
	CompletedMailAutomation = 'completedMailAutomation',
	RejectedMailAutomation = 'rejectedMailAutomation',
	AckMailAutomation = 'ackMailAutomation',
	CopyAttachmentMailAutomation = 'copyAttachmentMailAutomation',
	UnlimitedAgents = 'unlimitedAgents',
	AddTeamMember = 'addTeamMember',
	PremiumDialogFlag = 'premiumDialogFlag',
	PortalHubspotWidget = 'portalHubspotWidget',
	UnLimitedTicketsPerMonth = 'unLimitedTicketsPerMonth',
	MyBillingButton = 'myBillingButton',
	EmailTemplates = 'emailTemplates',
	DataMapping = 'dataMapping',
	PortalSlackNotifications = 'portalSlackNotifications',
	TemplatesLocalization = 'templatesLocalization',
	PreviewRecords = 'previewRecords',
	RopaGeneationAndEditing = 'ropaGeneationAndEditing',
	HideIntegrationSecret = 'hideIntegrationSecret',
	RequestsFiltering = 'requestsFiltering',
	PrivacyCenterCustomQuestion = 'privacyCenterCustomQuestion',
	ManualInput = 'manualInput',
	RopaV2= 'ropaV2',
	DisableEditCategories = 'disableEditCategories',
	AdminInstall = 'adminInstall',
	Ropa = 'ropa',
	UserSearch = 'userSearch',
	PIIDiscovery = 'pIIDiscovery',
	PIIFiltering = 'pIIFiltering',
	EditIntegrationName = 'editIntegrationName',
	PrivacyPolicyLink = 'privacyPolicyLink',
	HideNoCategoriesDataSources = 'hideNoCategoriesDataSources',
	EmployeeViewInsightsV2 = 'employeeViewInsightsV2',
	PCEmailBranding = 'pCEmailBranding',
	IntegrationOwnerWorkflow = 'integrationOwnerWorkflow',
	ConnectWorkspace = 'connectWorkspace',
	CustomDomainPC = 'customDomainPC',
	CustomSystemInner = 'customSystemInner',
	SplitPrivacyCenterCustomCode = 'splitPrivacyCenterCustomCode',
	TicketSearch = 'ticketSearch',
	NoEmptyInventory = 'noEmptyInventory',
	TollFree = 'tollFree',
	PanoraysPlan = 'panoraysPlan',
	FindCategories = 'findCategories',
	DataMappingColumnPicker = 'dataMappingColumnPicker',
	VettingPlan = 'vettingPlan',
	AutoRedactRule = 'autoRedactRule',
	DataMappingDashboard = 'dataMappingDashboard',
	DiscoveryTools = 'discoveryTools',
	PaMenu = 'paMenu',
	DiscoverySource = 'discoverySource',
	SSODiscovery = 'sSODiscovery',
	DiscoveryToolsEmail = 'discoveryToolsEmail',
	ObjectActionConfig = 'objectActionConfig',
	PoliciesPlan = 'policiesPlan',
	PoliciesDev = 'policiesDev',
	ClassificationDeepDive = 'classificationDeepDive',
	ScanPerIntegration = 'scanPerIntegration',
	RemoveDataType = 'removeDataType',
	ProcessingActivitiesExportPopup = 'processingActivitiesExportPopup',
	ProcessingActivities = 'processingActivities',
	LockProcessingActivitiesSuggestions = 'lockProcessingActivitiesSuggestions',
	LockRolesAndPermissions = 'lockRolesAndPermissions',
	SetupFlowApiKey = 'setupFlowApiKey',
	SystemReviewTask = 'systemReviewTask',
	DiscoveryUrlsChange = 'discoveryUrlsChange',
	LockIntegrationTagging = 'lockIntegrationTagging',
	EditTeammate = 'editTeammate',
	ContextlessCatalog = 'contextlessCatalog',
	DevEditDoNotMailRight = 'devEditDoNotMailRight',
	SystemBusinessOwnerColumn = 'systemBusinessOwnerColumn',
	DevSetionSection = 'devSetionSection',
	SsoGoogleProvider = 'ssoGoogleProvider',
	WebsiteProvider = 'websiteProvider',
	DevCustomFrameworks = 'devCustomFrameworks',
	AWSDiscovery = 'aWSDiscovery',
	DevNewActivityLayout = 'devNewActivityLayout',
	OktaSSO = 'oktaSSO',
	GcpDiscovery = 'gcpDiscovery',
	DevRadarOriginFilter = 'devRadarOriginFilter',
	PortalVpn = 'portalVpn',
	PcCopyCustomizationFF = 'pcCopyCustomizationFF',
	DevAutopilot = 'devAutopilot',
	AutopilotByPlan = 'autopilotByPlan',
	DevCustomDataSubject = 'devCustomDataSubject',
	DevCustomDataTypes = 'devCustomDataTypes',
	DevEditTeammateDepartment = 'devEditTeammateDepartment',
	DisableRadarPolling = 'disableRadarPolling',
	DevGcpDownload = 'devGcpDownload',
	IntegrationCustomSecrets = 'integrationCustomSecrets',
	BeamerNotifications = 'beamerNotifications',
	DevPIIClassifier = 'devPIIClassifier',
	DevAgentTasks = 'devAgentTasks',
	DevSystemCategoryList = 'devSystemCategoryList',
	DevActivityCategoryList = 'devActivityCategoryList',
	DevExportDsrAuditLog = 'devExportDsrAuditLog',
	DevCustomPiiIntegration = 'devCustomPiiIntegration',
	DevExtendRoles = 'devExtendRoles',
	RandomData = 'randomData',
	DevAuditCopyRequests = 'devAuditCopyRequests',
	DevMarkAsUnread = 'devMarkAsUnread',
	EnforceSsoAuthentication = 'enforceSsoAuthentication',
	DevCustomEditorPC = 'devCustomEditorPC',
	DevActivitiesTableCustomView = 'devActivitiesTableCustomView',
	DevDataSourceTableCustomView = 'devDataSourceTableCustomView',
	DevRisksV2 = 'devRisksV2',
	DevWebhookV2 = 'devWebhookV2',
	DevCustomValues = 'devCustomValues',
	DevSystemPotentialRisks = 'devSystemPotentialRisks',
	DemoIntegrations = 'demoIntegrations',
	DevPiiDebugValuesMode = 'devPiiDebugValuesMode',
	DevDeleteTickets = 'devDeleteTickets',
	DevDataSourcesCategoryColumn = 'devDataSourcesCategoryColumn',
	DevCustomFieldsFilter = 'devCustomFieldsFilter',
	DevRadarMetadata = 'devRadarMetadata',
	DevCustomValuesV2 = 'devCustomValuesV2',
	DevAIDiscoveryPhase1 = 'devAIDiscoveryPhase1',
	DevScanThreshold = 'devScanThreshold',
	AutopilotDoNotSell = 'autopilotDoNotSell',
  	DevAIAssessments = 'devAIAssessments',
	DevMineOSChatbot = 'devMineOSChatbot',
	DevNewDsr = 'devNewDsr',
  	DevPiiClassificationSchemaActionsMenu = 'devPiiClassificationSchemaActionsMenu',
	DevCustomSystemEdit = 'devCustomSystemEdit',
	DevPiiClassificationDataTypeActionsMenu = 'devPiiClassificationDataTypeActionsMenu',
	DevAiAssetsDatasets = 'devAiAssetsDatasets',
	EmployeesTableDataSourcesColumn = "employeesTableDataSourcesColumn",
	SystemFunctions = "systemFunctions",
	JiraWorkflow = 'jiraWorkflow',
	DevAIAssessmentsCustomTemplates = 'devAIAssessmentsCustomTemplates',
	MaintenanceMode = 'maintenanceMode',
	DevSystemReviewDataCol = 'devSystemReviewDataCol',
	DevServerTemplates = 'devServerTemplates',
	DevCustomLanguages = 'devCustomLanguages',
	DevPrivacyRights = 'devPrivacyRights',
	DevAssessmentReviewDate = 'devAssessmentReviewDate',
	DevTicketsTableViews = 'devTicketsTableViews',
	DevAIAssessmentsRopa = 'devAIAssessmentsRopa',
	ArchiveTickets = 'archiveTickets',
	HideConnectToMine = 'hideConnectToMine',
	DevGroupsDiscovery = 'devGroupsDiscovery',
	DevPdfConvertor = 'devPdfConvertor',
    DevAssessmentsApiV2 = 'devAssessmentsApiV2',
	AssessmentReviewReminderV2 = 'assessmentReviewReminderV2',
    DevAssessmentTemplateSavedQuestions = 'devAssessmentTemplateSavedQuestions',
	DevPrivacyRightsLocale = 'devPrivacyRightsLocale',
	DevAIAssessmentsDataFlow = 'devAIAssessmentsDataFlow',
	DevUnstructuredCopy = 'devUnstructuredCopy',
	DevOktaSSO = 'devOktaSSO',
	DevAIAssessmentsDataTypes = 'devAIAssessmentsDataTypes',
	DevAIAssessmentsDashboard = 'devAIAssessmentsDashboard',
	DevAIAssessmentsInventoryTable = 'devAIAssessmentsInventoryTable',
	DevAiAssessmentsExport = 'devAiAssessmentsExport',
	DevAiAssessmentsExportBanner = 'devAiAssessmentsExportBanner',
    DevSsoAzureProvider = 'devSsoAzureProvider',
    EntraSso = 'entraSso',
	DevAssessmentSectionReorder = 'devAssessmentSectionReorder',
    DevDsrDataMutationInProgressRetry = 'devDsrDataMutationInProgressRetry',
	IPaasIntegration = 'iPaasIntegration',
	DevAssessmentsRopaMigration = 'devAssessmentsRopaMigration',
	DevDatasourcesMultipleOwners = 'devDatasourcesMultipleOwners',
	LookerAnalytics = 'lookerAnalytics',
	DisableEditPaFromDataSource = 'disableEditPaFromDataSource',
	RiskRegistryPhase1 = 'riskRegistryPhase1',
	HideCustomizationTab = 'hideCustomizationTab',
	DevRadarEmailOnly = 'devRadarEmailOnly',
	DevAssessmentsTableCustomView = 'devAssessmentsTableCustomView',
	InventorySystemReviewTask = "inventorySystemReviewTask",
	DevDynamicQuestionsInAssessments = 'devDynamicQuestionsInAssessments',
	DevDevelopersPrismaticTab = "devDevelopersPrismaticTab",
    DevFileRequiredToggle = 'devFileRequiredToggle',
	AssessmentsServerFiltering = "assessmentsServerFiltering",
	DevAlternativeTemplates = 'devAlternativeTemplates',
	DevSidenavV2 = 'devSidenavV2',
	DevPcCustomQuestionRequiredToggle = 'devPcCustomQuestionRequiredToggle',
	DevAssessmentsClone = 'devAssessmentsClone',
	DevDSRCustomTemplates = 'devDSRCustomTemplates',
  	DevDsrTemplatesPrerequisites = 'devDsrTemplatesPrerequisites',
	DevFileUploadInAssessments = 'devFileUploadInAssessments',
}

export enum PublicFeatureFlags {
	CmsBackup = 'cmsBackup',
	SsoLogin = 'ssoLogin',
	DevUsTenant = 'devUsTenant',
	DataMappingMicrosoftAuth = 'dataMappingMicrosoftAuth',
    DevSignInUpImprove = 'devSignInUpImprove'
}
