import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef, ChangeDetectorRef, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { CheckboxControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { RandomUUIDPipe } from 'src/app/shared/pipes/random-uuid.pipe';

@Component({
	standalone: true,
	selector: 'mine-checkbox',
	templateUrl: './mine-checkbox.component.html',
	styleUrls: ['./mine-checkbox.component.scss'],
	imports: [CommonModule, RandomUUIDPipe],
	providers: [RandomUUIDPipe, {       
		provide: NG_VALUE_ACCESSOR, 
		useExisting: forwardRef(() => MineCheckboxComponent),
		multi: true     
	  }],
})
export class MineCheckboxComponent extends CheckboxControlValueAccessor {
	
	@Input()
	text: string = '';
	
	@Input()
	id: string = '1';
	
	@Input()
	center: boolean = false;
	
	disabled = false;
	
	isChecked = false;
	
	@ViewChild('input') input: ElementRef<any>;

	readonly uniqueId = `${this.id}-${this.randomUUIDPipe.transform()}`;

	constructor(
		private randomUUIDPipe: RandomUUIDPipe,
		private cdr: ChangeDetectorRef,
		elmRef: ElementRef,
		rendered2: Renderer2,
	) {
		super(rendered2, elmRef);
	}

	onChange = (val: any) => {
		this.writeValue(val);
	};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	writeValue(value: boolean) {
		this.isChecked = value;
		if (this.input) {
			this.input.nativeElement.checked = this.isChecked;
		}
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.cdr.detectChanges();
	}
}