import { Pipe, PipeTransform } from '@angular/core';
import { ColorsConvertorHelper } from '../mine-color-picker/colors-convertor.helper';

@Pipe({
  standalone: true,
  name: 'frameworkChipSecondaryColor'
})
export class FrameworkChipSecondaryColorPipe implements PipeTransform {

  // getting main chip color, convert to rgba and return the secondary color as rgba
  transform(mainColor: string): string {
    const mainColorAsRgba = ColorsConvertorHelper.hexToRgba(mainColor);
    const splittedColor = mainColorAsRgba?.replace(/[^\d,]/g, '')?.split(',');
    return splittedColor?.length >= 3 ? `rgba(${splittedColor[0]}, ${splittedColor[1]}, ${splittedColor[2]}, 0.1)` : `rgba(0, 0, 0, 0.1)`;  
  }
}