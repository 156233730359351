import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';

import * as dayjs from 'dayjs';

@Pipe({
	standalone: true,
	name: 'lastModificationDate'
})
export class LastModificationDatePipe implements PipeTransform {

	constructor(
        private datePipe: DatePipe,
        private contentPipe: ContentPipe
    ) { }

	transform(value: string): string {
        if (!value) return '-';
		if (dayjs().diff(value, 'days') < 1) {
			return this.contentPipe.transform('common.today');
		}
        return this.datePipe.transform(value, 'MMM dd, yyyy');
	}
}
