import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { ContentPipe } from './content/content.pipe';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private titleService: Title, 
    private meta: Meta, 
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private contentPipe: ContentPipe
  ) { }

  public init() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data))
     .subscribe((event) => this.updateMeta(event));
  }

  private updateMeta(event) {
    this.updateTitle(event['title']);
    this.updateDescription(event['description']);
  }

  private updateDescription(description: string): void {
		if(description === undefined) {
			description = this.contentPipe.transform('seo.descriptionDefault');
		}

		this.meta.updateTag({ name: 'description', content: description });
		this.meta.updateTag({ property: 'og:description', content: description });
	}

	public updateTitle(titleKey: string): void {
    const title = this.contentPipe.transform(titleKey === undefined ? 'seo.titleDefault' : `seo.${titleKey}`);
    this.titleService.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
  }
}