<label class="input__header" [attr.for]="id">
	<ng-content select="[mine-search-input-label]"></ng-content>
	<span *ngIf="required" class="input__header--required">&nbsp;*</span>
</label>

<div class="input__wrapper">
	<input #searchBar [formControl]="inputCtrl" [attr.id]="id" 
	class="input__element" type="text" [placeholder]="placeholder" 
	(paste)="onPaste($event)" (focus)="onFocus()" (focusout)="onFocusOut()" />
	
	<svg class="input__search-icon">
		<use xlink:href="../../../assets/img/sprite.svg#icon-Search-Icon"></use>
	</svg>

	<svg @fadeInOut *ngIf="inputCtrl.value" class="input__close-icon" (click)="reset()">
		<use xlink:href="../../../assets/img/sprite.svg#icon-Dialog-x"></use>
	</svg>

	<ul *ngIf="listOpen && autocomplete && searchResults" @fadeInOut #dropdown class="dropdown__panel">
		<ng-container *ngIf="filteredOptions?.length; else noResults">
			<li
				#option
				class="dropdown__option"
				*ngFor="let option of filteredOptions"
				[attr.data-label]="option.value"
				(click)="onOptionChanged(option)">
				<span mineHighlightSubStr [string]="option.value" [search]="inputCtrl.value"></span>
			</li>
		</ng-container>

		<li class="dropdown__option" (click)="listOpen = false">
			<ng-content select="[custom-option]"></ng-content>
		</li>
	</ul>
</div>

<p @fadeInOut *ngIf="selectedValue && !autocomplete && searchResults" class="input__search-results">
	{{ "common.inputSearchResults" | content }} ‘{{ selectedValue.value }}‘
</p>

<ng-template #noResults>
	<span class="input__search-no-results">
		{{ "common.inputSearchResultsNotFound" | content }} "{{ inputCtrl.value }}"
	</span>
</ng-template>