import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    standalone: true,
    name: 'randomUUID'
})
export class RandomUUIDPipe implements PipeTransform {

    transform(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}