import { computed, Injectable, Signal } from '@angular/core';
import { map, tap, Observable } from 'rxjs';
import { RisksCatalogStore } from '../state/risks-catalog.store';
import { RiskCatalog } from '../../api/models/risks/risks.interface';
import { AiAssessmentTemplateEnum } from '../../ai-assessments/models/ai-assessments.enum';
import { SanitizationService } from '../../shared/services/sanitization.service';
import { ApiClientRisksService } from 'src/app/api/api-client-risks.service';

@Injectable({
     providedIn: 'root'
})
export class RisksCatalogService {

    constructor(
        private sanitizationService: SanitizationService,
        private store: RisksCatalogStore,
        private apiClientRisksService: ApiClientRisksService
    ) { }

    getEmpty(): Signal<boolean | undefined> {
        return this.store.signal('empty');
    }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }
        
    setDisabled(disabled: boolean): void {
        this.store.set({ 'disabled': disabled });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }
        
    selectLoading(): Observable<boolean | undefined> {
        return this.store.select('loading');
    }
        
    getDisabled(): Signal<boolean | undefined> {
        return this.store.signal('disabled');
    }
        
    selectDisabled(): Observable<boolean | undefined> {
        return this.store.select('disabled');
    }

    getRisksCatalog(asArray: boolean = false): Signal<Map<string, RiskCatalog> | RiskCatalog[]> {
        const risksCatalogSignal = this.store.signal('risksCatalog');
        if (asArray) {
            return computed(() => Array.from(new Set([...risksCatalogSignal().values()]))) as Signal<RiskCatalog[]>;
        }
        return risksCatalogSignal;
    }

    selectRisksCatalog(asArray: boolean = false): Observable<Map<string, RiskCatalog> | RiskCatalog[]> {
        if (asArray) {
            return this.store.select('risksCatalog').pipe(
                map(risksCatalog => Array.from(new Set([...risksCatalog.values()])))
            );
        }
        return this.store.select('risksCatalog');
    }

    getRiskCatalogItemByType(type: string) {
        return this.store.get('risksCatalog').get(type);
    }

    getCatalogRisksByType(): Signal<Map<AiAssessmentTemplateEnum, { key: string, value: string, isDefault: boolean }[]>> {
        return computed(() => {
            // Initialize the mapped risks with all template types
            const mappedRisks = new Map<AiAssessmentTemplateEnum, { key: string, value: string, isDefault: boolean; }[]>();
    
            Object.values(AiAssessmentTemplateEnum).forEach(templateType => {
                mappedRisks.set(templateType, []);
            });
    
            // Retrieve risks as an array from getRisksCatalog
            const riskCatalogs = this.getRisksCatalog(true)() as RiskCatalog[];
    
            // Iterate through each risk catalog and map it to the corresponding template type
            riskCatalogs
                .filter(riskCatalog => !riskCatalog.isHidden)  // Filter out hidden risks
                .forEach(riskCatalog => {
                    const { type, name, assessmentTypes, isDefault } = riskCatalog;
                    const riskItem = { key: type, value: name, isDefault };
                    if (assessmentTypes.length === 0) {
                        // If assessmentTypes is empty, map it to all template types
                        mappedRisks.forEach((_, templateType) => {
                            mappedRisks.get(templateType)?.push(riskItem);
                        });
                    } else {
                        // Otherwise, map to specific assessment types
                        assessmentTypes.forEach(templateType => {
                            mappedRisks.get(templateType)?.push(riskItem);
                        });
                    }
                });
    
            return mappedRisks;
        });
    }

    isCustomRiskAlreadyExist(name: string): boolean {
        const risksCatalog = this.getRisksCatalog(true)() as RiskCatalog[];
        const sanitizedName = this.sanitizationService.sanitizeText(name?.trim());
        return !!risksCatalog.find(risk => {
            const sanitizedRiskName = this.sanitizationService.sanitizeText(risk.name?.trim());
            return sanitizedRiskName === sanitizedName;
        });
    }

    createRiskCatalog(riskCatalog: RiskCatalog): Observable<RiskCatalog> {
        return this.apiClientRisksService.createRiskCatalog(riskCatalog).pipe(
            tap((savedCatalog) => {
              this.store.addRiskCatalog(savedCatalog);
            })
        );
    }

    updateRiskCatalogByType(type: string, riskCatalog: RiskCatalog): Observable<RiskCatalog> {
        return this.apiClientRisksService.updateRiskCatalogByType(type, riskCatalog).pipe(
            tap((savedCatalog) => this.store.updateRiskCatalog(savedCatalog))
        );
    }

    updateRiskCatalog(id: number, riskCatalog: RiskCatalog): Observable<RiskCatalog> {
        return this.apiClientRisksService.updateRiskCatalog(id, riskCatalog).pipe(
            tap((savedCatalog) => this.store.updateRiskCatalog(savedCatalog))
        );
    }

    deleteRiskCatalog(id: number, type: string): Observable<void> {
        return this.apiClientRisksService.deleteRiskCatalog(id).pipe(
            tap(() => this.store.deleteRiskCatalog(type))
        );
    }
}