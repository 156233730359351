
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PiiClassifierState, PiiClassifierStore } from './pii-classifier.store';
import { Observable } from 'rxjs';
import { PiiSystem } from '../models/pii-classifier.interface';

@Injectable({ 
	providedIn: 'root' 
})

export class PiiClassifierQuery extends QueryEntity<PiiClassifierState> {

	constructor(
		protected store: PiiClassifierStore,
	) {
		super(store);
	}

	selectSortedSytems(): Observable<PiiSystem[]> {
		return this.selectAll({
			sortBy: (s1, s2) => this.sortByLastScan(s1, s2)
		});
	}

	sortByLastScan(a: PiiSystem, b: PiiSystem): number {
		const dateA = a.lastScan?.scanDate ? new Date(a.lastScan?.scanDate).getTime() : null;
		const dateB = b.lastScan?.scanDate ? new Date(b.lastScan?.scanDate).getTime() : null;
  
		if (dateA === null && dateB === null) {
		  return 0; 
		} else if (dateA === null) {
		  return 1; 
		} else if (dateB === null) {
		  return -1; 
		}
  
		return dateA - dateB;
	}

}
