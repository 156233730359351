import { animate, animateChild, keyframes, query, stagger, state, style, transition, trigger } from "@angular/animations";
import { AnimationSpeedEnum, AnimationStateEnum } from "./animation-state.enum";

import * as kf from './keyframes';

export class Animations {
/**
* Stagger animation for a sequential of items animation
* ex: staggerAnimation('@step', 400)
*  */
static staggerAnimation = (childTrigger, dealy = 100) => query(childTrigger, stagger(dealy, animateChild()), { optional: true });

static scaleIn = trigger('scaleIn', [
    transition(AnimationStateEnum.Enter, [
        style({ transform: 'scale(0.1)' }),
        animate(`${AnimationSpeedEnum.Normal} ${AnimationSpeedEnum.Fast} ease`, style({ transform: 'scale(1)' }))
    ]),
]);

static fadeInRight = trigger('fadeInRight', [
    transition(AnimationStateEnum.Enter, [
        animate(`${AnimationSpeedEnum.Slow} ease`, keyframes([
            style({ opacity: 0, transform: 'translateX(15%)' }),
            style({ opacity: 1, transform: 'translateX(0)' }),
        ])),
    ])
]);

static inOutRightAnimation = (triggerName = 'fadeInOutRight', speed = AnimationSpeedEnum.Normal, enterStart = '15%', enterEnd = '0', leaveStart = '0', leaveEnd = '15%', animateTiming = 'ease') =>
    trigger(triggerName, [
        transition(AnimationStateEnum.Enter, [
            animate(`${speed} ${animateTiming}`, keyframes([
                style({ opacity: 0, transform: `translateX(${enterStart})` }),
                style({ opacity: 1, transform: `translateX(${enterEnd})` }),
            ])),
        ]),
        transition(AnimationStateEnum.Leave, [
            animate(`${speed} ${animateTiming}`, keyframes([
                style({ opacity: 1, transform: `translateX(${leaveStart})` }),
                style({ opacity: 0, transform: `translateX(${leaveEnd})` }),
            ])),
        ])
    ]);

static fadeIn = trigger('fadeIn', [
    transition(`${AnimationStateEnum.Any} => ${AnimationStateEnum.Show}`, [
        style({ opacity: 0 }), animate(`${AnimationSpeedEnum.Fast} ease-in`)
    ]),
]);

static fadeInOnEnter = trigger('fadeInOnEnter', [
    transition(AnimationStateEnum.Enter, [
        style({ opacity: 0 }), animate(`${AnimationSpeedEnum.Normal} ${AnimationSpeedEnum.Fast} ease`)
    ]),
]);

static fadeInOut = trigger('fadeInOut', [
    state(AnimationStateEnum.BeforeCreation, style({ opacity: 0 })),
    transition(AnimationStateEnum.EnterOrLeave, animate(`${AnimationSpeedEnum.Normal} ${AnimationSpeedEnum.Fast} ease`)),
]);

static fadeInUpItems = [
    trigger('fadeInUpAnimation', [
        transition(AnimationStateEnum.Enter, [
            Animations.staggerAnimation('@items', 100)
        ])
    ]),
    trigger('items', [
        state(AnimationStateEnum.BeforeCreation, style({ opacity: 0, transform: 'translateY(-5%)' })),
        transition(AnimationStateEnum.Enter, [
            animate(`${AnimationSpeedEnum.VerySlow} ease`, style({ opacity: 1, transform: 'translateY(0)' }))
        ]),
    ])
];

static fadeInUpAll = [
    trigger('fadeInUpAllAnimation', [
        transition(AnimationStateEnum.Enter, [
            Animations.staggerAnimation('@*', 100)
        ])
    ]),
    trigger('item', [
        state(AnimationStateEnum.BeforeCreation, style({ opacity: 0, transform: 'translateY(-5%)' })),
        transition(AnimationStateEnum.Enter, [
            animate(`${AnimationSpeedEnum.Moderate} ease`, style({ opacity: 1, transform: 'translateY(0)' }))
        ]),
    ])
];

static slideInOut = [
    trigger('slideInOut', [
        state(AnimationStateEnum.Show, style({
            transform: `translate3d(0,0,0)`
        })),
        state(AnimationStateEnum.Hide, style({
            transform: `translate3d(100%, 0, 0)`
        })),
        transition(`${AnimationStateEnum.Show} => ${AnimationStateEnum.Hide}`,
            animate(`${AnimationSpeedEnum.Moderate} cubic-bezier(0.68, -0.55, 0.265, 1.55)`)),
        transition(`${AnimationStateEnum.Hide} => ${AnimationStateEnum.Show}`,
            animate(`${AnimationSpeedEnum.Moderate} cubic-bezier(0.68, -0.55, 0.265, 1.55)`))
    ]),
];

static steps = [
    trigger('steps', [
        transition(AnimationStateEnum.Enter, [
            animate(100), Animations.staggerAnimation('@step')
        ])
    ]),
    trigger('step', [
        transition(AnimationStateEnum.Enter, [
            animate(`${AnimationSpeedEnum.Normal} ease`, keyframes([
                style({ opacity: 0, transform: 'translateY(-10px)' }),
                style({ opacity: 1, transform: 'translateY(0px)' }),
            ]))
        ])
    ])
];

static step = trigger('step', [
    transition(`${AnimationStateEnum.Any} => ${AnimationStateEnum.Show}`, [
        animate(`${AnimationSpeedEnum.Slow} ease`, keyframes([
            style({ opacity: 0, transform: 'translateX(15%)' }),
            style({ opacity: 1, transform: 'translateX(0)' }),
        ])),
    ])
]);

static list = [
    trigger('list', [
        transition(AnimationStateEnum.Enter, [
            Animations.staggerAnimation('@item', 50)
        ])
    ]),
    trigger('item', [
        transition(AnimationStateEnum.Enter, [
            style({ transform: 'scale(0.1)', opacity: 0 }),
            animate(`${AnimationSpeedEnum.Normal} ${AnimationSpeedEnum.Fast} cubic-bezier(.8, -0.6, 0.2, 1.5)`,
                style({ transform: 'scale(1)', opacity: 1 }))
        ])
    ])
];

static postCheckout = [
    trigger('postCheckout', [
        transition(`${AnimationStateEnum.Any} <=> ${AnimationStateEnum.Any}`, [
            animate(500), Animations.staggerAnimation('@postCheckoutItem', 0)
        ])
    ]),
    trigger('postCheckoutItem', [
        transition(AnimationStateEnum.Enter, [
            style({ opacity: 0 }),
            animate(`${AnimationSpeedEnum.Slow} ease`, style({ opacity: 1 }))
        ])
    ]),
];

static cards = [
    trigger('cards', [
        transition(AnimationStateEnum.Enter, [
            Animations.staggerAnimation('@card', 30)
        ])
    ]),
    trigger('card', [
        transition(AnimationStateEnum.Enter, [
            animate(`${AnimationSpeedEnum.Normal} ease`, keyframes([
                style({ opacity: 0, transform: 'translateY(-5%)' }),
                style({ opacity: 1, transform: 'translateY(0px)' }),
            ]))
        ])
    ]),
];

static markdown = trigger('markdown', [
    state(AnimationStateEnum.Hide, style({ opacity: 0 })),
    state(AnimationStateEnum.Show, style({ opacity: 1 })),
    transition(`${AnimationStateEnum.Hide} => ${AnimationStateEnum.Show}`, [
        animate(`${AnimationSpeedEnum.Slow} ${AnimationSpeedEnum.Fast} ease`)
    ]),
    transition(`${AnimationStateEnum.Show} => ${AnimationStateEnum.Hide}`, [
        animate(`${AnimationSpeedEnum.Fast} ease`)
    ])
]);

static pimple = trigger('pimple', [
    transition(AnimationStateEnum.Enter, [
        animate(`${AnimationSpeedEnum.Normal} cubic-bezier(0.34, 1.56, 0.64, 1)`, keyframes(kf.scaleIn))
    ]),
    transition(AnimationStateEnum.Leave, [
        animate(`${AnimationSpeedEnum.Normal} cubic-bezier(0.34, 1.56, 0.64, 1)`, keyframes(kf.scaleOut))
    ])
]);

static systemNotification = trigger('systemNotification', [
    transition(`${AnimationStateEnum.Any} => ${AnimationStateEnum.Show}`, [
        style({ opacity: 0 }),
        animate(`${AnimationSpeedEnum.Normal} ${AnimationSpeedEnum.Normal}`, style({ opacity: 1, backgroundColor: 'var(--mine-light-yellow)' }))
    ]),
    transition(`${AnimationStateEnum.Any} => ${AnimationStateEnum.Hide}`, [
        animate(`${AnimationSpeedEnum.VerySlow} ${AnimationSpeedEnum.ExtremeSlow} ease`, style({ backgroundColor: 'var(--mine-white)' }))
    ])
]);

static checkmark = [
    trigger('checkmark', [
        transition(`${AnimationStateEnum.Hide} => ${AnimationStateEnum.Show}`, animate(`${AnimationSpeedEnum.Normal} ${AnimationSpeedEnum.Fast} ease`, keyframes(kf.clusterCheckDone)))
    ]),
    trigger('background', [
        transition(AnimationStateEnum.Enter, [
            style({ opacity: 0, transform: 'scale(.1)' }),
            animate(`${AnimationSpeedEnum.Normal} cubic-bezier(.13, .23, .57, 2.05)`, style({ opacity: 1, transform: 'scale(1)' }))
        ])
    ])
];

static copy = [
    trigger('copy', [
        transition(`${AnimationStateEnum.Hide} => ${AnimationStateEnum.Show}`, [
            animate(`${AnimationSpeedEnum.Normal} ease-in`, style({ opacity: 1 }))
        ]),
        transition(`${AnimationStateEnum.Show} => ${AnimationStateEnum.Hide}`, [
            animate(`${AnimationSpeedEnum.Normal} ${AnimationSpeedEnum.VerySlow} ease-out`, style({ opacity: 0 }))
        ]),
    ])
];

static snackbar = [
    trigger('toggle', [
        transition(':enter', [
            style({ opacity: 0, height: '0' }),
            animate(`${AnimationSpeedEnum.Normal} cubic-bezier(0.34, 1.56, 0.64, 1)`, style({ opacity: 1, height: '50px' }))
        ]),
        transition(':leave', [
            animate(`${AnimationSpeedEnum.Normal} ${AnimationSpeedEnum.Fast} cubic-bezier(0.34, 1.56, 0.64, 1)`, style({ opacity: 0, height: '0' }))
        ])
    ])
];

static disabledToActive = [
    trigger('disabledToActive', [
        transition(`${AnimationStateEnum.Any} => ${AnimationStateEnum.Show}`, [
            style({ borderWidth: '12px', borderColor: '{{borderColor}}' , borderStyle: 'solid', borderRadius: '50%'}),
            animate(`.5s ${AnimationSpeedEnum.Normal} ease`,  style({ borderWidth: '5px', borderColor: 'var(--mine-purple-light)', borderStyle: 'solid', borderRadius: '50%'}))
        ]),
    ])
];

static progressBar = [
    trigger('progressBar', [
        transition(`${AnimationStateEnum.Enter}`, [
            style({ opacity: 0, width: 0 }),
            animate(`.2s ${AnimationSpeedEnum.Normal} ease`, style({ opacity: 1, width: '*' }))
        ]),
    ])
];

static glow = trigger('glow', [
    transition(`${AnimationStateEnum.Any} => ${AnimationStateEnum.Show}`, [
        animate(`.5s ${AnimationSpeedEnum.Slow} ease-in-out`, style({boxShadow: '0px 0px 10px 5px rgba(255, 255, 255, 0.55)' }))
    ]),
    transition(`${AnimationStateEnum.Any} => ${AnimationStateEnum.Hide}`, [
        animate(`.5s ${AnimationSpeedEnum.Slow} ease`, style({ boxShadow: 'none' }))
    ])
]);

static fadeOut = trigger('fadeOut', [
    transition(`${AnimationStateEnum.Any} => ${AnimationStateEnum.Show}`, [
        animate(`.5s ${AnimationSpeedEnum.Slow} ease-out`, style({opacity: 0}))
    ]),
]);

static enterFromBottom = trigger('enterFromBottom', [
    transition(`${AnimationStateEnum.Enter}`, [
        style({marginTop: '4rem' }),
        animate(`.5s ${AnimationSpeedEnum.Normal} ease-out`, style({marginTop: '*'}))
    ]),
]);

static collapse = trigger('collapse', [
    transition(`${AnimationStateEnum.Leave}`, [
        style({height: '*' }),
        animate(`.5s ${AnimationSpeedEnum.ExtremeSlow} ease`, style({height: '0', 'margin-top': '0', 'margin-bottom': '0'}))
    ]),
]);

static rotateElement = (from: number, to: number, duration: number) =>
    trigger('rotateElement', [
        transition(AnimationStateEnum.Enter, [
            animate(`${duration}s ease`, keyframes([
                style({ transform: `rotate(0deg)` }),
                style({ transform: `rotate(${from}deg)` }),
                style({ transform: `rotate(0deg)` }),
                style({ transform: `rotate(${to}deg)` }),
                style({ transform: `rotate(0deg)` }),
            ])),
        ]),
    ]);

static showHide = (duration: number = 0.3, delay: number = 0) =>
    trigger('showHide', [
        state(
            'hide',
            style({
                height: '0',
                opacity: '0',
                paddingTop: '0',
                paddingBottom: '0',
                overflow: 'hidden',
            })
        ),
        state(
            'show',
            style({
                height: '*',
                opacity: '1',
                paddingTop: '*',
                paddingBottom: '*',
            })
        ),
        transition('hide => show', [
            style({ overflow: 'hidden' }),
            animate(`${duration}s ${delay}s ease-in-out`),
        ]),
        transition('show => hide', [
            style({ overflow: 'hidden' }),
            animate(`${duration}s ${delay}s ease-in-out`),
        ]),
    ]);
}

