import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Risk } from 'src/app/api/models/risks/risks.interface';

@Injectable({
     providedIn: 'root'
})
export class RisksService {

    formGroupToRisk(riskItem: FormGroup): Risk {
        if (!riskItem.value) return null;
        return {
            id: riskItem.value?.id,
            name: riskItem.value.name,
            isDefault: riskItem.value.isDefault,
            type: riskItem.value.type,
            createdDate: riskItem.value.createdDate,
            accepted: riskItem.value.accepted,
            likelihood: riskItem.value.likelihood,
            severity: riskItem.value.severity,
            inherentRisk: riskItem.value.inherentRisk,
            residualRisk: riskItem.value.residualRisk,
            mitigations: riskItem.value.mitigations,
            comment: riskItem.value.comment,
            assessmentPage: riskItem.value?.assessmentPage,
        } as Risk;
    }
}