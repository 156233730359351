<header class="panel__header">

	<div class="panel__header-icon">
		<svg class="panel__header-svg">
			<use xlink:href="../../../assets/img/sprite.svg#icon-hexagon-warning"></use>
		</svg>
	</div>

	<span class="panel__primary-label">{{ title }}</span>

</header>


<div class="panel__wrapper">
	<div class="panel__content panel__content--risk">
		<form [formGroup]="form">
			<div *rxIf="risk?.assessmentPage" class="panel__section">
				<h2 class="panel__section-title mine-title-xs">{{ 'risks.riskPanel.generalSectionTitle' | content }}</h2>
				<div class="panel__section-content panel__section-content--general">
					<p class="panel__value-title">{{'risks.riskPanel.documentedIn' | content}}</p>
					<div class="panel__assessment">
						<div class="panel__assessment-table-row" (click)="assessmentClicked(risk.assessmentPage.id)">
							<div class="panel__assessment-table-cell panel__assessment-table-cell--name">
								<ng-container *rxIf="!!risk.assessmentPage.name; else emptyCellTpl">
									<svg class="panel__assessment-icon">
										<use xlink:href="../../../assets/img/sprite.svg#icon-assessment"></use>
									</svg>
									<p class="panel__assessment-cell-name-text" 
										[innerHTML]="risk.assessmentPage.name | safe"
										[tp]="risk.assessmentPage.name"
										[tpOnlyTextOverflow]="true"
									></p>
								</ng-container>
							</div>
							<div class="panel__assessment-table-cell panel__assessment-table-cell--status">
								<ng-container *rxIf="!!risk.assessmentPage?.state; else emptyCellTpl">
									<ng-container [ngSwitch]="risk.assessmentPage.state">
											<svg *ngSwitchCase="AiAssessmentStatusEnum.Completed" class="panel__status-selector-icon panel__status-selector-icon--completed">
												<use xlink:href="../../../assets/img/sprite.svg#icon-Check-circle"></use>
											</svg>
											<svg *ngSwitchDefault class="panel__status-selector-icon panel__status-selector-icon--draft">
												<use xlink:href="../../../assets/img/sprite.svg#dashed-circle"></use>
											</svg>
									</ng-container>
									{{ risk.assessmentPage?.state }}
								</ng-container>
							</div> 
							<svg class="panel__arrow-svg">
								<use xlink:href="../../../assets/img/sprite.svg#icon-Arrow-right"></use>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div *rxIf="isCustom" class="panel__section">
				<div class="panel__section-content">
					<p class="panel__value-title">{{ 'risks.riskPanel.nameInputLabel' | content }}</p>
					<mine-input class="form__input"
						id="name"
						[focus]="isCustom"
						[error]="error"
						[formControl]="form.get('name')"
						[placeholder]="'risks.riskPanel.nameInputPlaceholder' | content">
					</mine-input>
					<mine-info-box class="panel__info" [bgColor]="infoBoxBgColor">
						<svg class="panel__info-icon" box-icon>
							<use xlink:href="../../../assets/img/sprite.svg#icon-question-mark"></use>
						</svg>
						<p box-content>
							{{ 'risks.riskPanel.infoBoxText' | content }}
						</p>
					</mine-info-box>
				</div>
			</div>
			<div class="panel__section">
				<h2 class="panel__section-title mine-title-xs">{{ 'risks.riskPanel.riskAnalysisSectionTitle' | content }}</h2>
				<div class="panel__section-content panel__section-content--risk-analysis">
					<div class="panel__likelihood">
						<p class="panel__value-title">{{'risks.riskPanel.likelihoodDropdownTitle' | content}}</p>
						<mine-dropdown
							[placeholder]="'risks.riskPanel.dropdownPlaceholder' | content"
							[id]="'likelihood'"
							[formControl]="form.get('likelihood')"
							[options]="options"
						></mine-dropdown>
					</div>
					<div class="panel__severity">
						<p class="panel__value-title">{{'risks.riskPanel.severityDropdownTitle' | content}}</p>
						<mine-dropdown
							[placeholder]="'risks.riskPanel.dropdownPlaceholder' | content"
							[id]="'severity'"
							[formControl]="form.get('severity')"
							[options]="options"
						></mine-dropdown>
					</div>
					<div class="panel__inherent-risk">
						<div class="panel__parentheses">
							<svg class="panel__icon-parentheses-svg">
								<use xlink:href="../../../assets/img/sprite.svg#icon-parentheses"></use>
							</svg>
						</div>
		
						<div class="panel__risk">
							<p class="panel__value-title">{{ 'risks.riskPanel.inherentRisk' | content }}</p>
							<div *ngIf="!!form.get('severity')?.value && !!form.get('likelihood')?.value else emptyCalc" class="panel__calc-container">
								<div class="panel__calculation-dot" [ngClass]="'panel__calculation-dot--'+(form.get('severity').value.id | riskCalulation: form.get('likelihood').value.id)"></div>
								
								<span class="panel__calculation-text">{{form.get('severity').value.id | riskCalulation: form.get('likelihood').value.id }}</span>
							</div>
		
							<ng-template #emptyCalc>
								<div class="panel__empty-calc">
									<svg class="panel__empty-calc-svg">
										<use xlink:href="../../../assets/img/sprite.svg#dashed-circle"></use>
									</svg>
									<span>{{ 'risks.riskPanel.unknownResidualRisk' | content }}</span>
								</div>
							</ng-template>
						</div>
					</div>
					<div class="panel__accept-risk">
						<p class="panel__value-title">{{'risks.riskPanel.acceptThisRiskText' | content}}</p>
						<div class="panel__radio-btn-container">
							<mine-radio-button 
								id="accepted1"
								name="accepted"
								[formControl]="form?.get('accepted')"
								[value]="true">
								{{'processing-activities.yes' | content}}
							</mine-radio-button>
						
							<mine-radio-button 
								id="accepted2"
								name="accepted"
								[formControl]="form?.get('accepted')"
								[value]="false">
								{{'processing-activities.no' | content}}
							</mine-radio-button>
						</div>
					</div>
				</div>
			</div>
		
			<div class="panel__section" *rxIf="!form?.get('accepted')?.value">
				<h2 class="panel__section-title mine-title-xs">{{ 'risks.riskPanel.mitigationSectionTitle' | content }}</h2>
				<div class="panel__section-content panel__section-content--mitigation">
					<div class="panel__mitigation">
						<p class="panel__value-title">{{ 'risks.riskPanel.mitigationChipsInputTitle' | content }}</p>
						<mine-chips-input
							class="input-border panel__mitigation-dropdown"
							[addItemConfig]="mitigationsConfig$ | async"
							[validationConfig]="validationConfig"
							[chips]="mitigationsChips()"
							[customStyle]="{maxHeight: '100%'}"
							(onChipsChanged)="onValueChanged($event)"
						>
							<mine-button-secondary custom-option (onClick)="addCustomValueV2()">
								<span class="panel__plus">+</span>
								{{ 'risks.riskPanel.addMitigationButton' | content }}
							</mine-button-secondary>
						</mine-chips-input>
					</div>
					<div class="panel__residual">
						<p class="panel__value-title risk__residual-title">{{'risks.riskPanel.residualRiskOutcomeTitle' | content}}</p>
						<div class="panel__calc-container" *rxIf="residualRisk else emptyRisk">
							<div class="panel__calculation-dot panel__calculation-dot--Low"></div>
							<span class="panel__calculation-text">{{ RiskRateEnum.Low }}</span>
						</div>
		
						<ng-template #emptyRisk>
							<div class="panel__empty-calc">
								<svg class="panel__empty-calc-svg">
									<use xlink:href="../../../assets/img/sprite.svg#dashed-circle"></use>
								</svg>
								<span>{{ 'risks.riskPanel.unknownResidualRisk' | content }}</span>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
			<div class="panel__section panel__section--comment">
				<p class="panel__value-title">{{ 'risks.riskPanel.commentTitle' | content }}</p>
				<mine-richtext-editor
					id="comment"
					formControlName="comment"
					[placeholder]="'risks.riskPanel.commentPlaceholder' | content"
					class="panel__textarea">
				</mine-richtext-editor>
			</div>
			<p *rxIf="editMode" class="panel__created-date">
				{{ 'risks.riskPanel.created' | content: { params: { date: form.get('createdDate')?.value | date } }  }}
			</p>
		</form>
	</div>

	<div *rxIf="showRiskHelper" class="panel__content panel__content--risk-helper">
		<risk-helper-panel
			[assessmentId]="assessmentId" [risk]="riskHelper"></risk-helper-panel>
	</div>
</div>


<footer class="panel__footer">
	<button [spinner]="loading()" [disabled]="form?.invalid && isCustom" mine-button (click)="onSave()" color="primary" class="panel__primary-btn">

		<svg class="panel__icon-check-svg">
			<use xlink:href="../../../assets/img/sprite.svg#check"></use>
		</svg>
        {{ primaryButtonText }}
	</button>
</footer>