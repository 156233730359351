<h3 class="risk-helper-panel__title">{{ 'risks.riskHelper.usefulInfo' | content }}</h3>

<h2 class="risk-helper-panel__subtitle--about-risk risk-helper-panel__subtitle">{{ aboutRiskTitle }}</h2>

<div class="risk-helper-panel__explanation">
    {{ risk.calculate.explanation }}
</div>

<div class="risk-helper-panel__how-to-analyze">
    <svg class="risk-helper-panel__how-to-analyze-svg">
        <use xlink:href="../../../assets/img/sprite.svg#icon-circle-question-mark-filled"></use>
    </svg>
    <h2 class="risk-helper-panel__subtitle">{{ 'risks.riskHelper.howTo' | content }}</h2>
</div>
<ul class="risk-helper-panel__tabs">
    <li class="risk-helper-panel__tab" *rxFor="let tab of tabs; index as i" #panel (click)="onTabClick(i)">
        <label [class.risk-helper-panel__label--active]="i === tabCtrl.value" class="risk-helper-panel__label">{{tab.name}}</label>
    </li>
    <div class="risk-helper-panel__underline" [ngStyle]="underlineStyle"></div>
</ul>
<div class="risk-helper-panel__content" [innerHTML]="content | safe"></div>

<ng-container *rxIf="showTable">

    <table *rxLet="tableData?.context$; let tableContext" class="risk-helper-panel__table" [class]="'risk-helper-panel__table--' + risk.id">
        <tr class="risk-helper-panel__table-row">
            <th *rxFor="let header of tableData?.headers" class="risk-helper-panel__table-header">{{header}}</th>
        </tr>

        <ng-container *rxIf="tableContext.length; else empty">
            <tr *rxFor="let row of tableContext; index as i; trackBy: trackByFn" 
                    class="risk-helper-panel__table-row" [class.risk-helper-panel__table-row--clickable]="risk.id === RiskHelperType.ExternalBreach || risk.id === RiskHelperType.InternalBreach"
                    (click)="onClickRow(row.system)">
                <ng-container *ngTemplateOutlet="tableTpl; context: { $implicit: tableContext[i], index: i }"></ng-container>
            </tr>
        </ng-container>
    </table>

</ng-container>


<ng-template #externalBreach let-data let-idx="index">
 
	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--system">
		<mine-service-icon class="risk-helper-panel__logo shadow" [icon]="data.logo"></mine-service-icon>
		<span class="risk-helper-panel__table-cell-text">{{data.name | sentenceCase}}</span>
	</td>

	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--rating">
		<mine-button-primary *rxIf="data.rating === null; else rating"
									class="risk-helper-panel__rating-btn"
                                    (onClick)="onClickGetRating($event, data.system, idx)"
									[customStyle]="{ 'padding': '0 1.6rem', width: 'auto' }"
									[spinner]="data.spinner"
									[spinnerSize]="16">
			<span class="risk-helper-panel__rating-btn-text">
				{{'vendor-management.getRatingButton' | content}}
			</span>
		</mine-button-primary>
	</td>
	
	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--sesitivity">
		<span>{{data.sesitivity ?? '&#x2500;'}}</span>
	</td>

	<ng-template #rating>
		<span [scoreToColor]="data.rating | vendorScoreToColor">{{data.rating ?? '&#x2500;'}}</span>
	</ng-template>

</ng-template>

<ng-template #internalBreach let-data>
	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--system">
		<mine-service-icon class="risk-helper-panel__logo shadow" [icon]="data.logo"></mine-service-icon>
		<span class="risk-helper-panel__table-cell-text">{{data.name | sentenceCase}}</span>
	</td>

	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--employees">
		<span>{{ !!data.employees ? (data.employees | displayNumber) : 0}}</span>
	</td>


	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--sesitivity">
		<span>{{data.sesitivity ?? '&#x2500;'}}</span>
	</td>

</ng-template>

<ng-template #overallocation let-data>
	
	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--data-type">
		<span class="risk-helper-panel__table-cell-text">{{data.dataType}}</span>
	</td>

	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--records">
		<span>{{!!data.records ? data.records : '&#x2500;'}}</span>
	</td>

	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--frameworks">
		<ng-container *ngTemplateOutlet="frameworks; context: { $implicit: data.frameworks }"></ng-container>
	</td>
	
</ng-template>

<ng-template #misclassification let-data>
	
	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--data-type">
		<span class="risk-helper-panel__table-cell-text">{{data.dataType}}</span>
	</td>

	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--systems">
		<mine-service-icon *rxFor="let system of data.systems | slice:0:TableMaxIconsForDisplay"
								 [icon]="system.logo"
								 class="risk-helper-panel__logo shadow">
		</mine-service-icon>

		<p *rxIf="data.systems.length > TableMaxIconsForDisplay" class="risk-helper-panel__logo-leftover">+{{data.systems.length - TableMaxIconsForDisplay}}</p>
	</td>

	<td class="risk-helper-panel__table-cell risk-helper-panel__table-cell--frameworks">
		<ng-container *ngTemplateOutlet="frameworks; context: { $implicit: data.frameworks }"></ng-container>
	</td>
	
</ng-template>

<ng-template #frameworks let-frameworks>

	<mine-chip *rxFor="let f of frameworks"
                    class="risk-helper-panel__frameworks"
                    [backgroundColor]="f.chip.chipColor.textColor | frameworkChipSecondaryColor" 
                    [color]="f.chip.chipColor.textColor">
			<svg class="risk-helper-panel__frameworks-svg" [style.fill]="f.chip.chipColor.textColor">
					<use xlink:href="../../../assets/img/sprite.svg#icon-pii"></use>
			</svg>
			{{f.text}}
	</mine-chip>

</ng-template>


<ng-template #empty>

	<tr class="risk-helper-panel__table-empty">
		<img src="{{assetsUrl}}/cloudwings.png" class="risk-helper-panel__table-empty-img">

		<p class="risk-helper-panel__table-empty-text">{{'ropa.dpiaRiskCalculateCommon.emptyState' | content}}</p>
	</tr>

</ng-template>