import { KeyValue } from "@angular/common";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { CustomValueTypeEnum } from "src/app/api/models/company-settings/custom-values.enum";
import { CustomValuesQuery } from "src/app/company-settings/state/custom-values/custom-values.query";

export function customValueNameValidator(customValuesQuery: CustomValuesQuery, predefinedList: KeyValue<string, string>[] | string[], fieldType: CustomValueTypeEnum, id?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    return customValuesQuery.isCustomValueNameExists(fieldType, control.value, id) || isPredefinedName(predefinedList, control.value, fieldType)  ? { alreadyExists: true } : null;
  };
}

// validate new value not exists in predefined values list
function isPredefinedName(predefinedList: KeyValue<string, string>[] | string[], name: string, fieldType: CustomValueTypeEnum): boolean {
  switch(fieldType) {
    case CustomValueTypeEnum.TransferMechanism:
    case CustomValueTypeEnum.LegalBasis:
      return !!(predefinedList as KeyValue<string, string>[]).find(v => v.value.trim().toLowerCase() === name.trim().toLowerCase());
    case CustomValueTypeEnum.BusinessUnit:
      return !!(predefinedList as string[]).find(v => v.trim().toLowerCase() === name.trim().toLowerCase());
    default:
      return false;
  }
}

