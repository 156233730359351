import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MineExpansionPanelComponent } from '../mine-expansion-panel/mine-expansion-panel.component';
import { MineInfoIconComponent } from '../mine-info-icon/mine-info-icon.component';

@Component({
  standalone: true,
  selector: 'mine-view-section',
  templateUrl: './mine-view-section.component.html',
  styleUrls: ['./mine-view-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MineExpansionPanelComponent,
    MineInfoIconComponent,
  ]
})
export class MineViewSectionComponent {
  @Input() title: string;
  @Input() tooltip: string;
  @Input() disableToggle = false;
}
