import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { map, Observable } from "rxjs";

import { DiscoveryToolsStatus } from "../discovery-tools/discovery-tools-home/discovery-section.interface";
import { HttpClientHelperService } from "./http-client-helper.service";
import { CallbackResponse } from "./models/auth/callback-response";
import { OAuthAuthorizationResponse } from "./models/auth/o-auth-authorization-response";
import { AwsCloudConfig, GcpConfig, GcpReportResponse, GcpReportStatusResponse, OktaSsoConfig, RegionsResponse } from "./models/discovery-tools/discovery-tools.intrface";
import { DataMappingAuthResponse } from "./models/data-mapping/data-mapping.interface";
import { FeatureFlagQuery } from "../feature-flag/state/feature-flag.query";
import { FeatureFlags } from "./models/profile/profile-feature-flags.enum";

@Injectable({
    providedIn: 'root'
})
export class ApiClientDiscoveryService {
    
    constructor(
        private httpHelper: HttpClientHelperService,
        private featureFlagQuery: FeatureFlagQuery,
    ) {}

    private readonly GoogleSSOCtrl = 'GoogleSsoLoginAuth';
    private readonly discoveryToolsCtrl = 'discoveryTools';
    private readonly GoogleDataMappingCtrl = 'GoogleDataMappingAuth';
    private readonly GcpDataMappingCtrl = 'GCPDataMappingAuth';

    getSSOUrl(redirectUrl: string): Observable<string> {
        return this.httpHelper.invokeGetAuth<any>(`${this.GoogleSSOCtrl}/SsoLogin?rwc=true&redirect=${redirectUrl}`).pipe(map(res => res.loginLink));
    }

    ssoConnectionCallback(queryParams: ParamMap, redirectUrl: string): Observable<CallbackResponse> {
        const oauthRes = this.createOauthObject(queryParams);
        
        return this.httpHelper.invokePostAuth<CallbackResponse>(`${this.GoogleSSOCtrl}/callback`, {
            oAuthAuthorizationResponse: oauthRes, 
            redirectUrl
        });
    }

    getDiscoveryToolsStatus(): Observable<DiscoveryToolsStatus> {
        return this.httpHelper.invokeGetAuth<DiscoveryToolsStatus>(`${this.discoveryToolsCtrl}/Status`);
    }

    googleEmailCallback(queryParams: ParamMap, redirectUrl: string): Observable<CallbackResponse> {
        const oauthRes = this.createOauthObject(queryParams);
        const devGroupsDiscoveryFlag = this.featureFlagQuery.getFlag(FeatureFlags.DevGroupsDiscovery);
        
        return this.httpHelper.invokePostAuth<CallbackResponse>(`${this.GoogleDataMappingCtrl}/${devGroupsDiscoveryFlag ? 'v2/TenantEmailCallback' : 'TenantEmailCallback'}`, {
            redirectUrl,
            oAuthAuthorizationResponse: oauthRes, 
        });
    }

    connectGoogleWorkspace(redirectUrl: string): Observable<any> {
        return this.httpHelper.invokeGetAuth(`${this.GoogleDataMappingCtrl}/TenantEmailLogin?rwc=true&redirect=${redirectUrl}`);
    }

    private createOauthObject(queryParams: ParamMap): OAuthAuthorizationResponse {
        return {
            code: queryParams.get('code'),
            error: queryParams.get('error'),
            state: queryParams.get('state'),
            errorDescription: queryParams.get('errorDescription')
        };
    }

    getAwsCloudConfiguration(): Observable<AwsCloudConfig> {
        return this.httpHelper.invokeGetAuth<AwsCloudConfig>(`${this.discoveryToolsCtrl}/AwsCloud`);
    }

    saveAwsCloudConfiguration(awsConfig: AwsCloudConfig): Observable<void> {
        return this.httpHelper.invokePostAuth<void>(`${this.discoveryToolsCtrl}/AwsCloud`, awsConfig);
    }

    startAwsCloudScan(): Observable<void> {
        return this.httpHelper.invokePostAuth<void>(`${this.discoveryToolsCtrl}/AwsCloudScan`);
    }

    getAwsRegions(): Observable<RegionsResponse> {
        return this.httpHelper.invokeGetAuth<RegionsResponse>(`${this.discoveryToolsCtrl}/AwsRegions`);
    }

    saveOktaSsoConfiguration(oktaSsoConfig: OktaSsoConfig): Observable<void> {
        return this.httpHelper.invokePostAuth<void>(`${this.discoveryToolsCtrl}/OktaSSO`, oktaSsoConfig);
    }

    getOktaSsoConfiguration(): Observable<OktaSsoConfig> {
        return this.httpHelper.invokeGetAuth<OktaSsoConfig>(`${this.discoveryToolsCtrl}/OktaSSO`);
    }

    gcpLoginCallback(queryParams: ParamMap, redirectUrl: string): Observable<CallbackResponse> {
        const oauthRes = this.createOauthObject(queryParams);
        return this.httpHelper.invokePostAuth<CallbackResponse>(`${this.GcpDataMappingCtrl}/gcpCallback`, {
            oAuthAuthorizationResponse: oauthRes, 
            redirectUrl
        });
    }

    getGcpLoginUrl(redirectUrl: string, rwc: boolean, organization: string): Observable<DataMappingAuthResponse> {
        return this.httpHelper.invokeGetAuth(`${this.GcpDataMappingCtrl}/gcpLogin?rwc=${rwc}&redirect=${redirectUrl}&organization=${organization}`);
    }

    getGcpConfiguration(): Observable<GcpConfig> {
        return this.httpHelper.invokeGetAuth<GcpConfig>(`${this.discoveryToolsCtrl}/gcpConfig`);
    }

    getGcpReportData(): Observable<GcpReportResponse> {
        return this.httpHelper.invokeGetAuth<GcpReportResponse>(`${this.discoveryToolsCtrl}/discovery-reports`);
    }

    getGcpReportStatus(): Observable<GcpReportStatusResponse> {
        return this.httpHelper.invokeGetAuth<GcpReportStatusResponse>(`${this.discoveryToolsCtrl}/gcp-report-ready`);
    }
}