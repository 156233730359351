import { ChangeDetectionStrategy, Component, ComponentRef, computed, DestroyRef, OnInit, signal, Signal, ViewContainerRef } from '@angular/core';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { CommonModule, SlicePipe } from '@angular/common';
import { RxIf } from '@rx-angular/template/if';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { MineButtonDirective } from '@mineos/mine-ui';
import { RiskCatalogPanelComponent } from '../risk-catalog-panel/risk-catalog-panel.component';
import { MineSidePanelComponent } from 'src/app/shared/mine-side-panel/mine-side-panel.component';
import { RiskRegistryTableService } from '../services/risk-registry-table.service';
import { TableColumn } from 'src/app/shared/models/table-column.interface';
import { Risk, RiskHelperType, RiskRegistryColumnEnum } from 'src/app/api/models/risks/risks.interface';
import { SkeletonDirective } from 'src/app/shared/skeletons/skeleton.directive';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { Badge } from 'src/app/shared/mine-card/mine-card.component.interface';
import { AiAssessmentTemplateNamePipe } from 'src/app/ai-assessments/pipes/ai-assessment-template-name.pipe';
import { AiAssessmentTemplateIconPipe } from 'src/app/ai-assessments/pipes/ai-assessment-template-icon.pipe';
import { MineChipComponent } from 'src/app/shared/mine-chip/mine-chip.component';
import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';
import { LastModificationDatePipe } from 'src/app/ai-assessments/pipes/last-modification-date.pipe';
import { RelatedEntitiesArrayPipe } from 'src/app/ai-assessments/pipes/related-entities-array.pipe';
import { MineServiceIconComponent } from 'src/app/shared/mine-service-icon/mine-service-icon.component';
import { AiAssessmentInstance } from 'src/app/api/models/ai-assessments/ai-assessments.interface';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { EmptyStateContent } from 'src/app/shared/table-empty-state/mine-table-empty-state.interface';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { MineTableEmptyStateComponent } from 'src/app/shared/table-empty-state/mine-table-empty-state.component';
import { MineNoResultsComponent } from 'src/app/shared/mine-no-results/mine-no-results.component';
import { MineTableHeaderOptions } from 'src/app/shared/mine-table-header/mine-table-header.interface';
import { MineTableHeaderComponent } from 'src/app/shared/mine-table-header/mine-table-header.component';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';
import { RxActions } from '@rx-angular/state/actions';
import { RiskRegistryActions } from '../models/risk-registry-actions.interface';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { RiskRegistryCategoriesListComponent } from '../risk-registry-categories-list/risk-registry-categories-list.component';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { MineSortDirective } from 'src/app/shared/mine-sort/mine-sort.directive';
import { MineSortHeaderDirective } from 'src/app/shared/mine-sort/mine-sort-header.directive';
import { AiAssessmentsRiskPanelComponent } from 'src/app/ai-assessments/ai-assessments-page/ai-assessments-form/risks/ai-assessments-risk-panel/ai-assessments-risk-panel.component';
import { filter, finalize, first, map, Observable, of, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AiAssessmentsService } from 'src/app/ai-assessments/services/ai-assessments.service';
import { RisksService } from '../services/risks.service';
import { RiskRegistryTableStore } from '../state/risk-registry-table.store';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { DialogsManagerService } from 'src/app/services/dialogs-manager.service';

const rxAngularDirectives = [
  RxIf,
  RxFor,
  RxLet,
];

@Component({
  selector: 'risk-registry',
  standalone: true,
  templateUrl: './risk-registry.component.html',
  styleUrls: ['./risk-registry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'class': 'page-padding u-display-block'},
  imports: [
    ContentPipeModule,
    CommonModule,
    ...rxAngularDirectives,
    MineButtonDirective,
    RiskCatalogPanelComponent,
    MineSidePanelComponent,
    SkeletonDirective,
    SafePipe,
    AiAssessmentTemplateNamePipe,
    AiAssessmentTemplateIconPipe,
    MineChipComponent,
    HtmlDecodePipe,
    LastModificationDatePipe,
    RelatedEntitiesArrayPipe,
    MineServiceIconComponent,
    SlicePipe,
    OrderByPipe,
    MineTableEmptyStateComponent,
    MineNoResultsComponent,
    MineTableHeaderComponent,
    RiskRegistryCategoriesListComponent,
    MineSortDirective,
    MineSortHeaderDirective
  ],
})
export class RiskRegistryComponent implements OnInit {

  readonly RiskRegistryColumnEnum = RiskRegistryColumnEnum;
  
  readonly maxIconsToShow = 2;

  readonly maxMitigationsToShow = 2;

  readonly colorOpacitySuffix = '40';

  private readonly leavePageConfigData = {
    title: this.contentPipe.transform('common.leavePageTitle'),
    okBtn: this.contentPipe.transform('common.leave'),
    cancelBtn: this.contentPipe.transform('common.stay'),
    showXBtn: true,
  };

  readonly typeBadge: Badge = {
    textColor: 'var(--mine-grey-3)',
    backgroundColor: 'var(--mine-grey-6)',
  };

  readonly residualRiskBadge: Badge = {
    textColor: 'var(--mine-black)',
    backgroundColor: '#C8F8E1',
  };

  riskCatalogComponentRef: ComponentRef<RiskCatalogPanelComponent>;
  riskPanelComponentRef: ComponentRef<AiAssessmentsRiskPanelComponent>;
  mineSidePanelRef: ComponentRef<MineSidePanelComponent>;
  emptyStateContent: EmptyStateContent;
  
  data: Signal<Risk[]>;
  loading: Signal<boolean | undefined>;
  empty: Signal<boolean | undefined>;
  columns: Map<string, TableColumn>;
  mineTableHeaderOptions: MineTableHeaderOptions;
  filters: Signal<FilterCategory[]>;
  sort: Signal<MineSort>;
  activeRiskId: number;
  updateRiskLoading = signal<boolean>(false);
  skipGuard = true;
  
  private actions: RxActions<RiskRegistryActions, {}>;

  constructor (
    private viewContainerRef: ViewContainerRef,
    private riskRegistryTableService: RiskRegistryTableService,
    private contentPipe: ContentPipe,
    private destroyRef: DestroyRef,
    private aiAssessmentsService: AiAssessmentsService,
    private risksService: RisksService,
    private riskRegistryTableStore: RiskRegistryTableStore,
    private dialogManager: DialogsManagerService
  ) {}

  ngOnInit(): void {
    this.initActions();
    this.initTableHeaderOptions();
    this.initTableFilters();
    this.initTableData();
  }

  onClickRow(risk: Risk) {
    if(this.activeRiskId === risk.id) {
        return;
    }

    this.handleRiskPanelOpenRequest(risk);
  }

  private handleRiskPanelOpenRequest(risk: Risk) {
    this.handleCurrentPanelCloseRequest().pipe(
        filter(res => res),  // Proceed only if the user confirms or there are no unsaved changes
        tap(() => this.openRiskPanel(risk))
    ).subscribe();
}

  private getShowRiskHelper(type: string): boolean {
    return (Object.values(RiskHelperType) as string[]).includes(type);
}

  private openRiskPanel(risk: Risk): void {
    this.activeRiskId = risk.id;

    // create the component that should be projected as ng-content 
    this.riskPanelComponentRef = this.viewContainerRef.createComponent(AiAssessmentsRiskPanelComponent);

    const showRiskHelper = this.getShowRiskHelper(risk.type);

    this.riskPanelComponentRef.instance.showRiskHelper = showRiskHelper;
    this.riskPanelComponentRef.instance.risk = risk;
    this.riskPanelComponentRef.instance.loading = this.updateRiskLoading;

    this.riskPanelComponentRef.instance.formDirty.pipe(
      tap(() => this.skipGuard = false),
       takeUntilDestroyed(this.destroyRef)
     ).subscribe();

    this.riskPanelComponentRef.instance.save.pipe(
        first(),
        map(riskForm => this.risksService.formGroupToRisk(riskForm)),
        switchMap(risk => this.handleRiskSave(risk)),
        tap(() => this.viewContainerRef.clear()),
        tap(() => this.clearPanel()),
        takeUntilDestroyed(this.destroyRef)
    ).subscribe();
    
    // create mine-panel component (our shared panel)
    this.mineSidePanelRef = this.viewContainerRef.createComponent(MineSidePanelComponent, { projectableNodes: [[this.riskPanelComponentRef.location.nativeElement]] });
    this.mineSidePanelRef.instance.style = `width: ${showRiskHelper ? 100 : 60}rem; max-width: 100vw;`;

    this.mineSidePanelRef.instance.toggle();

    this.mineSidePanelRef.instance.panelClosed.pipe(
      switchMap(() =>
          this.handleCurrentPanelCloseRequest().pipe(
              tap(() => {
                  if(this.mineSidePanelRef?.instance) {
                      this.mineSidePanelRef.instance.toggle();
                  }
              }),
              map((res) => !!res)
          )
      ),
      filter((res) => res),
      takeUntilDestroyed(this.destroyRef)
  ).subscribe(); 
  }

  private getAssessmentWithUpdatedRisk(assessment: AiAssessmentInstance, risk: Risk): AiAssessmentInstance {
    const currentRisks = assessment?.risks || [];
    const updatedRisks = currentRisks.map(existingRisk => 
        existingRisk.id === risk.id ? { ...existingRisk, ...risk } : existingRisk
    );

    return {
        ...assessment,
        risks: updatedRisks,
    };
  }
  
  private handleRiskSave(risk: Risk): Observable<AiAssessmentInstance> {
    this.updateRiskLoading.set(true);
    return this.aiAssessmentsService.selectInstance(risk.assessmentPage.id).pipe(
      first(),
      map(assessment => this.getAssessmentWithUpdatedRisk(assessment, risk)),
      switchMap(updatedAssessment => this.aiAssessmentsService.updateInstance(updatedAssessment)),
      first(),
      tap(() => this.riskRegistryTableStore.updateRisk(risk)),
      finalize(() => this.updateRiskLoading.set(false))
    );
  }

  private handleCurrentPanelCloseRequest(): Observable<boolean> {
    if (!!this.activeRiskId && this.riskPanelComponentRef?.instance?.isFormDirty) {
        return this.dialogManager.openDialogCommon(ConfirmationDialogComponent, this.leavePageConfigData).afterClosed$.pipe(
            first(),
            tap(res => {
                if (res) {
                    this.clearPanel();
                }
            }),
            map(res => !!res)
        );
    } else {
        this.clearPanel();
        return of(true);
    }
}

  private clearPanel(): void {
    this.activeRiskId = null;
    this.mineSidePanelRef = null;
    this.skipGuard = true;

    if(this.riskPanelComponentRef) {
        this.riskPanelComponentRef.destroy();
    }

    if(this.viewContainerRef) {
        this.viewContainerRef.clear();
    }
}

  onSearchChanged(search: DropdownOption): void {
    this.actions.changeSearchValue(search.value ?? '');
  }

  onFiltersChanged(filters: FilterCategory[]): void {
    this.actions.changeFiltersValue(filters);
  }

  sortChange(sort: MineSort): void {
    this.actions.changeSortValue(sort);
  }

  private initActions() {
    this.actions = this.riskRegistryTableService.getActions();
  }

  private initTableHeaderOptions(): void {
    this.mineTableHeaderOptions = {
      search: true,
      filter: true,
      results: false,
      columnPicker: true,
      saveViewBtn: false,
    } as MineTableHeaderOptions;
  }

  private initTableFilters() {
    this.sort = this.riskRegistryTableService.getSort();
    this.empty = this.riskRegistryTableService.getEmpty();
    this.loading = this.riskRegistryTableService.getLoading();
    this.columns = this.riskRegistryTableService.getColumns();
    this.filters = this.riskRegistryTableService.getActiveFilters();

    this.emptyStateContent = this.contentPipe.transform('risks.riskRegistry.emptyState') as EmptyStateContent;
  }

  private initTableData(): void {
    this.data = computed(() => {
      return this.riskRegistryTableService.getRiskRegistry(true)() as Risk[];
    });
  }

  trackByIdFn(riskId: number) {
    return (index: number, item: any) => {
      return `${riskId}-${item.id}`; 
    };
  }

  trackDataFn(index: number, item: Risk): number {
    return item.id;
  }

  trackColumnsFn(index: number, item: TableColumn): string {
    return item.key;
  }

  originalOrder = (): number => {
    return 0;
  };

  onRiskCatalogClick(): void {
    // create the component that should be projected as ng-content 
    this.riskCatalogComponentRef = this.viewContainerRef.createComponent(RiskCatalogPanelComponent);

    // create mine-panel component (our shared panel)
    this.mineSidePanelRef = this.viewContainerRef.createComponent(MineSidePanelComponent, { projectableNodes: [[this.riskCatalogComponentRef.location.nativeElement]] });
    this.mineSidePanelRef.instance.style = `width: 56rem; max-width: 100vw;`;

    this.mineSidePanelRef.instance.toggle();
    this.mineSidePanelRef.instance.panelClosed.subscribe(() => this.onRiskCatalogPanelClose());
  }

  private onRiskCatalogPanelClose(): void {
    this.activeRiskId = null;
		this.mineSidePanelRef = null;
		this.viewContainerRef.clear();
  }
}