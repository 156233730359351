import { UrlTree, Router, CanMatchFn, UrlSegment, Route } from '@angular/router';
import { filter, map, Observable, switchMap, tap } from 'rxjs';
import { inject } from '@angular/core';

import { LoggerService } from '../../logger/logger.service';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { PermissionsService } from 'src/app/auth/permissions.service';
import { RoutesManager } from '../../shared/models/routes.interfaces';
import { RolePermissionsHelper } from 'src/app/auth/role-permmissions-helper';

const loggerName: string = 'HomeGuard';

const HomeGuardFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const logger = inject(LoggerService);
    const profileQuery = inject(ProfileQuery);
    const permissionsService = inject(PermissionsService);

    const reviewerHomePage: UrlTree = router.parseUrl(`${RoutesManager.tasks}`);
    const roles = RolePermissionsHelper.getPerrmitedRoleByRoute([RoutesManager.homePage]);
    
    return profileQuery.select('role').pipe(
        filter(role => role !== undefined),
        switchMap(() => permissionsService.selectIsAllowed(roles)),
        tap(isAllowed => logger.debug(loggerName, `isRouteAllowed: ${isAllowed}`)),
        map(isAllowed => isAllowed ? true : reviewerHomePage)
    );    
};

export const HomeGuard: CanMatchFn = HomeGuardFn;