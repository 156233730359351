import { UrlTree, Router, Route, UrlSegment, CanMatchFn } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { inject } from '@angular/core';

import { RoutesManager } from '../shared/models/routes.interfaces';
import { RolePermissionsHelper } from './role-permmissions-helper';
import { PermissionsService } from './permissions.service';
import { LoggerService } from '../logger/logger.service';

const loggerName: string = 'UserRoleGuard';

const UserRoleGuardFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const logger = inject(LoggerService);
    const permissionsService = inject(PermissionsService);

    const rolesPermmitted = RolePermissionsHelper.getPerrmitedRoleByRoute(
        segments?.map(segment => segment.path) ?? ['']
    );

    return permissionsService.selectIsAllowed(rolesPermmitted).pipe(
        tap(isAllowed => logger.debug(loggerName, `isRouteAllowed: ${isAllowed}`)),
        map(isAllowed => isAllowed ? true : router.parseUrl(`${RoutesManager.pageNotAllowed}`)),
    );
};

export const UserRoleGuard: CanMatchFn = UserRoleGuardFn;