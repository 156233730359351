<div class="table__header">
    <ng-content select="[mine-table-filters]"></ng-content>

    <ng-content select="[mine-table-search]"></ng-content>

    <mine-search-input *ngIf="options.search"
        [formControl]="searchControl" class="search__input"
        [id]="'table-search-dropdown'" [required]="false" 
        [placeholder]="searchPlaceholder ? searchPlaceholder : 'common.search' | content" 
        [autocomplete]="false" [searchResults]="false">
    </mine-search-input>

    <ng-content select="[mine-table-custom-filters]"></ng-content>

    <mine-table-filter #tableFilters *ngIf="options.filter" class="table__filter" 
        [class.table__filter--disabled]="disableFiltering" 
        [class.table__filter--no-margin]="options.saveViewBtn" 
        [disabled]="disableFiltering" 
        [filters]="filters" 
        (filtersChanged)="onFiltersChanged($event)">
    </mine-table-filter>

    <mine-button-tertiary *ngIf="options.saveViewBtn"
            [btnTheme]="buttonTheme"
            class="table__save-view-btn"
            [isDisabled]="savedViewsCount >= maxSavedViews"
            [tooltip]="savedViewsCount >= maxSavedViews ? ('common.tableViewsLimitTooltip' | content) : ''"
            (onClick)="onSaveView()">
            <p class="table__save-btn-content">
                <svg class="table__svg-save">
                    <use xlink:href="../../../assets/img/sprite.svg#icon-badge"></use>
                </svg>
                {{'common.saveViewBtn' | content}}
            </p>
    </mine-button-tertiary>

    <ng-content select="[mine-table-count]"></ng-content>

    <p *ngIf="options.results && resultsCount !== undefined && resultsCount !== null" class="results__count">
        {{resultsCount}} {{ 'common.records' | content }}
    </p>

</div>

<div class="table__header">

    <mine-table-column-picker *ngIf="options.columnPicker" 
        [columns]="columns" (columnsChanged)="onColumnsChanged($event)">
    </mine-table-column-picker>
    
    <mine-action-menu *ngIf="options.actionsMenu && actionMenuOptions"
        class="table__action-menu"
        [options]="actionMenuOptions.options"
        [position]="actionMenuOptions.position"
        (action)="onMenuActionClicked($event)">
    </mine-action-menu>
    
    <ng-content select="[mine-table-header-cta]"></ng-content>
    
</div>