import { Pipe, PipeTransform } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';

import * as dayjs from 'dayjs';
@Pipe({
	standalone: true,
	name: 'daysAgo'
})
export class DaysAgoPipe implements PipeTransform {

	constructor(private contentPipe: ContentPipe) { }

	transform(value: string): string {
		let diff = `${dayjs().diff(value, 'days')}${this.contentPipe.transform('common.daysAgo')}`;

		if (dayjs().diff(value, 'days') < 1) {
			diff = `${dayjs().diff(value, 'hours')}${this.contentPipe.transform('common.hoursAgo')}`;
		}

		if (dayjs().diff(value, 'hour') < 1) {
			diff = `${dayjs().diff(value, 'minute')}${this.contentPipe.transform('common.minutesAgo')}`;
		}

		if (dayjs().diff(value, 'minute') < 1) {
			diff = this.contentPipe.transform('common.now');
		}

		return diff;
	}
}
