import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

import { TippyModule } from '../../tippy.module';

@Component({
  standalone: true,
  selector: 'mine-view-item',
  templateUrl: './mine-view-item.component.html',
  styleUrls: ['./mine-view-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TippyModule]
})
export class MineViewItemComponent {

  @Input() active: boolean = false;

  @Input() label: string;

  @Input() hoverDisabled: boolean = false;

  @Output() selectView: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();

  // todo: move to directive by route
  @HostBinding('class.active')
	get activeHostClass() {
		return this.active;
	}

  @HostBinding('class.hover-disabled')
	get hoverDisabledClass() {
		return this.hoverDisabled;
	}

  @HostListener('click', ['$event.target'])
	onSelectView(e: HTMLElement) {
		this.selectView.emit(e);
	}
}
