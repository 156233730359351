<ng-container [ngSwitch]="provider">

    <ng-container *ngSwitchCase="AuthProviders.google">
        <ng-container *ngTemplateOutlet="general"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AuthProviders.microsoft">
        <ng-container *ngTemplateOutlet="general"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AuthProviders.slack">
        <ng-container *ngTemplateOutlet="general"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AuthProviders.auth0">
        <ng-container *ngTemplateOutlet="auth0"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AuthProviders.sso">
        <ng-container *ngTemplateOutlet="sso"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AuthProviders.jira">
        <ng-container *ngTemplateOutlet="jira"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="default"></ng-container>
    </ng-container>

</ng-container>


<ng-template #general>
    <svg class="provider__logo">
		<use [attr.xlink:href]="'../../../assets/img/sprite-logos.svg#icon-'+provider"></use>
	</svg>
    <p class="provider__text">{{'authentication.loginContinueWith' | content}} {{provider | titlecase}}</p>
</ng-template>

<ng-template #auth0>
    <svg class="provider__logo">
        <use [attr.xlink:href]="'../../../assets/img/sprite.svg#icon-Mail'"></use>
    </svg>
    <p class="provider__text">{{'authentication.loginContinueWithEmail' | content}}</p>
</ng-template>

<ng-template #sso>
    <svg class="provider__logo">
        <use [attr.xlink:href]="'../../../assets/img/sprite.svg#icon-sso-key'"></use>
    </svg>
    <p class="provider__text">{{'authentication.loginContinueWithSso' | content}}</p>
</ng-template>

<ng-template #jira>
    <svg class="provider__logo">
        <use [attr.xlink:href]="'../../../assets/img/sprite-logos.svg#icon-jira'"></use>
    </svg>
    <p class="provider__text">{{'jira.continueButton' | content}}</p>
</ng-template>

<ng-template #default>
    <p class="provider__text">{{'authentication.providerLoginDefault' | content}}</p>
</ng-template>