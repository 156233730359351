<quill-editor #quillEditor
              [id]="id"
              class="richtext__quill"
              [formControl]="formCtrl"
              [placeholder]="placeholder"
              [modules]="basicEditorModules"
              (onSelectionChanged)="selectionChanged($event)"
              (onContentChanged)="contentChanged($event)"
              (onEditorCreated)="editorCreated($event)">
</quill-editor>

<div clickClose *ngIf="textVariables" class="richtext__vars-container">
   <input #dropdownCheckbox type="checkbox" id="dropdown" class="richtext__vars-checkbox" name="dropdown">
   <label for="dropdown" class="richtext__vars-label" [tp]="varsTooltip">
      <svg class="richtext__vars-svg">
         <use xlink:href="../../../assets/img/sprite.svg#icon-text-vars"></use>
      </svg> 
   </label>

   <ul class="box-container richtext__vars-dropdown">
      <li *ngFor="let v of textVariables" (click)="onVarSelected(v.value)" class="richtext__vars-item">{{v.text}}</li>
   </ul> 
</div>