import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Animations } from 'src/app/animations/animations';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { FilterCategory } from 'src/app/core/models/filtering.interface';

import { MineCheckboxComponent } from '../../mine-checkbox/mine-checkbox.component';
import { MineCheckboxGroupComponent } from '../../mine-checkbox-group/mine-checkbox-group.component';
import { MineButtonPrimaryComponent } from '../../mine-button-primary/mine-button-primary.component';
import { MineExpansionPanelComponent } from '../../mine-expansion-panel/mine-expansion-panel.component';

@Component({
  standalone: true,
  selector: 'mine-table-filter',
  templateUrl: './mine-table-filter.component.html',
  styleUrls: ['./mine-table-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.pimple],
  imports: [
    CommonModule, ReactiveFormsModule, MineCheckboxGroupComponent, MineExpansionPanelComponent,
    MineCheckboxComponent, MineButtonPrimaryComponent
  ]
})
export class MineTableFilterComponent implements OnChanges {

  readonly filterContent = this.contentPipe.transform('common.filter');

  readonly panelStyle = {
      width: '29.3rem',
      maxHeight: '33.6rem',
      marginTop: '5px',
      padding: '0',
      overflow: 'hidden',
      color: 'var(--mine-blue-dark)',
      position: 'absolute',
      left: '0',
      display: 'flex',
      'flex-direction': 'column',
  };

  @Input()
  filters: FilterCategory[];

  @Input()
  disabled?: boolean;

  @Output()
  filtersChanged = new EventEmitter<FilterCategory[]>();

  filtersForm: FormGroup;
  selectedFiltersCount = new Map<string, number>();
  totalSelectedFilters = 0;

  constructor(
    private contentPipe: ContentPipe,
    private formBuilder: FormBuilder,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.filters?.firstChange === false) {
      this.filters = changes.filters.currentValue;
    }
    this.initFilters();
  }

  private initFilters(): void {
    const formGroups = {};
    this.filters?.forEach(category => {
      const options = {};
      category?.options?.forEach(option => options[option.label] = new FormControl<boolean>(option.selected));
      formGroups[category.label] = new FormGroup(options);
    });

    this.filtersForm = this.formBuilder.group(formGroups);
    this.updateFiltersCount();
  }

  private updateFiltersCount(): void {
    let totalSelected = 0;
    this.filters?.forEach(res => {
      const counter = res.options.filter(o => o.selected).length;
      this.selectedFiltersCount.set(res.label, counter);
      totalSelected += counter;
    });

    this.totalSelectedFilters = totalSelected;
  }

  clearFilters(): void {
    this.filtersForm.reset(false);
    this.applyFilters();
  }

  applyFilters(): void {
    this.updateFilters();
    this.filtersChanged.emit(this.getSelectedFilters());
  }

  private updateFilters(): void {
    Object.entries(this.filtersForm.value).forEach((control, index) => {
      this.filters[index].options = this.filters[index].options.map(res => ({ ...res, selected: control[1][res.label] }));
    });

    this.updateFiltersCount();
  }

  private getSelectedFilters(): FilterCategory[] {
    const slectedFilters: FilterCategory[] = [];
    this.filters?.forEach(res => {
      slectedFilters.push({ id: res.id, label: res.label, options: res.options.filter(option => option.selected) });
    });
    
    return slectedFilters;
  }
}