<div class="login__left">
    <img class="login__logo" width="113" height="34" ngSrc="{{assetsUrl}}/Portal/mine-os-black-logo.svg" alt="Mine logo">

    <div class="login__subtitle-wrapper">
        <h1 class="mine-title-xl login__subtitle">
            {{'authentication.loginSubTitle' | content}}
        </h1>
        
        <!-- US Tenant -->
        <mine-dropdown *ngIf="usTenantFF && !devSignInUpImproveFF"
            id="tenant-selector"
            class="login__tenant-dropdown"
            [formControl]="tenantCtrl"
            [options]="tenantOptions"
            [type]="DropdownType.Standalone" 
            [selectedValuePrefix]="'common.tenant' | content">
        </mine-dropdown>
    </div>

    <p class="login__explanation" *ngIf="!devSignInUpImproveFF">{{'authentication.loginExplanation' | content}}</p>
    <p class="login__explanation-v2" *ngIf="devSignInUpImproveFF">{{'authentication.loginExplanationV2' | content}}</p>

    <div class="login__btns-container">
        <mine-provider-button [provider]="AuthProviders.google" class="login__btn" (click)="onLoginButtonClick(AuthProviders.google)"></mine-provider-button>

        <mine-provider-button [provider]="AuthProviders.microsoft" class="login__btn login__microsoft login__btn" (click)="onLoginButtonClick(AuthProviders.microsoft)"></mine-provider-button>

        <p class="login__separator-text" *ngIf="!devSignInUpImproveFF">{{'authentication.orSeparator' | content}}</p>
        <mine-provider-button class="login__btn" [provider]="AuthProviders.auth0" (click)="onLoginButtonClick(AuthProviders.auth0)"></mine-provider-button>

        <mine-provider-button *ngIf="ssoFlag" class="login__btn" [provider]="AuthProviders.sso" (click)="onLoginButtonClick(AuthProviders.sso)"></mine-provider-button>
    </div>
    <div class="login__signup" *ngIf="!devSignInUpImproveFF">
        <span>{{'authentication.loginSignupText' | content}}</span>

        <a routerLink="/{{routesManager.onboarding}}" class="login__signup-link">{{('authentication.loginSignupLink' + (devSignInUpImproveFF ? 'V2' : '')) | content}}</a>
    </div>

    <p class="login__disclaimer">
        {{'authentication.loginDisclaimer2' | content}}
        <a class="login__disclaimer-link" href="{{privacyPolicy}}">{{'common.privacyPolicy' | content}}</a> &
        <a class="login__disclaimer-link" href="{{termsOfService}}">{{'common.termOfUse' | content}}</a>.
    </p>

    <div class="login__signup-v2" *ngIf="devSignInUpImproveFF">
        {{'authentication.loginSignupTextV2' | content}}

        <button mine-button color="tertiary" routerLink="/{{routesManager.onboarding}}/{{routesManager.signup}}" class="login__signup-link-v2">
            {{('authentication.loginSignupLink' + (devSignInUpImproveFF ? 'V2' : '')) | content}}</button>
    </div>

    <div class="login__switch-tenant" *ngIf="usTenantFF && devSignInUpImproveFF">
        {{'authentication.currentTenant' | content: {params: {tenant: tenantCtrl.value.value} } }}

        <button mine-button color="tertiary" (click)="switchTenant()" class="login__switch-tenant-link-v2"
        >{{'authentication.switchTenant' | content: {params: {tenant: tenantToSwitch.value} } }}</button>
    </div>

</div>


<div class="login__testimony-container">

    <div class="login__testimony-box">
        <img class="login__logo-sprinkles login__logo-sprinkles--up" width="49" height="60" ngSrc="{{assetsUrl}}/Portal/glitter.png">
        <img class="login__testimony-pic" width="76" height="76" [ngSrc]="'authentication.loginTestimonyPic' | content">
        <p>
            {{'authentication.loginTestimonyText'| content}}
            <span class="login__testimony-separator"></span>
        </p>
        <p class="login__testimony-role" [innerHTML]="'authentication.loginTestimonyRoleText' | content | safe">
        </p>
        <img class="login__logo-sprinkles login__logo-sprinkles--down" width="49" height="60" ngSrc="{{assetsUrl}}/Portal/glitter.png">
    </div>
</div>