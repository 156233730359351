export enum MineBadgeType {
  Suggestion = 'suggestion',
  Error = 'error',
  Role = 'role',
  Scan = 'scan',
  Radar = 'radar',
  PendingResponse = 'pending-response',
  Responded = 'responded',
  Autopilot = 'autopilot',
  AutopilotError = 'autopilot-error',
  Attention = 'attention',
  // when dsr v2 ready remove old autopilot badge type and change the names
  AutopilotV2 = 'autopilot-v2',
  AutopilotV2Error = 'autopilot-v2-error',
  AiAssessment = 'ai-assessment',
  AiAssessmentReviewDate = 'icon-due-date',
  MineOSChatbot = 'mineos-chatbot',
  Beta = 'beta',
}