import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { IntegrationField } from '../systems/system-page/system-request-handling/models/integration-content.interface';
import { TestInternalSystem } from '../systems/system-page/system-request-handling/integration-email/test-internal-system.interface';
import { HttpClientHelperService } from './http-client-helper.service';
import { ReviewStatus, SuggestedStateEnum } from './models/systems/systems.enum';
import {
    AddCatalogSystemResponse,
    DataTypeSuggetionsUpdateData,
    PublicSystemsResponse,
    UnverifiedSystem,
    ProcessingActiviySuggetionsUpdateData,
    SystemEmployeeEvidenceResponse,
    IconSignedUrlPayload,
    IconSignedUrlResponse,
    UpdateIconUrlPayload,
    SystemsIconsV2Payload,
    SystemsIconsV2Response,
    BatchDataType
} from 'src/app/api/models/systems/systems.interface';
import { CompanySystemsResponse, CreateInstanceResponse, EditGeneralResponse, SystemGeneralData, IntegrationCatalogTypes, IntegrationSystem, PublicSystem, SystemReviewStatus, SystemsIconsResponse, SystemsResponse, SystemInstanceResponse, SystemStatusResponse, ExtendedDataType, TrashSystemsResponse, UnverifiedSystemsResponse, SystemEmployeesAdditionalDataResponse, IntegrationSystemTestResponse } from './models/systems/systems.interface';
import { ApiKeyResponse, InetgrationsDeletionResponse, IntegrationConfig, IntegrationMarkedDone, IntegrationTest, InternalSystemTestResponse, OauthResponse } from './models/integrations/integrations.interface';
import { OauthAuthorizationState } from '../systems/system-page/system-request-handling/models/oauth-authorization-state.interface';
import { ManageEmployeeInSystem, SystemInstance, UpdateSystemCategories } from '../systems/models/systems.interface';
import { ArchivedFeatures } from './models/systems/archive-system.enum';

@Injectable({
  providedIn: 'root'
})
export class ApiClientSystemsService {
  private readonly controller: string = 'systems';
  private readonly integrationsController = 'integrations';
  private readonly integrationsAuthController = 'integrationsAuth';
  private readonly integrationsInstanceController = 'IntegrationInstance';
  
  constructor(private httpClientHelper: HttpClientHelperService) { }

  getPublicSystemsLegacy(): Observable<PublicSystemsResponse> {
    return this.httpClientHelper.invokeGetAuth<PublicSystemsResponse>(`${this.controller}/PublicSystems`);
  }
  
  addSystem(system: PublicSystem): Observable<SystemInstanceResponse> {
    return this.httpClientHelper.invokePostAuth(`${this.controller}/Add`, system);
  }

  updateSystem(systemId: string, system: Partial<SystemInstance>): Observable<void> {
    return this.httpClientHelper.invokePatchAuth(`${this.controller}/Systems/${systemId}`, system);
  }

  addCatalogSystem(system: PublicSystem): Observable<AddCatalogSystemResponse> {
    return this.httpClientHelper.invokePostAuth(`${this.controller}/AddToInventory`, system);
  }

  getSystem(systemId: string): Observable<SystemInstanceResponse> {
    return this.httpClientHelper.invokeGetAuth(`${this.controller}/Get/${systemId}`);
  }

  getSystemsByEmployee(employeeId: string, offset = 0, limit = 500): Observable<SystemsResponse> {
    return this.httpClientHelper.invokeGetAuth(`${this.controller}/Employee/${employeeId}?offset=${offset}&limit=${limit}`);
  }

  getInegrationType(systemType: string): Observable<IntegrationCatalogTypes> {
    return this.httpClientHelper.invokeGetAuth(`${this.controller}/IntegrationTypes/${systemType}`);
  }

  editGeneralSystemInstance(systemId: string, generalData: SystemGeneralData): Observable<EditGeneralResponse> {
    return this.httpClientHelper.invokePostAuth(`${this.controller}/edit/${systemId}`, generalData);
  }

  editOwner(systemId: string, owner: string): Observable<void> {
    return this.httpClientHelper.invokePostAuth(`${this.controller}/editOwner/${systemId}`, { owner });
  }

  getCompanySystems(): Observable<CompanySystemsResponse> {
    return this.httpClientHelper.invokeGetAuth(`${this.controller}/CompanySystems`);
  }

  editIntegrationInstance(integraitonInstance: IntegrationSystem): Observable<IntegrationSystem> {
    return this.httpClientHelper.invokePostAuth(`${this.integrationsInstanceController}/Edit`, integraitonInstance);
  }

  oauthConnect(systemId: string, id: string, integrationType: string, fields: IntegrationField, redirect: string): Observable<OauthResponse> {
		return this.httpClientHelper.invokePostAuth<OauthResponse>(`${this.integrationsAuthController}/Connect?redirect=${redirect}`, { systemId, id, integrationType, ...fields, 'requestType': 'V2' } );
  }

  oauthCallback(oauthState: OauthAuthorizationState): Observable<IntegrationConfig> {
		return this.httpClientHelper.invokePostAuth<IntegrationConfig>(`${this.integrationsAuthController}/Callback`, oauthState);
	}

  oauthDisconnect(id: string): Observable<IntegrationConfig> {
		return this.httpClientHelper.invokePostAuth<IntegrationConfig>(`${this.integrationsController}/DisconnectIntegrationOauthType`, {id});
	}

  getIntegrationInstanceId(systemId: string): Observable<CreateInstanceResponse> {
    return this.httpClientHelper.invokeGetAuth(`${this.integrationsInstanceController}/GenerateId/${systemId}`);
  }

  removeInstance(integrationId: string): Observable<void> {
    return this.httpClientHelper.invokeDeleteAuth(`${this.integrationsInstanceController}/${integrationId}`);
  }

  archiveSystem(systemId: string): Observable<ArchivedFeatures[]> {
    return this.httpClientHelper.invokePostAuth(`${this.controller}/archiveV2/${systemId}`);
  }

  changeReviewStatus(status: ReviewStatus, systemId: string): Observable<SystemReviewStatus> {
		return this.httpClientHelper.invokePostAuth(`${this.controller}/Approval/${systemId}/ChangeStatus`, {
			newStatus: status,
		});
  }
  
  getSystemsIcons(systemTypes: string[]): Observable<SystemsIconsResponse> {
    return this.httpClientHelper.invokePostAuth(`${this.controller}/SystemsIcons`, {
      systems: systemTypes.map(type => {
        return { "systemType": type };
      }),
		});
  }

  iconsV2(payload: SystemsIconsV2Payload): Observable<SystemsIconsV2Response> {
    return this.httpClientHelper.invokePostAuth<SystemsIconsV2Response>(`${this.controller}/v2/icons`, payload);
  }

  testInternalSystemIntegration(integration: TestInternalSystem): Observable<InternalSystemTestResponse> {
		return this.httpClientHelper.invokePostAuth<InternalSystemTestResponse>(`${this.integrationsController}/TestIntegrationWebhookSlim`, integration);
	}

  testAutomatedCustomIntegration(integration: TestInternalSystem): Observable<InternalSystemTestResponse> {
		return this.httpClientHelper.invokePostAuth<InternalSystemTestResponse>(`${this.integrationsController}/TestCustomIntegration`, integration);
	}

  testApiKeyIntegration(data: IntegrationTest): Observable<ApiKeyResponse> {
		return this.httpClientHelper.invokePostAuth<ApiKeyResponse>(`${this.integrationsController}/TestIntegrationApiKeyType`, data);
	}

  deleteTicketIntegrations(ticketId: string, integrations: string[]): Observable<InetgrationsDeletionResponse> {
		return this.httpClientHelper.invokePostAuth<InetgrationsDeletionResponse>(`${this.integrationsController}/Data/${ticketId}`, { integrations });
	}

  markIntegrationAsDone(ticketId: string, integrationId: string, done: boolean): Observable<void> {
		const body: IntegrationMarkedDone = {
			integrationId,
			done
		};
		return this.httpClientHelper.invokePostAuth<void>(`${this.integrationsController}/MarkAsDone/${ticketId}`, body);
	}

  updateSystemDataTypes(systemId: string, extendedDataTypes: ExtendedDataType[]): Observable<UpdateSystemCategories> {
    return this.httpClientHelper.invokePostAuth<UpdateSystemCategories>(`${this.controller}/Categories/${systemId}`, {
      extendedDataTypes,
    });
  }

  batchUpdateSystemsDataTypes(batchDataTypes: BatchDataType[]): Observable<void> {
    return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/data-types`, batchDataTypes);
  }

  getStatus(): Observable<SystemStatusResponse> {
    return this.httpClientHelper.invokeGetAuth<SystemStatusResponse>(`${this.controller}/status`);
  }

  removeDataTypeFromSystem(systemId: string, dataType: string): Observable<string> {
    return this.httpClientHelper.invokePostAuth<string>(`${this.controller}/RemoveDataType/${systemId}`, {
      dataType
    });
  }

  manageEmployeeInSystem(systemId: string, employee: ManageEmployeeInSystem): Observable<void> {
    return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/ManageEmployee/${systemId}`, employee);
  }

  updateProcessingActivitiesState(systemId: string, data: ProcessingActiviySuggetionsUpdateData[], newState: SuggestedStateEnum): Observable<void> {
    return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/SuggestedProcessingActivities/${systemId}`, {
      activities: data
    }).pipe(map(() => void 0));
  }

  updatePaState(systemId: string, data: ProcessingActiviySuggetionsUpdateData): Observable<void> {
    return this.httpClientHelper.invokePostAuth<void>(`Systems/SetSuggestionsStatus/${systemId}`, data)
      .pipe(map(() => void 0));
  }

  updateDataTypesState(systemId: string, data: DataTypeSuggetionsUpdateData[]): Observable<void> {
    return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/SuggestedDataTypes/${systemId}`, {
      dataTypes: data
    }).pipe(map(() => void 0));
  }

  getTrashSystems(limit: number = 500): Observable<TrashSystemsResponse> {
    return this.httpClientHelper.invokeGetAuth<TrashSystemsResponse>(`${this.controller}/trash?limit=${limit}`);
  }

  getUnverifiedSystems(limit: number = 850): Observable<UnverifiedSystemsResponse> {
    return this.httpClientHelper.invokeGetAuth<UnverifiedSystemsResponse>(`${this.controller}/unverified?limit=${limit}`);
  }

  getUnverifiedSystem(systemId: string): Observable<UnverifiedSystem> {
    return this.httpClientHelper.invokeGetAuth<UnverifiedSystem>(`${this.controller}/unverified/${systemId}`);
  }

  moveToTrash(systemsIds: string []): Observable<void> {
    return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/moveToTrash`, {
      systems: systemsIds,
    });
  }

  moveToInventory(systemsIds: string []): Observable<void> {
    return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/moveToInventory`, {
      systems: systemsIds,
    });
  }

  currentAuthenticationApiKey(integrationId: string, integrationType: string): Observable<ApiKeyResponse> {
    const body = {
      integrationId,
      integrationType
    };
    return this.httpClientHelper.invokePostAuth<ApiKeyResponse>(`${this.integrationsInstanceController}/AuthenticateCurrentInstanceConfig`, body);
  }

  testAndEditIntegrationInstance(integraitonInstance: IntegrationSystem): Observable<IntegrationSystemTestResponse> {
    return this.httpClientHelper.invokePostAuth(`${this.integrationsInstanceController}/EditWithTest`, integraitonInstance);
  }

  getSystemEmployeesAdditionalData(systemId: string): Observable<SystemEmployeesAdditionalDataResponse> {
    return this.httpClientHelper.invokeGetAuth<SystemEmployeesAdditionalDataResponse>(`${this.controller}/Get/${systemId}/employeesAdditionalData`);
  }

  getSystemEmployeesEvidence(systemId: string, emoloyeeId: string): Observable<SystemEmployeeEvidenceResponse> {
    return this.httpClientHelper.invokeGetAuth<SystemEmployeeEvidenceResponse>(`${this.controller}/v1/${systemId}/employees/${emoloyeeId}/evidence`);
  }

  iconSignedUrl(systemId: string, payload: IconSignedUrlPayload): Observable<IconSignedUrlResponse> {
    return this.httpClientHelper.invokePostAuth(`${this.controller}/IconSignedUrl/${systemId}`, payload);
  }

  updateIconUrl(systemId: string, payload: UpdateIconUrlPayload): Observable<void> {
    return this.httpClientHelper.invokePostAuth(`${this.controller}/UpdateIconUrl/${systemId}`, payload);
  }

}
