<header class="risk-registry__header">
    <h1 class="mine-title-m risk-registry__title">
        {{'risks.riskRegistry.tableTitle' | content}}
    </h1>

    <button (click)="onRiskCatalogClick()" data-cy="risk-registry__risk-catalog-button" mine-button color="secondary">{{'risks.riskRegistry.riskCatalog' | content}}</button>
</header>

<risk-registry-categories-list
    (filtersChanged)="onFiltersChanged($event)"
     #categoriesList 
    class="risk-registry__categories-list">
</risk-registry-categories-list>

<ng-container *rxIf="!loading()">
    <ng-container *rxLet="filters(); let filters">
        <mine-table-header
            class="risk-registry__table-header"
            [columns]="columns"
            [filters]="filters"
            [options]="mineTableHeaderOptions"
            (searchChanged)="onSearchChanged($event)"
            (filtersChanged)="onFiltersChanged($event)">
        </mine-table-header>
    </ng-container>
</ng-container>

<table class="risk-registry__table" mineSort
     (sortChange)="sortChange($event)">
    <tr #tableHeader class="risk-registry__row">
        <ng-container *rxFor="let column of columns | keyvalue: originalOrder; trackBy: trackColumnsFn">
            <ng-container *rxIf="column.value.inView">
                <th class="risk-registry__header-cell"
                    [mine-sort-header]="column.key"
                    [disabled]="!column.value.isSortable || loading() || empty()">
                    {{column.value.text}}
                </th>
            </ng-container>
        </ng-container>
    </tr>
    <ng-container *rxIf="!loading(); else beforeLoadedTpl">
        <ng-container *rxIf="!!data()?.length; else emptyTpl">
            <tr (click)="onClickRow(row)"
                *rxFor="let row of data(); trackBy: trackDataFn" 
                class="risk-registry__row">
                <ng-container *rxFor="let column of columns | keyvalue: originalOrder; trackBy: trackColumnsFn">
                    <ng-container *rxIf="column.value.inView">
                        <ng-container [ngSwitch]="column.key">
                            <td *ngSwitchCase="RiskRegistryColumnEnum.Risk"
                                class="risk-registry__cell">
                                <ng-container *rxIf="!!row.name; else emptyCellTpl">
                                    <p class="risk-registry__cell-name-text" 
                                        [innerHTML]="row.name | safe"
                                        [tp]="row.name"
                                        [tpOnlyTextOverflow]="true"
                                    ></p>
                                </ng-container>
                            </td>
                            <td *ngSwitchCase="RiskRegistryColumnEnum.Assessment"
                                class="risk-registry__cell">
                                <mine-chip *rxIf="!!row?.assessmentPage?.name; else emptyCellTpl"
                                    class="risk-registry__type-badge"
                                    [backgroundColor]="typeBadge.backgroundColor"
                                    [color]="typeBadge.textColor">
                                    <svg class="risk-registry__robot-icon">
                                        <use [attr.xlink:href]="'../../../assets/img/sprite.svg#icon-assessment'"></use>
                                    </svg>
                                    <p class="risk-registry__type-badge-text" 
                                        [innerHTML]="row?.assessmentPage?.name  | safe"
                                        [tp]="row?.assessmentPage?.name"
                                        [tpOnlyTextOverflow]="true"
                                    ></p>
                                </mine-chip>
                            </td>

                            <td *ngSwitchCase="RiskRegistryColumnEnum.AssessmentType"
                                class="risk-registry__cell">
                                <mine-chip *rxIf="!!row?.assessmentPage?.type; else emptyCellTpl"
                                    class="risk-registry__type-badge"
                                    [backgroundColor]="typeBadge.backgroundColor"
                                    [color]="typeBadge.textColor">
                                    <svg class="risk-registry__robot-icon">
                                        <use [attr.xlink:href]="'../../../assets/img/sprite.svg#' + (row?.assessmentPage?.type | aiAssessmentTemplateIcon)"></use>
                                    </svg>
                                    <span class="risk-registry__type-badge-text">
                                        {{row?.assessmentPage?.type | aiAssessmentTemplateName}}
                                    </span>
                                </mine-chip>
                            </td>


                            <td *ngSwitchCase="RiskRegistryColumnEnum.InherentRisk"
                                class="risk-registry__cell">
                                <ng-container *rxIf="!!row.inherentRisk; else emptyCalcTpl">
                                    <div class="risk-registry__calculation-dot" [ngClass]="'risk-registry__calculation-dot--'+row.inherentRisk"></div>
								
                                    <span>{{ row.inherentRisk }}</span>
                                </ng-container>
                            </td>

                            <td *ngSwitchCase="RiskRegistryColumnEnum.ResidualRisk"
                                class="risk-registry__cell">
                                <ng-container *rxIf="!!row.residualRisk; else emptyCalcTpl">
                                    <mine-chip 
                                        class="risk-registry__type-badge"
                                        [backgroundColor]="residualRiskBadge.backgroundColor"
                                        [color]="residualRiskBadge.textColor">
                                        <svg class="risk-registry__residual-risk-icon">
                                            <use [attr.xlink:href]="'../../../assets/img/sprite.svg#icon-checkmark-star'"></use>
                                        </svg>
                                        {{ row.residualRisk }}
                                    </mine-chip>
                                </ng-container>
                            </td>

                            <td *ngSwitchCase="RiskRegistryColumnEnum.DataSources"
                                class="risk-registry__cell">
                                <ng-container *rxLet="row?.assessmentPage?.externalRelatedEntities | relatedEntitiesArray: 'dataSources' : 'externalRelatedEntityId'; let relatedEntities">
                                    <ng-container *rxIf="relatedEntities?.length > 0; else emptyCellTpl">
                                        <ng-container *rxFor="let entity of relatedEntities | orderBy:'iconPath' | slice:0:maxIconsToShow">
                                            <mine-service-icon class="risk-registry__service-icon shadow" [icon]="entity.iconPath">
                                            </mine-service-icon>
                                        </ng-container>
                                        <ng-container *rxIf="relatedEntities?.length > maxIconsToShow">
                                            <p class="risk-registry__system-counter">+{{ (relatedEntities.length - relatedEntities.slice(0, maxIconsToShow).length) }}</p>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </td>

                            <td *ngSwitchCase="RiskRegistryColumnEnum.Mitigations" class="risk-registry__cell">
                                <ng-container *rxLet="row?.mitigations | slice:0:(maxMitigationsToShow); let mitigations">
                                  <ng-container *rxIf="!mitigations?.length; else mitigationsTpl">
                                        <span>-</span>
                                  </ng-container>
                                  
                                    <ng-template #mitigationsTpl>
                                        <mine-chip 
                                            *rxFor="let mitigation of mitigations"
                                            [backgroundColor]="typeBadge.backgroundColor"
                                            [color]="typeBadge.textColor">
                                            <span class="risk-registry__type-badge-text">
                                                {{ mitigation }}
                                            </span>
                                        </mine-chip>

                                        <ng-container *rxIf="row.mitigations?.length > maxMitigationsToShow">
                                            <p class="risk-registry__mitigations-counter">
                                                +{{ row.mitigations.length - maxMitigationsToShow }}
                                            </p>
                                        </ng-container>
                                  </ng-template>
                                </ng-container>
                              </td>
                              
                        
                            <td *ngSwitchCase="RiskRegistryColumnEnum.Created"
                                class="risk-registry__cell">
                                <span class="risk-registry__date">{{ row.createdDate | lastModificationDate }}</span>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-container>
    </ng-container>
</table>

<ng-template #beforeLoadedTpl>
    <div class="risk-registry__row risk-registry__loading-row"
        *rxFor="let row of [].constructor(4)">
        <!-- fix this repeat: columns.size; -->
        <ng-container *skeleton="true;
                    repeat: columns.size;
                    width: '9rem';
                    height: '1.4rem';">
        </ng-container>
    </div>
</ng-template>

<ng-template #emptyCalcTpl>
    <svg class="risk-registry__empty-calc-svg">
        <use xlink:href="../../../assets/img/sprite.svg#dashed-circle"></use>
    </svg>
    <span class="risk-registry__empty-calc-text">Missing</span>
</ng-template>

<ng-template #emptyTpl>
    <div class="risk-registry__empty-container">
        <ng-container *rxIf="empty(); else noFilterResultsTpl">
            <mine-table-empty-state [content]="emptyStateContent">
            </mine-table-empty-state>
        </ng-container>

        <ng-template #noFilterResultsTpl>
            <mine-no-results></mine-no-results>
        </ng-template>
    </div>
</ng-template>

<ng-template #emptyCellTpl>
    <span>-</span>
</ng-template>