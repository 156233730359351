import { ChangeDetectionStrategy, Component, DestroyRef, Injector, OnInit } from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { filter, first, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { LoggerService } from 'src/app/logger/logger.service';
import { AuthProviders } from 'src/app/api/models/auth/auth-providers';
import { MineSnackbarType } from 'src/app/shared/mine-snackbar/mine-snackbar-type';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { PublicFeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { MineSnackbarService } from 'src/app/shared/mine-snackbar/mine-snackbar.service';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';
import { DropdownType } from 'src/app/shared/mine-dropdown/dropdown-types.enum';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { TenantEnum } from 'src/app/core/models/tenant.enum';
import { TenantService } from '../tenant.service';
import { AuthService } from '../auth.service';

@Component({
  	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
	private readonly loggerName: string = 'LoginComponent';

	readonly routesManager = RoutesManager;
	AuthProviders = AuthProviders;
	DropdownType = DropdownType;

	tenantCtrl: FormControl<DropdownOption>;
	tenantOptions: DropdownOption[];
    tenantToSwitch: DropdownOption;

	assetsUrl = environment.assets.url;
	privacyPolicy = environment.routing.privacyPolicy;
	termsOfService = environment.routing.termsOfService;

	ssoFlag = false;
	usTenantFF = false;
    devSignInUpImproveFF = false;

	constructor(
		private auth: AuthService,
		private injector: Injector,
		private logger: LoggerService,
        private destroyRef: DestroyRef,
		private contentPipe: ContentPipe,
		private activatedRoute: ActivatedRoute,
		private featureFlagQuery: FeatureFlagQuery,
		private snackbarService: MineSnackbarService
	) { }

	ngOnInit() {
		this.logger.debug(this.loggerName, 'ngOnInit()');
		this.initFeatureFlags();
		this.auth0Flow();

		if (this.usTenantFF) {
			this.initTenant();
		}
	}

	private initFeatureFlags(): void {
		const flags = this.featureFlagQuery.getMultipleFlags([
			PublicFeatureFlags.SsoLogin,
			PublicFeatureFlags.DevUsTenant,
            PublicFeatureFlags.DevSignInUpImprove
		]);

		this.ssoFlag = flags.ssoLogin;
		this.usTenantFF = flags.devUsTenant;
        this.devSignInUpImproveFF = flags.devSignInUpImprove;
	}

	private initTenant(): void {
        const tenantService = this.injector.get(TenantService);
		this.tenantOptions = tenantService.tenantOptions;
        this.tenantCtrl = new FormControl<DropdownOption>(tenantService.getTenant());
        this.tenantCtrl.valueChanges.pipe(
            tap(value => tenantService.setTenant(value.id as TenantEnum)),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe();
        this.tenantToSwitch = this.alternativeTenant;
	}

	private auth0Flow(): void {
		//show snackbar after auth0 verification
		this.activatedRoute.queryParams.pipe(
			first(),
			filter(res => res.success && res.code === 'success'),
			tap(() => this.snackbarService.showTimed(MineSnackbarType.Confirmation, this.contentPipe.transform('authentication.auth0EmailVerified')))
		).subscribe();
	}

	onLoginButtonClick(provider: AuthProviders){
		window.location.href = this.auth.getLoginUrl(provider, provider === AuthProviders.auth0, false);
	}

    switchTenant(): void {
        this.tenantToSwitch = this.tenantCtrl.value;
        this.tenantCtrl.setValue(this.alternativeTenant);
    }

    private get alternativeTenant(): DropdownOption {
        return this.tenantOptions.find(option => option.id.toLowerCase() !== this.tenantCtrl.value.id.toLowerCase());
    }
}
