
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PiiDataTypesState, PiiDataTypesStore } from './pii-data-types.store';
import { FilterCategory, FilterOption } from 'src/app/core/models/filtering.interface';
import { Observable } from 'rxjs';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { PiiSystemDataTypeColumnsEnum, PiiSystemDataTypesFiltersEnum } from '../../pii-system-findings/pii-system-data-types/data-types-table-header/data-types.enum';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';
import { PiiDataTypeItem } from '../../models/pii-classifier.interface';
import { FrameworkChip } from 'src/app/data-types/models/frameworks.interface';
import { DataTypesQuery } from 'src/app/data-types/state/data-types.query';
import { ConvertPiiTypeToDataTypePipe } from 'src/app/shared/pipes/convert-pii-type-to-data-type.pipe';

@Injectable({ 
	providedIn: 'root' 
})
export class PiiDataTypesQuery extends QueryEntity<PiiDataTypesState> {

	constructor(
		protected store: PiiDataTypesStore,
		private dataTypesQuery: DataTypesQuery,
		private convertPiiTypeToDataTypePipe: ConvertPiiTypeToDataTypePipe
	) {
		super(store);
	}

	selectByFiltersAndSearchTerm(activeFilters: FilterCategory[], searchTerm: string, sort: MineSort = null): Observable<PiiDataTypeItem[]> {
		const selectedFrameworks = activeFilters.find(f => f.id === PiiSystemDataTypesFiltersEnum.Frameworks)?.options ?? [];
		const selectedViolaton = activeFilters.find(f => f.id === PiiSystemDataTypesFiltersEnum.Violations)?.options ?? [];

		// has filters or search term
		return this.selectAll({
			filterBy: [
				entity => this.filterByFrameWorks(entity?.frameworks ?? [], selectedFrameworks) && // filter by frameworks
						  this.filterByViolations(entity?.policyViolation, selectedViolaton) && // filter by violation
						  this.filterBySearchTerm(entity.piiDataType, searchTerm)

			],
			sortBy: (s1, s2) => sort?.active === PiiSystemDataTypeColumnsEnum.DataType ? this.sortByDataTypeName(s1, s2, sort.direction) :
								sort?.active === PiiSystemDataTypeColumnsEnum.MatchesFound ? this.sortByMatches(s1, s2, sort.direction) : 1
		});
	}

	private sortByDataTypeName(dataTypeA: PiiDataTypeItem, dataTypeB: PiiDataTypeItem, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Default ? 0 : 
			   direction === SortDirectionEnum.Asc ? 
			dataTypeA.piiDataType.localeCompare(dataTypeB.piiDataType) :
			dataTypeB.piiDataType.localeCompare(dataTypeA.piiDataType);
	}

	private sortByMatches(a: PiiDataTypeItem, b: PiiDataTypeItem, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			a.totalMatches - b.totalMatches : 
			b.totalMatches - a.totalMatches;
	}

	private filterByFrameWorks(frameworks: FrameworkChip[], selected: FilterOption[]): boolean {
		if (selected.length === 0) {
			return true;
		}

		const selectedFrameworksIds = new Set<string>(selected.map(f => f.id));
		return !!frameworks.some(f => selectedFrameworksIds.has(f.id));
	}

	private filterByViolations(hasViolation: boolean, selected: FilterOption[]): boolean {
		if (selected.length === 0) {
			return true;
		}
		const selectedViolationsIds = new Set<boolean>(selected.map(f => !!f.id));
		return selectedViolationsIds.has(hasViolation);
	}
	
	private filterBySearchTerm(piiDataType: string, searchTerm: string): boolean {
		if (searchTerm === '') {
			return true;
		}

		const dataType = this.dataTypesQuery.getEntity(this.convertPiiTypeToDataTypePipe.transform(piiDataType));
		return dataType.name.toLowerCase().startsWith(searchTerm.toLowerCase());
	}
}
