import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { QuillModule, QuillModules } from 'ngx-quill';
import { CommonModule } from '@angular/common';

import { TippyModule } from '../tippy.module';
import { AnalyticsEvent } from '../../analytics/analytics-event';
import { AnalyticsService } from '../../analytics/analytics.service';
import { EventActionType } from '../../analytics/event-action.enum';
import { TextareaVars } from '../../automations/models/text-area-variables.interface';
import { DropdownClickCloseDirective } from '../directives/dropdown-click-close.directive';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { CustomVariable } from './mine-richtext-editor.interface';
import { SafePipe } from '../pipes/safe.pipe';

import Quill from 'quill';
import { AlignClass } from 'quill/formats/align';
import { DirectionClass } from 'quill/formats/direction';

@Component({
	standalone: true,
	selector: 'mine-richtext-editor',
	templateUrl: './mine-richtext-editor.component.html',
	styleUrls: ['./mine-richtext-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [QuillModule, TippyModule, CommonModule, ReactiveFormsModule, DropdownClickCloseDirective, SafePipe],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => MineRichtextEditorComponent),
		multi: true
	}]
})
export class MineRichtextEditorComponent implements OnInit, ControlValueAccessor {

	private editor: any; // it is typed "any" in Quill.js docs

	private lastIndex: number;

	private disabled: boolean;

	@Input() id = 'editor';

	@Input() placeholder = '';

	@Input() customVariable: CustomVariable; // allow custom variable from outside, we can make it even an array

	@Input() textVariables: TextareaVars[];

	@Input() error?: string;

	varsTooltip: string;

	basicEditorModules: QuillModules;

	formCtrl: FormControl;

	constructor(private analytics: AnalyticsService, private contentPipe: ContentPipe) { }

	@HostBinding('class.focus')
	private get focusState() { return this.editor?.hasFocus() && !this.error; }
	
	@HostBinding('class.error')
	private get errorState() { return !!this.error; }

	@HostBinding('class.disabled') 
	private get disable() { return this.disabled; }

	ngOnInit(): void {
		this.initToolbar();
		this.formCtrl = new FormControl();
		this.varsTooltip = this.contentPipe.transform('common.textareaConfig').addVars;
		Quill.register(AlignClass, true);
		Quill.register(DirectionClass, true);
	}

	writeValue(value: string): void {
		this.formCtrl.setValue(value);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onChange = (value: string) => {};
  	onTouched = (value: string) => {};

  // save a reference to the editor
	editorCreated(valuw: any): void {
		this.editor = valuw;
		this.loadCustomVariableToEditor();
	}

	onVarSelected(variable: string): void {
		this.analytics.track({
			eventCategory: `textarea - add variable`,
			eventAction: EventActionType.Click,
			eventLabel: variable
		} as AnalyticsEvent);

		this.editor.insertText(this.lastIndex, variable);
	}

	contentChanged(content: any): void {
		this.onChange(content?.html ?? '');
	}

	selectionChanged(selection: any): void {
		if (!this.editor.hasFocus()) {
			this.lastIndex = selection.oldRange.index;
		}
	}

  // set default config of richtext (buttons, sizes, colors, etc). when array is empty - we will get the default values
	private initToolbar(): void { 
		const toolbarContainer = [
			['bold', 'italic', 'underline'],
			[{ 'list': 'ordered'}, { 'list': 'bullet' }],
			[{ 'direction': 'rtl' }],
			// [{ 'size': [] }],
			// [{ 'header': [1, 2, 3, 4, 5, 6] }],
			[{ 'color': [] }],
			['link'],
			[{ 'align': [] }],
		] as Array<Array<string | object>>;

		if (this.customVariable) {
			const options = this.customVariable.options.map(res => res.title);
			const newToolbarVar = { 'custom-variable': options };
			toolbarContainer.push([newToolbarVar]);
		}

		this.basicEditorModules = {
			toolbar: {
				container: toolbarContainer,
				handlers: {
					'custom-variable': selectedVarTitle => { 
						if (selectedVarTitle) {
							this.handleCustomVariable(selectedVarTitle as string);
						}
					}
				}
			}
		};
	}

	private handleCustomVariable(variable: string): void {
		const selectedVarTag = this.customVariable.options.find(res => res.title === variable).tag;
		const cursorPosition = this.editor.getSelection().index;
		this.editor.insertText(cursorPosition, selectedVarTag);
	}

	private loadCustomVariableToEditor(): void {
		if (this.customVariable) {
			document.querySelector(`.ql-custom-variable .ql-picker-label`).innerHTML = this.customVariable.title + document.querySelector(`.ql-custom-variable .ql-picker-label`).innerHTML;
			const customVariableItems = Array.prototype.slice.call(document.querySelectorAll(`.ql-custom-variable .ql-picker-item`));
			customVariableItems.forEach(item => item.textContent = item.dataset.value);
		}
	}
}
