<button class="actions__btn"
    [class.actions__btn--active]="showActionsMenu"
    (click)="menuClick($event)">
    <svg class="actions__btn-icon"
    [class.actions__btn-icon--active]="showActionsMenu">
        <use xlink:href="../../../assets/img/sprite.svg#icon-dots"></use>
    </svg>
</button>

<ul *ngIf="showActionsMenu" @fadeInOut 
    class="actions__menu actions__menu--{{position}}">
    <li *ngFor="let option of options" 
        class="actions__option" 
        data-cy="actions__option-{{option.id}}"
        (click)="onActionClick(option.id, $event)"
        [class.actions__option--disabled]="option.disabled">
       {{option.value}}
    </li>
</ul>
