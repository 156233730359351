import { Injectable, Injector } from '@angular/core';
import { Observable, map, switchMap, tap  } from 'rxjs';

import { LoggerService } from 'src/app/logger/logger.service';
import { ApiClientCompanySettingsService } from 'src/app/api/api-client-company-settings.service';
import { CustomValuesStore } from './custom-values.store';
import { CustomValue } from 'src/app/api/models/company-settings/custom-values.interface';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { KeyValue } from '@angular/common';
import { ProcessingActivitiesQuery } from 'src/app/processing-activities/state/processing-activities.query';
import { ProcessingActivitiesService } from 'src/app/processing-activities/state/processing-activities.service';
import { CustomDataTypesService } from '../custom-data-types.service';

@Injectable({ 
	providedIn: 'root' 
})
export class CustomValuesService {
	
	private readonly loggerName: string = 'CustomValuesService';

	constructor(
		private logger: LoggerService,
		private apiClientCompanySettings: ApiClientCompanySettingsService,
		private customValuesStore: CustomValuesStore,
		private customDataTypeService: CustomDataTypesService,
		private contentPipe: ContentPipe,
		private injector: Injector,
	) { }

	init(): Observable<void> {
		this.logger.debug(this.loggerName, 'init()');
		return this.getCustomValuesFromServer().pipe(
			map(() => void 0),
		);
	}
	
	private getCustomValuesFromServer(): Observable<CustomValue[]> {
		return this.apiClientCompanySettings.getCustomValues().pipe(
			map(res => res.customValues),
			tap(res => this.customValuesStore.set(res)),
			tap(() => this.customValuesStore.setLoading(false)),
		);
	}

	addCustomValue(type: CustomValueTypeEnum, name: string): Observable<CustomValue> {
		return this.apiClientCompanySettings.addCustomValue(type, name).pipe(
			tap(res => this.customValuesStore.add(res)),
			tap(res => this.addToAdditionalStores(type, res)),
		);
	}

	private addToAdditionalStores(type: CustomValueTypeEnum, customValue: CustomValue): void {
		if (type === CustomValueTypeEnum.DataType) { 
			// datatypes has own store so it need updating
			this.customDataTypeService.saveCustomDataTypeToStore(customValue);
		}
	}

	updateCustomValues(type: CustomValueTypeEnum, updatedCustomValues: CustomValue[]): Observable<CustomValue[]> {
		return this.apiClientCompanySettings.updateCustomValues(type, updatedCustomValues).pipe(
			map(res => res.customValues),
			tap(res => this.customValuesStore.upsertMany(res)),
			tap(res => this.updataValueInAdditionalStores(type, res)),
		);
	}

	private updataValueInAdditionalStores(type: CustomValueTypeEnum, customValue: CustomValue[]): void {
		if (type === CustomValueTypeEnum.DataType) { 
			// datatypes has own store so it need updating
			this.customDataTypeService.saveUpdateCustomDataTypeToStore(customValue);
		}
	}

	deleteCustomValue(type: CustomValueTypeEnum, customValueId: string): Observable<void> {
		return this.apiClientCompanySettings.deleteCustomValue(type, customValueId).pipe(
			tap(() => this.customValuesStore.remove(customValueId)),
			tap(() => this.deleteFromAdditionalStores(type, customValueId)),
			switchMap(() => this.refreshStoresAfterDeletingCustomValue()),
		);
	}

	private deleteFromAdditionalStores(type: CustomValueTypeEnum, id: string): void {
		if (type === CustomValueTypeEnum.DataType) { 
			// datatypes has own store so it need updating
			this.customDataTypeService.deleteCustomDataTypeFromStore(id);
		}
	}

	private refreshStoresAfterDeletingCustomValue(): Observable<void> {
		// right now custom values are only from activities, so only processing activities refresh is relevant
		return this.injector.get(ProcessingActivitiesService).getProcessingActivities(true).pipe(map(() => void 0));
	}

	// get predefined values to check the validation of custom values
	getPredefinedValues(fieldType: CustomValueTypeEnum): KeyValue<string, string>[] | string[] {
		switch(fieldType) {
		  case CustomValueTypeEnum.LegalBasis:
			return this.contentPipe.transform('ropa.legalBasisList');
		  case CustomValueTypeEnum.TransferMechanism:
			return this.contentPipe.transform('ropa.crossBorderList'); 
		  case CustomValueTypeEnum.BusinessUnit:
			return this.injector.get(ProcessingActivitiesQuery).getFunctions();
		  default:
			return [];
		}
	  }

}