import { Pipe, PipeTransform } from '@angular/core';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';

@Pipe({
  standalone: true,
  name: 'fieldTypeToText'
})
export class FieldTypeToTextPipe implements PipeTransform {

  private fields = this.contentPipe.transform('company-settings-customization.fieldTypeDisplayText');

  constructor(private contentPipe: ContentPipe) {}

  transform(field: CustomValueTypeEnum): string {
    switch(field) {
      case CustomValueTypeEnum.BusinessUnit:
        return this.fields.businessUnit;
      case CustomValueTypeEnum.LegalBasis:
        return this.fields.legalBasis;
      case CustomValueTypeEnum.TransferMechanism:
        return this.fields.transferMechanism;
      case CustomValueTypeEnum.Mitigation:
        return this.fields.mitigation;
      case CustomValueTypeEnum.DataSubject:
        return this.fields.dataSubject;
      case CustomValueTypeEnum.PaEntity:
        return this.fields.paEntity;
      case CustomValueTypeEnum.DataType:
        return this.fields.dataType;
      default:
        return '';
    }
  }

}
