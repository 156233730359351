import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, EventEmitter, HostBinding, Input, OnInit, Output, signal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MineButtonDirective } from '@mineos/mine-ui';
import { filter, finalize, first, map, Observable, tap } from 'rxjs';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { ChipConfig, MineChip } from 'src/app/shared/mine-chip/mine-chip.interface';
import { MineChipsInputComponent } from 'src/app/shared/mine-chips-input/mine-chips-input.component';
import { AddItemConfig, ValidationConfig } from 'src/app/shared/mine-chips-input/mine-chips-input.interface';
import { MineDropdownComponent } from 'src/app/shared/mine-dropdown/mine-dropdown.component';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';
import { MineRadioButtonComponent } from 'src/app/shared/mine-radio-button/mine-radio-button.component';
import { CommonModule } from '@angular/common';
import { MineButtonSecondaryComponent } from 'src/app/shared/mine-button-secondary/mine-button-secondary.component';
import { RiskCalulationPipe } from 'src/app/processing-activities/services/risk-calulation.pipe';
import { RxIf } from '@rx-angular/template/if';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';
import { ChipColorName } from 'src/app/shared/mine-chip/mine-chip.enum';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MineRichtextEditorComponent } from 'src/app/shared/mine-richtext-editor/mine-richtext-editor.component';
import { Risk, RiskHelper, RiskHelperType, RiskRateEnum } from 'src/app/api/models/risks/risks.interface';
import { MineInputComponent } from 'src/app/shared/mine-input/mine-input.component';
import { MineInfoBoxComponent } from 'src/app/shared/mine-info-box/mine-info-box.component';
import { BoxBgColor } from 'src/app/shared/mine-info-box/mine-info-box.enum';
import { NoWhitespaceValidator } from 'src/app/shared/validators/input-validators';
import { RisksCatalogService } from 'src/app/risks/services/risks-catalog.service';
import { RandomUUIDPipe } from 'src/app/shared/pipes/random-uuid.pipe';
import { RiskHelperPanelComponent } from 'src/app/risks/risk-helper-panel/risk-helper-panel.component';
import { DialogsManagerService } from 'src/app/services/dialogs-manager.service';
import { NewCustomValueDialogComponent } from 'src/app/company-settings/customization/custom-values/field-custom-values-panel/new-custom-value-dialog/new-custom-value-dialog.component';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { AiAssessmentStatusEnum } from 'src/app/ai-assessments/models/ai-assessments.enum';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { Router } from '@angular/router';

const rxAngularDirectives = [
  RxIf,
  RxFor,
  RxLet,
];

@Component({
  selector: 'ai-assessments-risk-panel',
  standalone: true,
  templateUrl: './ai-assessments-risk-panel.component.html',
  styleUrls: ['./ai-assessments-risk-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ContentPipeModule,
    RiskCalulationPipe, 
    CommonModule,
    ReactiveFormsModule,
    MineButtonDirective,
    MineDropdownComponent,
    MineRadioButtonComponent,
    MineChipsInputComponent,
    MineButtonSecondaryComponent,
    MineRichtextEditorComponent,
    MineInfoBoxComponent,
    MineInputComponent,
    ...rxAngularDirectives,
    RiskHelperPanelComponent,
    SafePipe,
  ],
  providers: [RandomUUIDPipe]
})
export class AiAssessmentsRiskPanelComponent implements OnInit {
  constructor (
    private customValuesQuery: CustomValuesQuery,
    private destroyRef: DestroyRef,
    private contentPipe: ContentPipe,
    private risksCatalogService: RisksCatalogService,
    private randomUUIDPipe: RandomUUIDPipe,
    private riskCalulationPipe: RiskCalulationPipe,
    private cdr: ChangeDetectorRef,
		private dialogManager: DialogsManagerService,
    private router: Router
  ) {}

  @Input() risk: Partial<Risk>;
  @Input() assessmentId: string;
  @Input() isCustom: boolean = false;
  @Input() showRiskHelper: boolean = false;
  @Input() loading = signal<boolean>(false);

  @HostBinding('class.risk-helper') 
  get riskHelperClass() {
    return this.showRiskHelper;
  }

  @Output() save: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() formDirty: EventEmitter<void> = new EventEmitter<void>();

  readonly infoBoxBgColor = BoxBgColor.Grey;
  readonly AiAssessmentStatusEnum = AiAssessmentStatusEnum;

  options: DropdownOption[] = [];
  mitigationsConfig$: Observable<AddItemConfig>;
	mitigationsChips = signal<Map<string, ChipConfig>>(null);
	validationConfig: ValidationConfig = {maxChips: 25};	
  form: FormGroup;
  riskHelper: RiskHelper;

  private RiskRateEnum = RiskRateEnum;

  ngOnInit() {
    this.initForm();
    this.initRiskHelper();
    this.initMitigationsData();
    this.initDropdownOptions();
    this.watchForFormChanges();
  }

  private initRiskHelper() {

    const riskHelperData = this.contentPipe.transform('ropa.dpiaRisk') as RiskHelper[];

    if(this.risk.type === RiskHelperType.ExcessiveDataCollection) {
      this.riskHelper = riskHelperData.find(riskHelper => riskHelper.id === RiskHelperType.Overcollection);
      this.riskHelper = { ...this.riskHelper, id: RiskHelperType.ExcessiveDataCollection };
    } else {
      this.riskHelper = riskHelperData.find(riskHelper => riskHelper.id === this.risk.type);
    }

    this.assessmentId = this.assessmentId || this.risk.assessmentPage.id;
  }

  private watchForFormChanges(): void {
    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(() => this.form.dirty),
        filter(isDirty => isDirty),
        first(),
        tap(() => this.formDirty.emit())
      )
      .subscribe();
  }

  get residualRisk(): boolean {
		return !!this.form.get('mitigations')?.value?.length;
	}

  get title(): string {
    return this.isCustom ? this.contentPipe.transform('risks.riskPanel.customRiskInfoText') : this.risk.name;
  }

  get primaryButtonText(): string {
    return this.isCustom ? this.contentPipe.transform('risks.riskPanel.addRiskButton')
     : this.contentPipe.transform('risks.riskPanel.saveRiskButton');
  }

  get editMode(): boolean {
    return !!this.risk?.id;
  }

  get isFormDirty(): boolean {
    return this.form.dirty;
  }

  assessmentClicked(assessmentId: string): void {
    this.router.navigate([RoutesManager.ai_assessments, assessmentId]);
  }

  addCustomValueV2(): void {
		const dialogRef$ = this.dialogManager.openDialogCommon(NewCustomValueDialogComponent, CustomValueTypeEnum.Mitigation, '383px');

		dialogRef$.afterClosed$.pipe(
			filter(val => val),
			first(),
			tap(res => this.handleNewMitigation(res.name)),
			finalize(() => this.cdr.detectChanges()),
		).subscribe();
	}

  private handleNewMitigation(mitigation: string): void {
		this.form.get('mitigations').setValue([...this.form.get('mitigations').value, mitigation]);
    this.mitigationsChips.update(mitigationsChips =>  mitigationsChips.set(mitigation, { isRemovable: true, chipColor: MineChip.get(ChipColorName.Grey) } as ChipConfig));
    this.form.markAsDirty();
	}

  onValueChanged(value: string[]): void {
    this.form.get('mitigations')?.setValue(value);
    this.form.markAsDirty();
  }

  private initForm(): void {
    this.form = new FormGroup({
      id: new FormControl<number>(this.risk.id),
      type: new FormControl<string>(this.isCustom ? this.randomUUIDPipe.transform() : this.risk?.type),
      name: new FormControl<string>(this.risk.name, [Validators.required, NoWhitespaceValidator, this.riskNameUniquenessValidator()]),
      likelihood: new FormControl<DropdownOption>(this.risk.likelihood ? {id: this.risk.likelihood, value: this.risk.likelihood} : null),
      severity: new FormControl<DropdownOption>(this.risk.severity ? {id: this.risk.severity, value: this.risk.severity} : null),
      mitigations: new FormControl<string[]>(this.risk?.mitigations ?? []),
      comment: new FormControl<string>(this.risk?.comment ?? ''),
      accepted: new FormControl<boolean>(this.risk?.accepted),
      createdDate: new FormControl<Date>(this.risk?.createdDate),
      isDefault: new FormControl<boolean>(this.risk?.isDefault),
    });
  }

  private riskNameUniquenessValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => { 
      if(control && this.risksCatalogService.isCustomRiskAlreadyExist(control?.value)) {
        return { duplicate: true };
      }
      return null;
    };
  }

  get error(): string {
    const control = this.form.get('name');

    if (!control?.pristine && control?.invalid && control?.errors) {
      if (control.errors['required'] || control.errors['whitespace']) {
        return this.contentPipe.transform('common.required');
      }
      if (control.errors['duplicate']) {
        return this.contentPipe.transform('risks.riskPanel.riskNameAlreadyExistError');
      }
    }
    return '';
  }

  private getMitigationsData(customMitigations: DropdownOption[]): AddItemConfig {
    return {
     dropDownOptions: [...this.contentPipe.transform('ropa.mitigations'), ...customMitigations],
     fixedOptions: true, 
     preventDropdownOffset: true,
     inputPlaceHolder:  this.contentPipe.transform('processing-activities.mitigationsPlaceholder'),
     width: '366px',
     hasCustomOption: true,
   };
 }

  private initMitigationsData(): void {
		const mitigations = this.form?.get('mitigations')?.value;
    const mitigationMap = new Map<string, ChipConfig>();
		mitigations?.forEach(d => mitigationMap.set(d, { isRemovable: true, chipColor: MineChip.get(ChipColorName.Grey) } as ChipConfig));
    this.mitigationsChips.set(mitigationMap);

    const selectMitigations = this.customValuesQuery.selectCustomValuesByType(CustomValueTypeEnum.Mitigation);
    
		this.mitigationsConfig$ = selectMitigations.pipe(
			map(custom => custom?.map(c => { return {id: c.id, value: c.name } as DropdownOption;})),
			map(custom => this.getMitigationsData(custom)),
      takeUntilDestroyed(this.destroyRef)
		);
	}

  private initDropdownOptions(): void {
    Object.values(RiskRateEnum).forEach(r => { 
      this.options.push({id: r, value: r}); 
    });
  }

  onSave() {
    const formValue = this.form.value;

    let inherentRisk = new FormControl(null);

    if(formValue.severity?.id && formValue.likelihood?.id) {
      inherentRisk = new FormControl(this.riskCalulationPipe.transform(formValue.severity?.id, formValue.likelihood?.id));
    }
    
    const riskForm = new FormGroup({
      id: new FormControl(formValue.id),
      type: new FormControl(formValue.type),
      name: new FormControl(formValue.name),
      likelihood: new FormControl(formValue.likelihood?.id ?? null),
      severity: new FormControl(formValue.severity?.id ?? null),
      mitigations: new FormControl(formValue.mitigations),
      comment: new FormControl(formValue.comment),
      accepted: new FormControl(formValue.accepted),
      residualRisk: new FormControl(formValue?.mitigations?.length > 0 ? RiskRateEnum.Low :  null),
      inherentRisk,
      isDefault: new FormControl(formValue.isDefault),
      assessmentPage: new FormControl(this.risk?.assessmentPage),
      createdDate: new FormControl(this.risk?.createdDate),
    });
    
    this.save.emit(riskForm);
  }
}
