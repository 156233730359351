import { Component, OnInit, ChangeDetectionStrategy, HostBinding, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AnimationStateEnum } from 'src/app/animations/animation-state.enum';
import { Animations } from 'src/app/animations/animations';
import { NgIf } from "@angular/common";

@Component({
	standalone: true,
	selector: 'mine-expansion-panel',
	templateUrl: './mine-expansion-panel.component.html',
	styleUrls: ['./mine-expansion-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations : [Animations.showHide()],
    imports: [NgIf]
})
export class MineExpansionPanelComponent implements OnInit {

	@Input()
	label: string;

	@Input()
	id: string = "input";

	@Input()
	isOpen: boolean = false;
	
	@Input() disableToggle: boolean = false;

    @Input() disableContent: boolean = false;

	state: AnimationStateEnum.Hide | AnimationStateEnum.Show;

	AnimationStateEnum = AnimationStateEnum;

	@ViewChild('panelContent', { static: true }) panelContent: ElementRef;
  
	@HostBinding('class.expanded')
	get expanded() {
		return this.state === AnimationStateEnum.Show;
	}

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.state = this.isOpen ? AnimationStateEnum.Show : AnimationStateEnum.Hide;
	}

	toggle() {
		if (!this.disableToggle) {
			this.state = this.state === AnimationStateEnum.Hide ? AnimationStateEnum.Show : AnimationStateEnum.Hide;
		}
	}

	// use it when u need to close the panel manualy from outside
	forceClose() {
		if (!this.panelContent) return;
		this.state = AnimationStateEnum.Hide;
		this.cdr.detectChanges();
	}

	forceOpen(): void {
		this.state = AnimationStateEnum.Show;
		this.cdr.detectChanges();
	}

	onAnimationStart(): void {
		if (this.panelContent) {
			this.panelContent.nativeElement.style.overflow = 'hidden';
		}
	}

	onAnimationDone(): void {
		if (this.panelContent && this.state === AnimationStateEnum.Show) {
			this.panelContent.nativeElement.style.overflow = 'unset';
		}
	}
}
