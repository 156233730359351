import { Component, ChangeDetectionStrategy, Input, forwardRef, ElementRef, HostListener, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList, HostBinding, ViewChild, Output, EventEmitter, DestroyRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, tap, fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';

import { MineHighlightSubStrDirective } from '../directives/mine-highlight-substring.directive';
import { MineHighlightDirective } from '../directives/mine-highlight.directive';
import { DropdownOption } from '../mine-dropdown/mine-dropdown.interface';
import { Animations } from 'src/app/animations/animations';

@Component({
	standalone: true,
	selector: 'mine-search-input',
	templateUrl: './mine-search-input.component.html',
	styleUrls: ['./mine-search-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [Animations.fadeInOut],
	imports: [CommonModule, ReactiveFormsModule, MineHighlightSubStrDirective, MineHighlightDirective],
  	providers: [{       
		provide: NG_VALUE_ACCESSOR, 
		useExisting: forwardRef(() => MineSearchInputComponent),
		multi: true     
  	}],
})
export class MineSearchInputComponent implements ControlValueAccessor, AfterViewInit {
	@Input() id: string; //unique id is a must
	@Input() required: boolean;
	@Input() placeholder: string;
	@Input() autocomplete: boolean;
	@Input() searchResults: boolean = true;
	@Input() options: DropdownOption[] = [];

	@Output() isFocused = new EventEmitter<boolean>(false);

	@ViewChildren('option') optionsList: QueryList<ElementRef>;
	@ViewChild('searchBar') searchBarInput: ElementRef;

	inputCtrl: FormControl<string> = new FormControl<string>('');
	filteredOptions: DropdownOption[] = [];
	selectedValue: DropdownOption;
	selectedOptionElement: ElementRef;
	listOpen = false;
	disabled = false;

	constructor(
		private elementRef: ElementRef,
		private cdr: ChangeDetectorRef,
		private destroyRef: DestroyRef,
	) { }

	@HostBinding('class.disabled')
	get disabledClass() {
		return this.disabled;
	}

	@HostListener('document:click', ['$event.target'])
	onClick(targetElement) {
		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.closeOptionsPanel();
		}
	}

	onPaste(event: ClipboardEvent): void {
		const value = event.clipboardData.getData('text/plain');
		this.value = {id: value, value} as DropdownOption;
		this.cdr.detectChanges();
	}

	onFocus(): void {
		this.isFocused.emit(true);
	}

	onFocusOut(): void {
		this.isFocused.emit(false);
	}

	ngAfterViewInit(): void {
		if (this.selectedValue) {
			this.inputCtrl.setValue(this.selectedValue.value);
		}

		if (this.autocomplete) {
			fromEvent<KeyboardEvent>(document, 'keydown').pipe(
				filter(() => this.listOpen), // apply only when panel is open
				filter((event) => event.key === 'Enter'),
				tap(() => this.onOptionChanged({ id: this.inputCtrl.value, value: this.inputCtrl.value } as DropdownOption)),
				tap(() => this.cdr.detectChanges()),
				takeUntilDestroyed(this.destroyRef)
			).subscribe();
		}

		this.inputCtrl.valueChanges.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(value => this.filterOptions(value?.toLowerCase())),
			map(value => !!value),
			tap(value => this.listOpen = value),
			tap(() => this.cdr.detectChanges()),
			tap(() => this.value = { id: this.inputCtrl.value, value: this.inputCtrl.value} as DropdownOption),
			tap(() => this.cdr.detectChanges()),
			takeUntilDestroyed(this.destroyRef)
		).subscribe();
	}

	filterOptions(value: string): void {
		if (value) {
			this.filteredOptions = this.options.filter(option => this.compareStrArrays(option.value?.split(' '), value?.split(' ')));
			this.cdr.detectChanges();
		}
		else if (!value && !this.value && !!this.selectedValue) {
			this.reset();
		}
	}

	private compareStrArrays(str1: string[], str2: string[]): boolean {
		return (str1.some(str => str2.some(s => !!str && !!s && str.toLowerCase().indexOf(s.toLowerCase()) > -1))) ||
			(str2.some(str => str1.some(s => !!str && !!s && str.toLowerCase().indexOf(s.toLowerCase()) > -1)));
	}

	onOptionChanged(selectedValue: DropdownOption): void {
		this.value = selectedValue;
		this.searchBarInput.nativeElement.value = selectedValue.value;
		this.closeOptionsPanel();
	}

	closeOptionsPanel() {
		this.listOpen = false;
		this.cdr.detectChanges();
	}

	//implementation of reactive form
	set value(val: DropdownOption) {
		if (!val?.id || !val?.value) {
			this.inputCtrl.reset();
		}
		
		this.selectedValue = val;
		this.onChange(val);
	}

	reset(): void {
		this.value = null;
		this.inputCtrl.reset();
		this.cdr.detectChanges();
	}

	onChange = (val: DropdownOption) => { };
	onTouch = (val: string) => { };

	writeValue(value: DropdownOption): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.cdr.detectChanges();
	}
}