import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';
import { ContentPipe } from 'src/app/services/content/content.pipe';

// common pipe for transforming id to name to activity fields that supports custom values [we are showing and working with names but server contract are Ids]
@Pipe({
  standalone: true,
  name: 'activityFieldIdToName'
})
export class ActivityFieldIdToNamePipe implements PipeTransform {

  constructor(
    private contentPipe: ContentPipe,
    private customValuesQuery: CustomValuesQuery,
  ) {}

  transform(id: string, field: CustomValueTypeEnum): string {
      // try to get it from custom values
      const customValue = this.customValuesQuery.getEntity(id?.trim());
      if (customValue) {
        return customValue.name.trim();
      }

      // not custom value, return the name from squidex. if list is not in squidex return the same value
      return this.getSquidexPredefinedValues(field).find(l => l.key === id)?.value ?? id;
  }

  private getSquidexPredefinedValues(field: CustomValueTypeEnum): KeyValue<string, string>[] {
    switch (field) {
      case CustomValueTypeEnum.LegalBasis:
        return this.contentPipe.transform('ropa.legalBasisList');
      case CustomValueTypeEnum.TransferMechanism:
        return this.contentPipe.transform('ropa.crossBorderList');
      case CustomValueTypeEnum.BusinessUnit:
        return []; // not in squidex
      default:
        return [];
    }
  }

}
