import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[autoSize]'
})
export class TextAreaAutoSizeDirective {

	constructor(private elm: ElementRef) { }

	@HostListener('input', ['$event'])
	onInput(event: any) {
		if(!this.elm.nativeElement.value) {
			this.elm.nativeElement.style.height = 'unset';
		}
		this.elm.nativeElement.style.height = (event.target.scrollHeight) + 'px';
	}
}