import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { produce } from 'immer';
import { CustomValue } from 'src/app/api/models/company-settings/custom-values.interface';
import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';

export interface CustomValuesState extends EntityState<CustomValue, string> { }

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'customValues',
	idKey: 'id',
	producerFn: produce
})
export class CustomValuesStore extends EntityStore<CustomValuesState> {

  constructor(
	private htmlDecodePipe: HtmlDecodePipe
  ) {
    super();
  }

  akitaPreAddEntity(customValue: CustomValue): CustomValue {
	return this.getDecodedCustomValue(customValue);
  }

  akitaPreUpdateEntity(oldCustomValue: CustomValue, newCustomValue: CustomValue): CustomValue {
	return this.getDecodedCustomValue(newCustomValue);
  }

  private getDecodedCustomValue(customValue: CustomValue): CustomValue {
	return {
		...customValue,
		name: this.htmlDecodePipe.transform(customValue.name),
	};
  }
}
