import { Pipe, PipeTransform } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { AiAssessmentTemplateEnum } from '../models/ai-assessments.enum';

@Pipe({
  standalone: true,
  name: 'aiAssessmentTemplateIcon'
})
export class AiAssessmentTemplateIconPipe implements PipeTransform {

  constructor(
    private contentPipe: ContentPipe,
    private featureFlagQuery: FeatureFlagQuery
  ) {}

  transform(type: string): string {
    let options = [...this.contentPipe.transform('ai-assessments.addNewTemplate.mine.options')];
    
    if (this.featureFlagQuery.getFlag(FeatureFlags.DevAIAssessmentsRopa)) {
      const paOption = this.contentPipe.transform('ai-assessments.paAssessmentTemplateOption');
      options = [...options, paOption];
    }

    if (!type?.length || !options?.length) 
      return 'icon-robot';
    
    const value = AiAssessmentTemplateEnum[type];
    const icon = options.find(option => option.type === value)?.icon;
    return icon ? `icon-${icon}` : 'icon-general';
  }
}
