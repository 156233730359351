import { AfterViewInit, ChangeDetectorRef, ContentChildren, DestroyRef, Directive, EventEmitter, inject, Input, OnChanges, Output, QueryList, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, Subject, tap } from 'rxjs';

import { MineSort } from './mine-sort.interface';
import { MineSortHeaderDirective } from './mine-sort-header.directive';
import { SortDirectionEnum } from '../sort-direction.enum';

@Directive({
  standalone: true,
  selector: '[mineSort]',
})
export class MineSortDirective implements AfterViewInit, OnChanges {
  @ContentChildren(MineSortHeaderDirective, {descendants: true})
  columns = {} as QueryList<MineSortHeaderDirective>;

  @Input('sortActive')
  active: string;

  @Input('sortDirection')
  direction: SortDirectionEnum = SortDirectionEnum.Asc;

  @Input('sortDisabled')
  disabled: boolean;

  @Output('sortChange')
  change = new EventEmitter<MineSort>();

  private columnsChanged = new Subject<void>();

  private destroyRef = inject(DestroyRef);

  constructor(
    private cdref: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes?.active || changes?.direction) && this.columns?.length) {
      this.setActiveColumn();
    }
  }

  ngAfterViewInit(): void {
    this.setActiveColumn();
    this.registerColumnsToSortEvent();

    this.columns.changes.pipe(
      tap(() => this.columnsChanged.next()),
      map(() => this.registerColumnsToSortEvent()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private registerColumnsToSortEvent(): void {
    this.columns.toArray().forEach(col => {
      col.setDisabled(this.disabled);
      col.sort.pipe(
        tap(col => this.active = col.name),
        tap(col => this.direction = col.getDirection()),
        map(() => this.setActiveColumn()),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
    });
  }

  private setActiveColumn(): void {
    if (!this.disabled && this.active) {
      setTimeout(() => {        
        const columns = this.columns.toArray();
        const activeColumn = columns?.find(col => col.name === this.active);
        columns.forEach(col => col.clear());
  
        if (activeColumn) {
          activeColumn.setActive(this.direction);
          this.change.emit({ active: this.active, direction: this.direction } as MineSort);
        }
        this.cdref.detectChanges();
      }, 0);
    }
  }
}
