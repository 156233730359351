
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { FeedbackStateEnum } from 'src/app/api/models/feedback/feedback.enum';
import { SystemUsage, UnverifiedSystem } from 'src/app/api/models/systems/systems.interface';
import { FilterCategory, FilterOption } from 'src/app/core/models/filtering.interface';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';
import { EmployeesCountFilterOptions, GeneralFilterOptions, OriginFilterOptions, RadarFilterGroups } from '../radar-table/radar-filters/radar-filters.enum';
import { RadarColumnKeys } from '../radar-table/radar-table.enum';
import { ConnectedEmployeesHelper } from '../shared/connected-employees/connected-employees.helper';
import { SystemUsageRate } from '../shared/system-usage/system-usage.enum';
import { SystemUsageHelper } from '../shared/system-usage/system-usage.helper';
import { UnverifiedSystemsState, UnverifiedSystemsStore } from './unverified-systems.store';
import { DataSourceOriginEnum } from 'src/app/api/models/systems/systems.enum';

const SPECIAL_ORIGIN_OPTIONS = {
	[OriginFilterOptions.OnlyEmail]: OriginFilterOptions.Email,
};

@Injectable({ 
	providedIn: 'root' 
})
export class UnverifiedSystemsQuery extends QueryEntity<UnverifiedSystemsState> {

	constructor(
		protected store: UnverifiedSystemsStore,
	) {
		super(store);
	}

	selectSystemsByFiltersAndSearchTerm(activeFilters: FilterCategory[], searchTerm: string, sort: MineSort = null, listFilter?: any): Observable<UnverifiedSystem[]> {
		// no filters & search term && sort
		if (activeFilters.length === 0 && searchTerm === '' && !sort) {
			return this.selectAll();
		}
		const selectedUsages = activeFilters.find(f => f.id === RadarFilterGroups.Usage)?.options ?? [];
		const selectedEmployeesCount = activeFilters.find(f => f.id === RadarFilterGroups.EmployeesCount)?.options ?? [];
		const selectedCategories = activeFilters.find(f => f.id === RadarFilterGroups.Category)?.options ?? [];
		const selectedFeedbacksStatus = activeFilters.find(f => f.id === RadarFilterGroups.General)?.options ?? [];
		const selectedOrigin = activeFilters.find(f => f.id === RadarFilterGroups.Origin)?.options ?? [];
		const selectedRecommended = listFilter === GeneralFilterOptions.Recommended;
		const selectedUknown = listFilter === GeneralFilterOptions.Unknown;

		// has filters or search term
		return this.selectAll({
			filterBy: [
				entity => this.filterByUsage(entity.systemUsage?.discoveryScore ?? 0, selectedUsages) && // filter by usage
						  this.filterByEmployeesCount(entity.connectedAccounts ?? 0, selectedEmployeesCount) && // filter by employees count
						  this.filterByCategories(entity.category, selectedCategories) && // filter by category
						  this.filterByFeedback(entity.generalFeedbackState, selectedFeedbacksStatus) &&
						  this.filterByOrigin(entity.dataSourceOrigin, selectedOrigin) &&
						  this.filterByRecommended(entity.dataSourceOrigin, entity.systemUsage, entity.connectedAccounts, selectedRecommended) &&
						  this.filterBySearchTerm(entity.name, searchTerm) &&
						  this.filterBySystemType(entity.systemType, listFilter, selectedUknown)
			],
			sortBy: (s1, s2) => sort?.active === RadarColumnKeys.DiscoveryDate ? this.sortByDiscoveryDate(s1, s2, sort.direction) :
								sort?.active === RadarColumnKeys.FeedbackStatus ? this.sortByFeedbackStatus(s1, s2, sort.direction) : 
								sort?.active === RadarColumnKeys.Category ? this.sortByCategory(s1, s2, sort.direction) : 
								sort?.active === RadarColumnKeys.DataSource ? this.sortByDataSourceName(s1, s2, sort.direction) : 
								sort?.active === RadarColumnKeys.Employees ? this.sortByConnectedEmployees(s1, s2, sort.direction) :
								sort?.active === RadarColumnKeys.Usage ? this.sortByUsage(s1, s2, sort.direction) : 
								sort?.active === RadarColumnKeys.DataSourceOrigin ? this.sortByDataSourceOrigin(s1, s2, sort.direction) : 1,
		});
	}

	private sortByDiscoveryDate(systemA: UnverifiedSystem, systemB: UnverifiedSystem, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Default ? 0 : 
			   direction === SortDirectionEnum.Asc ? 
			systemA.discoveryDate ? new Date(systemA.discoveryDate).getTime() - new Date(systemB.discoveryDate).getTime() : -1 :
			systemB.discoveryDate ? new Date(systemB.discoveryDate).getTime() - new Date(systemA.discoveryDate).getTime() : -1;
	}

	private sortByFeedbackStatus(systemA: UnverifiedSystem, systemB: UnverifiedSystem, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Default ? 0 : 
			   direction === SortDirectionEnum.Asc ? 
			systemA.generalFeedbackState.localeCompare(systemB.generalFeedbackState) :
			systemB.generalFeedbackState.localeCompare(systemA.generalFeedbackState);
	}

	private sortByDataSourceOrigin(systemA: UnverifiedSystem, systemB: UnverifiedSystem, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Default ? 0 : 
			   direction === SortDirectionEnum.Asc ? 
			systemA.dataSourceOrigin[0]?.localeCompare(systemB.dataSourceOrigin[0]) :
			systemB.dataSourceOrigin[0]?.localeCompare(systemA.dataSourceOrigin[0]);
	}

	private sortByCategory(systemA: UnverifiedSystem, systemB: UnverifiedSystem, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Default ? 0 : 
			   direction === SortDirectionEnum.Asc ? 
			systemA.category?.localeCompare(systemB.category) :
			systemB.category?.localeCompare(systemA.category);
	}

	private sortByDataSourceName(systemA: UnverifiedSystem, systemB: UnverifiedSystem, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Default ? 0 : 
			   direction === SortDirectionEnum.Asc ? 
			systemA.name.localeCompare(systemB.name) :
			systemB.name.localeCompare(systemA.name);
	}

	private sortByConnectedEmployees(systemA: UnverifiedSystem, systemB: UnverifiedSystem, direction: SortDirectionEnum): number {
		if (direction === SortDirectionEnum.Default) {
			return 0;
		}

		const aConnected = systemA.connectedAccounts ?? Number.NEGATIVE_INFINITY;
		const bConnected = systemB.connectedAccounts ?? Number.NEGATIVE_INFINITY;
		return direction === SortDirectionEnum.Asc ? (aConnected - bConnected) : (bConnected - aConnected);
	}

	private sortByUsage(systemA: UnverifiedSystem, systemB: UnverifiedSystem, direction: SortDirectionEnum): number {
		if (direction === SortDirectionEnum.Default) {
			return 0;
		}

		const aUsage = systemA.systemUsage?.discoveryScore ?? Number.NEGATIVE_INFINITY;
		const bUsage = systemB.systemUsage?.discoveryScore ?? Number.NEGATIVE_INFINITY;
		return direction === SortDirectionEnum.Asc ? (aUsage - bUsage) : (bUsage - aUsage);
	}

	private filterByRecommended(origins: DataSourceOriginEnum[], usageScore: SystemUsage, connectedAccounts: number, selected: boolean): boolean {
		if (!selected) {
			return true;
		}

		return this.isRecommeded(origins, usageScore, connectedAccounts);
	}

	private filterByFeedback(feedbackStatus: FeedbackStateEnum, selected: FilterOption[]): boolean {
		if (selected.length === 0) {
			return true;
		}
		return selected.filter(option => option.id === feedbackStatus)?.length > 0;

	}

	private filterByUsage(usageScore: number, selectedUsages: FilterOption[]): boolean {
		if (selectedUsages.length === 0) {
			return true;
		}

		const convertedUsageScore = SystemUsageHelper.convertScoreToUsageEnum(usageScore);
		return selectedUsages.filter(option => option.id === convertedUsageScore)?.length > 0;
	}

	private filterByOrigin(origins: DataSourceOriginEnum[], selected: FilterOption[]): boolean {
		const originOptions = origins.reduce((options, origin) => {
			const optionsForOrigin = this.updateOriginToFilteringTypes(origin);
			return [origin, ...options, ...optionsForOrigin];
		},[] as string[]);

		const regularOriginIds: string[] = selected.map(({ id }) => id);
		const exclusiveOriginIds: string[] = selected.filter(({ id }) => SPECIAL_ORIGIN_OPTIONS[id]).map(({ id }) => SPECIAL_ORIGIN_OPTIONS[id]);
		const hasRegularSelection = regularOriginIds.some(id => originOptions.find(option => option.includes(id)));
		const hasExclusiveSelection = exclusiveOriginIds.some(id => originOptions.find(option => option.includes(id))) && 
			!hasRegularSelection &&
			origins.length === 1;

		return selected.length === 0 ||
			hasRegularSelection ||
			hasExclusiveSelection;
	}

	private filterByEmployeesCount(connectedAccounts: number, selectedEmployeesCount: FilterOption[]): boolean {
		if (selectedEmployeesCount.length === 0) {
			return true;
		}

		const convertedConnectedAccounts = ConnectedEmployeesHelper.convertEmployeesCountToEnum(connectedAccounts);
		return selectedEmployeesCount.filter(option => option.id === convertedConnectedAccounts)?.length > 0;
	}

	private filterByCategories(category: string, selectedCategories: FilterOption[]): boolean {
		if (selectedCategories.length === 0) {
			return true;
		}

		return selectedCategories.filter(option => option.label === category)?.length > 0;
	}

	private filterBySearchTerm(systemName: string, searchTerm: string): boolean {
		if (searchTerm === '') {
			return true;
		}

		return systemName.toLowerCase().startsWith(searchTerm.toLowerCase());
	}

	private filterBySystemType(systemType: string, systemTypeToFilter: string, selected: boolean): boolean {
		if (!selected) {
			return true;
		}
		return systemType === systemTypeToFilter;
	}

	selectCountCategory(categoryName: string): Observable<number> {
		return this.selectCount( ({category}) => category === categoryName);
	}

	selectCountOrigin(origin: DataSourceOriginEnum): Observable<number> {
		return this.selectCount( ({dataSourceOrigin} ) => {
			return dataSourceOrigin.some(dsOrigin => this.updateOriginToFilteringTypes(origin).includes(dsOrigin));
		});
	}

	selectCountSystemType(systemTypeToCount: string): Observable<number> {
		return this.selectCount( ({systemType}) => systemType === systemTypeToCount);
	}

	private updateOriginToFilteringTypes(origin: DataSourceOriginEnum): DataSourceOriginEnum[] {
		switch (origin) {
			case DataSourceOriginEnum.Email:
				return [DataSourceOriginEnum.Email];
			case DataSourceOriginEnum.Sso:
				return [DataSourceOriginEnum.Sso, DataSourceOriginEnum.GoogleSso, DataSourceOriginEnum.OktaSso, DataSourceOriginEnum.EntraSso];
			case DataSourceOriginEnum.Cloud:
				return [DataSourceOriginEnum.Cloud, DataSourceOriginEnum.AwsCloud, DataSourceOriginEnum.GcpCloud];
			default:
				return [origin]; 
		}
	}
	
	selectCountRecommended(): Observable<number> {
		return this.selectCount( ({dataSourceOrigin, systemUsage, connectedAccounts }) => this.isRecommeded(dataSourceOrigin, systemUsage, connectedAccounts));
	}
	
	getCountRecommended(): number {
		return this.getCount(({ dataSourceOrigin, systemUsage, connectedAccounts }) => this.isRecommeded(dataSourceOrigin, systemUsage, connectedAccounts));
	}

	private isRecommeded(dataSourceOrigin: DataSourceOriginEnum[], systemUsage: SystemUsage, connectedAccounts: number): boolean {
		const originsToInclude: string[] = Object.values(OriginFilterOptions);

		return originsToInclude.some((origin: DataSourceOriginEnum) => dataSourceOrigin.includes(origin) && (
			!systemUsage || SystemUsageHelper.convertScoreToUsageEnum(systemUsage.discoveryScore) === SystemUsageRate.High ||
			!connectedAccounts || ConnectedEmployeesHelper.convertEmployeesCountToEnum(connectedAccounts) ===  EmployeesCountFilterOptions.Many
		));
	}

	selectCountUsage(usage: SystemUsageRate): Observable<number> {
		return this.selectCount( ({ systemUsage }) => SystemUsageHelper.convertScoreToUsageEnum(systemUsage.discoveryScore) === usage);
	}

	selectCountEmployees(employeesCount: EmployeesCountFilterOptions): Observable<number> {
		return this.selectCount( ({ connectedAccounts }) => ConnectedEmployeesHelper.convertEmployeesCountToEnum(connectedAccounts) === employeesCount);
	}

	selectCountWithFeedback(): Observable<number> {
		return this.selectCount( ({ generalFeedbackState }) => [FeedbackStateEnum.Pending, FeedbackStateEnum.Responded].includes(generalFeedbackState));
	}

	getMany(systemsIds: string[]): UnverifiedSystem[] {
		const systemsIdsSet = new Set(systemsIds);
		return this.getAll({
			filterBy: ({ systemId }) => systemsIdsSet.has(systemId),
		});
	}
}
